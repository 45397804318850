import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as Strings from 'resources/strings';
import ActivityInfo from 'components/pages/Missions/components/ActivityInfo';
import MissionDetails from 'components/pages/Missions/components/MissionDetails';
import Button from 'components/common/Button/Button';
import { affiliateLink, productPurchase } from 'utils/global-constants';
import { ButtonWrapper } from './style';

function MissionsLanding({
  isMissionComplete,
  missionsData,
  startMissions,
  isButtonLoading,
  analyticsEventHandler,
  buttonText,
  isDisabled,
}) {
  const params = useLocation();
  const currentFlow = params.pathname.includes('progress')
    ? 'mission progress'
    : 'missions';
  // `getButtonText` returns buttonText based on mission's verificationMechanism
  const getButtonText = () => {
    // if the mission have any affiliateLink then show 'Go to partner page' in the CTA
    if (missionsData.activity.verificationMechanism === affiliateLink) {
      return (
        missionsData.affiliate?.affiliateLinkText ?? Strings.goToPartnerPage
      );
    }
    // else show 'start mission'
    return Strings.startMission;
  };

  return (
    <>
      <MissionDetails missionsData={missionsData} isMissionComplete={isMissionComplete} />
      {!isMissionComplete && missionsData.activity.verificationMechanism === productPurchase
      && missionsData.productCheckoutUrl != null && (
        <Route path='/missions/:id/' exact>
          <ButtonWrapper>
            <Button
              title={Strings.buyNowCTALabel}
              onClick={() => startMissions(missionsData, Strings.buyNowCTALabel)}
              isLoading={isButtonLoading}
              disabled={isButtonLoading || isDisabled}
            />
          </ButtonWrapper>
        </Route>
      )}
      <ActivityInfo
        isMissionComplete={isMissionComplete}
        missionsData={missionsData}
        analyticsEventHandler={analyticsEventHandler}
        flow={currentFlow}
      />
      {!isMissionComplete && missionsData.activity.verificationMechanism !== productPurchase && (
        <Route path='/missions/:id/' exact>
          <ButtonWrapper>
            <Button
              title={buttonText ?? getButtonText()}
              onClick={() => startMissions(missionsData, getButtonText())}
              isLoading={isButtonLoading}
              disabled={isButtonLoading || isDisabled}
            />
          </ButtonWrapper>
        </Route>
      )}
    </>
  );
}

export default MissionsLanding;

MissionsLanding.propTypes = {
  isMissionComplete: PropTypes.bool,
  missionsData: PropTypes.shape(PropTypes.any),
  startMissions: PropTypes.func,
  isButtonLoading: PropTypes.bool,
  analyticsEventHandler: PropTypes.func,
  buttonText: PropTypes.string,
  isDisabled: PropTypes.bool,
};
