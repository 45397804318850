import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const MissionType = styled.div`
  font-family: ${(props) => (props.theme.fontFamily.robotoBold)};
  margin-top: 6px;
  margin-bottom: 8px;
  font-size: ${(props) => props.theme.fontSize.primary};
  line-height: 16px;
  height: 48px;
  display: flex;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => (props.disabled ? props.theme.fontColor.carbonGrey : props.theme.fontColor.carbonBlack)};
`;

export const CurrentCompleted = styled(Typography)`
&&{
    font-size: ${(props) => props.theme.fontSize.description};
    line-height: 14px;
}
`;

export const ImageWrapper = styled.div`
    margin-bottom: 8px;
    height: 94px;
`;

export const Image = styled.img`
    height:100%;
    width: 100%;
    border-radius: 4px;
`;

export const MissionWrapper = styled.div`
    margin: 16px 12px 58px 0px;
    display: inline-block;
    width: 100px;
    height: 180px;
`;

export const ScrollWrapper = styled.div`
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    top: 20px;
    position: relative;
    ::-webkit-scrollbar {
      display: none;
    }
    scroll-behavior: smooth;
  }
`;

export const Subtitle = styled.div`
    margin-bottom: 16px;
`;

export const Description = styled(Typography)`
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 21px;
`;

export const CarbonSavingWrapper = styled.div`
    margin: 40px 26px 19px 26px;
`;

export const MissionStatusWrapper = styled.div`
    background-color: ${(props) => props.theme.backgroundColor.white};
    border-radius: 5px;
    margin: 0px 24px 40px 24px;
    padding: 16px 26px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);

`;

export const StatusWrapper = styled.div`
    color: ${(props) => props.theme.fontColor.black};
    background-color: ${(props) => props.theme.backgroundColor.dodgerBlue};
    margin: 0px 12px 16px 25px;
`;

export const StatusImageWrapper = styled.div`
    height: 28px;
    width: 28px;
    margin-bottom: 7px;
`;

export const Status = styled(Typography)`
    font-size: ${(props) => props.theme.fontSize.description};
    line-height: 14px;
    text-align: center;
    margin-top: 8px;
`;
