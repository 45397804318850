// This is the file wich conatins all the constants which are used globally.
export const mUsers = 'mUsers';
export const mUserMissions = 'mUserMissions';
export const status = 'status';
export const completedMissionsRefs = 'completedMissionsRefs';
export const missions = 'missions';
export const allMissions = 'all-missions';
export const missionReward = 'missionReward';
export const rewardId = 'reward-id';
export const linkedMissionsReward = 'linkedMissionsReward';
export const referrals = 'referrals';
export const currentMission = 'currentMission';
export const newMission = 'newMission';
export const abandoned = 'abandoned';
export const current = 'current';
export const completed = 'completed';
export const affiliateLink = 'affiliateLink';
export const promoCode = 'promoCode';
export const referralLink = 'referralLink';
export const productPurchase = 'productPurchase';
export const sessionId = 'sessionId';
export const statusNew = 'new';
export const productMission = 'productMission';
