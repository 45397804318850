import React from 'react';
import PropTypes from 'prop-types';

import { productActionTitle } from 'resources/strings';
import { Title, YoutubeLinkWrapper } from './style';

/// `ProductAction` renders UI for youtube video on missions page
const ProductAction = ({ videoLink }) => (
  <>
    <Title>{productActionTitle}</Title>
    <YoutubeLinkWrapper>
      <iframe src={videoLink} title={productActionTitle} />
    </YoutubeLinkWrapper>
  </>
);

ProductAction.propTypes = {
  videoLink: PropTypes.string,
};

ProductAction.defaultProps = {
  videoLink: '',
};

export default ProductAction;
