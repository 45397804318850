import * as Strings from 'resources/strings';
import * as constants from 'utils/Referrals/flow_constants';

// `isRewardExhausted` to check if the rewards exhausted or not
export const isRewardExhausted = (referalData) => referalData?.completed === referalData?.total;

// `getLoginStatus` to get the current state of the referral reward
export const getLoginStatus = (message, referrerDoc) => {
  switch (message) {
    case constants.referralLoginSuccess:
      return {
        title: Strings.congratulations,
        subtitle: Strings.loginSuccessMessage,
        desc: referrerDoc?.referralReward?.message,
        buttonText: Strings.getStartedLabel,
      };
    case constants.rewardsExhausted:
      return {
        title: Strings.Sorry,
        subtitle: Strings.rewardsExhaustedMessage,
        desc: Strings.referralErrorText,
        buttonText: Strings.getStartedLabel,
      };
    case constants.referralInvalidLink:
      return {
        title: Strings.Oops,
        subtitle: Strings.invalidReferralMessage,
        desc: Strings.referralErrorText,
        buttonText: Strings.continueAdvaMissionLabel,
      };
    default:
      return {
        title: Strings.Oops,
        subtitle: Strings.accountExistMessage,
        desc: Strings.referralErrorText,
        buttonText: Strings.continueAdvaMissionLabel,
      };
  }
};
