import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAllMissions } from 'provider/dashboard-provider';

import SadSmiley from 'assets/images/sad_smiley.svg';
import ViewEarnedRewards from 'components/pages/EarnedRewards/components/ViewEarnedRewards';
import ResponseState from 'components/common/ResponseState';
import * as Strings from 'resources/strings';
import Loader from 'components/common/Loader/Loader';

/**
 *
 * `EarnedRewardsContainer` returns the container for ViewEarnedRewards component
 */
const EarnedRewardsContainer = () => {
  const {
    userEarnedRewards,
    rewardsCollected,
    isRewardsLoading,
    rewardsCollectedLoader,
    getRewardsCollected,
  } = useAllMissions();
  const history = useHistory();

  useEffect(() => {
    getRewardsCollected();
  }, [Object.keys(userEarnedRewards).length]);

  return isRewardsLoading || rewardsCollectedLoader ? (
    <Loader />
  ) : (
    <>
      {Object.keys(rewardsCollected)?.length > 0 ? (
        <ViewEarnedRewards rewardsData={rewardsCollected} />
      ) : (
        <ResponseState
          image={SadSmiley}
          description={Strings.rewardsEmptyText}
          buttonText={Strings.goToDashboard}
          onClick={() => history.push('/')}
          isEmptyState
        />
      )}
    </>
  );
};

export default EarnedRewardsContainer;
