/// Strings being used in every modules can be defined here and can be used as
///  `<div>{DefinedString}<div>`

/// `PreLoginDashboard` strings to be displayed in PreLoginDashboard component
export const explorePopularTitle = 'Explore popular Adva Missions';
export const footerTitle = 'To make a bigger Impact & earn rewards daily...';
export const loginToAdvaLabel = 'Login to Adva Missions';

/// `Login` strings to be displayed in Login component
export const changeNumberDesc = 'Not you? Change your phone number';
export const welcomeToMissions = 'Welcome to Adva Missions! ';
export const welcomeDesc = 'Adva Missions is a feature within Adva where you can undertake tasks that promote eco-friendly living and reduce your carbon emissions. Each mission provides you with sustainable alternatives to products you use everyday. Get access to exclusive offers from our partners for a variety of eco products and nurture green habits along the way. What’s more, there are some missions with cashback offers so look out for them!';
export const featureDesc = 'What is Adva? Adva is an education and action-oriented app designed to create a global environmental movement through collective individual action. Click on the globe on the top right to learn more.';
export const signupDesc = 'Signing up for Adva Missions is easy. Simply key in your phone number below and enter the OTP to get started. Start your Adva Missions journey today and act against climate change!';
export const loginDesc = 'Please login using your phone number and an OTP to get started.';
export const joinCommunity = 'Join the Adva Community to get rewarded for leading a sustainable lifestyle. ';
export const sendOTP = 'Send OTP';
export const resendOTP = 'Resend OTP';
export const resendOTPIn = 'Resend OTP in';
export const secs = 'secs';
export const refreshPage = 'refresh page';
export const verifyPhoneNumber = 'Verify Phone Number';
export const request = 'Please';
export const startOver = 'to start over.';
export const phoneNumberPrefix = '+65';
export const privacyPolicy = 'Privacy Policy';
export const termsAndConditions = 'Terms and Conditions';
export const cookiePolicy = 'Cookie Policy';

/// `Dashboard` strings to be Dashboard in page not found component
export const userStatsTitle = 'Your journey in a nutshell';
export const otherMissionsTitle = 'Other missions';
export const aboutAdva = "If you wish to learn more about Adva , click on the globe at the top right to go to Adva's website.";
export const whatIsAdva = 'Adva Missions is an initiative by Adva which enables you to get rewarded as you complete tasks. We have various missions available, try them out today and see which resonates with you most!';
export const startMissionsDesc = 'No ongoing missions. Let’s embark on one';
export const stayTunedDesc = 'Stay tuned for new missions';
export const rewardsCollectedTitle = 'Rewards Collected';
export const rewardsCollectedDesc = 'See all the coupons you have earned';
export const yourWallet = 'Your wallet';
export const ecoPoints = 'Eco points';
export const redeemEcoPoints = 'Redeem Ecopoints';
export const credited = 'Credited';
export const pendingDebit = 'Pending Debit';
export const debited = 'Debited';
export const areYouSure = 'Are you sure you want to continue?';
export const yes = 'Yes';
export const no = 'No';
export const view = 'View';
export const dashboard = 'Dashboard';
export const advaMissionsLabel = 'Welcome to Adva Missions!';

/// `AppBar` strings to be Dashboard in page not found component
export const notificationsNone = 'Oops! Currently there are no notifications to be displayed!';
export const advaMissions = 'Adva Missions';
export const futureText = 'The future in our hands';
export const notificationsTurnOffDesc = 'Do not want SMS notifications?';
export const notificationsTurnOnDesc = 'Allow SMS notifications?';
export const turnOff = 'Turn off';
export const turnOn = 'Turn on';

/// `Mission` strings to be displayed in Mission component
export const timesCompletedDesc = 'Times completed';
export const timesDesc = 'Times';
export const continueDesc = 'Continue';
export const start = 'Start';
export const completed = 'Completed';
export const missionDesc = 'Mission';
export const schedule = 'Schedule';
export const dismiss = 'Dismiss';
export const startMission = 'Start Mission';
export const smsReminderTitle = 'Set an SMS reminder';
export const smsReminderDesc = 'Let us help you keep on track. Tell us when you’d like to be reminded next.';
export const makeProgressDesc = 'Let’s make progress on the ongoing missions';
export const completedRelatedMissionText = 'Awesome job, you\'ve completed all your missions in this quest, we\'re re-directing you to your dashboard to find other interesting missions to choose.';
export const viewReward = 'View Reward';
export const verifyMissionProgress = 'Verify Mission Progress';
export const goToPartnerPage = 'Go to partner page';
export const viewMissionlabel = 'View Mission';
export const continueMission = 'Continue Mission';
export const missionCompletedStatus = 'Mission Completed';
export const rewardCollectedLabel = 'Reward Collected';
export const missionLimitReachedText = 'You have reached maximum number of ongoing missions. Complete them to start a new one';
export const singaporeDollar = 'SGD $';
export const customerTestimonialsTitle = 'Customer Testimonials';
export const closeLabel = 'close';
export const priceLabel = 'Price';
export const expectedDeliveryLabel = 'Expected Delivery';
export const withinSingapore = ' (within Singapore)';
export const boughtLabel = 'Bought';
export const inTheLastText = 'in the last ';
export const sevenDays = '7 days.';
export const timesLabel = 'times';
export const DetailsTitle = 'Details';
export const ImpactTitle = 'Impact';
export const StepsTitle = 'Steps';

/// `UploadBill` strings to be displayed in UploadBill component
export const upload = 'Upload';
export const confirmBillDesc = 'Confirm bill upload';
export const previewBillDesc = 'Check the bill image before sending it for verification';
export const reupload = 'Reupload';
export const submitBill = 'Submit Bill';
export const codeInvalidErrorMessage = 'The entered CODE is invalid. Please re-enter the CODE.';
export const imageUploaded = 'Image uploaded successfully !';
export const progressVerified = 'Progress verified successfully !';
export const redo = 'Redo';
export const unsupportedFileType = 'Unsupported file type !';
export const reUploadBill = 'Reupload Bill';

/// `PageNotFound` strings to be displayed in page not found component
export const pageNotFoundTitle = 'Page not found';
export const goToHomepage = 'Go to Homepage';
export const pageNotFoundDesc = 'We’re sorry, the page you requested could not be found. Please go back to dashboard';

/// `EarnedRewards` strings to be displayed in EarnedRewards component
export const lostRewards = 'No Rewards Earned';
export const earnedDesc = 'Earned';
export const yourOffer = 'Your Offer';
export const goToDashboard = 'Go to Dashboard';
export const congratulations = 'Congratulations!';
export const earnedRewardDesc = 'You have earned yourself a reward for';
export const helpingDesc = 'and helped the planet while doing that..';
export const splashScreenTitle = 'While you were away..';
export const viewNextRewardLabel = 'View next reward';
export const continueAdvaMissionLabel = 'Continue to Adva Missions';
export const copyCodeLabel = 'Copy voucher code';
export const rewards = 'rewards';
export const viewAllRewards = 'View all rewards';
export const completedRewardsText = 'You have completed this mission series';
export const missionSeriesTitle = 'Linked Mission Series';
export const rewardsEmptyText = 'You have not yet collected any rewards! Complete a mission and find your reward collected here!';

/// `LinkedMissions` strings to be displayed in LinkedMissions component
export const linkedMissionsTitle = 'Your chance to UPSIZE your reward';
export const linkedMissionsDesc = 'Complete all the missions in the series below to win even bigger rewards!';
export const completePrevMission = 'Complete the previous mission to start this mission';
export const ongoingSeriesTitle = 'Ongoing Mission Series ';
export const viewAllLabel = 'View all';
export const viewLessLabel = 'View less';

/// 'snackbar text'
export const errorMessage = 'Something went wrong. Please try again!';
export const copyCodeSuccessMessage = 'Coupon code copied!';

/// 'All Mission category'
export const allCategoryLabel = 'All Categories ';
export const missions = 'Missions';
export const mission = 'Mission';
export const missionsYouMightLike = 'Mission you might like';

/// strings to be displayed on `All Missions` page
export const allMissions = 'All Missions';
export const showMore = 'Show More';
export const viewMissionsText = 'You are viewing';

/// 'Find missions' strings to be displayed in FindMissions component;
export const findMissionsTitle = 'Find missions based on categories';

/// Empty state text for missions
export const missionsEmptyText = 'You have completed all the missions here!';

/// about adva missions
export const aboutMissionsTitle = 'What’s Adva Missions?';
export const aboutMisssionsDesc = 'Read more about our initiative and partners.';

/// strings to be displayed on congratulations page
export const referralCardAvailabilty = 'Your referral reward is now available in the';
export const sectionInfo = 'section in the dashboard';
export const rewardReferralText = 'Referral Reward';

/// Strings to be displayed in `Referral Reward` page
export const loginSuccessMessage = 'Login successful!';
export const getStartedLabel = 'Get started';
export const referralErrorText = 'Don\'t worry you can still earn **many rewards** by **completing missions!**';
export const accountExistMessage = 'Account already exists!';
export const rewardsExhaustedMessage = 'All the referral rewards are exhausted!';
export const invalidReferralMessage = 'Invalid referral link!';
export const Sorry = 'Sorry!';
export const Oops = 'Oops!';

// referral
export const referralLinkText = 'Refer Now';
export const referFriendLabel = 'Refer a friend';
export const continueToMissionCTALabel = 'Continue mission';
export const inviteText = 'You have already invited ';
export const friends = ' friends';
export const inviteFriendstext = 'Invite as many as ';
export const multipyReward = ' to multiply your reward';
export const shareReferralTitle = 'Referral for Adva Missions';
export const shareReferralText = 'Hey, create an account on AdvaMissions using my referral link and earn a reward';

// Product Action
export const productActionTitle = 'See the product in action';
// Buy a Product
export const buyNowCTALabel = 'Buy Now';
export const redirectingCTALabel = 'Redirecting...';
export const processingText = 'Kindly wait while we are receiving your payment confirmation.';
export const refreshText = 'Please do not refresh.';
export const paymentFailed = 'Payment Failed!';
export const backToMissionCTALabel = 'Back to Mission';
export const paymentFailedDesc = 'If the payment was debited from your account, kindly reach out to us at ';
export const olamSupportEmail = 'support@olam.com';

// mission blogs
export const missionBlogsTitle = 'Learn more about sustainability';
export const writtenByLabel = 'Written by:';
export const blogShareText = 'Hey, you might find this article on sustainability interesting. Check it out here.';
export const blogShareTitle = 'Adva Missions Sustainability Article';
export const blogPostCountLabel = 'blog posts';
export const blogEmptyStateText = 'Sorry! We are unable to find this article at the moment. Please try again later or click on another article to view it!';
