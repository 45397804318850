import styled from 'styled-components';

export const RewardContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.backgroundColor.grayishOrange};
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  min-height: 106px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    min-height: 82px;
  }
`;

export const ImageWrapper = styled.div`
  width: 69px;
  height: 73px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 53px;
    height: 56px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const OfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 5px 0 3px 31px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 4px 0 4px 16px;
  }
`;

export const OfferTitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily.robotoRegular};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  font-size: ${(props) => props.theme.fontSize.secondary};
  line-height: 23px;
  letter-spacing: 0.32px;
  margin-bottom: 4px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.description};
    line-height: 18px;
    letter-spacing: 0.25px;
    margin-bottom: 2px;
  }
`;

export const OfferText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.robotoRegular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  font-size: ${(props) => props.theme.fontSize.large};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.tertiary};
    line-height: 28px;
  }
`;
