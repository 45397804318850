import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  getMissionDetails,
  getUserMission,
  getUserActivities,
  getLinkedMission,
  getAllMissions,
} from 'services/Missions/missions-service';

const MissionsContext = createContext();

export const useMissions = () => useContext(MissionsContext);

export const MissionsProvider = ({ children }) => {
  const history = useHistory();
  const [allMissions, setAllMissions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [missionsData, setMissionsData] = useState({});
  const [isMissionLoading, setIsMissionLoading] = useState(true);
  const [userMissions, setUserMission] = useState({});
  const [userActivities, setActivities] = useState({});
  const [linkedMissions, setLinkedMissions] = useState([]);
  const [linkedMissionIds, setLinkedMissionIds] = useState();
  const [linkedMissionLoader, setLinkedMissionLoader] = useState(true);

  // `fetchAllMissions` fetches all the missions data response from getAllMissions
  const fetchAllMissions = async () => {
    const allMissionsData = {};
    const allMissionsResponse = await getAllMissions();
    allMissionsResponse.data.forEach((data) => {
      allMissionsData[data.id] = { id: data.id, mission: data };
    });
    setAllMissions({ ...allMissionsData });
  };

  const fetchMissionsData = async (id) => {
    setIsLoading(true);
    const response = await getMissionDetails(id);
    if (response === 'Not Found') {
      history.push('/');
    } else {
      setMissionsData(response.data);
      setIsLoading(false);
    }
  };
  /// [fetchUserMissions] fetches the MUserActivities and MUserMissions
  const fetchUserMissions = async (userId, userMissionId) => {
    setIsMissionLoading(true);
    const response = await getUserMission(userId, userMissionId);
    setUserMission(response);
    if (response.status !== 'abandoned' && response.activities.length) {
      const activities = getUserActivities(userId, response.activities);
      activities.onSnapshot((snapshot) => {
        setIsMissionLoading(true);
        const snap = userActivities;
        snapshot.docChanges().forEach((activityDoc) => {
          const activity = activityDoc.doc.data();
          snap[activity.id] = activity;
        });
        setActivities(snap);
        setIsMissionLoading(false);
      });
    } else {
      setIsMissionLoading(false);
    }
  };

  // 'fetchLinkedMissionsData' fetches linked missions by mission id
  const fetchLinkedMissionsData = async (ids) => {
    const linkedMissionData = [];
    const response = await getLinkedMission(ids);
    if (!response.error) {
      response.forEach((mission) => {
        const sortedMissions = [];
        mission.missionsOrder.forEach((element) => {
          sortedMissions.push(allMissions[element.mission.id].mission);
        });
        linkedMissionData.push([...sortedMissions, mission.reward]);
      });
      setLinkedMissions(linkedMissionData);
      if (response.length > 0) {
        // set the linked mission ids
        const missionIds = response[0].missionsOrder.map((missions) => missions.mission.id);
        setLinkedMissionIds([...missionIds]);
      } else {
        setLinkedMissionIds([]);
      }
    }
    setLinkedMissionLoader(false);
  };

  useEffect(() => {
    // to fetch all the missions
    fetchAllMissions();
  }, []);

  const value = {
    isLoading,
    missionsData,
    isMissionLoading,
    userMissions,
    userActivities,
    linkedMissions,
    linkedMissionLoader,
    linkedMissionIds,
    allMissions,
    setLinkedMissionLoader,
    fetchMissionsData,
    fetchUserMissions,
    setIsLoading,
    fetchLinkedMissionsData,
  };

  return (
    <MissionsContext.Provider value={value}>
      {children}
    </MissionsContext.Provider>
  );
};

MissionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
