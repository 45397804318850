import { createGlobalStyle } from 'styled-components';

/**
 * GlobalStyle contains common styles of the app.
 */
const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.fontFamily.robotoRegular};
  }
`;

export default GlobalStyle;
