import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as Strings from 'resources/strings';
import * as constants from 'utils/Analytics/flow-constants';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import { checkPath } from 'utils/Missions/utils';

import MissionCard from 'components/pages/Dashboard/components/MissionCard';
import {
  ScrollWrapper,
} from 'components/pages/Dashboard/style';
import LinkedMissionProgress from 'components/common/LinkedMissionProgress';
import RewardCard from '../../RewardCard';
import { RewardCardWrapper, MissionCardWrapper } from './style';

/**
  * [LinkedMissionSeries] to Display the list of Mission card series
  * @param {linkedMissions} list of linked mission
  * @returns JSX
  * <LinkedMissionSeries />
  */
function LinkedMissionSeries({
  linkedMissions,
  ongoingMissions,
  completedMissions,
  startMissions,
  isReward,
}) {
  const scrollWrapperRef = useRef();
  const missionCardRef = useRef();
  const params = useParams();
  const [completedMissionIds, setCompletedMissionIds] = useState([]);
  const [ongoingMissionIds, setOngoingMissionIds] = useState([]);
  const [completedIndex, setCompletedIndex] = useState([]);
  const [ongoingIndex, setOngoingIndex] = useState([]);
  const { initiateTracking } = useAnalytics();

  useEffect(() => {
    const completedIds = Object?.keys(completedMissions);
    const ongoingIds = Object?.keys(ongoingMissions);
    setCompletedMissionIds(completedIds);
    setOngoingMissionIds(ongoingIds);
  }, [Object.keys(ongoingMissions).length]);

  // In order to show the status of the progress bar we need
  // index of completed and ongoing linked missions
  useEffect(() => {
    // To get the completed missions index
    // in order to show the status of completed missions in progress bar
    const completedMissionsData = [];
    Object.keys(linkedMissions).forEach((key, index) => {
      // returns the completed linked missions index
      if (completedMissionIds?.includes(
        linkedMissions[key]?.id?.toString(),
      )) {
        completedMissionsData.push(index);
      }
    });
    setCompletedIndex(completedMissionsData);
  }, [completedMissionIds.length]);

  useEffect(() => {
    // To get the ongoing missions index
    // in order to show the status of ongoing missions in progress bar
    const ongoingMissionsData = [];
    Object.keys(linkedMissions).forEach((key, index) => {
      // returns the ongoing linked missions index
      if (ongoingMissionIds?.includes(
        linkedMissions[key]?.id?.toString(),
      )) {
        ongoingMissionsData.push(index);
      }
    });
    setOngoingIndex(ongoingMissionsData);
  }, [ongoingMissionIds.length]);

  useEffect(() => {
    // In order to scroll to left and to show the
    // next successive mission available
    scrollWrapperRef.current.scrollLeft = completedIndex.length
      * missionCardRef?.current?.clientWidth;
  }, [
    scrollWrapperRef?.current?.clientWidth,
    completedIndex.length,
  ]);

  // `getStatus` to get the status
  const getStatus = (mission) => {
    let status = '';
    if (params.missionId === (mission?.id).toString()
      && !ongoingMissionIds?.includes(mission?.id.toString()) && !isReward) {
      status = Strings.startMission;
    } else if (completedMissionIds?.includes(mission?.id.toString())) {
      status = Strings.completed;
    } else if (ongoingMissionIds?.includes(mission?.id.toString())) {
      status = Strings.continueMission;
    } else {
      status = Strings.viewMissionlabel;
    }
    return status;
  };

  // `isDisabled` to check the disabled status
  const isDisabled = (prevMission) => completedMissionIds?.includes(
    prevMission?.id.toString(),
  );

  // `getCurrentPage` to get the current page
  const getCurrentPage = () => {
    let currentPage = constants.dashboard;
    if (isReward) {
      currentPage = constants.missionReward;
    } else if (checkPath('/progress')) {
      currentPage = constants.missionProgress;
    } else if (params.missionId) {
      currentPage = constants.missions;
    }
    return currentPage;
  };

  // `getEventName` to get the event name
  const getEventName = (status) => {
    switch (status) {
      case Strings.startMission:
        return constants.startLinkedMissionsAnalytics;
      case Strings.viewMissionlabel:
        return constants.viewLinkedMissionAnalytics;
      case Strings.continueMission:
        return constants.continueLinkedMissionAnalytics;
      default:
        return constants.startLinkedMissionsAnalytics;
    }
  };

  // 'linkedmissionsAnalyticsEventHandler` analytics event handler for linked mission
  const linkedmissionsAnalyticsEventHandler = (
    eventLabel, status,
  ) => {
    initiateTracking({
      eventName: getEventName(status),
      eventCategory: constants.navigation,
      eventAction: getCurrentPage(),
      eventLabel: `${eventLabel}`,
    });
  };

  return (
    <>
      <ScrollWrapper ref={scrollWrapperRef}>
        {linkedMissions
          && linkedMissions.map((mission, index) => (
            (index < linkedMissions.length - 1) ? (
              <MissionCardWrapper ref={missionCardRef}>
                <MissionCard
                  title={mission?.title}
                  image={mission?.missionImage?.url}
                  linkedMissionflag={false}
                  status={getStatus(mission)}
                  isCompleted={completedMissionIds?.includes(
                    mission?.id.toString(),
                  )}
                  isDisabled={!isDisabled(linkedMissions[index - 1]) && index > 0}
                  isContinue={ongoingMissionIds?.includes(mission?.id.toString())}
                  missionId={mission?.id}
                  startMissions={startMissions}
                  analyticsEventHandler={linkedmissionsAnalyticsEventHandler}
                  showGutter={false}
                />
              </MissionCardWrapper>
            ) : (
              <RewardCardWrapper>
                <RewardCard
                  missionReward={mission}
                  verificationMechanism={mission.type}
                  height='inherit'
                />
              </RewardCardWrapper>
            )
          ))}
      </ScrollWrapper>
      <LinkedMissionProgress
        linkedMissionsLength={linkedMissions.length}
        completedIndex={completedIndex}
        ongoingIndex={ongoingIndex}
        linkedMissions={linkedMissions}
      />
    </>
  );
}

LinkedMissionSeries.propTypes = {
  linkedMissions: PropTypes.arrayOf(PropTypes.any),
  ongoingMissions: PropTypes.instanceOf(Object),
  completedMissions: PropTypes.instanceOf(Object),
  startMissions: PropTypes.func,
  isReward: PropTypes.bool,
};

export default LinkedMissionSeries;
