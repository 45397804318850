import styled from 'styled-components';
import { Card, CardMedia, Typography } from '@material-ui/core';

export const RewardDetailsCard = styled(Card)`
  && {
    background-color: ${(props) => (
    props.isMissionComplete
      ? props.theme.backgroundColor.carbonGrey
      : props.theme.backgroundColor.selago
  )};
    color:${(props) => (
    props.isMissionComplete
      ? props.theme.fontColor.carbonGrey
      : props.theme.fontColor.black
  )};
    display: flex;
    border-radius: 8px;
    position: relative;
    max-width: 380px;
    height: 144px;
  }
`;

export const Image = styled(CardMedia)`
  && {
    width: 120px;
    height: inherit;
    border-radius: 8px;
  }
`;

export const CompleteMissionDiv = styled.div`
  display: flex;
  margin-bottom: 14px;
`;

export const MissionDetails = styled.div`
  && {
    padding: 18px 30px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    && {
      padding: 18px 20px;
    }
  }
`;

export const TrophyIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-top:10px;
`;

export const RewardDesc = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 18px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fontSize.description};
      line-height: 15px;
  }
  }
`;

export const RewardImageWrapper = styled.div`
  width: 120px;
  height: 144px;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  left: 95px;
  background-color: ${(props) => props.theme.backgroundColor.dodgerBlue};
  border: 3px solid ${(props) => props.theme.border.white};
  width: 40px;
  height: 40px;
  border-radius:50px;
  text-align: center;
`;

export const Offer = styled(Typography).attrs((props) => ({
  variant: props.variant,
}))`
&& { 
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: 21px;
    white-space: pre-line;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fontSize.smMedium};
  }
`;
