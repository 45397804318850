import React from 'react';

import { DashboardProvider } from 'provider/dashboard-provider';
import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import ViewMissionsContainer from 'components/containers/Missions/ViewMissionsContainer';

/// `AllMissions` is renders UI for all missions page with BaseLayout
const AllMissions = () => (
  <BaseLayout showPoster={false} hideGutters>
    <DashboardProvider>
      <ViewMissionsContainer />
    </DashboardProvider>
  </BaseLayout>
);

export default AllMissions;
