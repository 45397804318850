import styled from 'styled-components';

// Hiding the other cards of a particular linkedreward
// except the first one.
const LinkedSeriesWrapper = styled.div`
  & > :not(:first-child) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    background-color: ${(props) => props.theme.backgroundColor.selago};
    padding-top: 16px;
    margin-bottom: 16px;
    & > :not(:first-child) {
      display: block;
    }
  }
`;

export default LinkedSeriesWrapper;
