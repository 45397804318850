import PropTypes from 'prop-types';

import DefaultLink from './style';

/**
 * Common Link Component
 * @param {title}
 * @param {onClick}
 * @returns JSX
 */
const ButtonLink = ({
  title,
  onClick,
  fontSize,
  disabled,
}) => (
  <DefaultLink onClick={disabled ? null : onClick} role='link' tabIndex={0} onKeyDown={disabled ? null : onClick} fontSize={fontSize} disabled={disabled}>
    {title}
  </DefaultLink>
);

ButtonLink.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  fontSize: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonLink.defaultProps = {
  title: '',
  onClick: () => { },
  fontSize: '14px',
  disabled: false,
};

export default ButtonLink;
