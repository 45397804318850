import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TextField } from '@material-ui/core';

import { useAuth } from 'provider/auth-provider';
import { useSnackbar } from 'provider/snackbar-provider';
import { useAnalytics } from 'helpers/Analytics/Analytics';

import * as Strings from 'resources/strings';
import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import TextFieldStyles from 'components/common/CommonStyles/commonStyles';
import { addUserReward, getUserMission, verifyStaffPin } from 'services/Missions/missions-service';
import Button from 'components/common/Button/Button';
import {
  CodeField,
  VerifyMissionWrapper,
  VerifyText,
  VerifyData,
} from './style';

/**
 * Verify Mission Progress component
 * @returns JSX
 */

export default function VerifyMissionProgress() {
  const history = useHistory();
  const location = useLocation();
  const classes = TextFieldStyles();
  const { initiateTracking } = useAnalytics();
  const { setIsShow, setDisplayText } = useSnackbar();

  const { currentUser } = useAuth();

  const [isValidCode, setIsValidCode] = useState(true);
  const [isbuttonLoading, setIsbuttonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [codeValue, setCodeValue] = useState('');
  /// [verifyPin] calls the verifypin service and gets the response whether its verified or rejected
  /// and take the user back to progress page if verified
  /// else show error message on the same page
  const verifyPin = async () => {
    setIsbuttonLoading(true);
    const response = await verifyStaffPin(Number(codeValue), currentUser.id,
      location.state.currentUserMissionId);
    if (response.data.verificationStatus === 'rejected') {
      setIsValidCode(false);
      setErrorMessage(Strings.codeInvalidErrorMessage);
      setIsbuttonLoading(false);
    } else {
      setIsValidCode(true);
      initiateTracking({
        eventName: 'analytics_verify mission progress',
        eventCategory: 'navigation',
        eventAction: 'mission progress_verify staff pin',
        eventLabel: `${Strings.verifyMissionProgress}_${location.state.missionTitle}`,
      });
      setErrorMessage('');
      if (location.state.goToClaimReward) {
        const userReward = await addUserReward(currentUser.id, location.state.currentUserMissionId);
        if (userReward != null) {
          history.replace({
            pathname: `/missions/${location.state.missionId}/mission-reward`,
            search: `?reward-id=${userReward.data.missionRewardId}`,
            state: { referralRewardId: userReward.data.referralRewardId },
          });
        }
        setIsbuttonLoading(false);
      } else {
        setIsShow(true);
        setDisplayText(Strings.progressVerified);
        history.push(`/missions/${location.state.missionId}`);
        setIsbuttonLoading(false);
      }
    }
  };

  /// if the mission is completed we should not redirect to verify mission again
  const checkRouteStatus = async () => {
    const response = await getUserMission(currentUser.id, location.state.currentUserMissionId);
    if (response.status === 'completed') {
      history.push('/');
    }
  };

  useEffect(() => {
    // to check whether the status is completed or not
    // if completed redirect to dashboard
    // it happens when the user clicks back button
    checkRouteStatus();
  }, []);

  // to set the CODE value and reset the error message while entering the value
  const handleInput = (event) => {
    setErrorMessage('');
    setIsValidCode(true);
    setIsbuttonLoading(false);
    setCodeValue(event.target.value);
  };

  return (
    <BaseLayout>
      <VerifyMissionWrapper>
        <VerifyText>Verify mission progress</VerifyText>
        <VerifyData>
          Pass the phone to the cashier at store. They will input the code
          for verifying your mission progress
        </VerifyData>
        <CodeField>
          <TextField
            error={!isValidCode}
            label='CODE'
            placeholder='CODE'
            fullWidth
            name='code'
            variant='outlined'
            type='password'
            required
            helperText={errorMessage}
            value={codeValue}
            onChange={handleInput}
            autoComplete='off'
            className={classes.errorMessage}
            inputProps={{ maxLength: 6 }}
          />
        </CodeField>
        <Button
          onClick={codeValue.length < 6 || isbuttonLoading ? () => { } : verifyPin}
          disabled={codeValue.length < 6}
          variant='contained'
          name='verifyMissionProgress'
          id='verify-mission-progress-button'
          isLoading={isbuttonLoading}
          title={Strings.verifyMissionProgress}
        />
      </VerifyMissionWrapper>
    </BaseLayout>
  );
}
