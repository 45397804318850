import React from 'react';

import PropTypes from 'prop-types';

import AppBar from 'components/common/appbar';
import MissionsPoster from 'components/common/MissionsPoster';
import { FixedContainer, Layout } from './styles';

/**
 * BaseLayout for the app containing static parts of UI.
 * @param {*} children
 * @returns JSX
 */
const BaseLayout = ({
  children,
  showPoster,
  hideGutters,
  footer,
}) => (
  <>
    <AppBar />
    <div>
      <main>
        <Layout>
          {showPoster && <MissionsPoster />}
          <FixedContainer
            isShowPoster={showPoster}
            hideGutters={hideGutters}
            footer={footer}
          >
            {children}
          </FixedContainer>
        </Layout>
      </main>
    </div>
  </>
);

export default BaseLayout;

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showPoster: PropTypes.bool,
  hideGutters: PropTypes.bool,
  footer: PropTypes.bool,
};

BaseLayout.defaultProps = {
  showPoster: true,
  hideGutters: false,
  footer: false,
};
