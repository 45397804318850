import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const authentication = firebase.auth();
export const db = firebase.firestore();
export const fieldValue = firebase.firestore.FieldValue;
export default firebase;

/**
  FirebaseService to get token from Firebase authentication
  Singleton function which gets the token from current user
*/
export const firebaseService = (() => {
  let service;

  function getService() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  async function getToken() {
    const token = await authentication.currentUser.getIdToken();
    return token;
  }

  return {
    getService,
    getToken,
  };
})();
