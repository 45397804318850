import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { useAllMissions } from 'provider/dashboard-provider';

import breadCrumbLinks from 'utils/Dashboard/bread-crumbs-links';
import { getCategoryName } from 'utils/Missions/utils';
import ButtonLink from '../ButtonLink/ButtonLink';

import {
  BreadCrumbWrapper,
  BreadCrumb,
  Wrapper,
} from './style';

/**
 * breadcrumb -> list of links to navigate to differentt pages, it processes the current window path
 * and returns the list of navigating links
 * @returns
 */
const Breadcrumb = ({ analyticsEvent }) => {
  const history = useHistory();
  const { missionCategories } = useAllMissions();
  const windowPath = window.location.pathname;

  // splitting the current window path and storing those elements in routes array
  const routes = windowPath.split('/');

  // if some one has unknowingly typed in '/' at the end of the url, remove it from the routes array
  if (routes[routes.length - 1] === '') {
    routes.pop();
  }

  // `routeToBreadCrumbUrl` routes to the particular page
  const routeToBreadCrumbUrl = (path, breadCrumbName) => {
    analyticsEvent(breadCrumbName);
    history.push(path);
  };

  return (
    <>
      <BreadCrumbWrapper>
        {routes.map((path, index) => {
          /* if the path element is the last element of routes array, it will be the active one */
          const isActive = path === routes[routes.length - 1];
          const breadCrumbName = breadCrumbLinks[path]?.breadCrumbName
            || getCategoryName(missionCategories, path);
          return (
            <BreadCrumb isLast={index === routes.length - 1} key={path}>
              <Wrapper>
                {isActive ? (
                  breadCrumbName
                ) : (
                  <ButtonLink
                    title={breadCrumbName}
                    onClick={() => {
                      routeToBreadCrumbUrl(
                        breadCrumbLinks[path].pagePath,
                        breadCrumbName,
                      );
                    }}
                  />
                )}
              </Wrapper>
            </BreadCrumb>
          );
        })}
      </BreadCrumbWrapper>
    </>
  );
};

export default Breadcrumb;

Breadcrumb.propTypes = {
  analyticsEvent: PropTypes.func,
};

BreadCrumb.defaultProps = {
  analyticsEvent: () => { },
};
