import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import * as constants from 'utils/global-constants';
import {
  getUserMissions,
  getAllMissions,
  getLinkedMission,
  getFeaturedMissions,
  getMissionCategories,
  getAllMissionBlogs,
  getParticularBlogData,
} from 'services/Missions/missions-service';
import { getUserEarnedRewards } from 'services/UserRewards/user-rewards';
import { getEcoPointsTooltipContent } from 'services/RedeemEcopoints/redeem-ecopoints-service';
import { useAuth } from './auth-provider';

const UseAllMissionsContext = createContext();

export const useAllMissions = () => useContext(UseAllMissionsContext);

export const DashboardProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isFeaturedMissionLoading, setIsFeaturedMissionLoading] = useState(true);
  const [isRewardsLoading, setIsRewardsLoading] = useState(true);
  const [isOngoingLinkedMissionLoading, setIsOngoingLinkedMissionLoading] = useState(true);
  const [missionCategoriesLoader, setMissionCategoriesLoader] = useState(true);
  const [rewardsCollectedLoader, setRewardsCollectedLoader] = useState(true);
  const [isAllBlogsLoading, setIsAllBlogsLoading] = useState(true);
  const [selectedBlog, setSelectedBlog] = useState([]);
  const [isBlogsLoading, setIsBlogsLoading] = useState(true);

  const [allMissions, setAllMissions] = useState({});
  const [ecoPointsToolTipContent, setEcoPointsToolTipContent] = useState('');
  const [featuredMissions, setFeaturedMissions] = useState({});
  const [userMissions, setUserMissions] = useState({});
  const [ongoingMissions, setOngoingMissions] = useState({});
  const [completedMissions, setCompletedMissions] = useState({});
  const [userEarnedRewards, setUserEarnedRewards] = useState({});
  const [rewardsCollected, setRewardsCollected] = useState({});
  const [ongoingLinkedMissions, setOngoingLinkedMissions] = useState([]);
  const [missionCategories, setMissionCategories] = useState([]);
  const [allmissionCategories, setAllmissionCategories] = useState({});
  const [allMissionData, setAllMissionData] = useState([]);
  const [missionBlogs, setMissionBlogs] = useState({});

  // `fetchAllMissions` fetches all the missions data response from getAllMissions
  const fetchAllMissions = async () => {
    const resposnse = await getAllMissions();
    return resposnse.data;
  };

  // `fetchUserRewards` is used to fetch the userRewards of the user
  const fetchUserRewards = async () => {
    const userRewards = await getUserEarnedRewards(currentUser.id);
    const userRewardsData = {};
    userRewards.docs.forEach((reward) => {
      userRewardsData[reward.data().id] = reward.data();
    });
    setUserEarnedRewards(userRewardsData);
    setIsRewardsLoading(false);
  };

  // `getRewardsCollected` is used to group rewards based on
  // the type of the mission
  const getRewardsCollected = async () => {
    setRewardsCollectedLoader(true);
    const userRewardsObj = { ...userEarnedRewards };
    Object.keys(userRewardsObj).forEach((key) => {
      if (userRewardsObj[key]?.type === constants.linkedMissionsReward) {
        // To get the linked missions reward id
        const linkedRewardIds = userRewardsObj[key]?.linkedRewardIds;
        const linkedMissions = [];
        // loop through the rewardId inorder to push the linked missions
        // in same order and to delete the same mission reward from
        // existing user earned rewards
        linkedRewardIds.forEach((rewardId) => {
          linkedMissions.push(userEarnedRewards[rewardId]);
          delete userRewardsObj[rewardId];
        });
        userRewardsObj[key].linkedMissions = linkedMissions;
      }
    });
    setRewardsCollected(userRewardsObj);
    setRewardsCollectedLoader(false);
  };

  // `setAllMissionsData` to get all the missions
  const setAllMissionsData = async () => {
    setIsLoading(true);
    const allMissionsData = {};
    const allMissionsResponse = await fetchAllMissions();
    allMissionsResponse.forEach((data) => {
      allMissionsData[data.id] = { id: data.id, mission: data };
    });
    setAllMissions({ ...allMissionsData });
    setIsLoading(false);
  };

  // `fetchUserMissions` fetches the current on going missions of the current user
  const fetchUserMissionsData = async (collection, subCollection, status) => {
    setIsLoading(true);
    // getting all the missions data
    const allMissionsData = {};
    const allMissionsResponse = await fetchAllMissions();
    allMissionsResponse.forEach((data) => {
      allMissionsData[data.id] = { id: data.id, mission: data };
    });
    setAllMissions({ ...allMissionsData });

    // getting all current and completed userMissions of the user
    const userMissionsData = {};
    const ongoingMissionData = {};
    const completedMissionData = {};
    const response = await getUserMissions(
      collection,
      currentUser.id,
      subCollection,
      status,
      'status',
    );
    // Refactoring response to nested object collection to filter it with the other missions data
    response.docs.forEach((data) => {
      userMissionsData[data.data().missionId] = data.data();
    });
    setUserMissions(userMissionsData);
    // Looping through the allMissionsResponse data to fetch the title and image url of the
    // current ongoing Missions and completed Missions
    allMissionsResponse.map((mission) => {
      if (mission.id
        === (userMissionsData[mission.id] && userMissionsData[mission.id].missionId)
        && (userMissionsData[mission.id].status === 'current')) {
        ongoingMissionData[mission.id] = {
          ...userMissionsData[mission.id],
          title: mission.title,
          url: mission.missionImage?.url,
        };
      }
      if (mission.id
        === (userMissionsData[mission.id] && userMissionsData[mission.id].missionId)
        && (userMissionsData[mission.id].status === 'completed')) {
        completedMissionData[mission.id] = {
          ...userMissionsData[mission.id],
          title: mission.title,
          url: mission.missionImage?.url,
        };
      }
      return mission;
    });
    setOngoingMissions(ongoingMissionData);
    setCompletedMissions(completedMissionData);
    setIsLoading(false);
  };

  // `fetchRedeemTooltipContent` used to get the ToolTip Content for Ecopoints
  const fetchRedeemTooltipContent = async () => {
    const tootTipContent = await getEcoPointsTooltipContent();
    setEcoPointsToolTipContent(tootTipContent);
  };

  // `updateOngoingLinkedMissionData` update the ongoing missions data
  const updateOngoingLinkedMissionData = (linkedMissionData) => {
    setOngoingLinkedMissions([...linkedMissionData]);
    setIsOngoingLinkedMissionLoading(false);
  };

  // `fetchOngoingLinkedMissions` to get all the ongoing linked mission series
  const fetchOngoingLinkedMissions = async () => {
    setIsOngoingLinkedMissionLoading(true);
    const linkedMissionData = [];
    const missionIds = [...Object.keys(ongoingMissions), ...Object.keys(completedMissions)];
    if (missionIds.length > 0) {
      getLinkedMission(missionIds).then((missionData) => {
        missionData.forEach((mission) => {
          let isNotAllCompleted = false;
          // to check whether all the linked missions are completed or not
          for (let i = 0; i < mission.missions.length; i += 1) {
            if (!Object.keys(completedMissions).includes(`${mission.missions[i].id}`)) {
              isNotAllCompleted = true;
              break;
            }
          }
          if (isNotAllCompleted) {
            const sortedMissions = [];
            mission.missionsOrder.forEach((element) => {
              sortedMissions.push(allMissions[element.mission.id].mission);
            });
            linkedMissionData.push([...sortedMissions, mission.reward]);
          }
        });
        updateOngoingLinkedMissionData(linkedMissionData);
      });
    } else {
      updateOngoingLinkedMissionData(linkedMissionData);
    }
  };

  // `updateFeaturedMissionData` set the featured mission data
  const updateFeaturedMissionData = (featuredMissionData) => {
    let filteredFeaturedMissions = { ...featuredMissionData };
    const userMissionsData = { ...ongoingMissions, ...completedMissions };
    // To filter ongoing tasks and completed task from featured missions
    Object.keys(featuredMissionData).forEach((key) => {
      if (filteredFeaturedMissions[key].id === userMissionsData[key]?.missionId) {
        delete filteredFeaturedMissions[key];
      }
    });
    // To show only 3 featured missions
    if (Object.keys(filteredFeaturedMissions).length > 3) {
      filteredFeaturedMissions = Object.fromEntries(
        Object.entries(filteredFeaturedMissions).slice(0, 3),
      );
    }
    setFeaturedMissions({ ...filteredFeaturedMissions });
    setIsFeaturedMissionLoading(false);
  };

  // `fetchFeaturedMissions` to fetch all the featured missions
  const fetchFeaturedMissions = () => {
    setIsFeaturedMissionLoading(true);
    // get the featured mission data from strapi
    getFeaturedMissions().then(
      (missionData) => {
        missionData.map((data) => {
          featuredMissions[data.id] = data;
          return featuredMissions;
        });
        updateFeaturedMissionData(featuredMissions);
      },
    );
  };
  // `fetchAllMissionsCategories` to fetch all mission based on the categories
  const fetchAllMissionsCategories = async () => {
    setMissionCategoriesLoader(true);
    const filteredAllMissions = { ...allMissions };
    const userMissionsData = { ...ongoingMissions, ...completedMissions };
    const allmissionCategoriesData = {};
    // To fetch the mission category in ascending order
    const missionCategoryResponse = await getMissionCategories();
    if (!missionCategoryResponse.error) {
      setMissionCategories(missionCategoryResponse);
      // To filter ongoing tasks and completed task from all missions
      Object.keys(filteredAllMissions).forEach((key) => {
        if (filteredAllMissions[key].mission.id === userMissionsData[key]?.missionId) {
          delete filteredAllMissions[key];
        }
      });
      // Filtering to data based on the category in order to get
      // all the missions based on the category
      missionCategoryResponse.forEach((category) => {
        const currentCategory = category;
        const missions = [];
        Object.keys(filteredAllMissions).forEach((key) => {
          if (filteredAllMissions[key]?.mission?.missionsCategory?.key === currentCategory.key) {
            missions.push(filteredAllMissions[key]);
          }
        });
        // Assigning the missions based on the category
        if (missions.length > 0) {
          allmissionCategoriesData[currentCategory.key] = missions;
        }
      });
      // store all mission data available for the user
      setAllMissionData(Object.values(filteredAllMissions));
      setAllmissionCategories(allmissionCategoriesData);
      setMissionCategoriesLoader(false);
    } else {
      setMissionCategoriesLoader(false);
    }
  };

  /// `getMissionBlogs` get all the blogs data
  const getMissionBlogs = async () => {
    const allBlogs = await getAllMissionBlogs();
    setMissionBlogs(allBlogs.data);
    setIsAllBlogsLoading(false);
  };

  /// `getMissionBlogById` get particular the blog data
  const getMissionBlogById = async (id) => {
    setIsBlogsLoading(true);
    const blogData = await getParticularBlogData(id);
    setSelectedBlog(blogData.data);
    setIsBlogsLoading(false);
  };

  useEffect(() => {
    getMissionBlogs();
    if (currentUser) {
      fetchUserMissionsData(
        'mUsers',
        'mUserMissions',
        ['current', 'completed'],
      );
      fetchRedeemTooltipContent();
      fetchUserRewards();
    } else {
      // to get the all mission data
      // when the user is not logged in
      setAllMissionsData();
    }
  }, []);

  const value = {
    userMissions,
    isLoading,
    ongoingMissions,
    userEarnedRewards,
    isRewardsLoading,
    ecoPointsToolTipContent,
    isOngoingLinkedMissionLoading,
    ongoingLinkedMissions,
    featuredMissions,
    isFeaturedMissionLoading,
    completedMissions,
    allMissions,
    missionCategoriesLoader,
    allmissionCategories,
    missionCategories,
    allMissionData,
    rewardsCollected,
    rewardsCollectedLoader,
    missionBlogs,
    isBlogsLoading,
    isAllBlogsLoading,
    selectedBlog,
    fetchUserMissionsData,
    fetchUserRewards,
    fetchOngoingLinkedMissions,
    fetchFeaturedMissions,
    fetchAllMissionsCategories,
    getRewardsCollected,
    getMissionBlogById,
  };

  return (
    <UseAllMissionsContext.Provider value={value}>
      {children}
    </UseAllMissionsContext.Provider>
  );
};

DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
