import styled from 'styled-components';

export const BenefitsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    grid-template-columns: auto auto;
  }
`;

export const Benefits = styled.div`
  display: flex;
  align-items: center;
  margin: 0 9px 9px 0; 
`;

export const IconWrapper = styled.div`
  height: 32px;
  width: 32px;
  margin-right: 9px;
`;

export const BenefitsText = styled.div`
  font-size: ${(props) => props.theme.fontSize.secondary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
  }
`;
