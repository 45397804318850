import * as constants from 'utils/global-constants';
import { getCollection } from 'services/firebase-service';
import { get } from 'services/api-service';

/// `getUserReferral` get the user referral document
export const getUserReferral = async (userId) => {
  try {
    const response = await getCollection(
      constants.referrals,
      userId,
    );
    return response;
  } catch (error) {
    console.error(error);
    return {};
  }
};

/// `getReferralInfo` get the referral data from strapi
export const getReferralInfo = async () => {
  try {
    const response = await get('/referral-configurations', {});
    return response.data;
  } catch (error) {
    // if it has error return empty object
    console.error(error);
    return {};
  }
};
