import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const DialogContentTitle = styled(DialogTitle)`
  && {
    padding: 20px 24px 8px 24px;
   }
`;
export const DialogContentDescription = styled(DialogContent)`
  && {
    padding: 0px 24px;
   }
`;

export default DialogContentDescription;
