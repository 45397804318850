import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { getUserNotifications, updateNotifications, updateNotificationsStatus } from 'services/NotificationsService/notifications-service';

import { useAuth } from 'provider/auth-provider';

const UseNotificationsContext = createContext();

export const useNotifications = () => useContext(UseNotificationsContext);

/// [NotificationsProvider] responsible for notifying the users about status
/// of the current user activities and mission
const NotificationsProvider = ({ children }) => {
  const { currentUser, fetchUserStats } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [invisible, setInvisible] = useState(true);
  const [openNotifications, setOpenNotifications] = useState(false);

  /// [fetchNotifications] fetches the notification data for the current user
  const fetchNotifications = () => {
    setIsLoading(true);
    const response = getUserNotifications(
      currentUser.id,
    );
    response.onSnapshot((snapshot) => {
      const snap = notifications;
      const updatedNotifications = [];
      snapshot.docChanges().forEach((data, index) => {
        const notificationsData = data.doc.data();
        if (index < 4) {
          // To fetch only the first four notifications
          updatedNotifications.push(notificationsData);
        }
      });
      if (updatedNotifications.length === 1) {
        // To check for the duplication of the data
        let removeIndex = 0;
        let check = false;
        for (let index = 0; index < snap.length; index += 1) {
          check = updatedNotifications.find((obj) => snap[index].id === obj.id);
          if (check) {
            removeIndex = index;
            break;
          }
        }
        if (check) {
          // To remove the duplicated data
          snap[removeIndex] = updatedNotifications.pop();
        } else {
          // To add the notifications from the beginning of the notifications array
          snap.unshift(updatedNotifications[0]);
        }
      } else {
        updatedNotifications.forEach((data) => {
          snap.push(data);
        });
      }

      if (snap.length > 4) {
        snap.pop();
      }
      let increment = 0;
      snap.forEach((data) => {
        if (data.state === 'unread') {
          increment += 1;
        }
      });
      const sortedData = snap.sort((a, b) => b.reminderDate.seconds - a.reminderDate.seconds);
      if (increment > 0) {
        setInvisible(false);
      } else {
        setInvisible(true);
      }
      setNotifications(sortedData);
      setIsLoading(false);
    });
  };

  /// `setNotificationsStatus` updates the disable notification status of the current user
  const setNotificationsStatus = async () => {
    await updateNotificationsStatus(currentUser.id, !currentUser.disableNotification ?? true);
  };

  /// `updateNotificationState` to update the state of the notification to read
  const updateNotificationState = (docId) => {
    updateNotifications(docId);
  };

  useEffect(() => {
    if (currentUser) {
      fetchNotifications();
      fetchUserStats();
    }
  }, []);

  const value = {
    notifications,
    fetchNotifications,
    isLoading,
    invisible,
    setInvisible,
    updateNotificationState,
    setOpenNotifications,
    openNotifications,
    setNotificationsStatus,
  };
  return (
    <UseNotificationsContext.Provider value={value}>
      {children}
    </UseNotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationsProvider;
