import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAllMissions } from 'provider/dashboard-provider';
import Loader from 'components/common/Loader/Loader';
import ViewMissions from 'components/common/AllMissionsLayout/ViewMissions';
import * as globalConstants from 'utils/global-constants';
import * as constants from 'utils/Analytics/flow-constants';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import getEventAction from 'helpers/Analytics/analytics_helpers';
import * as Strings from 'resources/strings';
import { getCardsLength, getCategoryName } from 'utils/Missions/utils';
import themes from 'styles/theme';
import ViewMissionCard from 'components/pages/Missions/components/ViewMissionCard/index';

/// `ViewMissionsContainer` is the container for ViewMissions component
const ViewMissionsContainer = () => {
  // missionData contains all the data
  // splicedMissionData contains data before slicing mission data when page loads
  // showMore controls hide and show for `Show More` CTA
  const [missionsData, setMissionsData] = useState({
    missionData: [],
    splicedMissionData: [],
    showMore: false,
  });

  const {
    fetchAllMissionsCategories,
    allmissionCategories,
    allMissions,
    missionCategoriesLoader,
    isLoading,
    missionCategories,
    allMissionData,
    ongoingMissions,
    completedMissions,
  } = useAllMissions();

  const history = useHistory();
  const params = useParams();
  const [categoryTitle, setCategoryTitle] = useState('');
  const { initiateTracking } = useAnalytics();

  /// filtering the category name from missionCategories as per params value
  useEffect(() => {
    const title = getCategoryName(missionCategories, params.categoryname);
    setCategoryTitle(title);
  }, []);

  // cardsCount stores the number of cards to be displayed
  // on page load.
  const cardsCount = getCardsLength(window.innerHeight);
  // sort all missions based on mission title in alphabetical order
  const sortMissions = (missionsArray) => {
    missionsArray?.sort((a, b) => ((a?.mission?.title?.toLowerCase()
      > b?.mission?.title?.toLowerCase()) ? 1 : -1));
  };

  useEffect(() => {
    // fetch all missions based on category
    fetchAllMissionsCategories();
  }, [
    Object.keys(allMissions).length,
    Object.keys(ongoingMissions).length,
    Object.keys(completedMissions).length,
  ]);

  useEffect(() => {
    const missionsArray = [];
    // seggregate the missions based on params value
    // into either all-missions or based on category
    if (Object.keys(allmissionCategories)?.length > 0) {
      if (params.categoryname === globalConstants.allMissions && allMissionData?.length > 0) {
        missionsArray.push(...allMissionData);
      }
      if (Object.keys(allmissionCategories).includes(params.categoryname)) {
        missionsArray.push(...allmissionCategories[params.categoryname]);
      }

      // sorting the missions in alphabetical order
      sortMissions(missionsArray);
      setMissionsData({
        missionData: missionsArray,
        splicedMissionData:
          missionsArray?.length > cardsCount
            && window.innerWidth <= parseInt(themes.breakpoints.mobileLandscape, 10)
            ? missionsArray?.slice(0, cardsCount)
            : missionsArray,
        showMore: false,
      });
    }
  }, [Object.keys(allMissions).length,
    allmissionCategories,
    Object.keys(ongoingMissions).length,
    Object.keys(completedMissions).length,
  ]);

  // 'analyticsEventHandler` analytics event handler for show more
  const analyticsEventHandler = (eventName, eventAction, eventLabel) => {
    const categoryName = getCategoryName(missionCategories, params.categoryname);
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: `${eventAction}`,
      eventAction: getEventAction({ params, categoryName }),
      eventLabel: `${eventLabel}`,
    });
  };

  /// `showMoreHandler` is use to control 'Show More' CTA
  const showMoreHandler = () => {
    analyticsEventHandler(
      constants.showMoreAnalytics,
      constants.visitorAction,
      Strings.showMore,
    );
    setMissionsData({
      missionData: [...missionsData?.missionData],
      splicedMissionData: missionsData?.missionData,
      showMore: true,
    });
  };

  return missionCategoriesLoader || isLoading ? (
    <Loader />
  ) : (
    <ViewMissions
      missionsData={missionsData}
      history={history}
      showMoreHandler={() => showMoreHandler()}
      cardsCount={cardsCount}
      showMore={missionsData?.showMore}
      analyticsEventHandler={analyticsEventHandler}
      missionTitle={categoryTitle?.length ? categoryTitle : Strings.allMissions}
      children={missionsData?.splicedMissionData?.map((eachMission) => (
        <div key={eachMission?.id}>
          <ViewMissionCard
            categoryImage={eachMission?.mission?.missionImage?.url}
            categoryName={eachMission?.mission?.missionsCategory?.name}
            missionTitle={eachMission?.mission?.title}
            offer={eachMission?.mission?.missionReward?.name}
            altText={
              eachMission?.mission?.missionImage?.alternativeText
            }
            share={eachMission?.mission?.share}
            missionId={eachMission?.id}
          />
        </div>
      ))}
    />
  );
};

export default ViewMissionsContainer;
