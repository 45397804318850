import styled from 'styled-components';

export const RewardCardWrapper = styled.div`
  width: 413px;
  padding: 0 12px 20px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 0 28px 16px 20px;
    width: unset;
  }
`;

export const RewardHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }
`;

export const MissionTitle = styled.div`
  font-family: ${(props) => (props.isBigReward ? props.theme.fontFamily.robotoBold : props.theme.fontFamily.robotoRegular)};
  font-weight: ${(props) => (props.isBigReward ? props.theme.fontWeight.medium : props.theme.fontWeight.normal)};
  color: ${(props) => props.theme.fontColor.carbonBlack};
  font-size: ${(props) => props.theme.fontSize.smMedium};
  line-height: 25px;
  letter-spacing: 0.31px;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
    letter-spacing: 0.25px;
}
`;

export const EarnedDate = styled.div`
  font-family: ${(props) => props.theme.fontFamily.robotoRegular};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.fontColor.carbonGrey};
  font-size: ${(props) => props.theme.fontSize.primary};
  line-height: 23px;
  letter-spacing: 0.31px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.description};
    line-height: 18px;
    letter-spacing: 0.25px;
  }
`;
