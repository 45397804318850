import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0px;
  margin-bottom: ${(props) => (props.showGutter ? '12px' : '0px')};
`;

export const Dots = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background-color: ${(props) => (props.isVisible ? props.theme.backgroundColor.dodgerBlue : props.theme.backgroundColor.cloudBlue)};
  margin: 0px 3px;
`;
