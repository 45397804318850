import React from 'react';

import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';

import * as Strings from 'resources/strings';
import Button from 'components/common/Button/Button';
import ButtonLink from 'components/common/ButtonLink/ButtonLink';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextFieldStyles from 'components/common/CommonStyles/commonStyles';
import {
  PhoneNumberField,
  OtpField,
  ResendOtp,
  EditPhoneNumberLink,
  ResendOtpLink,
  ErrorMessage,
  RefreshPageText,
} from './style';

/**
 * @param {boolean} showOtp
 * @param {(e)=>void} setOtp
 * @param {object} values
 * @param {(e)=>void} handleInput
 * @param {(e)=>void} submitPhoneNumber
 * @param {(e)=>void} editPhoneNumberHandler
 * @param {(e)=>void} timer
 * @returns LoginForm component displays the login form with PhoneNumber and OtpField
 */

function LoginForm({
  showOtp,
  setOtp,
  values,
  handleInput,
  submitPhoneNumber,
  submitOtp,
  timer,
  editPhoneNumberHandler,
  otpValue,
  isLoading,
  clearOtpError,
}) {
  const classes = TextFieldStyles();
  return (
    <form>
      <PhoneNumberField>
        <TextField
          /* eslint-disable react/jsx-no-duplicate-props */
          inputProps={{ inputMode: 'numeric' }}
          InputProps={{
            startAdornment:
  <InputAdornment position='start'>
    {Strings.phoneNumberPrefix}
  </InputAdornment>,
          }}
          label='Phone Number'
          id='outlined-start-adornment'
          placeholder='Phone Number'
          variant='outlined'
          fullWidth
          error={values.isPhoneNumberWrong}
          values={values.phoneNumber}
          name='phoneNumber'
          onChange={handleInput}
          helperText={values.phoneNumberError}
          required
          className={classes.errorMessage}
          disabled={showOtp}
        />
        {/* // Show NotYou only in otp screen */}
        {showOtp ? (
          <EditPhoneNumberLink>
            <ButtonLink onClick={editPhoneNumberHandler} title={Strings.changeNumberDesc} />
          </EditPhoneNumberLink>
        ) : <></>}
      </PhoneNumberField>
      <OtpField showOtp={showOtp}>
        {showOtp ? (
          <TextField
            label='OTP'
            error={values.otpError !== ''}
            helperText={values.otpError}
            placeholder='OTP'
            fullWidth
            values={values.phoneNumber}
            name='otp'
            variant='outlined'
            onChange={(event) => {
              setOtp(event.target.value);
              clearOtpError();
            }}
            required
            autoComplete='off'
            className={classes.errorMessage}
          />
        ) : null}
      </OtpField>
      {/* // show Send OTP button only after 120 secs from previous OTP */}
      {!showOtp ? (
        <Button
          onClick={submitPhoneNumber}
          disabled={values.phoneNumber === '' || timer !== 0 || isLoading}
          title={timer === 0 ? Strings.sendOTP : `${Strings.sendOTP} in ${timer}s`}
          variant='contained'
          name='verifyNumber'
          id='login-button'
          isLoading={isLoading}
        />
      )
        : (
          <div>
            {/* // show Resend OTP button only after 120 secs from previous OTP */}
            {timer === 0 ? (
              <ResendOtpLink>
                <ButtonLink onClick={submitPhoneNumber} title={Strings.resendOTP} />
              </ResendOtpLink>
            )
              : (
                <ResendOtp>
                  {Strings.resendOTPIn}
                  {' '}
                  {timer}
                  {' '}
                  {Strings.secs}
                </ResendOtp>
              )}
            <Button
              onClick={otpValue.length < 6 ? null : submitOtp}
              variant='contained'
              id='login-button'
              name='verifyOtp'
              disabled={otpValue.length < 6 || isLoading}
              title={Strings.verifyPhoneNumber}
              isLoading={isLoading}
            />
          </div>
        )}
      {(values.reCaptchaError && values.reCaptchaError !== '') && (
        <ErrorMessage>
          {values.reCaptchaError}
          <RefreshPageText>
            {Strings.request}
            &nbsp;
            <ButtonLink onClick={() => window.location.reload()} title={Strings.refreshPage} fontSize='12px' />
            &nbsp;
            {Strings.startOver}
          </RefreshPageText>
        </ErrorMessage>
      )}
    </form>
  );
}

LoginForm.propTypes = {
  showOtp: PropTypes.bool,
  setOtp: PropTypes.func,
  values: PropTypes.shape({
    phoneNumber: PropTypes.string,
    username: PropTypes.string,
    phoneNumberError: PropTypes.string,
    isPhoneNumberWrong: PropTypes.bool,
    otpError: PropTypes.string,
    reCaptchaError: PropTypes.string,
  }),
  handleInput: PropTypes.func,
  submitPhoneNumber: PropTypes.func,
  editPhoneNumberHandler: PropTypes.func,
  submitOtp: PropTypes.func,
  timer: PropTypes.number,
  otpValue: PropTypes.string,
  isLoading: PropTypes.bool,
  clearOtpError: PropTypes.func,
};

LoginForm.defaultProps = {
  showOtp: false,
  setOtp: () => { },
  values: {},
  handleInput: () => { },
  submitPhoneNumber: () => { },
  editPhoneNumberHandler: () => { },
  submitOtp: () => { },
  timer: 0,
  otpValue: '',
  isLoading: false,
};

export default LoginForm;
