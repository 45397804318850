import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { useSustainabilityMetrics } from 'provider/sustainability-metrics-provider';

import { stats, sustainabilityMetrics } from 'utils/SustainabilityMetrics/flow-constants';
import * as Strings from 'resources/strings';
import Loader from 'components/common/Loader/Loader';
import { ScrollWrapper } from 'components/pages/Dashboard/style';
import roundToTwoDecimal from 'utils/roundoff';
import {
  Wrapper,
  Title,
  MissionWrapper,
  ImageWrapper,
  Points,
  Status,
} from './style';

function UserStatus({ userStats, showTitle }) {
  const { isLoading, sustainableMetrics, fetchMetrics } = useSustainabilityMetrics();
  useEffect(() => {
    fetchMetrics();
  }, []);

  /// `returnStatsData` returns the stats value along with the sustainability metrics
  const returnStatsData = (statsValue, statsType) => {
    const metrics = sustainableMetrics[statsType]
    && sustainabilityMetrics[sustainableMetrics[statsType]];
    if (sustainableMetrics[statsType] === 'Grams') {
      const data = statsValue >= 1000 ? `${roundToTwoDecimal(statsValue / 1000)}kg` : statsValue + metrics;
      return data;
    }
    if (metrics) {
      return roundToTwoDecimal(statsValue) + metrics;
    }
    return roundToTwoDecimal(statsValue);
  };

  // TODO (Manjusha): Horizontal scroll wrapper should be moved to the commons
  return isLoading ? (
    <Loader isButtonLoader />
  ) : (
    <Wrapper>
      { /* showTitle = {true} --> to show the title in impact section in dashboard
           showTitle = {false}--> to hide title of impact section in claim reward page
        */ }
      {showTitle && <Title>{Strings.userStatsTitle}</Title>}
      <ScrollWrapper>
        {Object.keys(stats).map((statsData, index) => (
          <>
            {((userStats && userStats[statsData] != null)
            || (stats[statsData].default && showTitle)) && (
              <MissionWrapper
                key={stats[statsData].type ?? statsData}
                backgroundColor={stats[statsData].backgroundColor}
                isRequired={index === 0}
              >
                <ImageWrapper>
                  {stats[statsData].icon}
                </ImageWrapper>
                <Points>
                  {statsData === 'dollarValueSaved' ? 'S$' : ''}
                  {userStats && userStats[statsData]
                    ? returnStatsData(userStats[statsData], statsData) : 0}
                </Points>
                <Status>{stats[statsData].type ?? statsData}</Status>
              </MissionWrapper>
            )}
          </>
        ))}
      </ScrollWrapper>
    </Wrapper>
  );
}

UserStatus.propTypes = {
  userStats: PropTypes.shape({}),
  showTitle: PropTypes.bool,
};

export default React.memo(UserStatus);
