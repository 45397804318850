import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { productPurchase } from 'utils/global-constants';
import * as Strings from 'resources/strings';
import RewardCard from 'components/pages/Missions/components/RewardCard';
import MissionTabsPanel from 'components/pages/Missions/components/MissionTabsPanel';
import ProductAction from 'components/pages/Missions/components/ProductAction';
import CustomerTestimonials from 'components/pages/Missions/components/CustomerTestimonials';
import Spacer from 'components/common/Spacer/Spacer';
import { Title } from 'components/common/Accordion/style';
import { Coupon, Circle, CouponDescription } from 'components/pages/Missions/components/CouponCard/style';
import {
  ActivityInfoWrapper,
  CardWrapper,
} from './style';

const ActivityInfo = ({
  isMissionComplete,
  missionsData,
}) => (
  <ActivityInfoWrapper>
    <MissionTabsPanel
      missionsData={missionsData}
      activityMetrics={missionsData.activity.activityMetrics}
    />
    <>
      <Title variant='body1'>{isMissionComplete ? Strings.rewardCollectedLabel : Strings.yourOffer}</Title>
      <CardWrapper>
        <RewardCard
          isMissionComplete={isMissionComplete}
          missionReward={missionsData.missionReward}
          verificationMechanism={missionsData.missionReward.type}
        />
        <Spacer height='16px' />
        {missionsData.missionReward.type === 'promoCode' && (
          <Route path='/missions/:id' exact>
            <Coupon hideGutters>
              <Circle isLeft />
              <CouponDescription>
                Start mission to reveal coupon code
              </CouponDescription>
              <Circle />
            </Coupon>
            <Spacer height='12px' />
          </Route>
        )}
      </CardWrapper>
    </>
    {missionsData?.reviews?.length > 0
      && missionsData.activity?.verificationMechanism === productPurchase
      && missionsData.productCheckoutUrl !== null
      && <CustomerTestimonials customerReviews={missionsData.reviews} />}
    {missionsData.activity?.verificationMechanism === productPurchase
      && missionsData.productCheckoutUrl != null && missionsData?.youtubeVideoLink
      && <ProductAction videoLink={missionsData?.youtubeVideoLink} />}
    <Spacer height='24px' />
  </ActivityInfoWrapper>
);
export default ActivityInfo;

ActivityInfo.propTypes = {
  isMissionComplete: PropTypes.bool,
  missionsData: PropTypes.objectOf(PropTypes.any),
};
