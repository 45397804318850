const themes = {
  backgroundColor: {
    black: '#000000',
    white: '#FFFFFF',
    dodgerBlue: '#4652FD',
    selago: '#E7F0FC',
    easternBlue: '#22A5A5',
    blackSqueeze: '#E9F6F6',
    seaGreen: '#2D8444',
    titanWhite: '#ECEEFF',
    negativeRed: '#D04B4B',
    disabled: '#F0F0F0',
    fadedWhite: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    razmataz: '#E10050',
    cloudBlue: '#B8BBD6',
    silver: '#C4C4C4',
    carbonGrey: '#F1F1F3',
    grayishOrange: '#FAF0E4',
    goldenYellow: '#FAF0E0',
    linen: '#FAF2E7',
    fantasy: '#FAEDED',
    gradientDodgerBlue: 'conic-gradient(#4652FD, #4652FD00)',
  },
  fontFamily: {
    robotoBold: 'Roboto-Bold',
    robotoRegular: 'Roboto-Regular',
  },
  fontColor: {
    white: '#FFFFFF',
    black: '#000000',
    silverChalice: '#9E9E9E',
    sanMarino: '#3f51b5',
    dodgerBlue: '#4652FD',
    negativeRed: '#D04B4B',
    grey: '#B8B8B8',
    cloudBlue: '#B8BBD6',
    carbonBlack: '#1F2134',
    carbonGrey: '#727384',
    seaGreen: '#2D8444',
    lavenderGrey: '#B8BBD6',
  },
  fontSize: {
    small: '10px',
    description: '12px',
    primary: '14px',
    pageTitle: '32px',
    smSecondary: '15px',
    secondary: '16px',
    smMedium: '18px',
    medium: '20px',
    tertiary: '21px',
    large: '24px',
  },
  fontWeight: {
    normal: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
    extraBold: '800',
  },
  border: {
    white: '#FFFFFF',
    gallery: '#EEEEEE',
    jungleMist: '#A7CFCF',
    blackwithOpacity: 'rgba(0, 0, 0, 0.36)',
    lavenderGrey: '#B8BBD6',
    dodgerBlue: '#4652FD',
    transparent: 'rgba(0, 0, 0, 0)',
    ghost: '#C9C9D2',
    seaGreen: '#2D8444',
    black: '#0000',
  },
  boxShadow: {
    whiteWithOpacity: '0px 8px 16px rgba(31, 33, 52, 0.1)',
    greyShadow: '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)',
    blackWithTopShadow: '0px -7px 64px rgba(0, 0, 0, 0.10)',
    blackWithBottomShadow: '0px 7px 64px rgba(0, 0, 0, 0.10)',
  },
  breakpoints: {
    mobile: '320px',
    mobileLandscape: '480px',
    tablet: '768px',
    tabletLarge: '1000px',
    tabletLandscape: '1024px',
    desktop: '1200px',
    desktopMedium: '1380px',
    desktopLarge: '1500px',
    desktopWide: '1920px',
  },
};

export default themes;
