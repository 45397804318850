import React from 'react';
import PropTypes from 'prop-types';

import { useSnackbar } from 'provider/snackbar-provider';

import * as Strings from 'resources/strings';

const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg', 'bmp', 'webp', 'tiff', 'tif'];

function validateFileType(event, onChangeFunc, setIsShow, setDisplayText) {
  const image = event.target.files[0];
  const fileName = image.name;
  const idxDot = fileName.lastIndexOf('.') + 1;
  const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

  let unAllowedExtension = true;
  allowedExtensions.forEach((extension) => {
    if (extFile === extension) {
      unAllowedExtension = false;
    }
  });
  if (!unAllowedExtension) {
    onChangeFunc(event);
  } else {
    /* eslint-disable no-param-reassign */
    event.target.value = null;
    setDisplayText(Strings.unsupportedFileType);
    setIsShow(true);
  }
}

const FileInput = ({ onChangeFunc, children, refs }) => {
  const { setIsShow, setDisplayText } = useSnackbar();
  return (
    <div>
      <input
        type='file'
        ref={refs}
        name='files'
        accept='image/*'
        onChange={(event) => validateFileType(event, onChangeFunc, setIsShow, setDisplayText)}
        hidden
      />
      <div>
        {children}
      </div>
    </div>
  );
};

export default FileInput;

FileInput.propTypes = {
  onChangeFunc: PropTypes.func,
  children: PropTypes.node.isRequired,
  refs: PropTypes.shape({}),
};
