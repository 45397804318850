import React from 'react';

import PropTypes from 'prop-types';

import { useNotifications } from 'provider/notifications-provider';

import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';

import themes from 'styles/theme';

const styles = () => ({
  customBadge: {
    backgroundColor: themes.backgroundColor.razmataz,
    border: '1px solid',
    boderColor: themes.border.white,
  },
});

/// [NotificationsBadge] notifies the user with a badge to check on to the notifications
function NotificationsBadge(props) {
  const { classes } = props;
  const { invisible } = useNotifications();

  return (
    <div>
      <Badge
        variant='dot'
        classes={{ badge: classes.customBadge }}
        invisible={invisible}
      >
        <NotificationsIcon />
      </Badge>
    </div>
  );
}

NotificationsBadge.propTypes = {
  classes: PropTypes.shape({
    customBadge: PropTypes.string,
  }),
};

export default withStyles(styles)(NotificationsBadge);
