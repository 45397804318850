import * as constants from 'utils/Analytics/flow-constants';

// `getEventAction` function to get event action
const getEventAction = ({ params, categoryName, breadCrumb = false }) => {
  let eventAction = breadCrumb
    ? `${constants.allCategories}`
    : '';
  if (params.categoryname === 'all-missions') {
    eventAction = `${constants.allCategories}_${constants.allMissions}`;
  } else if (params.categoryname) {
    eventAction = `${constants.allCategories}_${categoryName}`;
  } else if (!breadCrumb && !params.categoryname) {
    eventAction = `${constants.allCategories}_${constants.recommendedMissions}`;
  }
  return eventAction;
};

export default getEventAction;
