// constants for clevertap events

export const sendOTP = 'Send OTP';
export const loginToAdvaMissions = 'Login to Adva Missions';
export const verifyPhoneNumber = 'Verify Phone Number';
export const startMission = 'Start Mission';
export const viewRewardsCollected = 'View Rewards Collected';
export const shareReferralLink = 'Share Referral Link';
export const viewAllMissions = 'View All Missions';
export const viewCategory = 'View Category';
export const uploadPhoto = 'Upload Photo';
export const submitBill = 'Submit Bill';
export const buyNow = 'Buy Now';
