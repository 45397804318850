import React from 'react';

import { DashboardProvider } from 'provider/dashboard-provider';
import { ReferralProvider } from 'provider/referral_provider';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import DashboardLanding from 'components/pages/Dashboard/components/DashboardLanding';

/**
 * * [Dashboard] consists of all the user mission status and other
 * * ongoing missions data along with the total number of rewards earned.
 * @returns <DashboardComponent>
 */
const Dashboard = () => (
  <BaseLayout showPoster={false} hideGutters>
    <DashboardProvider>
      <ReferralProvider>
        <DashboardLanding />
      </ReferralProvider>
    </DashboardProvider>
  </BaseLayout>
);

export default Dashboard;
