import axios from 'axios';

import { firebaseService } from 'config/firebase-service';

// Firestore Base URL
const baseURL = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
};

/* Axios Instance */
const axiosInstance = axios.create({
  baseURL,
  headers,
});

/* Request and Response Interceptor */
axiosInstance.interceptors.request.use(
  async (request) => {
    if (request.url.startsWith(process.env.REACT_APP_FIREBASE_FUNCTIONS_URL)) {
      const fsService = firebaseService.getService();
      const token = await fsService.getToken();
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
    }
    return request;
  },
  (error) => Promise.reject(error.response.data),
);

/* GET, POST, PUT, DELETE Calls */
export const firebaseGet = ({ url, config, base = baseURL }) => {
  const apiURL = base + url;
  return axiosInstance.get(apiURL, config ?? {});
};

export const firebasePost = (url, data, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.post(apiURL, data ?? {}, config ?? {});
};

export const firebasePatch = (url, data, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.patch(apiURL, data ?? {}, config ?? {});
};

export const firebasePut = (url, data, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.put(apiURL, data ?? {}, config ?? {});
};

export const firebaseDelete = (url, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.delete(apiURL, config ?? {});
};
