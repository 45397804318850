import styled from 'styled-components';

export const InviteText = styled.div`
  font-size: ${(props) => props.theme.fontSize.smMedium};
  text-align: center;
  margin: 34px 24px;
  line-height: 25px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 28px 24px;
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 21px;
  }
`;

export const InviteMembers = styled.span`
  color: ${(props) => props.theme.fontColor.dodgerBlue};
`;
