import React from 'react';
import PropTypes from 'prop-types';

import Markdown from 'components/common/Markdown';
import {
  Wrapper,
  ImageWrapper,
  Image,
  Description,
  DashedBorder,
  PreLoginBorder,
} from './style';

/// [AboutMissionCard] renders the UI for about adva missions card
const AboutMissionsCard = ({ data, isConnector, isPreLogin }) => (
  <>
    <Wrapper isPreLogin={isPreLogin}>
      <ImageWrapper>
        <Image src={data.image.url} alt='mission-image' />
      </ImageWrapper>
      <Description isPreLogin={isPreLogin}>
        <Markdown content={data.description} />
      </Description>
    </Wrapper>
    {isPreLogin ? (
      <PreLoginBorder isConnector={isConnector} />
    ) : (
      <DashedBorder isConnector={isConnector} />
    )}
  </>
);

AboutMissionsCard.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    description: PropTypes.string,
  }),
  isConnector: PropTypes.bool,
  isPreLogin: PropTypes.bool,
};

AboutMissionsCard.defaultProps = {
  data: {
    image: {
      url: '',
    },
    description: '',
  },
  isConnector: false,
  isPreLogin: false,
};

export default AboutMissionsCard;
