import React from 'react';

import BaseLayout from '../../common/BaseLayout/BaseLayout';
import { MissionsProvider } from '../../../provider/missions-provider';
import FileInput from '../../common/FileInput/FileInput';

// when a file gets uploaded this function will be called
const fileUploaded = () => {
  // console.log('upload file');
};

/**
 * FileUploader Component.
 * @returns JSX
 */
const FileUploader = () => (
  <BaseLayout>
    <MissionsProvider>
      <FileInput onChangeFunc={fileUploaded} />
    </MissionsProvider>
  </BaseLayout>
);

export default FileUploader;
