import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { DialogContentTitle, DialogContentDescription } from './style';

/**
 * Common ResponsiveDialog Component
 * @param {title}
 * @param {description}
 * @param {handleAgree}
 * @param {handleDismiss}
 * @param {open}
 * @param {dismissText}
 * @param {agreeText}
 * @param {component}
 * @returns JSX
 */

const useStyles = makeStyles(() => ({
  paper: { maxWidth: '350px' },
}));
export default function ResponsiveDialog({
  title,
  description,
  handleAgree,
  handleDismiss,
  openDialog,
  dismissText,
  agreeText,
  component,
  isLoading,
}) {
  const classes = useStyles();
  const handleClose = () => {
    handleDismiss();
  };

  const handleAccept = () => {
    handleAgree();
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        classes={{ paper: classes.paper }}
      >
        <DialogContentTitle id='responsive-dialog-title'>{title}</DialogContentTitle>
        <DialogContentDescription>
          <DialogContentText>
            {description}
          </DialogContentText>
        </DialogContentDescription>
        {/* if any extra component needs to be displayed on Dialog
        other than title and description {component} props can be used */}
        {component}
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {dismissText}
          </Button>
          {isLoading ? (
            <CircularProgress size={30} />
          ) : (
            <Button onClick={handleAccept} color='primary'>
              {agreeText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

ResponsiveDialog.propTypes = {
  title: PropTypes.string,
  dismissText: PropTypes.string,
  agreeText: PropTypes.string,
  handleAgree: PropTypes.func,
  handleDismiss: PropTypes.func,
  description: PropTypes.string,
  openDialog: PropTypes.bool,
  component: PropTypes.node,
  isLoading: PropTypes.bool,
};

ResponsiveDialog.defaultProps = {
  title: '',
  dismissText: '',
  agreeText: '',
  handleAgree: () => { },
  handleDismiss: () => { },
  description: '',
  openDialog: false,
  component: <></>,
  isLoading: false,
};
