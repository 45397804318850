import React from 'react';

import PropTypes from 'prop-types';

import themes from 'styles/theme';
import Loader from 'components/common/Loader/Loader';
import PrimaryButton from './style';

/**
 * Common button component
 * @param {title}
 * @param {disabled}
 * @param {onClick}
 * @param {variant}
 * @param {name}
 * @param {id}
 * @returns JSX
 */
const Button = ({
  title,
  onClick,
  disabled,
  variant,
  name,
  id,
  isLoading,
}) => {
  // `getBackGroundColor` to get the background color of button
  const getBackGroundColor = () => {
    if (disabled && isLoading) {
      return themes.backgroundColor.dodgerBlue;
    }
    if (disabled && !isLoading) {
      return themes.backgroundColor.disabled;
    }
    return themes.backgroundColor.dodgerBlue;
  };

  return (
    <PrimaryButton
      onClick={onClick}
      variant={variant}
      id={id}
      name={name}
      disabled={disabled}
      backgroundColor={getBackGroundColor}
      isLoading={isLoading}
    >
      {isLoading ? (
        <Loader color={themes.backgroundColor.white} isButtonLoader size={35} />
      ) : title}
    </PrimaryButton>
  );
};
export default Button;

Button.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  title: '',
  onClick: () => { },
  disabled: false,
  variant: 'contained',
  id: '',
  name: '',
  isLoading: false,
};
