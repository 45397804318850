import React from 'react';
import PropTypes from 'prop-types';

import {
  priceLabel,
  expectedDeliveryLabel,
  singaporeDollar,
  withinSingapore,
  boughtLabel,
  sevenDays,
  inTheLastText,
  timesLabel,
} from 'resources/strings';
import {
  SubHeading,
  ProductPrize,
  RecentPurchase,
  ProductDelivery,
  RecentPurchaseDuration,
} from './style';

// 'ProductDetails' to display details about the product
const ProductDetails = ({
  boughtInSevenDays,
  productDeliveryDuration,
  productPrice,
  showPriceLabel,
}) => (
  <div>
    {productPrice && (
      <SubHeading>
        {showPriceLabel && <>{`${priceLabel}: `}</>}
        <ProductPrize>{`${singaporeDollar}${productPrice}`}</ProductPrize>
      </SubHeading>
    )}
    {productDeliveryDuration && (
      <SubHeading>
        {`${expectedDeliveryLabel}: `}
        <ProductDelivery>{productDeliveryDuration}</ProductDelivery>
        {withinSingapore}
      </SubHeading>
    )}
    {boughtInSevenDays && (
      <RecentPurchase>
        {boughtLabel}
        <RecentPurchaseDuration>{` ${boughtInSevenDays} ${timesLabel} `}</RecentPurchaseDuration>
        {inTheLastText}
        <RecentPurchaseDuration>{sevenDays}</RecentPurchaseDuration>
      </RecentPurchase>
    )}
  </div>
);

export default ProductDetails;

ProductDetails.propTypes = {
  boughtInSevenDays: PropTypes.number,
  productDeliveryDuration: PropTypes.string,
  productPrice: PropTypes.number,
  showPriceLabel: PropTypes.bool,
};

ProductDetails.defaultProps = {
  boughtInSevenDays: 0,
  productDeliveryDuration: '',
  productPrice: 0,
  showPriceLabel: false,
};
