import React, { createContext, useContext } from 'react';

import PropTypes from 'prop-types';

const AnalyticsContext = createContext();

export const useAnalytics = () => useContext(AnalyticsContext);

export const AnalyticsContainer = ({ children }) => {
  const checkDuplicateEvent = (eventLabel) => {
    const lastAnalyticsEvent = window?.dataLayer[window?.dataLayer?.length - 1] ?? {};
    // if same cta with same label is triggered continously, then its duplicate event
    return eventLabel === lastAnalyticsEvent.eventlabel;
  };

  /// [gTagReportConversion] registers a google ad conversion to ad console
  function gTagReportConversion(url) {
    window.dataLayer.push('js', new Date());
    window.dataLayer.push('config', 'AW-342787321');
    function callback() {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    }
    window.dataLayer.push('event', 'conversion', {
      send_to: 'AW-342787321/sI0gCICP3NMCEPmJuqMB',
      event_callback: callback,
    });
    return false;
  }

  const initiateTracking = (event) => {
    const {
      eventName,
      eventCategory,
      eventAction,
      eventLabel,
    } = event;
    const isDuplicateEvent = checkDuplicateEvent(eventLabel);
    if (!isDuplicateEvent) {
      const analyticsEvent = {
        event: eventName ?? '',
        eventcategory: eventCategory ?? '',
        eventaction: eventAction ?? '',
        eventlabel: eventLabel ?? '',
      };
      window.dataLayer.push(analyticsEvent);
      // if it is start mission event send it as conversion to google ads
      if (eventName === 'analytics_start mission') {
        gTagReportConversion(window.location.hostname);
      }
    }
  };

  const value = { initiateTracking };
  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
