import styled from 'styled-components';
import { CircularProgress, Grid } from '@material-ui/core';

export const CircularLoader = styled(CircularProgress)`
&&{
  color: ${(props) => props.color};
}`;
export const GridWrapper = styled(Grid)`
&&{
  min-height: ${(props) => !props.isButtonLoader && '100vh'};
}
`;
export default CircularLoader;
