import { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';

import Snackbar from 'components/common/SnackBar';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

// To display the snackbar with the dynamic text which is needed to be displayed
// notified to the user
export const SnackbarProvider = ({ children }) => {
  const [isShow, setIsShow] = useState(false);
  const [displayText, setDisplayText] = useState('');

  const value = {
    isShow,
    setIsShow,
    displayText,
    setDisplayText,
  };

  return (
    <SnackbarContext.Provider value={value}>
      <>
        {children}
        {/* // Displays the snackbar component with the required message */}
        {isShow && (
          <Snackbar
            open
            autoHideDuration={3000}
            onClose={() => setIsShow(false)}
            copySuccessMessage={displayText}
            handleClose={() => setIsShow(false)}
          />
        )}
      </>
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
