import styled from 'styled-components';

export const Title = styled.div`
  margin: 48px 48px 30px 48px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 32px 25px 27px 25px;
    font-size: ${(props) => props.theme.fontSize.secondary};
  }
`;

export const Circle = styled.div`
  height: 37px;
  width: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.theme.fontColor.dodgerBlue};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 30px;
    height: 30px;
  }
`;

export const Wrapper = styled.div`
  margin: 0 48px;
  display: flex;
  flex-directiom: row;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 0px 25px;
  }
`;

export const Description = styled.div`
  font-size: ${(props) => props.theme.fontSize.smMedium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 21px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 17px;
  }
`;

export const ShortDescription = styled.div`
  font-size: ${(props) => props.theme.fontSize.smMedium};
  color: ${(props) => props.theme.fontColor.carbonGrey};
  line-height: 21px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 17px;
  }
`;

export const ContentWrapper = styled.div`
  margin-left: 19px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-left: 16px;
  }
`;

export const Border = styled.div`
  margin: 15px 65px;
  width: 24px;
  height: 30px;
  border-left: 2px solid ${(props) => props.theme.border.ghost};
  display: ${(props) => (props.isConnector ? 'block' : 'none')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 13px 40px;
    height: 26px;
  }
`;

export const Image = styled.img`
  height: 36px;
  width: 36px;
`;
