import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import clevertap from 'clevertap-web-sdk';
import App from './App';
import reportWebVitals from './reportWebVitals';

clevertap.privacy.push({ optOut: false });
clevertap.privacy.push({ useIP: false });
clevertap.init(process.env.REACT_APP_ADVA_CLEVERTAP_ACCOUNT_ID);
clevertap.spa = true;
clevertap.setLogLevel(2);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
