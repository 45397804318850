// Constants file for analytics events

// event name
export const backToDashboardAnalytics = 'analytics_back to dashboard';
export const startLinkedMissionsAnalytics = 'analytics_start linked mission';
export const viewLinkedMissionAnalytics = 'analytics_view linked mission';
export const continueLinkedMissionAnalytics = 'analytics_continue linked mission';
export const continueMissionAnalytics = 'analytics_continue mission';
export const viewMissionAnalytics = 'analytics_view mission';
export const rewardsCollectedAnalytics = 'analytics_rewards collected';
export const submitBillAnalytics = 'analytics_submit bill';
export const viewRewardAnalytics = 'analytics_view reward';
export const recommendedMissionsAnalytics = 'analytics_recommended missions';
export const categoryViewAllAnalytics = 'analytics_category view all';
export const categoryViewMissionAnalytics = 'analytics_category view mission';
export const loginAnalytics = 'analytics_login';
export const missionCategoriesAnalytics = 'analytics_mission categories';
export const breadCrumbAnalytics = 'analytics_breadcrumb links';
export const missionCardAnalytics = 'analytics_mission card';
export const showMoreAnalytics = 'analytics_show more';
export const shareAnalytics = 'analytics_share';
export const advaWebsiteAnalytics = 'analytics_adva io website';
export const uploadAnalytics = 'analytics_upload';
export const reuploadBillAnalytics = 'analytics_reupload bill';
export const redoAnalytics = 'analytics_redo';
export const copyCodeAnalytics = 'analytics_copy coupon code';
export const aboutAdvaMissionsAnalytics = 'analytics_whats adva missions';
export const viewAllRewardsAnalytics = 'analytics_view all rewards';
export const privacyPolicyAnalytics = 'analytics_privacy policy';
export const termsAnalytics = 'analytics_terms and conditions';
export const cookieAnalytics = 'analytics_cookie policy';
export const changePhoneNumberAnalytics = 'analytics_change phone number';
export const referralRewardCollectedAnalytics = 'analytics_referral reward collected card';
export const referNowAnalytics = 'analytics_refer now';
export const closeReferralAnalytics = 'analytics_close referral reward collected card';
export const referralrewardAnalytics = 'analytics_referral reward get started continue';
export const referralReward = 'referral reward';
export const referAFriend = 'refer a friend';
export const copyReferralCodeAnalytics = 'analytics_copy referral code';
export const referralRewardCardAnalytics = 'analytics_referral reward card';
export const showMoreTestimonialsAnalytics = 'analytics_testimonials show more';
export const closeTestimonialsAnalytics = 'analytics_testimonials close';
export const missionTabsAnalytics = 'analytics_mission tabs';

// event category
export const navigation = 'navigation';
export const visitorAction = 'visitor action';
export const globalHeaderNavigation = 'global header navigation';

// event action
export const missions = 'missions';
export const missionProgress = 'mission progress';
export const missionReward = 'mission reward';
export const dashboard = 'dashboard';
export const featuredMissions = 'featured mission';
export const linkedMissions = 'linked mission';
export const previewBill = 'mission progress_preview bill';
export const allCategories = 'all categories';
export const recommendedMissions = 'recommended mission section';
export const missionsCategorySection = 'missions_category section';
export const missionProgressSection = 'mission progress_category section';
export const missionRewardSection = 'mission reward_category section';
export const preLoginDashboardCategory = 'prelogin dashboard_category section';
export const preLoginDashboard = 'prelogin dashboard';
export const dashboardCategorySection = 'dashboard_category section';
export const missionCard = 'mission card';
export const breadcrumb = 'breadcrumb';
export const allMissions = 'all missions';
export const share = 'share';
export const headerNavigation = 'header navigation';
export const advaIoWebsite = 'adva io website';
export const schedule = 'schedule';
export const aboutAdvaMissions = 'what’s adva missions';
export const rewardsCollected = 'rewards collected';
export const viewAllRewards = 'view all rewards';
export const loginVerify = 'login verify';
export const referralRewardCollectedCard = 'referral reward collected card';
export const closeReferralCard = 'close referral reward collected card';
export const copyReferralCode = 'copy referral code';
export const analytics = 'analytics';
export const referralrewardCard = 'referral reward card';
export const customerTestimonials = 'missions_customer testimonials';
export const missionTabs = 'missions_tabs';

// event label
export const closePopup = 'close popup';

// mission blogs
export const allBlogsEvent = 'learn more about sustainability';
export const blogViewAllAnalytics = 'analytics_blog view all';
export const blogSection = 'blog section';
export const blogCard = 'blog card';
export const blogs = 'blogs';
