import React, { useEffect, useState } from 'react';

import {
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';

import { useAuth } from 'provider/auth-provider';

import { referralLink } from 'utils/global-constants';
import Loader from 'components/common/Loader/Loader';
import Login from 'components/pages/Login/Login';
import AuthConnect from 'components/pages/AuthConnect';
import { checkPath } from 'utils/Missions/utils';
import routeConfigs from './RouteConfig';

const Routes = () => {
  const {
    isLoading,
    currentUser,
    fetchUserStats,
    setEnteredPath,
  } = useAuth();
  const history = useHistory();
  if (isLoading) return <Loader />;
  const [routeLoader, setRouteLoader] = useState(true);
  const checkAuth = (Component) => (currentUser ? <Component /> : <Redirect to='/home' />);

  useEffect(() => {
    // if the completedMissionsRefs is not empty then redirect to splash screen
    if (currentUser?.completedMissionsRefs && currentUser?.completedMissionsRefs.length > 0) {
      // store the user entered path
      setEnteredPath(window.location.pathname);
      // Loader required
      history.replace('/splash-screen');
    } else {
      setRouteLoader(false);
    }
  }, [currentUser
    && currentUser?.completedMissionsRefs
    && currentUser?.completedMissionsRefs?.length > 0]);

  useEffect(() => {
    // fetch the user data
    fetchUserStats();
    // Inorder to store the referral link in local storage if the user logs in through referral link
    if (!currentUser && checkPath('/invite')) {
      localStorage.setItem(referralLink, window.location.href);
    }
  }, []);

  // renders the routr component
  const renderComponent = (path, Component) => {
    // if the user is logged in redirect to normal dashboard
    if (path === '/home' && currentUser) {
      return <Redirect to='/' />;
    }
    return <Component />;
  };
  return routeLoader ? <Loader /> : (
    <Switch>
      <Route
        path='/auth/connect'
        exact
        render={() => <AuthConnect />}
      />
      <Route
        path='/login'
        exact
        render={() => (currentUser ? <Redirect to='/' /> : <Login />)}
      />
      <Route
        path='/login/verify'
        exact
        render={() => (currentUser ? <Redirect to='/' /> : <Login showOTP />)}
      />
      <Route
        path='/invite/:referralId'
        exact
        render={() => (currentUser ? <Redirect to='/referral-reward' /> : <Redirect to='/home' />)}
      />
      {routeConfigs.map((route) => (
        <Route
          key={route.key}
          path={route.path}
          exact={route.exact}
          render={() => (route.auth
            ? checkAuth(route.component) : renderComponent(route.path, route.component))}
        />
      ))}
    </Switch>
  );
};

export default Routes;
