import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { signInWithCustomToken } from 'services/Login/login-service';
import { useAuth } from '../../../provider/auth-provider';
import Loader from '../../common/Loader/Loader';

/**
 * *[AuthConnect]
 *  when the user logged in from Adva App then login using
 *  custom token which we are sending in the query string
 * @returns JSX
 */
export default function AuthConnect() {
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  const location = useLocation();
  // to get the custom token from the query string
  const token = new URLSearchParams(location.search).get('token');

  // `signInWithToken` function that used to sign in with custom token
  const signInWithToken = async () => {
    // when the user already logged in to adva missions
    // in the browser then logout the user first
    if (currentUser) {
      await logout();
    }
    try {
      // authenticate the user using the custom token which we got from query string
      const response = await signInWithCustomToken(token);
      if (response && response.user) {
        // if the token is valid then redirect to dashboard
        history.replace('/');
      } else {
        // if the token is invalid then redirect to login page
        history.replace('/login');
      }
    } catch {
      // if throws error then redirect to login page
      history.replace('/login');
    }
  };

  useEffect(() => {
    signInWithToken();
  }, []);

  // show the loader until it gets verified
  return (
    <Loader />
  );
}
