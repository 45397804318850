import React, { useEffect } from 'react';

import { useAuth } from 'provider/auth-provider';
import { SustainableProvider } from 'provider/sustainability-metrics-provider';
import { useAllMissions } from 'provider/dashboard-provider';
import { useReferrals } from 'provider/referral_provider';

import Loader from 'components/common/Loader/Loader';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import MissionBanner from 'components/pages/Dashboard/components/Missions';
import UserStatus from 'components/pages/Dashboard/components/UserStatus';
import EarnedRewards from 'components/pages/Dashboard/components/EarnedRewards';
import OngoingLinkedMissions from 'components/pages/Dashboard/OngoingLinkedMissions';
import FindAllMissionsContainer from 'components/containers/FindAllMissions';
import MissionBlogs from 'components/common/MissionBlogs';
import RedeemEcopoints from '../RedeemEcopoints';
import ReferralLinkCard from '../ReferralLinkCard';
import { Wrapper, FindAllMissionsWrapper, MissionBlogWrapper } from './style';

/**
 * * [DashboardLanding] is the parent component for all other
 * * components used in the Dashboard.
 * @returns <>
 *            <MissionBanner />
 *            <OtherMissions />
 *            <EarnedRewards />
 *            <AboutCarbonSaving />
 *          </>
 */
const DashboardLanding = () => {
  const { currentUser, fetchUserStats, authLoader } = useAuth();
  const {
    isLoading,
    ongoingMissions,
    missionBlogs,
    isAllBlogsLoading,
  } = useAllMissions();
  const { initiateTracking } = useAnalytics();
  const {
    referralLoader,
    referralData,
    referralLinksData,
    referralLinksLoader,
  } = useReferrals();

  // To fetch the userStats data of the current user
  useEffect(() => {
    fetchUserStats();
  }, []);

  const dashboardLandingAnalyticsEventHandler = (
    eventName,
    eventCategory,
    eventAction,
    eventLabel,
  ) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: `${eventCategory}`,
      eventAction: `${eventAction}`,
      eventLabel: `${eventLabel}`,
    });
  };

  return isLoading || authLoader || referralLoader || referralLinksLoader ? (
    <Loader />
  ) : (
    <>
      <MissionBanner
        activeMissions={ongoingMissions}
        analyticsEventHandler={dashboardLandingAnalyticsEventHandler}
      />
      <SustainableProvider>
        <UserStatus userStats={currentUser.stats} showTitle />
      </SustainableProvider>
      <Wrapper>
        <OngoingLinkedMissions />
      </Wrapper>
      <ReferralLinkCard
        referralData={referralData}
        currentUser={currentUser}
        referralLinksData={referralLinksData}
      />
      <FindAllMissionsWrapper>
        <FindAllMissionsContainer />
      </FindAllMissionsWrapper>
      {currentUser.ecoPoints && <RedeemEcopoints />}
      {isAllBlogsLoading ? <Loader /> : (
        missionBlogs?.blogs?.length > 0
        && (
          <MissionBlogWrapper>
            <MissionBlogs missionBlogs={missionBlogs} />
          </MissionBlogWrapper>
        ))}
      <EarnedRewards />
    </>
  );
};

export default DashboardLanding;
