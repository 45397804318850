import styled from 'styled-components';

const MarkdownWrapper = styled.div`
white-space: pre-line;
img{
    width: 100%;
  }
`;

export default MarkdownWrapper;
