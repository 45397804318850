import styled from 'styled-components';
import { MissionCardWrapper } from 'components/pages/Missions/components/ViewMissionCard/style';

export const AllMissionsContainer = styled.div`
padding: 8px 63px 0 81px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 0 24px;
  }
`;

export const AllMissionsCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & >:nth-child(3n) {
    & > :first-child {
      margin-right: 0px;
    }
  }
  & > :nth-child(n) {
    & >:first-child {
      margin-left: 2px;
    }
  }
  ${MissionCardWrapper} {
    width: 364px;
    margin: 0 22px 20px 0;
  }
  height: ${window.innerHeight - 265}px;
  overflow-y: scroll;
  margin-bottom: 72px;
  &::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.backgroundColor.silver};
    border-radius: 8px;
  }
  @media (min-width: ${(props) => (props.theme.breakpoints.tabletLarge)}) and (max-width: ${(props) => (props.theme.breakpoints.desktopMedium)}) {
    & >:nth-child(2n) {
      & > :first-child {
        margin-right: 0px;
      }
    }
    & >:nth-child(3n) {
      & > :first-child {
        margin-right: 22px;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    ${MissionCardWrapper} {
      width: inherit;
      margin: 0 0 8px 0;
    }
    flex-direction: column;
    margin: 8px 0 ${(props) => (props.showMore ? '88px' : '0px')} 0;
    height: 100%;
    overflow: unset;
  }
`;

export const CTAContainer = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    display: block;
    padding-top: 16px;
    cursor: pointer;
`;

export const ShowMore = styled.p`
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-family: ${(props) => props.theme.fontFamily.RobotoRegular};
    font-size: ${(props) => props.theme.fontSize.primary};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    line-height: 14px;
    letter-spacing: 0.25px;
    color: ${(props) => props.theme.fontColor.dodgerBlue};
    text-align: center;
    margin-bottom: 32px;
  }
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  left: 24px;
  right: 24px;
  bottom: 32px;
  box-shadow: ${(props) => (props.showMore ? props.theme.boxShadow.blackWithTopShadow : 'unset')};
`;
