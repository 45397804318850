import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Title = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 30px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fontSize.secondary};
      line-height: 24px;
    }
  }
`;

export const Wrapper = styled.div`
  margin-top: 32px;
`;

export const CarouselWrapper = styled.div`
  margin-left: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0;
  }
`;
