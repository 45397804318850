import React from 'react';
import PropTypes from 'prop-types';

import StarsIcon from '@material-ui/icons/Stars';

import { eWalletList } from 'utils/Dashboard/flow-constants';
import * as Strings from 'resources/strings';
import {
  WalletTitle,
  Points,
  EcopointsList,
  IconStatus,
} from './style';

/**
 * `RedeemList` to show the Users Ecopoints list
 *  Credited, Debited, Pending Ecopoints
 * @param {userEcoPoints} Object
 * @returns JSX
 */
const RedeemList = ({ userEcoPoints }) => (
  <EcopointsList>
    {Object.keys(eWalletList).map((wallet) => (
      <div>
        <div>
          <StarsIcon />
          <IconStatus>
            {eWalletList[wallet].icon}
          </IconStatus>
        </div>
        <Points variant='h5'>{userEcoPoints[wallet] ?? 0}</Points>
        <WalletTitle>{Strings.ecoPoints}</WalletTitle>
        <WalletTitle>{eWalletList[wallet].title}</WalletTitle>
      </div>
    ))}
  </EcopointsList>
);

RedeemList.propTypes = {
  userEcoPoints: PropTypes.shape,
};

RedeemList.defaultProps = {
  userEcoPoints: {},
};

export default RedeemList;
