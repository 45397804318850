import React from 'react';
import { useHistory } from 'react-router-dom';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import ResponseState from 'components/common/ResponseState';
import {
  Oops,
  paymentFailed,
  backToMissionCTALabel,
  paymentFailedDesc,
  olamSupportEmail,
} from 'resources/strings';
import SadSmiley from 'assets/images/sad_smiley.svg';
import { productMission } from 'utils/global-constants';

/// `PaymentFailed` renders UI for payment failed screen
const PaymentFailed = () => {
  const history = useHistory();
  const productMissionData = JSON.parse(sessionStorage.getItem(productMission));

  return (
    <BaseLayout hideGutters>
      <ResponseState
        greetingsMessage={Oops}
        subtitle={paymentFailed}
        image={SadSmiley}
        description={paymentFailedDesc}
        buttonText={backToMissionCTALabel}
        emailLink={olamSupportEmail}
        onClick={() => history.push(`/missions/${productMissionData?.productMissionId}`)}
      />
    </BaseLayout>
  );
};

export default PaymentFailed;
