import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useAuth } from 'provider/auth-provider';
import { productPaymentVerification } from 'services/Missions/missions-service';
import PaymentProcessingLanding from 'components/pages/PaymentProcessing/PaymentProcessingLanding';
import { productMission, sessionId } from 'utils/global-constants';

/// `PaymentProcessingContainer` container for payment processing screen
const PaymentProcessingContainer = () => {
  const history = useHistory();
  const { search } = window.location;
  const { currentUser, fetchUserStats } = useAuth();
  const searchParams = new URLSearchParams(search);
  const productMissionData = JSON.parse(sessionStorage.getItem(productMission));

  // `productVerification` calls the product verification API
  const productVerification = async (paymentSessionId) => {
    const response = await productPaymentVerification(
      currentUser.id,
      {
        sessionId: paymentSessionId,
        missionId: productMissionData?.productMissionId,
        linkedMissionIds: productMissionData?.productLinkedMissionIds,
      },
    );
    // redirect to congratulations page depends upon payment status
    if (response.status) {
      history.replace({
        pathname: `/missions/${response.data.missionId}/mission-reward`,
        search: `?reward-id=${response.data.rewardData.missionRewardId}`,
        state: { referralRewardId: response.data.rewardData.referralRewardId },
      });
    } else {
      history.replace({
        pathname: '/payment-failed',
      });
    }
  };

  useEffect(() => {
    if (!currentUser?.productMission?.productMissionId) {
      // fetch the user data
      fetchUserStats();
    }
  }, []);

  useEffect(() => {
    // get the payment session id
    const paymentSessionId = searchParams.get(sessionId);
    // to check the payment status
    productVerification(paymentSessionId);
  }, []);

  return <PaymentProcessingLanding />;
};

export default PaymentProcessingContainer;
