import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from 'provider/auth-provider';
import { useReferrals } from 'provider/referral_provider';
import { useSnackbar } from 'provider/snackbar-provider';

import HappySmiley from 'assets/images/happy_smiley.svg';
import ReferralRewardIcon from 'assets/images/referral-reward.svg';

import { continueToMissionCTALabel } from 'resources/strings';
import redirectToMission from 'helpers/RedirectToMissions/flow-helpers';
import ResponseState from 'components/common/ResponseState';
import { isRewardExhausted, getLoginStatus } from 'utils/Referrals/utils';
import { currentMission } from 'utils/global-constants';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as constants from 'utils/Analytics/flow-constants';

/// [ ReferralRewardContainer] to show the referral reward earned by the user
const ReferralRewardContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const { setIsShow, setDisplayText } = useSnackbar();
  const { referralLinksData } = useReferrals();
  const { initiateTracking } = useAnalytics();
  const [referrerData] = useState(location.state?.referrerData || {});
  const [currentMissionData] = useState(localStorage.getItem(currentMission) || false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { currentUser } = useAuth();
  const { referrerDoc } = referrerData?.data || {};
  // To get the current state data based on the response from the api
  const userStatusObj = getLoginStatus(referrerData?.message, referralLinksData);

  /// `goToDashboard` is used to redirect to the dashboard screen
  const goToDashboard = () => {
    initiateTracking({
      eventName: constants.referralrewardAnalytics,
      eventCategory: constants.navigation,
      eventAction: constants.referralReward,
      eventLabel: userStatusObj?.buttonText,
    });
    history.push('/');
  };

  /// `redirectionToMissionDetails` used to redirect to mission details screen
  /// when the user is logging in by starting a mission and using referral link shared.
  const redirectionToMissionDetails = async () => {
    // Analytics for continue mission
    initiateTracking({
      eventName: constants.referralrewardAnalytics,
      eventCategory: constants.navigation,
      eventAction: constants.referralReward,
      eventLabel: continueToMissionCTALabel,
    });
    setIsButtonLoading(true);
    await redirectToMission(currentUser.id, history, setIsShow, setDisplayText);
    setIsButtonLoading(false);
  };

  return (
    <ResponseState
      greetingsMessage={userStatusObj?.title}
      subtitle={userStatusObj?.subtitle}
      image={!isRewardExhausted(referrerDoc) ? ReferralRewardIcon : HappySmiley}
      description={userStatusObj?.desc}
      buttonText={currentMissionData
        ? continueToMissionCTALabel : userStatusObj?.buttonText}
      isButtonLoading={isButtonLoading}
      onClick={currentMissionData
        ? () => redirectionToMissionDetails()
        : () => goToDashboard()}
    />
  );
};
export default ReferralRewardContainer;
