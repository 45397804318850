import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const GreetingMessage = styled(Typography)`
&&{
  padding-bottom: 16px;
  text-align: center;
  color: ${(props) => props.theme.fontColor.dodgerBlue};
}
`;

export const HelpingMessage = styled(GreetingMessage)`
&&{
  padding: 16px 0;
  color: ${(props) => props.theme.fontColor.black};
}
`;

export const Wrapper = styled.div`
  margin-top: 40px;
  position: relative;
`;

export const SustainabilityMetrics = styled.div`
@media (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    && {
      display:grid;
      ${(props) => props.totalCount > 1 && 'grid-template-columns: auto auto;'}
    }
  }
  display:grid;
  ${(props) => props.totalCount === 2 && 'grid-template-columns: auto auto;'}
  ${(props) => props.totalCount === 3 && 'grid-template-columns: auto auto auto;'}
  ${(props) => props.totalCount > 3 && 'grid-template-columns: auto auto auto auto;'}
`;

export const MetricsCard = styled.div`
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
`;

export const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 5px;
  padding: 5px 0;
  margin: 0 20px;
  text-align: center;
`;

export const MetricsWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 0px 0px 0px 20px;
  }
  margin: 0px 0px 0px 40px;
  display: flex;
  justify-content: center;
`;
