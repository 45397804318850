import {
  checkUserMission,
  createUserMission,
  getPromoCode,
  getUserMissions,
} from 'services/Missions/missions-service';
import { errorMessage } from 'resources/strings';
import * as constants from 'utils/global-constants';
import checkLinkedMission from 'helpers/LinkedMission/flow-helpers';

/// `redirectToMission` function that helps to redirect to the particular mission
/// when the user login by starting any mission
const redirectToMission = async (
  mUserId,
  history,
  setIsShow,
  setDisplayText,
  setAuthLoader,
) => {
  // get the currentMission data from localStorage
  const currentMission = JSON.parse(localStorage.getItem(constants.currentMission));
  // to check whether the user has already started the mission
  const existingUserMissionId = await checkUserMission(mUserId, currentMission.missionId);
  if (existingUserMissionId.data.status !== constants.newMission
    && existingUserMissionId.data.status !== constants.abandoned) {
    // if the user already started the mission then
    // redirect to progress page without creating new mission
    // else create user Mission for the particular mission
    // if the user completed the mission then redirect to next available related mission
    if (existingUserMissionId.data.status === constants.completed) {
      // if the mission is in completed status then check for next available related mission
      checkLinkedMission({
        relatedMissions: currentMission.relatedMissions.filter((e) => e
          !== parseInt(currentMission.missionId, 10)),
        userId: mUserId,
        useHistory: history,
        setIsShowSnackbar: setIsShow,
        setSnackbarText: setDisplayText,
      });
    } else if (existingUserMissionId.data.status === constants.current) {
      // if the mission is in current status then redirecting to that mission progress page
      if (currentMission.verification === constants.affiliateLink && currentMission.affiliateLink && currentMission.affiliateLink !== '') {
        // if the verification Mechanism is affiliateLink then redirect to the affiliateLink
        window.open(currentMission.affiliateLink);
      }
      history.push({
        pathname: `/missions/${currentMission.missionId}/progress`,
        search: `?instance-id=${existingUserMissionId.data.userMissionId}`,
      });
    }
  } else {
    const ongoingMissions = await getUserMissions(
      constants.mUsers,
      mUserId,
      constants.mUserMissions,
      [constants.current],
      constants.status,
    );
    // if user already has 5 ongoing missions
    // then we should not allow him to start a new one
    if (ongoingMissions.docs.length < 5) {
      if (currentMission.relatedMissions.length > 0
        && currentMission.relatedMissions[0] !== parseInt(currentMission.missionId, 10)) {
        // if the user is trying to access any linked mission then
        // check for the sequence of the linked mission
        // and redirect to the respective mission
        checkLinkedMission({
          relatedMissions: currentMission.relatedMissions,
          userId: mUserId,
          useHistory: history,
          setIsShowSnackbar: setIsShow,
          setSnackbarText: setDisplayText,
          currentUserMission: currentMission,
          createMission: createUserMission,
          getMissionPromoCode: getPromoCode,
          setAuthLoading: setAuthLoader,
        });
      } else {
        // if the user not started the mission then create new mission
        // and redirecting to mission progress page
        // to get the promocode of the mission if the mission type is promocode
        if (currentMission.missionRewardType === constants.promoCode) {
          const promoCode = await getPromoCode(currentMission.missionRewardId);
          currentMission.promocode = promoCode?.data?.code;
        }
        // if the missions id having productCheckoutUrl then redirect to that url
        if (currentMission.verification === constants.productPurchase
          || currentMission.productCheckoutUrl != null) {
          setAuthLoader(true);
          // store the product mission ids in the session storage
          sessionStorage.setItem(constants.productMission,
            JSON.stringify({
              productMissionId: currentMission.missionId,
              productLinkedMissionIds: currentMission.relatedMissions,
            }));
          window.open(currentMission.productCheckoutUrl, '_self');
        } else {
          const response = await createUserMission(mUserId, currentMission);
          if (response.status === 200) {
            if (currentMission.verification === constants.affiliateLink && currentMission.affiliateLink && currentMission.affiliateLink !== '') {
              window.open(currentMission.affiliateLink);
            }
            history.push({
              pathname: `/missions/${currentMission.missionId}/progress`,
              search: `?instance-id=${response.data}`,
            });
          } else {
            // if api fails redirect to mission Landing
            history.push(`/missions/${currentMission.missionId}`);
            setIsShow(true);
            setDisplayText(errorMessage);
          }
        }
      }
    } else {
      history.push(`/missions/${currentMission.missionId}`);
      setIsShow(true);
      setDisplayText();
    }
  }
  localStorage.removeItem(constants.currentMission);
};

export default redirectToMission;
