import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Title = styled(Typography)`
  && {
    margin-left: 80px;
    line-height: 21px;
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      font-size: ${(props) => props.theme.fontSize.smMedium};
      margin: 0;
      text-align: center;
    }
  }
`;

export const CardWrapper = styled.div`
  margin: 24px 80px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: block;
    margin: 24px 24px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    display: block;
    margin: 24px 0;
  }
`;
