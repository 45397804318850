import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/Button/Button';
import RewardImage from 'components/pages/MissionReward/components/RewardImage';
import UserStatus from 'components/pages/Dashboard/components/UserStatus';
import RewardProgress from 'components/pages/MissionReward/components/RewardProgress';
import LinkedMissionsContainer from 'components/containers/Missions/LinkedMissionsContainer';
import FindAllMissionsContainer from 'components/containers/FindAllMissions';
import CouponCard from 'components/pages/MissionReward/components/CouponCard';
import ReferralRewardSection from 'components/pages/MissionReward/components/ReferralRewardSection';
import Loader from 'components/common/Loader/Loader';
import * as Strings from 'resources/strings';

import {
  GreetingMessage,
  Wrapper,
  HelpingMessage,
  ButtonWrapper,
  MetricsWrapper,
} from './style';

const Reward = ({
  showProgress,
  completedMissions,
  missionsData,
  bonusReward,
  metricsData,
  referralRewardSeen,
  userEarnedRewards,
  params,
  currentIndex,
  goToNext,
  goToDashboardHandler,
  currentUser,
  referralRewardId,
}) => (
  <>
    {showProgress && completedMissions && (
      <RewardProgress completedMissionsData={completedMissions ?? []} />
    )}
    <Wrapper>
      <GreetingMessage variant='h5'>{Strings.congratulations}</GreetingMessage>
      {missionsData.missionReward.rewardImage?.url && (
        <RewardImage
          image={missionsData.missionReward.rewardImage?.url}
          rewardName={missionsData.missionReward.name}
          rewardDescription={missionsData.title ?? ''}
        />
      )}
      {bonusReward && bonusReward?.code && (
        <CouponCard code={bonusReward?.code} description={bonusReward?.desc} />
      )}
      <HelpingMessage>{Strings.helpingDesc}</HelpingMessage>
      <MetricsWrapper>
        {metricsData != null ? (
          <UserStatus userStats={metricsData} />
        ) : (
          <Loader isButtonLoader />
        )}
      </MetricsWrapper>
      {(!referralRewardSeen
        || (referralRewardId !== null
        && referralRewardId !== ''))
        && (
        <ReferralRewardSection
          rewardDetails={userEarnedRewards[currentUser?.referrer?.rewardId]}
        />
        )}
      {(!showProgress
      || (showProgress
      && completedMissions
      && completedMissions[currentIndex]?.userRewardIds?.length >= 1)) && (
      <LinkedMissionsContainer missionDataId={params.missionId} isReward />
      )}
      <FindAllMissionsContainer />
      {!showProgress && (
        <ButtonWrapper>
          <Button
            title={Strings.goToDashboard}
            onClick={goToDashboardHandler}
          />
        </ButtonWrapper>
      )}
      {showProgress && completedMissions && (
        <ButtonWrapper>
          <Button
            title={
              currentIndex !== completedMissions?.length - 1
                ? Strings.viewNextRewardLabel
                : Strings.continueAdvaMissionLabel
            }
            onClick={goToNext}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  </>
);

Reward.propTypes = {
  showProgress: PropTypes.bool,
  completedMissions: PropTypes.arrayOf(PropTypes.any),
  missionsData: PropTypes.shape({
    missionReward: PropTypes.shape({
      name: PropTypes.string,
      rewardImage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    title: PropTypes.string,
  }),
  bonusReward: PropTypes.shape(PropTypes.any),
  metricsData: PropTypes.shape({
    carbonSaved: PropTypes.number,
    plasticConsumption: PropTypes.number,
  }),
  referralRewardSeen: PropTypes.bool,
  userEarnedRewards: PropTypes.shape(PropTypes.any),
  params: PropTypes.shape({
    missionId: PropTypes.number,
  }),
  currentIndex: PropTypes.number,
  goToNext: PropTypes.func,
  goToDashboardHandler: PropTypes.func,
  currentUser: PropTypes.shape({
    referrer: PropTypes.shape({
      rewardId: PropTypes.number,
    }),
  }),
  referralRewardId: PropTypes.string,
};

export default Reward;
