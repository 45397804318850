import styled from 'styled-components';
import { Divider } from '@material-ui/core';

export const CategoryTitle = styled.div`
  font-size: ${(props) => props.theme.fontSize.secondary};
  line-height: 22px;
`;

export const MissionCount = styled.div`
  font-size: ${(props) => props.theme.fontSize.secondary};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.fontColor.lavenderGrey};
  line-height: 22px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.description};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 22px;
  }
`;

export const Title = styled.div`
  font-family: ${(props) => props.theme.fontFamily.robotoBold};
  font-size: ${(props) => props.theme.fontSize.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  height: 29px;
  line-height: 16px;
`;

export const Wrapper = styled.div`
  width: 350px;
  margin: 3px 10px 0px 10px;
  padding: 20px 20px 26px 20px;
  border-radius: 11px;
  box-shadow: ${(props) => props.theme.boxShadow.greyShadow};
  overflow: hidden;
  display: inline-block;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 287px;
    margin: ${(props) => (props.isfirstChild ? '3px 10px 0px 26px' : '3px 10px 0px 0px')};
  }
`;

export const ListDivider = styled(Divider)`
  && {
    background: ${(props) => props.theme.border.lavenderGrey};
    margin: 28px 0;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 22px 0;
  }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-bottom: 22px;
  }
`;
