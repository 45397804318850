import { getSubCollection } from 'services/firebase-service';
import { get } from 'services/api-service';

// `getUserEarnedRewards` is used to get all the userRewards earned by the user
export const getUserEarnedRewards = async (userId) => {
  try {
    const response = await getSubCollection({
      collectionName: 'mUsers',
      docId: userId,
      subCollection: 'mUserRewards',
      orderByField: 'earnedDate',
      orderBy: 'desc',
    });
    return response;
  } catch (error) {
    return error;
  }
};

// `getAllRewards` is used to all the rewards of the missions
export const getAllRewards = async () => {
  try {
    const allRewards = await get('rewards', {});
    return allRewards;
  } catch (error) {
    return error;
  }
};
