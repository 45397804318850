import styled from 'styled-components';

const IconWrapper = styled.div`
 height: ${(props) => props.wrapperHeight ?? '58px'};
 width: ${(props) => props.wrapperWidth ?? '58px'};
 border-radius: 10px;
 display: flex;
 justify-content: center;
 align-items: center;
 background-color: ${(props) => props.color};
`;

export default IconWrapper;
