import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import * as Strings from 'resources/strings';
import { useAuth } from 'provider/auth-provider';

import Title from './style';

// [SplashScreen] will be shown before showing the completed missions on page load
const SplashScreen = () => {
  const { currentUser, fetchUserStats } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (currentUser?.completedMissionsRefs && currentUser?.completedMissionsRefs.length > 0) {
      // to show the splash screen for 2 secs and
      // redirect to the completed reward page
      setTimeout(() => {
        let missionId = '';
        let userRewardId = '';
        for (let i = 0; i < currentUser?.completedMissionsRefs.length; i += 1) {
          if (!currentUser?.completedMissionsRefs[i].seen) {
            missionId = currentUser?.completedMissionsRefs[i].missionId;
            userRewardId = currentUser?.completedMissionsRefs[i].userRewardIds.missionRewardId;
            break;
          }
        }
        if (missionId && userRewardId) {
          history.replace({
            pathname: `/missions/${missionId}/mission-reward`,
            search: `?reward-id=${userRewardId}`,
            state: { showProgress: true },
          });
        } else {
          history.replace('/');
        }
      }, 1500);
    } else {
      history.replace('/');
    }
  }, [currentUser
    && currentUser?.completedMissionsRefs
    && currentUser?.completedMissionsRefs?.length > 0]);

  useEffect(() => {
    if (!currentUser?.completedMissionsRefs) {
      // fetch the user data
      fetchUserStats();
    }
  }, []);

  return (
    <BaseLayout hideGutters showPoster={false}>
      <Title>{Strings.splashScreenTitle}</Title>
    </BaseLayout>
  );
};
export default SplashScreen;
