import React from 'react';
import { useHistory } from 'react-router-dom';

import * as constants from 'utils/Analytics/flow-constants';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as Strings from 'resources/strings';
import Button from 'components/common/Button/Button';
import { clevertapEventTracking } from 'utils/Clevertap/Clevertap';
import { loginToAdvaMissions } from 'utils/Clevertap/flow-constants';
import { Wrapper, Title, ButtonWrapper } from './style';

/**
 * * [Footer] component holds the button to login adva missions
*/
const Footer = () => {
  const history = useHistory();
  const { initiateTracking } = useAnalytics();

  // redirect to login page
  const routeToLogin = () => {
    // analytics for login event
    clevertapEventTracking(loginToAdvaMissions);
    initiateTracking({
      eventName: constants.loginAnalytics,
      eventCategory: constants.navigation,
      eventAction: constants.preLoginDashboard,
      eventLabel: Strings.loginToAdvaLabel,
    });
    history.push('/login');
  };
  return (
    <Wrapper>
      <Title>{Strings.footerTitle}</Title>
      <ButtonWrapper>
        <Button title={Strings.loginToAdvaLabel} onClick={() => routeToLogin()} />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Footer;
