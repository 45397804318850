import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const CashBackText = styled(Typography)`
&&{
  font-size: ${(props) => props.theme.fontSize.primary};
  text-align:center;
  font-weight: 300;
  line-height: 24px;
}
`;

export const RewardDescription = styled(Typography)`
&&{
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  text-align:center;
  line-height: 21px;
  padding: 0 20px 16px 20px;
}
`;

export const Cash = styled(CashBackText)`
&&{
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: 700;
  padding-bottom: 16px;
  color: ${(props) => props.theme.fontColor.dodgerBlue};
}
`;

export const Image = styled.img`
  border-radius: 10px;
  height: 200px;
  width: 200px;
`;
