import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const LoginWrapper = styled.div`
    margin: 40px 0;
    padding: 0 20px;
    background-color: ${(props) => props.theme.backgroundColor.white};
`;

export const LoginText = styled.div`
    margin-bottom: 20px;
    font-size:${(props) => props.theme.fontSize.large};
`;

export const CommunityDescription = styled(Typography)`
  && {
    font-size:${(props) => props.theme.fontSize.primary};
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 25px;
  }
`;

export const LoginDescription = styled.span`
    font-size:${(props) => props.theme.fontSize.primary};
    font-weight: 400;
    line-height: 21px;
`;

export const PhoneNumberField = styled.div`
   margin-bottom: 20px;
`;

export const OtpField = styled.div`
    margin-bottom: ${(props) => (props.showOtp ? '60px' : '200px')};
`;

export const ResendOtp = styled.div`
    margin-bottom: 20px;
    font-size:${(props) => props.theme.fontSize.primary};
`;

export const EditPhoneNumberLink = styled.div`
    text-align: right;
    padding: 10px;
`;

export const ResendOtpLink = styled(EditPhoneNumberLink)`
  text-align: left;
  font-size:${(props) => props.theme.fontSize.primary};
  color: ${(props) => props.theme.fontColor.dodgerBlue};
`;

export const ErrorMessage = styled.div`
  font-size:${(props) => props.theme.fontSize.description};
  color: ${(props) => props.theme.fontColor.negativeRed};
  margin: 10px 0;
  line-height: 18px;
`;

export const RefreshPageText = styled.div`
  font-size:${(props) => props.theme.fontSize.description};
  color: ${(props) => props.theme.fontColor.black};
  display: flex;
`;

export const LegalNoticeWrapper = styled(Typography)`
&& {
    font-size:${(props) => props.theme.fontSize.primary};
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
