import { get } from 'services/api-service';

// `getSustainabilityMetrics` to fetch the susbtainability metrics data
const getSustainabilityMetrics = async () => {
  try {
    const response = await get('sustainability-metrics', {});
    return response;
  } catch (error) {
    return error;
  }
};

export default getSustainabilityMetrics;
