import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const AuthorImage = styled.img`
  && {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
`;

export const AuthorLabel = styled(Typography)`
  color: ${(props) => props.theme.fontColor.carbonGrey};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  font-size: ${(props) => props.theme.fontSize.description};
  line-height: 18px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.secondary};
  }
`;

export const AuthorName = styled(Typography)`
  color: ${(props) => props.theme.fontColor.carbonBlack};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  font-size: ${(props) => props.theme.fontSize.secondary};
  line-height: 18px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.primary};
  }
`;

export const AuthorContent = styled(Typography)`
  p{
    color: ${(props) => props.theme.fontColor.carbonGrey};
    font-style: italic;
    margin-top: 14px;
    font-weight: ${(props) => (props.theme.fontWeight.normal)};
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 21px;
    letter-spacing: -0.48px;
    overflow-wrap: break-word;
  }
  h2{
    font-weight: ${(props) => (props.theme.fontWeight.medium)};
    margin-bottom: 10px;
    font-size: ${(props) => props.theme.fontSize.smMedium};
    line-height: 21px;
    letter-spacing: -0.48px;
    overflow-wrap: break-word;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      font-size: ${(props) => props.theme.fontSize.secondary};
    }
  }
  ul{
    margin-top: 0;
    margin-bottom: 1rem;
    list-style-type: disc;
  }
  li{
    display: list-item;
    font-weight: ${(props) => (props.theme.fontWeight.normal)};
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 21px;
    letter-spacing: -0.48px;
    overflow-wrap: break-word;
  }
`;
export const AuthorImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const AuthorNameWrapper = styled.div`
  margin-left: 16px;
`;
export const AuthorBioWrapper = styled.div`
  margin-top: 25px;
`;
