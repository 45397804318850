import React, { useState } from 'react';
import PropTypes from 'prop-types';

import themes from 'styles/theme';
import Spacer from 'components/common/Spacer/Spacer';
import { TabsWrapper, TabLabel } from './style';

/**
 *
 * @param {array} tabs --> name of the tabs
 * @param {node} children --> content displayed on the tabs
 * `TabsSection` common tabs that allow navigation between groups of content
 */
const TabsSection = ({
  tabs,
  children,
  initialValue,
  onTabChange,
  analyticsEventHandler,
}) => {
  const [value, setValue] = useState(initialValue);
  // `handleChange` to set current tab
  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    onTabChange(event, newValue);
    analyticsEventHandler(tabs[newValue]);
  };
  return (
    <div>
      <TabsWrapper
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: themes.border.dodgerBlue,
            height: '3px',
          },
        }}
        variant='fullWidth'
      >
        {tabs.map((tab) => (
          <TabLabel label={tab} />
        ))}
      </TabsWrapper>
      <Spacer height='16px' />
      {children.map((component, index) => (
        <>{index === value && <div>{component}</div>}</>
      ))}
    </div>
  );
};

export default TabsSection;

TabsSection.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  initialValue: PropTypes.number,
  onTabChange: PropTypes.func,
  analyticsEventHandler: PropTypes.func,
};

TabsSection.defaultProps = {
  tabs: [],
  initialValue: 0,
  onTabChange: (e) => {
    e.preventDefault();
  },
  analyticsEventHandler: () => {},
};
