import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Wrapper, StepperWrapper, Step } from './style';

/// [RewardProgress] shows the progress for completed missions in reward page
const RewardProgress = ({ completedMissionsData }) => {
  const [size, setSize] = useState(0);
  const params = useParams();
  useEffect(() => {
    // To set the width to 100 after the component being rendered
    // in order to show the progress transition
    setTimeout(() => {
      setSize(100);
    }, 0);
  }, [params.missionId]);
  return (
    <Wrapper>
      {completedMissionsData.map((mission, index) => (
        <StepperWrapper>
          <Step
            seen={mission.seen ?? false}
            key={mission.missionId}
            totalSteps={completedMissionsData.length}
            width={size}
            currentMission={parseInt(params.missionId, 10) === mission.missionId}
            currentIndex={completedMissionsData.indexOf(
              completedMissionsData.find(
                (missionData) => missionData.missionId === parseInt(params.missionId, 10),
              ),
            )}
            missionIndex={index}
          />
        </StepperWrapper>
      ))}
    </Wrapper>
  );
};
export default RewardProgress;

RewardProgress.propTypes = {
  completedMissionsData: PropTypes.arrayOf(PropTypes.any),
};
