import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import MarkdownWrapper from './style';

export default function Markdown({ content }) {
  return <MarkdownWrapper><ReactMarkdown skipHtml children={content} /></MarkdownWrapper>;
}

Markdown.propTypes = {
  content: PropTypes.string,
};
