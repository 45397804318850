import styled from 'styled-components';

export const CardWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 14px 0;
`;
export const CategoryWrapper = styled.div`
display: flex;
`;
export const ImageWrapper = styled.div`
height: 48px;
width: 48px;
`;
export const Category = styled.div`
padding: 0 12px;

`;
export const CategoryName = styled.div`
font-family: ${(props) => (props.theme.fontFamily.robotoRegular)};
font-weight: ${(props) => props.theme.fontWeight.normal};
font-size: ${(props) => props.theme.fontSize.primary};
color: ${(props) => (props.theme.fontColor.carbonBlack)};
line-height: 20px;
letter-spacing: 0.25px;
@media (max-width: ${(props) => (props.theme.breakpoints.mobileLandscape)}) {
    font-size: ${(props) => (props.theme.fontSize.primary)};
    line-height: 20px;
}
`;
export const MissionCount = styled.div`
font-family: ${(props) => (props.theme.fontFamily.robotoRegular)};
font-weight: ${(props) => props.theme.fontWeight.normal};
font-size: ${(props) => props.theme.fontSize.description};
color: ${(props) => (props.theme.fontColor.carbonGrey)};
line-height: 20px;
letter-spacing: 0.28px;
`;

export const IconWrapper = styled.div`
color: ${(props) => (props.theme.backgroundColor.dodgerBlue)};
`;

export const Image = styled.img`
height:100%;
width: 100%;
border-radius: 6px;
`;
