import styled from 'styled-components';

export const ActivityInfoWrapper = styled.div`
  padding: 0 44px;
  @media (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    && {
      padding: 0 24px;
    }
  }
`;

export const CardWrapper = styled.div`
  padding: 16px 0px 0px;
`;
