import React from 'react';

import Image from 'components/common/Image/Image';
import * as Strings from 'resources/strings';
import {
  Wrapper,
  Title,
  LogoWrapper,
  TitleWrapper,
  ImageWrapper,
} from './style';

/**
 * MissionsPoster is the poster for desktop view
 * @returns JSX
 */

const MissionsPoster = () => (
  <Wrapper>
    <ImageWrapper>
      <Image image='missions_poster.png' alt='missions-poster' width='inherit' height='inherit' />
    </ImageWrapper>
    <LogoWrapper>
      <Image image='adva_logo_black.png' width='70px' height='75px' />
      <TitleWrapper>
        <Title variant='h5'>
          {Strings.advaMissions}
        </Title>
        <Title variant='h6'>
          {Strings.futureText}
        </Title>
      </TitleWrapper>
    </LogoWrapper>
  </Wrapper>
);

export default MissionsPoster;
