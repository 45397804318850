import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import {
  missionBlogsTitle,
  viewAllLabel,
} from 'resources/strings';
import Carousel from 'components/common/Carousel';
import ViewMissionCard from 'components/pages/Missions/components/ViewMissionCard/index';
import ButtonLink from 'components/common/ButtonLink/ButtonLink';
import Spacer from 'components/common/Spacer/Spacer';
import getBlogPostShareLink from 'utils/BlogPosts/utils';
import {
  allBlogsEvent,
  analytics,
  blogCard,
  blogs,
  blogSection,
  blogViewAllAnalytics,
  dashboard,
  navigation,
  preLoginDashboard,
  share,
  visitorAction,
} from 'utils/Analytics/flow-constants';
import {
  Wrapper,
  Title,
  BlogCardWrapper,
  TitleWrapper,
} from './style';

/**
 * `MissionBlogs` used to display the blogs for adva missions
 * @returns JSX
 */
const MissionBlogs = ({ missionBlogs }) => {
  const blogCardRef = useRef();
  const history = useHistory();
  const [cardWidth, setCardWidth] = useState(0);
  const { initiateTracking } = useAnalytics();

  useEffect(() => {
    // dynamic width of the missionBlog card
    const maxCardWidth = blogCardRef?.current?.clientWidth;
    setCardWidth(maxCardWidth);
  }, [blogCardRef?.current?.clientWidth]);

  const analyticsEvents = ({
    eventName,
    eventCategory,
    eventAction,
    eventLabel,
  }) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: `${eventCategory}`,
      eventAction: `${eventAction}`,
      eventLabel: `${eventLabel}`,
    });
  };

  /// `getEventAction` to get the event action
  const getEventAction = () => {
    let eventAction = blogs;
    if (window.location.pathname === '/') {
      eventAction = dashboard;
    } else if (window.location.pathname === '/home') {
      eventAction = preLoginDashboard;
    } else if (window.location.pathname.includes(blogs)) {
      eventAction = blogs;
    } else if (window.location.pathname === '/learn-more-about-sustainability') {
      eventAction = allBlogsEvent;
    }
    return eventAction;
  };

  /// `redirectToAllBlogs` redirect to all blogs page
  const redirectToAllBlogs = () => {
    analyticsEvents({
      eventName: blogViewAllAnalytics,
      eventCategory: navigation,
      eventAction: `${getEventAction()}_${blogSection}`,
      eventLabel: viewAllLabel,
    });
    history.push('/learn-more-about-sustainability');
  };

  /// `shareBlogs` share the blog post
  const shareBlogsEvent = (blogName) => {
    analyticsEvents({
      eventName: `${analytics}_${share}`,
      eventCategory: visitorAction,
      eventAction: `${getEventAction()}_${blogSection}`,
      eventLabel: `${share}_${blogName.substring(1).replaceAll('-', ' ')}`,
    });
  };

  /// `redirectToParticularBlog` used to redirect to particular blog
  const redirectToParticularBlog = (blogId) => {
    analyticsEvents({
      eventName: `${analytics}_${blogCard}`,
      eventCategory: navigation,
      eventAction: `${getEventAction()}_${blogSection}`,
      eventLabel: `${blogCard}_${blogId.substring(1).replaceAll('-', ' ')}`,
    });
    history.push(`/blogs${blogId}`);
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{missionBlogsTitle}</Title>
        <ButtonLink
          title={viewAllLabel}
          onClick={() => redirectToAllBlogs()}
        />
      </TitleWrapper>
      <Spacer height='16px' />
      <Carousel
        cardWidth={cardWidth}
        showChevron
      >
        {missionBlogs.blogs.filter((blog) => blog.title).slice(0, 3).map((blog, index) => (
          (blog?.title
            && (
              <BlogCardWrapper ref={blogCardRef} isRequired={index === 0}>
                <ViewMissionCard
                  categoryImage={blog.imageUrl}
                  categoryName={moment(blog?.publishedAt).format('MMM DD yyy')}
                  missionTitle={blog?.title}
                  offer={blog?.authorName}
                  altText='blog-image'
                  missionId={blog.id}
                  offerImage={blog.authorImageUrl}
                  share={() => getBlogPostShareLink(blog?.id)}
                  isDangerouslyHTML
                  redirectToBlogsLanding={() => redirectToParticularBlog(blog.id)}
                  shareAnalyticsEvent={() => shareBlogsEvent(blog.id)}
                />
              </BlogCardWrapper>
            ))
        ))}
      </Carousel>
    </Wrapper>
  );
};
MissionBlogs.propTypes = {
  missionBlogs: PropTypes.arrayOf(PropTypes.object),
};

MissionBlogs.defaultProps = {
  missionBlogs: [],
};

export default MissionBlogs;
