import React, { useEffect, useState } from 'react';

import { useAllMissions } from 'provider/dashboard-provider';

import Loader from 'components/common/Loader/Loader';
import MissionsCategorySection from 'components/common/MissionsCategorySection';

/// [FindAllMissionsContainer] contains the all missions category
/// data required for mission category slider

const FindAllMissionsContainer = () => {
  const [allMissionsData, setAllMissions] = useState({});
  const {
    fetchAllMissionsCategories,
    allmissionCategories,
    missionCategoriesLoader,
    allMissions,
    missionCategories,
    isLoader,
    ongoingMissions,
    completedMissions,
  } = useAllMissions();

  useEffect(() => {
    // to fetch all missions based on category
    fetchAllMissionsCategories();
  }, [
    Object.keys(allMissions).length,
    Object.keys(ongoingMissions).length,
    Object.keys(completedMissions).length,
  ]);

  useEffect(() => {
    let missionCategoriesObj = { ...allmissionCategories };
    // To show only 6 categories if it exceeds 6 categories
    if (Object.keys(missionCategoriesObj).length > 6) {
      missionCategoriesObj = Object.fromEntries(
        Object.entries(missionCategoriesObj).slice(0, 6),
      );
    }
    // If a category has only one mission then dont show that category itself
    Object.keys(missionCategoriesObj).forEach((key) => {
      if (missionCategoriesObj[key].length < 2) {
        delete missionCategoriesObj[key];
      }
    });
    setAllMissions(missionCategoriesObj);
  }, [Object.keys(allmissionCategories).length]);

  return missionCategoriesLoader || isLoader ? (
    <Loader />
  ) : (
    <>
      {Object.keys(allMissionsData).length > 0 && (
        <MissionsCategorySection
          allMissionsCategories={allMissionsData}
          missionCategories={missionCategories}
        />
      )}
    </>
  );
};

export default FindAllMissionsContainer;
