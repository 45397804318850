import React from 'react';

import PropTypes from 'prop-types';

import CarbonEmissions from 'assets/images/carbon_savings.png';
import {
  Wrapper,
  ImageWrapper,
  Image,
  CountWrapper,
  Description,
  DashedBorder,
} from './style';

/**
 * * [CarbonSavings] returns the caorbon details description components
 * @param {object} data
 * @param {bool} isConnector
 * @returns <Wrapper>
 */
function CarbonSavings({ data, isConnector }) {
  return (
    <>
      <Wrapper>
        <ImageWrapper>
          {/* // TODO ( Manjusha ): [CarbonEmissions] need to be updated
          once the api integration is done */}
          <Image src={CarbonEmissions} alt='carbon-emissions' />
        </ImageWrapper>
        <CountWrapper>
          {data.id}
        </CountWrapper>
        <div>
          <Description>{data.description}</Description>
        </div>
      </Wrapper>
      <DashedBorder isConnector={isConnector} />
    </>
  );
}

CarbonSavings.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
  }),
  isConnector: PropTypes.bool,
};

CarbonSavings.defaultProps = {
  data: {
    id: '',
    description: '',
  },
  isConnector: false,
};

export default CarbonSavings;
