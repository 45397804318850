import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  margin: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 32px;
  }
`;

export const GreetingsMessage = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: ${(props) => props.theme.fontColor.dodgerBlue};
    text-align: center;
    line-height: 24px;
  }
`;
export const Description = styled(Typography)`
  && {
    color: ${(props) => (props.isEmptyState ? props.theme.fontColor.carbonGrey : props.theme.fontColor.carbonBlack)};
    font-size: ${(props) => props.theme.fontSize.smMedium};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    line-height: 25px;
    text-align: center;
    max-width: 412px;
    white-space: pre-line;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      font-size: ${(props) => props.theme.fontSize.primary};
      line-height: 21px;
    }
  }
`;

export const ImageWrapper = styled.div`
 width: 285px;
 height: 285px;
 margin: 32px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 217px;
    height: 217px;
    margin: 15% 0;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  width: ${(props) => (props.isEmptyState ? '327px' : '50%')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 90%;
  }
`;

export const EmailLink = styled.a`
  text-decoration: none;
`;
