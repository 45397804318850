import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';

export const BillImage = styled.img`
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      height: 400px;
    }
    height: 500px;
    object-fit: contain;
    width: 100%;
    margin: 24px 0px 24px 0;
`;

export const ConfirmBill = styled(Typography)`
    && {
        font-weight: 400;
        font-size: ${(props) => props.theme.fontSize.large};
    }
`;

export const CheckBillText = styled(Typography)`
    && {
        font-weight: 400;
        font-size: ${(props) => props.theme.fontSize.primary};
    }
`;

export const ReUploadButton = styled(Button)`
    && {
        text-align: center;
        text-transform: capitalize;
        width: 100%;
    }
`;

export const Wrapper = styled.div`
   padding: 20px 20px 0 20px;
`;
