import React from 'react';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import PaymentProcessingContainer from 'components/containers/PaymentProcessingContainer';

/// `PaymentProcessing` renders UI for payment processing screen
const PaymentProcessing = () => (
  <BaseLayout hideGutters>
    <PaymentProcessingContainer />
  </BaseLayout>
);

export default PaymentProcessing;
