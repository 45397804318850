import styled from 'styled-components';

export const ActivityInfoDesc = styled.div`
  font-size: ${(props) => props.theme.fontSize.primary};
  white-space: pre-line;
  line-height: 21px;
`;

export const UserStatusWrapper = styled.div`
  margin: 16px auto;
  > div > div {
    top: 0;
    display: grid;
    grid-template-columns: auto auto max-content;
    row-gap: 16px;
    margin: 0 36px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      row-gap: 24px;
      margin: 0;
    }
  }
  > div > div div {
    margin: 0;
  }
`;

export const Wrapper = styled.div`
  margin: 48px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 32px 0;
  }
`;
