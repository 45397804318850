import React from 'react';
import PropTypes from 'prop-types';

import { SpacerWrapper } from './style';

const Spacer = ({ height, width }) => <SpacerWrapper height={height} width={width} />;

export default Spacer;

Spacer.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
