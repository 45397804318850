import styled from 'styled-components';

import { Typography, Tooltip } from '@material-ui/core';

export const Stepper = styled.div`
  display: flex;
  margin: 8px 0px 8px;
  justify-content: center;
`;

export const Card = styled.div`
  padding: 16px 0px 24px;
`;

export const ProgressWrapper = styled.div`
  padding: 0 44px;
  @media (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    && {
      padding: 0 24px;
    }
  }
`;

export const ReuploadText = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.primary};
    color: ${(props) => props.theme.fontColor.dodgerBlue};
    font-weight: 500;
    line-height: 14px;
    cursor: pointer;
  }
`;

export const TooltipWrapper = styled(Tooltip)`
  && {
    background-color: ${(props) => props.theme.backgroundColor.white};
    font-size: ${(props) => props.theme.fontSize.primary};
    font-weight: 400;
  }
`;

export const StepperWrapper = styled.div`
  padding: 0 0 24px 0;
`;

export const KnowMoreSection = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.primary};
    color: ${(props) => props.theme.fontColor.silverChalice};
    margin-top: 14px;
  }
`;

export const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
