import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-left: 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-left: 52px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 0;
  }
`;

export const FindAllMissionsWrapper = styled.div`
  margin: 0px 52px 0px 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 0;
  }
`;

export const MissionBlogWrapper = styled.div`
  padding: 0 80px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 0;
  }
`;
