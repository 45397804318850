import React, {
  useState, useLayoutEffect, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Tooltip, withStyles } from '@material-ui/core';

import ErrorIcon from 'assets/images/failed-icon.svg';
import CompletedIcon from 'assets/images/completed-icon.svg';
import DisableIcon from 'assets/images/disabled-icon.svg';
import WhiteIcon from 'assets/images/white-icon.svg';
import OnProgress from 'assets/images/pending-icon.svg';
import themes from 'styles/theme';
import { Steps, StatusIcon, Indicator } from './style';

function TotalSteps({
  step,
  index,
  uploadImageProgress,
  onClick,
  repetitionCount,
  rejectedCount,
}) {
  const [showTooltip, setShowTooltip] = useState(true);
  function getIcon(status) {
    switch (status) {
      case 'submitted':
        return OnProgress;
      case 'verified':
        return CompletedIcon;
      case 'rejected':
        return ErrorIcon;
      case 'next':
        return WhiteIcon;
      default:
        return DisableIcon;
    }
  }
  function getIndicatorColor(status) {
    switch (status) {
      case 'submitted':
        return themes.backgroundColor.dodgerBlue;
      case 'verified':
        return themes.backgroundColor.seaGreen;
      case 'rejected':
        return themes.backgroundColor.negativeRed;
      default:
        return themes.backgroundColor.disabled;
    }
  }
  function getWidth(status) {
    switch (status) {
      case 'submitted':
        return '22px';
      case 'rejected':
        return '22px';
      default:
        return '14px';
    }
  }

  const TooltipWrapper = withStyles((theme) => ({
    tooltip: {
      padding: 12,
      backgroundColor: theme.palette.common.white,
      color: themes.fontColor.black,
      boxShadow: themes.boxShadow.whiteWithOpacity,
      fontSize: themes.fontSize.primary,
      width: 200,
      fontWeight: 400,
      borderRadius: 10,
    },
    arrow: {
      color: theme.palette.common.white,
      '&:before': {
        boxShadow: themes.boxShadow.whiteWithOpacity,
      },
    },
  }))(Tooltip);

  // to dynamically get the width based on the screen size
  function useWindowSize() {
    const [size, setSize] = useState('');
    useLayoutEffect(() => {
      function updateSize() {
        setSize(`${(document.getElementById('stepper').parentElement.clientWidth - (((repetitionCount - rejectedCount) * 14) + (rejectedCount * 22)))}px`);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const width = useWindowSize();
  const wrapperRef = useRef(null);
  // Closes the tooltip on clicked on outside of the element
  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Steps status={step.verificationStatus === 'submitted' || step.verificationStatus === 'rejected'} ref={wrapperRef}>
      <TooltipWrapper
        arrow
        open={step.open && step.comments !== '' && step.comments != null && showTooltip}
        title={step.comments}
        placement='top'
      >
        <StatusIcon
          src={getIcon(step.verificationStatus)}
          width={getWidth(step.verificationStatus)}
          status={step.verificationStatus}
          onClick={() => {
            setShowTooltip(true);
            onClick(step.id);
          }}
        />
      </TooltipWrapper>
      {index !== uploadImageProgress.length - 1 && (
        <Indicator
          indicatorcolor={getIndicatorColor(step.verificationStatus)}
          width={width}
          totalSteps={repetitionCount}
        />
      )}
    </Steps>
  );
}
export default TotalSteps;
TotalSteps.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string,
    verificationStatus: PropTypes.string,
    open: PropTypes.bool,
    comments: PropTypes.string,
  }),
  index: PropTypes.number,
  uploadImageProgress: PropTypes.arrayOf(PropTypes.any),
  onClick: PropTypes.func,
  repetitionCount: PropTypes.number,
  rejectedCount: PropTypes.number,
};
