import styled from 'styled-components';

const Separator = styled.div`
  width: 100%;
  opacity: 0.125;
  border: solid 0.5px ${(props) => (props.theme.border.cloudBlue)};
  background: ${(props) => (props.theme.backgroundColor.cloudBlue)};
`;

export default Separator;
