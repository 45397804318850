import React, { useEffect } from 'react';

import { useAllMissions } from 'provider/dashboard-provider';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as Strings from 'resources/strings';
import Loader from 'components/common/Loader/Loader';
import BackgroundBannerImage from 'assets/images/background_banner_image.png';
import MissionCard from 'components/pages/Dashboard/components/MissionCard';
import Carousel from 'components/common/Carousel';

import {
  Image,
} from 'components/pages/Dashboard/style';
import {
  Wrapper,
  Title,
  BackgroundImage,
  FeaturedMissionsWrapper,
} from './style';

/// [PreLoginDashboardBanner] component displays all the missions
function PreLoginDashboardBanner() {
  const {
    isMissionLoading,
    fetchFeaturedMissions,
    featuredMissions,
    isFeaturedMissionLoading,
  } = useAllMissions();
  const { initiateTracking } = useAnalytics();
  useEffect(() => {
    // to fetch all the featured missions
    fetchFeaturedMissions();
  }, []);

  // 'allCategoriesEventHandler` analytics event handler for all categories
  const analyticsEventHandler = (
    eventName,
    eventCategory,
    eventAction,
    eventLabel,
  ) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: `${eventCategory}`,
      eventAction: `${eventAction}`,
      eventLabel: `${eventLabel}`,
    });
  };

  return (isMissionLoading || isFeaturedMissionLoading) ? (
    <Loader />
  ) : (
    <Wrapper>
      <Title>{Strings.explorePopularTitle}</Title>
      <BackgroundImage>
        <Image src={BackgroundBannerImage} alt='background-image' />
      </BackgroundImage>
      <FeaturedMissionsWrapper>
        <Carousel missionsData={featuredMissions}>
          {Object.values(featuredMissions).map((missionsData, index) => (
            <MissionCard
              key={missionsData?.id}
              index={index}
              missionId={missionsData?.id}
              image={missionsData?.missionImage?.url}
              title={missionsData?.title}
              repetitionCount={missionsData?.repetitionCount}
              showBorder={false}
              analyticsEventHandler={analyticsEventHandler}
              linkedMissionflag
              rewardImage={missionsData?.missionReward?.rewardImage?.url}
              rewardName={missionsData?.missionReward?.name}
              showReward
              showGutter={false}
            />
          ))}
        </Carousel>
      </FeaturedMissionsWrapper>
    </Wrapper>
  );
}

export default PreLoginDashboardBanner;
