import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Divider } from '@material-ui/core';

import { useAllMissions } from 'provider/dashboard-provider';

import * as constants from 'utils/Analytics/flow-constants';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import Button from 'components/common/Button/Button';
import Loader from 'components/common/Loader/Loader';
import * as Strings from 'resources/strings';
import CategoryCard from 'components/pages/AllCategories/components/CategoryCard/index';
import RecommendedMissions from 'components/common/RecommendedMissions';
import Breadcrumb from 'components/common/BreadCrumb';
import { getCategoryName } from 'utils/Missions/utils';
import getEventAction from 'helpers/Analytics/analytics_helpers';
import MissionsFooter from 'components/common/MissionsFooter/index';
import Spacer from 'components/common/Spacer/Spacer';
import themes from 'styles/theme';
import AdvaCard from 'assets/images/adva_card.svg';
import * as Constants from 'utils/global-constants';
import ResponseState from 'components/common/ResponseState';
import HappySmiley from 'assets/images/happy_smiley.svg';
import { clevertapEventTracking } from 'utils/Clevertap/Clevertap';
import { viewCategory } from 'utils/Clevertap/flow-constants';
import {
  Title,
  Wrapper,
  ButtonWrapper,
  CategoryImageCard,
  Category,
  CategoryName,
  MissionCount,
  Image,
  CategoryWrapper,
} from './style';

/**
 * `AllCategoryCards` used to display all the category card
 *  available for missions
 * @returns JSX
 */
const AllCategoryCards = () => {
  const history = useHistory();
  const {
    fetchAllMissionsCategories,
    allmissionCategories,
    missionCategories,
    missionCategoriesLoader,
    isLoading,
    allMissions,
    ongoingMissions,
    completedMissions,
    allMissionData,
  } = useAllMissions();
  const { initiateTracking } = useAnalytics();
  const params = useParams();
  useEffect(() => {
    // to get all the category and missions based on category
    fetchAllMissionsCategories();
  }, [
    Object.keys(allMissions).length,
    Object.keys(ongoingMissions).length,
    Object.keys(completedMissions).length,
  ]);

  // `redirectToDashboard` redirects to the dashboard
  const redirectToDashboard = () => {
    // analytics for back to dashboard
    initiateTracking({
      eventName: constants.backToDashboardAnalytics,
      eventCategory: constants.navigation,
      eventAction: constants.allCategories,
      eventLabel: Strings.goToDashboard,
    });
    history.push('/');
  };

  // 'analyticsEventHandler` analytics event handler for share mission
  const analyticsEventHandler = (eventLabel) => {
    const categoryName = getCategoryName(missionCategories, params.categoryname);
    initiateTracking({
      eventName: constants.breadCrumbAnalytics,
      eventCategory: constants.navigation,
      eventAction: `${getEventAction({ breadCrumb: true, params, categoryName })}_${constants.breadcrumb}`,
      eventLabel: `${eventLabel}`,
    });
  };

  // `redirectToCategoryMissions` redirects to category missions page
  const redirectToCategoryMissions = (categoryKey, categoryName) => {
    // clevertap event for category card
    clevertapEventTracking(viewCategory, { 'mission-category': categoryName });
    // analytics for category card
    initiateTracking({
      eventName: constants.missionCategoriesAnalytics,
      eventCategory: constants.navigation,
      eventAction: constants.allCategories,
      eventLabel: categoryName,
    });
    history.push(`/all-categories/${categoryKey}`);
  };

  return missionCategoriesLoader || isLoading ? (
    <Loader />
  ) : (
    <>
      {allMissionData.length ? (
        <>
          <Breadcrumb analyticsEvent={analyticsEventHandler} />
          <Title>{Strings.allCategoryLabel}</Title>
          {window.innerWidth <= parseInt(themes.breakpoints.mobileLandscape, 10) && <Divider />}
          <Wrapper>
            {window.innerWidth <= parseInt(themes.breakpoints.mobileLandscape, 10)
              && (
                <>
                  <CategoryCard
                    categoryImage={AdvaCard}
                    categoryName={Strings.allMissions}
                    categoryKey={Constants.allMissions}
                    missionsCount={allMissionData.length}
                    redirectToCategoryMissions={redirectToCategoryMissions}
                  />
                  <Divider />
                  {missionCategories.map((category) => (
                    (allmissionCategories[category?.key]?.length > 0) && (
                      <>
                        <CategoryCard
                          categoryImage={category?.image?.url}
                          categoryName={category.name}
                          categoryKey={category.key}
                          missionsCount={allmissionCategories[category.key].length}
                          redirectToCategoryMissions={redirectToCategoryMissions}
                        />
                        <Divider />
                      </>
                    )
                  ))}
                </>
              )}
            {window.innerWidth > parseInt(themes.breakpoints.mobileLandscape, 10) && (
              <CategoryWrapper>
                <>
                  <CategoryImageCard
                    onClick={() => redirectToCategoryMissions(Constants.allMissions)}
                  >
                    <Image src={AdvaCard} alt='category' />
                    <Category>
                      <CategoryName>{Strings.allMissions}</CategoryName>
                      <MissionCount>{`${allMissionData.length} ${allMissionData.length > 1 ? Strings.missions : Strings.mission}`}</MissionCount>
                    </Category>
                  </CategoryImageCard>
                </>
                {missionCategories.map((category) => (
                  (allmissionCategories[category?.key]?.length > 0) && (
                    <>
                      <CategoryImageCard onClick={() => redirectToCategoryMissions(category.key)}>
                        <Image src={category?.image?.url} alt='category' />
                        <Category>
                          <CategoryName>{category.name}</CategoryName>
                          <MissionCount>{`${allmissionCategories[category.key].length} ${allmissionCategories[category.key].length > 1 ? Strings.missions : Strings.mission}`}</MissionCount>
                        </Category>
                      </CategoryImageCard>
                    </>
                  )
                ))}
              </CategoryWrapper>
            )}
          </Wrapper>
          <RecommendedMissions />
          {window.innerWidth <= parseInt(themes.breakpoints.mobileLandscape, 10) ? (
            <ButtonWrapper>
              <Button title={Strings.goToDashboard} onClick={() => redirectToDashboard()} />
            </ButtonWrapper>
          ) : (
            <>
              <Spacer height='100px' />
              <MissionsFooter goToDashboard={() => redirectToDashboard()} />
            </>
          )}
        </>
      ) : (
        <ResponseState
          image={HappySmiley}
          description={Strings.missionsEmptyText}
          buttonText={Strings.goToDashboard}
          onClick={() => history.push('/')}
          isEmptyState
        />
      )}
    </>
  );
};

export default AllCategoryCards;
