import React from 'react';

import { useHistory } from 'react-router-dom';

import PageNotFoundImage from 'assets/images/page_not_found.svg';

import * as Strings from 'resources/strings';
import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import Button from 'components/common/Button/Button';

import {
  ImageWrapper,
  Subtitle,
  Description,
  ButtonWrapper,
} from './style';

/// [PageNotFound] This page will be displayed when the path given by the user does not exist
const PageNotFound = () => {
  const history = useHistory();
  return (
    <BaseLayout>
      <ImageWrapper>
        <img src={PageNotFoundImage} alt='Page not found' />
      </ImageWrapper>
      <Subtitle>{Strings.pageNotFoundTitle}</Subtitle>
      <Description>{Strings.pageNotFoundDesc}</Description>
      <ButtonWrapper>
        <Button
          title={Strings.goToHomepage}
          name='goToHomepage'
          id='goToHomepage'
          onClick={() => history.push('/')}
        />
      </ButtonWrapper>
    </BaseLayout>
  );
};
export default PageNotFound;
