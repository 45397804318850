import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import EarnedRewardsTitle from 'components/pages/EarnedRewards/components/EarnedRewardsTitle';
import RewardsList from 'components/pages/EarnedRewards/components/RewardsList';
import Button from 'components/common/Button/Button';
import RewardDialog from 'components/pages/EarnedRewards/components/RewardDialog';
import * as Strings from 'resources/strings';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as constants from 'utils/Analytics/flow-constants';
import themes from 'styles/theme';
import {
  RewardsContainer,
  ButtonContainer,
  ButtonWrapper,
  RewardsCount,
} from 'components/pages/EarnedRewards/styles';

/**
 *
 * @param {object} rewardsData
 * `ViewEarmedRewards` renders the container for rewards title,
 *  rewards list and the button at the bottom
 */
const ViewEarnedRewards = ({ rewardsData }) => {
  const history = useHistory();
  const { initiateTracking } = useAnalytics();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [linkedRewardsData, setLinkedRewardsData] = useState([]);

  /// `goToDashboard` is used to redirect to the dashboard screen
  const goToDashboard = () => {
    // analytics for go to dashboard cta
    initiateTracking({
      eventName: constants.backToDashboardAnalytics,
      eventCategory: constants.navigation,
      eventAction: constants.rewardsCollected,
      eventLabel: Strings.goToDashboard,
    });
    history.push('/');
  };

  // `toggleDialog` function opens the dialog box
  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  // `getLinkedRewardsData` gets linked rewards data
  // to show in the dialog box
  const setRewardsData = (data) => {
    setLinkedRewardsData(data);
  };

  return (
    <>
      <EarnedRewardsTitle />
      <RewardsContainer>
        <RewardsList
          rewardsListData={rewardsData}
          openDialog={toggleDialog}
          setRewardsData={setRewardsData}
        />
      </RewardsContainer>
      <ButtonContainer>
        {window.innerWidth
        > parseInt(themes.breakpoints.mobileLandscape, 10) && (
          <RewardsCount>
            {Strings.viewMissionsText}
            <b>{` ${Object.keys(rewardsData).length}`}</b>
            {` ${Strings.rewards}`}
          </RewardsCount>
        )}
        <ButtonWrapper>
          <Button
            title={Strings.goToDashboard}
            onClick={() => goToDashboard()}
          />
        </ButtonWrapper>
      </ButtonContainer>
      {isDialogOpen && (
        <RewardDialog
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={toggleDialog}
          linkedRewardsData={linkedRewardsData}
        />
      )}
    </>
  );
};

ViewEarnedRewards.propTypes = {
  rewardsData: PropTypes.shape({
    missionName: PropTypes.string,
    earnedDate: PropTypes.shape(),
    id: PropTypes.string,
    missionId: PropTypes.string,
    primary: PropTypes.shape(),
    userRewardId: PropTypes.string,
  }),
};

ViewEarnedRewards.defaultProps = {
  rewardsData: {},
};

export default ViewEarnedRewards;
