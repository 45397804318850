import styled from 'styled-components';

import LinkedSeriesWrapper from 'components/pages/EarnedRewards/components/RewardsList/style';

export const TitleWrapper = styled.div`
  display: flex;
  padding: 27px 32px 19px 30px;
  justify-content: space-between;
`;

export const Title = styled.h5`
  font-family: ${(props) => props.theme.fontFamily.robotoRegular};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.25px;
`;

export const CloseBtn = styled.div`
  width: 12px;
  height: 12px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

// Styling the cards inside the dialog-box
// Description: we are hiding the container that has both
// line-divider and the CTA, for the first card on the dialog-box.
export const RewardsDialogWrapper = styled(LinkedSeriesWrapper)`
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.backgroundColor.silver};
    border-radius: 8px;
  }
  & > :not(:first-child) {
    display: block;
  }
  background-color: ${(props) => props.theme.backgroundColor.selago};
  padding: 33px 13px 33px 35px;
  & > :first-child {
    & > :last-child {
      & > :last-child {
        display: none;
      }
    }
  }
`;
