import PropTypes from 'prop-types';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as Strings from 'resources/strings';
import {
  CardWrapper,
  CategoryWrapper,
  ImageWrapper,
  Category,
  CategoryName,
  MissionCount,
  IconWrapper,
  Image,
} from './style';

/**
 * `CategoryCard` category card for particular mission category
 * @param {categoryName}
 * @param {missionsCount}
 * @param {categoryImage}
 * @returns JSX
 */
const CategoryCard = ({
  categoryName,
  missionsCount,
  categoryImage,
  categoryKey,
  redirectToCategoryMissions,
}) => (
  <CardWrapper onClick={() => redirectToCategoryMissions(categoryKey, categoryName)}>
    <CategoryWrapper>
      <ImageWrapper>
        <Image src={categoryImage} alt='category' />
      </ImageWrapper>
      <Category>
        <CategoryName>{categoryName}</CategoryName>
        <MissionCount>{`${missionsCount} ${missionsCount > 1 ? Strings.missions : Strings.mission}`}</MissionCount>
      </Category>
    </CategoryWrapper>
    <IconWrapper>
      <ChevronRightIcon />
    </IconWrapper>
  </CardWrapper>
);

CategoryCard.propTypes = {
  categoryName: PropTypes.string,
  missionsCount: PropTypes.number,
  categoryImage: PropTypes.string,
  categoryKey: PropTypes.string,
  redirectToCategoryMissions: PropTypes.func,
};

CategoryCard.defaultProps = {
  categoryName: '',
  missionsCount: 0,
  categoryImage: '',
  categoryKey: '',
  redirectToCategoryMissions: () => { },
};

export default CategoryCard;
