import styled from 'styled-components';

import PrimaryButton from 'components/common/Button/style';

export const MissionCount = styled.div`
  font-family: ${(props) => props.theme.fontFamily.RobotoRagular};
  font-size: ${(props) => props.theme.fontSize.secondary};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  line-height: 22px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.fontColor.black};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.dataLength ? 'space-between' : 'flex-end')};
  align-items: center;
  background: ${(props) => props.theme.backgroundColor.white};
  padding: 27px 72px 24px 80px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: ${(props) => props.theme.boxShadow.blackWithTopShadow};
  ${PrimaryButton} {
    max-width: 410px;
  }
`;
