import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const MissionsWrapper = styled.div`
  && {
    background: url(${(props) => props.backgroundurl});
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom-left-radius: 50% 12%;
    border-bottom-right-radius: 50% 12%;
    clip-path: ellipse(100% 100% at top);
    height: 460px;
  }
`;

export const MissionDetailsWrapper = styled.div`
  margin: 29px 43px 0px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 31px 26px 0px;
  }
`;

export const MissionCategory = styled.div`
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  font-size: ${(props) => props.theme.fontSize.description};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  text-transform: uppercase;
  line-height: 18px;
`;

export const MissionTitleWrapper = styled(Typography)`
  && {
    display: flex;
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 30px;
    justify-content: space-between;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      font-size: ${(props) => props.theme.fontSize.medium};
      line-height: 24px;
    }
  }
`;

export const IconWrapper = styled.div`
  height: 32px;
  width: 32px;
  margin-right: 9px;
`;

export const Wrapper = styled.div`
  display: flex;
  > * {
    margin-right: 8px;
    &:not(:first-child) {
      :before {
        content: '. ';
        font-size: ${(props) => props.theme.fontSize.large};
      }
    }
  }
`;
export const ProductDetailsWrapper = styled.div`
  margin: 16px 0px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 16px 0px;
  }
`;

export const MissionCompleted = styled(MissionCategory)`
  text-transform: capitalize;
`;
