import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import Spacer from 'components/common/Spacer/Spacer';

import * as constants from 'utils/Analytics/flow-constants';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as Strings from 'resources/strings';
import { getEventAction } from 'utils/Missions/utils';
import { clevertapEventTracking } from 'utils/Clevertap/Clevertap';
import {
  Title,
  MissionImage,
  ImageWrapper,
  Wrapper,
  MissionCTA,
  RewardDesc,
  RewardImageWrapper,
  RewardWrapper,
  MissionDetailsWrapper,
} from './style';

/// [AllMissionsCard] shows the mission card to displayed on the find all missions component
const AllMissionsCard = ({
  missionId,
  title,
  missionImage,
  rewardImage,
  rewardName,
  missionCategory,
}) => {
  const history = useHistory();
  const { initiateTracking } = useAnalytics();

  // `routeToMissions` routes to the particular mission
  const routeToMissions = () => {
    // clevertap event for view mission
    clevertapEventTracking(Strings.viewMissionlabel, {
      'mission-name': title,
      'mission-category': missionCategory,
      'mission-reward': rewardName,
    });
    // analytics for view mission
    initiateTracking({
      eventName: constants.categoryViewMissionAnalytics,
      eventCategory: constants.navigation,
      eventAction: getEventAction(),
      eventLabel: `${Strings.viewMissionlabel}_${title}`,
    });
    history.push(`/missions/${missionId}`);
  };

  return (
    <Wrapper>
      <ImageWrapper>
        <MissionImage src={missionImage} />
      </ImageWrapper>
      <MissionDetailsWrapper>
        <Title>{title}</Title>
        <Spacer height='14px' />
        {rewardImage && (
          <RewardWrapper>
            <RewardImageWrapper>
              <MissionImage src={rewardImage} />
            </RewardImageWrapper>
            <RewardDesc>{rewardName}</RewardDesc>
          </RewardWrapper>
        )}
        <Spacer height='8px' />
        <MissionCTA onClick={() => routeToMissions()}>{Strings.viewMissionlabel}</MissionCTA>
      </MissionDetailsWrapper>
    </Wrapper>
  );
};
export default AllMissionsCard;

AllMissionsCard.propTypes = {
  missionId: PropTypes.string,
  title: PropTypes.string,
  missionImage: PropTypes.string,
  rewardImage: PropTypes.string,
  rewardName: PropTypes.string,
  missionCategory: PropTypes.string,
};

AllMissionsCard.defaultProps = {
  missionId: '1',
  title: 'Try Soy Milk with your Coffee',
  missionImage: '',
  rewardImage: '',
  rewardName: '',
  missionCategory: '',
};
