import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Img, ImageWrapper } from './style';

/**
 * Common Image component for the app
 * @param {String} Image
 * @param {String} height
 * @param {String} width
 * @param {String} alt
 * @returns JSX
 */

const Image = ({
  image, height, width, alt,
}) => {
  const images = require.context('assets/images', true);
  const loadImage = useCallback((imageName) => (images(`./${imageName}`).default), []);
  return (
    <ImageWrapper height={height} width={width}>
      <Img src={loadImage(image)} alt={alt} />
    </ImageWrapper>
  );
};

Image.propTypes = {
  image: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  alt: PropTypes.string,
};

Image.defaultProps = {
  image: 'starbuck-reward.png',
  height: '100%',
  width: '100%',
  alt: 'image',
};

export default Image;
