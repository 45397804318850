import React from 'react';

import PropTypes from 'prop-types';

import { LogoutContainer, LogoutText, LogoutButton } from './styles';

/**
 * Logout Content of tooltip on the appbar.
 * @returns JSX
 */
const Logout = ({ phoneNumber, onClick }) => (
  <LogoutContainer>
    <LogoutText>
      Logged in as &nbsp;
    </LogoutText>
    <LogoutText>
      {phoneNumber.slice(0, 3)}
      {' '}
      {phoneNumber.slice(3, 8)}
      {' '}
      {phoneNumber.slice(8, 13)}
    </LogoutText>
    <LogoutButton color='primary' onClick={onClick}>Logout</LogoutButton>
  </LogoutContainer>
);

export default Logout;

Logout.propTypes = {
  phoneNumber: PropTypes.string,
  onClick: PropTypes.func,
};
