import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import MissionRewardSection from 'components/pages/EarnedRewards/components/MissionRewardSection';
import themes from 'styles/theme';
import * as Strings from 'resources/strings';
import {
  TitleWrapper,
  Title,
  CloseBtn,
  RewardsDialogWrapper,
} from './style';

const useStyles = makeStyles({
  rootStyle: {
    zIndex: '1 !important',
    top: '90px !important',
  },
});

/**
 * Reward Dialog Component
 * @returns JSX
 */
const RewardDialog = ({ isDialogOpen, linkedRewardsData, setIsDialogOpen }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      PaperProps={{
        style: {
          borderRadius: '24px',
        },
      }}
      classes={{
        root: classes.rootStyle,
      }}
    >
      <>
        <TitleWrapper>
          <Title>{Strings.completedRewardsText}</Title>
          <CloseBtn onClick={() => setIsDialogOpen(false)}>
            <CloseIcon style={{ color: themes.fontColor.dodgerBlue }} />
          </CloseBtn>
        </TitleWrapper>
        <RewardsDialogWrapper>
          <MissionRewardSection
            missionName={`${Strings.missionSeriesTitle}`}
            earnedDate={linkedRewardsData?.earnedDate}
            rewardName={linkedRewardsData?.primary?.name}
            rewardImage={linkedRewardsData?.primary?.image}
            shortDesc={linkedRewardsData?.primary?.shortDesc}
            isBigReward
            rewardType={linkedRewardsData?.type}
            isDialogFlag={false}
          />
          {linkedRewardsData?.linkedMissions?.map((reward) => (
            <MissionRewardSection
              missionName={reward?.missionName}
              earnedDate={reward?.earnedDate}
              rewardName={reward?.primary?.name}
              rewardImage={reward?.primary?.image}
              shortDesc={reward?.primary?.shortDesc}
              isBigReward={false}
              missionId={reward?.missionId}
              rewardId={reward?.id}
              rewardType={linkedRewardsData?.type}
              isDialogFlag
            />
          ))}
        </RewardsDialogWrapper>
      </>
    </Dialog>
  );
};

RewardDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  linkedRewardsData: PropTypes.shape({
    earnedDate: PropTypes.shape(),
    primary: PropTypes.shape(),
    type: PropTypes.string,
    linkedMissions: PropTypes.arrayOf(
      PropTypes.shape({
        missionName: PropTypes.string,
        earnedDate: PropTypes.shape(),
        primary: PropTypes.shape({
          name: PropTypes.string,
          shortDesc: PropTypes.string,
        }),
      }),
    ),
    missionId: PropTypes.string,
    id: PropTypes.string,
  }),
  setIsDialogOpen: PropTypes.func,
};

export default RewardDialog;
