import styled from 'styled-components';

import { BreadCrumbWrapper } from 'components/common/BreadCrumb/style';
import { FixedContainer } from 'components/common/BaseLayout/styles';
import PrimaryButton from 'components/common/Button/style';

export const RewardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 39px 0 80px;
  margin: 8px 0 0 0;
  max-height: ${window.innerHeight - 268}px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.backgroundColor.silver};
    border-radius: 8px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    flex-direction: column;
    padding: unset;
    overflow: unset;
    max-height: unset;
    margin: 8px 0 108px 0;
  }
`;

export const EarnedRewardsTitleContainer = styled.div`
  position: sticky;
  padding: 8px 0 16px 80px;
  background-color: ${(props) => props.theme.backgroundColor.white};
  box-shadow: ${(props) => props.theme.boxShadow.blackWithBottomShadow};
  z-index: 1;
  top: 64px;
  ${BreadCrumbWrapper} {
    margin: 8px 0px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    position: sticky;
    top: 56.5px;
    padding: 8px 0 16px 20px;
  }
`;

export const RewardsTitle = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.robotoRegular};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.fontColor.carbonBlack};
  font-size: ${(props) => props.theme.fontSize.large};
  line-height: 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.secondary};
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: ${(props) => props.theme.boxShadow.blackWithTopShadow};
  background-color: ${(props) => props.theme.backgroundColor.white};
  padding: 27px 63px 24px 80px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 16px 0 32px 0;
  }
`;

export const ButtonWrapper = styled.div`
  ${PrimaryButton} {
    width: 327px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 0 28px 0 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const RewardsCount = styled.p`
  margin-top: 25px;
`;

export const LayoutContainer = styled.div`
  ${FixedContainer} {
    margin-bottom: 0px;
  }
`;
