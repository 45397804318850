import React from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as constants from 'utils/Analytics/flow-constants';
import Breadcrumb from 'components/common/BreadCrumb';
import getEventAction from 'helpers/Analytics/analytics_helpers';
import { AllMissionsTitleContainer, AllMissionsTitle } from './style';

/**
 *
 * @param {bool} showMore
 * @param {string} categoryName
 * @param {array} missionCategories
 * `MissionsTitle` is used to display the title for all missions page
 * * depending upon params value
 */
const MissionsTitle = (props) => {
  const { showMore, missionTitle, analyticsEventActionName } = props;
  const params = useParams();
  const { initiateTracking } = useAnalytics();

  // 'analyticsEventHandler` analytics event handler for share mission
  const analyticsEventHandler = (eventLabel) => {
    initiateTracking({
      eventName: constants.breadCrumbAnalytics,
      eventCategory: constants.navigation,
      eventAction: `${analyticsEventActionName ?? getEventAction({ breadCrumb: true, params, categoryName: missionTitle })}_${constants.breadcrumb}`,
      eventLabel: `${eventLabel}`,
    });
  };

  return (
    <AllMissionsTitleContainer showMore={showMore}>
      <Breadcrumb analyticsEvent={analyticsEventHandler} />
      <AllMissionsTitle>
        {missionTitle}
      </AllMissionsTitle>
    </AllMissionsTitleContainer>
  );
};

MissionsTitle.propTypes = {
  showMore: PropTypes.bool,
  missionTitle: PropTypes.string,
  analyticsEventActionName: PropTypes.string,
};

MissionsTitle.defaultProps = {
  showMore: true,
  missionTitle: 'all-missions',
  analyticsEventActionName: '',
};

export default MissionsTitle;
