import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import ResponsiveDialog from 'components/common/Dialog/Dialog';
import DatePicker from 'components/common/DatePicker/DatePicker';
import * as constants from 'utils/Analytics/flow-constants';

/**
 * SMSReminder Component which will open date field inside a dialog and helps
 * user to schedule sms based on the selected date
 *
 * @param {openDialog}
 * @returns JSX
 */
function Reminder({
  scheduleSMSReminder,
  userMissionId,
  analyticsEventHandler,
  setShowSmsReminderPopup,
  title,
}) {
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // if user doesnt selects any date from calender
  // the default date should be set to next day 12:00 AM
  const [scheduledDate, setScheduluedDate] = useState(moment(Date()).add(1, 'days').hours(0).startOf('hour')
    .unix() * 1000);
  const getSelectedDate = (date) => {
    if (date) {
      setScheduluedDate(date);
    }
  };
  useEffect(() => {
    // to remove the data from localStorage while opening the reminder popup
    localStorage.removeItem('showSmsReminderPopUp');
  }, []);
  const scheduleSMS = async () => {
    setIsLoading(true);
    await scheduleSMSReminder(userMissionId, scheduledDate);
    // analytics event for schedule reminder
    analyticsEventHandler(
      'analytics_schedule',
      'visitor action',
      'mission progress',
      `${constants.schedule}_${title}`,
    );
    setIsLoading(false);
    setOpen(false);
    setShowSmsReminderPopup(false);
  };
  const closeDialog = () => {
    setOpen(false);
    setShowSmsReminderPopup(false);
  };
  return (
    <ResponsiveDialog
      title='Set an SMS reminder'
      description='Let us help you keep on track. Tell us when you’d like to be reminded next.'
      handleAgree={scheduleSMS}
      handleDismiss={closeDialog}
      openDialog={open}
      agreeText='Schedule'
      dismissText='Dismiss'
      component={(<DatePicker getSelectedDate={getSelectedDate} format='MM/dd/yyyy' />)}
      isLoading={isLoading}
    />
  );
}

export default Reminder;

Reminder.propTypes = {
  scheduleSMSReminder: PropTypes.func,
  userMissionId: PropTypes.string,
  analyticsEventHandler: PropTypes.func,
  setShowSmsReminderPopup: PropTypes.func,
  title: PropTypes.string,
};

Reminder.defaultProps = {
  scheduleSMSReminder: () => { },
  analyticsEventHandler: () => { },
  userMissionId: '',
  setShowSmsReminderPopup: () => { },
  title: '',
};
