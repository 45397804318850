import {
  getCollection,
  updateDocumentField,
  updateDocumentArray,
} from 'services/firebase-service';
import { post } from 'services/adva-server-service';

import * as Constants from 'utils/global-constants';

/**
 * `fetchUserData` get user data.
 * @param {*} userId
 * @returns
 */
export const fetchUserData = async (userId) => {
  try {
    const userData = await getCollection(Constants.mUsers, userId);
    return userData;
  } catch (error) {
    return error;
  }
};

// `updateCompletedMissionStatus` update the array of completed refs with status
export const updateCompletedMissionStatus = async (
  userId,
  completedTasksRef,
  removeMissionData,
) => {
  try {
    const response = updateDocumentArray(
      Constants.mUsers,
      userId,
      Constants.completedMissionsRefs,
      completedTasksRef,
      removeMissionData,
    );
    return response;
  } catch (error) {
    return error;
  }
};

// `deleteUserCompletedMissionReward` delete all the completed mission refs from user doc
export const deleteUserCompletedMissionReward = async (userId) => {
  try {
    const response = updateDocumentField(
      Constants.mUsers,
      userId,
      { completedMissionsRefs: [] },
    );
    return response;
  } catch (error) {
    return error;
  }
};

// `createAccountWithReferralLink` to creare user doc with referral link
export const createAccountWithReferralLink = async (userId, data) => {
  try {
    const response = await post(
      `/m-users/${userId}/new-user-signup`,
      data,
    );
    if (response?.status === 200) {
      return response?.data;
    }
    return {};
  } catch (error) {
    console.error(error);
    return {};
  }
};
