import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as constants from 'utils/Analytics/flow-constants';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import Share from 'components/common/Share';
import getEventAction from 'helpers/Analytics/analytics_helpers';
import {
  MissionCardWrapper,
  MissionCardContent,
  ImageWrapper,
  CardDescWrapper,
  CategoryTitle,
  CategoryDescription,
  OfferDescription,
  ShareMissionWrapper,
  Image,
  OfferWrapper,
  OfferImageWrapper,
  OfferImage,
} from './style';

/**
 *
 * @param {string} image
 * @param {string} title
 * @param {string} description
 * @param {string} offer
 * @param {string} altText
 * @param {Object} share
 * @returns ViewMissionCard to view mission card for a particular category
 */
const ViewMissionCard = (props) => {
  const history = useHistory();
  const {
    categoryImage,
    categoryName,
    missionTitle,
    offer,
    altText,
    share,
    missionId,
    offerImage,
    isDangerouslyHTML,
    redirectToBlogsLanding,
    shareAnalyticsEvent,
  } = props;
  const { initiateTracking } = useAnalytics();
  const params = useParams();

  // 'analyticsEventHandler` analytics event handler for share mission
  const analyticsEventHandler = () => {
    initiateTracking({
      eventName: constants.shareAnalytics,
      eventCategory: constants.visitorAction,
      eventAction: getEventAction({ params, categoryName }),
      eventLabel: `${constants.share}_${missionTitle}`,
    });
  };

  // `redirectToMissionLanding` redirect to mission landing page
  const redirectToMissionLanding = (event) => {
    event.stopPropagation();
    event.preventDefault();
    // analytics for mission landing page
    initiateTracking({
      eventName: params.categoryname
        ? constants.missionCardAnalytics
        : constants.recommendedMissionsAnalytics,
      eventCategory: constants.navigation,
      eventAction: getEventAction({ params, categoryName }),
      eventLabel: `${constants.missionCard}_${missionTitle}`,
    });
    history.push(`/missions/${missionId}`);
  };

  return (
    <MissionCardWrapper onClick={
      redirectToBlogsLanding
        ? () => redirectToBlogsLanding(missionId)
        : (event) => redirectToMissionLanding(event)
    }
    >
      <MissionCardContent>
        <ImageWrapper>
          <Image src={categoryImage} altText={altText} />
        </ImageWrapper>
        <CardDescWrapper>
          <CategoryTitle>{categoryName.toUpperCase()}</CategoryTitle>
          {isDangerouslyHTML
            ? <CategoryDescription dangerouslySetInnerHTML={{ __html: missionTitle }} />
            : <CategoryDescription>{missionTitle}</CategoryDescription>}
          <OfferWrapper>
            {offerImage
              && (
                <OfferImageWrapper>
                  <OfferImage src={offerImage} />
                </OfferImageWrapper>
              )}
            <OfferDescription>{offer}</OfferDescription>
          </OfferWrapper>
        </CardDescWrapper>
      </MissionCardContent>
      <ShareMissionWrapper>
        <Share
          title={share?.shareTitle}
          url={share?.shareLink}
          text={share?.shareText}
          analyticsEvent={shareAnalyticsEvent ?? analyticsEventHandler}
        />
      </ShareMissionWrapper>
    </MissionCardWrapper>
  );
};

ViewMissionCard.propTypes = {
  categoryImage: PropTypes.string,
  altText: PropTypes.string,
  categoryName: PropTypes.string,
  missionTitle: PropTypes.string,
  offer: PropTypes.string,
  share: PropTypes.instanceOf(Object),
  missionId: PropTypes.number,
  offerImage: PropTypes.string,
  isDangerouslyHTML: PropTypes.bool,
  redirectToBlogsLanding: PropTypes.func,
  shareAnalyticsEvent: PropTypes.func,
};

ViewMissionCard.defaultProps = {
  categoryImage:
    '',
  altText: '',
  categoryName: '',
  missionTitle: '',
  offer: '',
  offerImage: '',
  share: {
    shareLink: '',
    shareText: '',
    shareTitle: '',
  },
  isDangerouslyHTML: false,
  shareAnalyticsEvent: null,
};

export default ViewMissionCard;
