import React from 'react';

import { DashboardProvider } from 'provider/dashboard-provider';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import EarnedRewardsContainer from 'components/containers/EarnedRewardsContainer';

import { LayoutContainer } from './styles';

/**
 * EarnedRewards Component.
 * @returns JSX
 */
const EarnedRewards = () => (
  <LayoutContainer>
    <DashboardProvider>
      <BaseLayout showPoster={false} hideGutters>
        <EarnedRewardsContainer />
      </BaseLayout>
    </DashboardProvider>
  </LayoutContainer>
);
export default EarnedRewards;
