import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
    display:flex;
    border-radius: 16px;
    max-width: ${(props) => (props.isPreLogin ? 'calc((100% - 50px) / 3)' : '605px')};
    margin: ${(props) => (props.isPreLogin ? '0' : '0 46px')};
    background-color: ${(props) => props.theme.backgroundColor.blackSqueeze};
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
        margin: 0 24px;
      }
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        max-width: 605px;
    }
`;

export const MissionCardContent = styled.div`
      display:flex;
`;

export const ImageWrapper = styled.div`
  width: 135px;
  height: 135px;
  padding: 32px 0 33px 28px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 26px 0 24px 23px;
    width: 110px;
    height: 110px;
  }
`;

export const Image = styled.img`
   height: 100%;
   width: 100%;
   border-radius: 4px;
`;

export const Description = styled(Typography)` &&
{
  padding: 12px 12px;
  width: ${(props) => (props.isPreLogin ? '50%' : '60%')};
  font-size: ${(props) => props.theme.fontSize.secondary};
  line-height: 22px;
  margin: auto;
  div > p
  {
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.isPreLogin ? '5' : '3')};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    div > p
    {
    -webkit-line-clamp: 5;
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
    }
  }
}
    
`;

export const DashedBorder = styled.div`
    border-left: 2px dashed;
    border-color: ${(props) => props.theme.border.jungleMist};
    height: 30px;
    display: ${(props) => (props.isConnector ? 'block' : 'none')};
`;

export const PreLoginBorder = styled.div`
    border-top: 2px dashed;
    color: ${(props) => props.theme.border.jungleMist};
    height:30px;
    width: 25px;
    margin-top: 30px;
    display: ${(props) => (props.isConnector ? 'block' : 'none')};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin: 0;
      width: 0;
      border-left : 2px dashed;
      border-top: 0px;
    } 
`;
