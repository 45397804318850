import React from 'react';

import { DashboardProvider } from 'provider/dashboard-provider';
import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import AboutMissionsContainer from 'components/containers/AboutMissions';
import { AboutMissionsProvider } from 'provider/about-missions-provider';

/// `AboutMissions` is renders UI for about adva missions page with BaseLayout
const AboutMissions = () => (
  <BaseLayout hideGutters>
    <DashboardProvider>
      <AboutMissionsProvider>
        <AboutMissionsContainer />
      </AboutMissionsProvider>
    </DashboardProvider>
  </BaseLayout>
);

export default AboutMissions;
