import { db, fieldValue } from 'config/firebase-service';

/**
 * Common function for fetching firestore api.
 * @param {*} collectionName
 * @param {*} docId
 * @param {*} subCollection
 * @param {*} subDocId
 * @returns
 */
export const getCollection = async (
  collectionName,
  docId,
  subCollection = '',
  subDocId = '',
) => {
  if (subCollection) {
    return (
      await db
        .collection(collectionName)
        .doc(docId)
        .collection(subCollection)
        .doc(subDocId)
        .get()
    ).data();
  }
  return db.collection(collectionName).doc(docId).get().then((data) => data.data());
};

/**
 * function for fetching subCollection with filters.
 * @param {*} collectionName
 * @param {*} docId
 * @param {*} subCollection
 * @param {*} listOfData
 * @param {*} fieldName
 * @returns
 */

export const getSubCollectionWithFilters = (
  collectionName,
  docId,
  subCollection = '',
  listOfData = [],
  fieldName = 'id',
) => {
  const response = db
    .collection(collectionName)
    .doc(docId)
    .collection(subCollection)
    .where(fieldName, 'in', listOfData);
  return response;
};

export const getSubCollection = async ({
  collectionName,
  docId,
  subCollection = '',
  orderByField,
  orderBy = 'desc',
}) => {
  let response;
  if (subCollection && orderByField && orderBy) {
    response = await db
      .collection(collectionName)
      .doc(docId)
      .collection(subCollection)
      .orderBy(orderByField, orderBy)
      .get();
  } else if (subCollection) {
    response = await db
      .collection(collectionName)
      .doc(docId)
      .collection(subCollection)
      .get();
  } else {
    response = (await db.collection(collectionName).doc(docId).get()).data();
  }
  return response;
};

export const createDocument = async (
  collectionName,
  docId,
  data,
) => {
  const response = await db
    .collection(collectionName)
    .doc(docId).set(data);
  return response;
};

/// [getCollectionByOrder] fetches the collection in required order
export const getCollectionByOrder = (
  collectionName,
  userId,
  status,
  orderBy,
) => {
  const response = db
    .collection(collectionName)
    .where('userId', '==', userId)
    .where('status', 'in', status)
    .orderBy('reminderDate', orderBy);
  return response;
};

/// `updateDocumentField` updates the required field in the document
export const updateDocumentField = (
  collectionName,
  docId,
  data,
) => {
  const response = db
    .collection(collectionName)
    .doc(docId)
    .set(data, { merge: true });
  return response;
};

/// `updateDocumentArray` updates the required array field in the document
export const updateDocumentArray = (
  collectionName,
  docId,
  fieldName,
  arrayUnionData,
  arrayRemoveData,
) => {
  // remove the element which has to be updated
  db
    .collection(collectionName)
    .doc(docId)
    .update(fieldName, fieldValue.arrayRemove(arrayRemoveData));
  // add the element with updated value
  db
    .collection(collectionName)
    .doc(docId)
    .update(fieldName, fieldValue.arrayUnion(arrayUnionData));
};
