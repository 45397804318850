import React, { useState } from 'react';

import moment from 'moment';

import { TableRow, TableBody, CircularProgress } from '@material-ui/core';

import { useNotifications } from 'provider/notifications-provider';
import { useAuth } from 'provider/auth-provider';

import * as Strings from 'resources/strings';
import ButtonLink from 'components/common/ButtonLink/ButtonLink';
import {
  NotificationsNone,
  NotificationTable,
  HyperLinkTag,
  NotificationTableCell,
  NotificationText,
  NotificationDate,
  NotificationStatusWrapper,
} from './styles';
/**
 * Notifications Content of tooltip on the appbar.
 * @returns Notifications
 */

const Notifications = () => {
  const {
    notifications,
    updateNotificationState,
    setOpenNotifications,
    setNotificationsStatus,
  } = useNotifications();

  const {
    fetchUserStats,
    currentUser,
  } = useAuth();

  const [disableLoading, setDisableLoading] = useState(false);

  /// [getDate] returns data in date and month format
  const getDate = (date) => {
    const momentData = moment(date.seconds * 1000).format('DD MMMM');
    const today = moment().format('DD MMMM');
    if (today === momentData) {
      return moment(date.seconds * 1000).format('hh:mm A');
    }
    return momentData;
  };

  /// [navigateUserTo] navigates user to external link
  const navigateUserTo = (docId, state) => {
    if (state === 'unread') {
      updateNotificationState(docId);
    }
    setOpenNotifications(false);
  };

  /// `setDisableStatus` sets the disable status of notifications and updates the data
  const setDisableStatus = async () => {
    setDisableLoading(true);
    await setNotificationsStatus();
    await fetchUserStats();
    setDisableLoading(false);
  };

  return (
    <div>
      <NotificationTable>
        <TableBody>
          {notifications.length > 0 ? (
            notifications.map((data, index) => (
              <div>
                <HyperLinkTag
                  href={data.notificationUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => navigateUserTo(data.id, data.state)}
                >
                  <TableRow key={data.description}>
                    <NotificationTableCell
                      component='th'
                      scope='row'
                      isBorder={index === notifications.length - 1}
                    >
                      <NotificationText state={data.state === 'unread'}>
                        {data.description}
                      </NotificationText>
                    </NotificationTableCell>
                    <NotificationTableCell
                      padding='none'
                      align='right'
                      isBorder={index === notifications.length - 1}
                    >
                      <NotificationDate>
                        {getDate(data.reminderDate)}
                      </NotificationDate>
                    </NotificationTableCell>
                  </TableRow>
                </HyperLinkTag>
              </div>
            ))
          ) : (
            <NotificationsNone>{Strings.notificationsNone}</NotificationsNone>
          )}
        </TableBody>
      </NotificationTable>
      <NotificationStatusWrapper>
        <NotificationText state={false}>
          {!currentUser.disableNotification
            ? Strings.notificationsTurnOffDesc
            : Strings.notificationsTurnOnDesc}
        </NotificationText>
        {disableLoading ? (
          <CircularProgress size={18} />
        ) : (
          <ButtonLink
            title={
              !currentUser.disableNotification
                ? Strings.turnOff
                : Strings.turnOn
            }
            onClick={() => {
              setDisableStatus();
            }}
          />
        )}
      </NotificationStatusWrapper>
    </div>
  );
};

export default Notifications;
