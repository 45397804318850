import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  margin: 34px 30px 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 20px 24px 16px;
  }
`;

export const Title = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 30px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fontSize.medium};
      line-height: 28px;
    }
  }
`;
/* To override styling from CustomerRatingCard */
export const CustomerRatingCardWrapper = styled.div`
  > * {
    &:first-child {
      box-shadow: none;
      height: unset;
      width: unset;
      padding: 0;
      margin: 0;
    }
  }
  p {
    display: block;
  }
`;

export const Link = styled.p`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  line-height: 26px;
  text-transform: uppercase;
  text-align: right;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 24px;
  }
`;
