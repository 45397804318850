import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ShareIcon from '@material-ui/icons/Share';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import { useSnackbar } from 'provider/snackbar-provider';
import copyText from 'utils/Missions/utils';
/**
 * Share Component
 * @returns JSX
 */
const Share = ({
  title, url, text, analyticsEvent, color,
}) => {
  const { setIsShow, setDisplayText } = useSnackbar();
  const [shareSupport, setShareSupport] = useState(false);
  try {
    useEffect(() => {
      if (navigator.share) {
        setShareSupport(true);
      }
    }, false);
  } catch (e) {
    console.error(e);
  }

  const shareIt = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (shareSupport) {
      analyticsEvent();
      navigator.share({
        title,
        text,
        url,
      })
        .then(() => console.info('Successful share'))
        .catch((error) => console.error('Error occured while sharing', error));
    }
  };

  const copyLink = (event) => {
    event.stopPropagation();
    event.preventDefault();
    copyText(url);
    analyticsEvent();
    setIsShow(true);
    setDisplayText('Link Copied');
  };

  return (
    <>
      {shareSupport
        ? (
          <IconButton size='small' aria-label='share' color='inherit' onClick={(event) => shareIt(event)}>
            <ShareIcon fontSize='small' style={{ color }} />
          </IconButton>
        ) : (
          <IconButton size='small' aria-label='share' color='inherit' onClick={(event) => copyLink(event)}>
            <FileCopyIcon fontSize='small' style={{ color }} />
          </IconButton>
        )}
    </>
  );
};

export default Share;

Share.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
  analyticsEvent: PropTypes.func,
  color: PropTypes.string,
};

Share.defaultProps = {
  title: 'Adva Missions',
  url: 'https://www.advamissions.com',
  text: 'Hey, you might find this mission interesting. Check it out here',
  analyticsEvent: () => { },
  color: 'inherit',
};
