import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useAllMissions } from 'provider/dashboard-provider';

import * as Strings from 'resources/strings';
import Loader from 'components/common/Loader/Loader';
import BackgroundBannerImage from 'assets/images/background_banner_image.png';
import MissionCard from 'components/pages/Dashboard/components/MissionCard';
import {
  ScrollWrapper,
  Image,
} from 'components/pages/Dashboard/style';
import {
  Wrapper,
  Greetings,
  BackgroundImage,
  Description,
  MissionCardWrapper,
} from './style';

/// [MissionBanner] component displays the active count of the missions completed
function MissionBanner({ activeMissions, analyticsEventHandler }) {
  const [greetings, setGreetings] = useState('');
  const {
    isMissionLoading,
    fetchFeaturedMissions,
    featuredMissions,
    isFeaturedMissionLoading,
  } = useAllMissions();
  const [hour, setHour] = useState();

  useEffect(() => {
    // to fetch all the featured missions
    fetchFeaturedMissions();
  }, []);

  // To Greet the user accordingly
  useEffect(() => {
    const date = new Date();
    setHour(date.getHours());
    const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;
    setGreetings(wish);
  }, [hour]);

  /// `getDescriptionText` returns the description dynamically based on the user mission activity
  const getDescriptionText = () => {
    if (Object.keys(activeMissions).length > 0) {
      return Strings.makeProgressDesc;
    }
    if (Object.keys(featuredMissions).length > 0) {
      return Strings.startMissionsDesc;
    }
    return Strings.stayTunedDesc;
  };

  // TODO (Manjusha): Horizontal scroll wrapper should be moved to the commons
  return (isMissionLoading || isFeaturedMissionLoading) ? (
    <Loader />
  ) : (
    <Wrapper>
      <Greetings>{greetings}</Greetings>
      <Description
        spacing={Object.keys(activeMissions).length || featuredMissions.length}
      >
        {Strings.welcomeToMissions}
        {Strings.whatIsAdva}
        <br />
        <br />
        {Strings.aboutAdva}
        <br />
        <br />
        {getDescriptionText()}
      </Description>
      <BackgroundImage>
        <Image src={BackgroundBannerImage} alt='background-image' />
      </BackgroundImage>
      <MissionCardWrapper>
        <ScrollWrapper>
          {Object.keys(activeMissions).length !== 0
            ? Object.keys(activeMissions).map((missionsData, index) => (
              <MissionCard
                key={activeMissions[missionsData].missionId}
                index={index}
                missionId={activeMissions[missionsData].missionId}
                image={activeMissions[missionsData]?.url}
                title={activeMissions[missionsData].title}
                activityLength={activeMissions[missionsData].activities.length}
                repetitionCount={activeMissions[missionsData].repetitions}
                isContinue
                analyticsEventHandler={analyticsEventHandler}
                linkedMissionflag
              />
            ))
            : Object.values(featuredMissions).map((missionsData, index) => (
              <MissionCard
                key={missionsData.id}
                index={index}
                missionId={missionsData.id}
                image={missionsData.missionImage?.url}
                title={missionsData.title}
                repetitionCount={missionsData.repetitionCount}
                isContinue={false}
                isViewMission
                analyticsEventHandler={analyticsEventHandler}
                linkedMissionflag
              />
            ))}
        </ScrollWrapper>
      </MissionCardWrapper>
    </Wrapper>
  );
}

MissionBanner.propTypes = {
  activeMissions: PropTypes.shape({}),
  analyticsEventHandler: PropTypes.func,
};

export default MissionBanner;
