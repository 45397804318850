import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Title = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 22px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fontSize.secondary};
      line-height: 30px;
    }
  }
`;

export const Wrapper = styled.div`
  margin: 24px 80px 24px 80px;
  max-width: 400px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 25px;
  }
`;

export const WalletTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RedeemCard = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.blackSqueeze};
  border-radius: 12px;
  margin: 24px 0;
  padding: 15px 25px;
`;

export const ButtonWrapper = styled.div`
  padding-top: 20px;
  text-align: center;
`;
