import { blogShareTitle, blogShareText } from 'resources/strings';

// `getBlogPostShareLink` used to get the share link of the blog post
const getBlogPostShareLink = (blogId) => (
  {
    shareTitle: blogShareTitle,
    shareText: blogShareText,
    shareLink: `${process.env.REACT_APP_WEBSITE_URL}blogs${blogId}`,
  }
);

export default getBlogPostShareLink;
