import React from 'react';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import { Divider } from '@material-ui/core';
import * as constants from 'utils/Analytics/flow-constants';
import * as Strings from 'resources/strings';
import { LegalNoticeWrapper } from './style';

export default function LegalNotice() {
  const { initiateTracking } = useAnalytics();
  // 'analyticsEventHandler` analytics event handler
  const analyticsEventHandler = (eventName, eventLabel) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: constants.navigation,
      eventAction: constants.loginVerify,
      eventLabel: `${eventLabel}`,
    });
  };
  return (
    <>
      <Divider />
      <LegalNoticeWrapper variant='body1'>
        By using Adva Missions, you agree with the
        {' '}
        <br />
        <a target='_blank' rel='noreferrer' href=' https://www.adva.io/privacy.html' onClick={() => analyticsEventHandler(constants.privacyPolicyAnalytics, Strings.privacyPolicy)}>{Strings.privacyPolicy}</a>
        {', '}
        <a target='_blank' rel='noreferrer' href='https://www.adva.io/terms-and-condition.html' onClick={() => analyticsEventHandler(constants.termsAnalytics, Strings.termsAndConditions)}>{Strings.termsAndConditions}</a>
        {' '}
        and
        {' '}
        <a target='_blank' rel='noreferrer' href='https://www.adva.io/cookies.html' onClick={() => analyticsEventHandler(constants.cookieAnalytics, Strings.cookiePolicy)}>{Strings.cookiePolicy}</a>
      </LegalNoticeWrapper>
    </>
  );
}
