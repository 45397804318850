import styled from 'styled-components';

import { Typography } from '@material-ui/core';
import PrimaryButton from 'components/common/Button/style';

export const Title = styled(Typography)`
  && {
    margin: 24px 0px;
    line-height: 24px;
    font-size: ${(props) => props.theme.fontSize.large};
    text-align: center;
    font-weight: ${(props) => props.theme.fontWeight.medium};
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      margin: 24px 24px;
      font-size: ${(props) => props.theme.fontSize.secondary};
      text-align: left;
    }
  }
`;

export const ButtonWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: calc(55% - 92px);
    ${PrimaryButton} {
      position: fixed;
      right: 0;
      bottom: 0;
      margin: 24px 46px;
      width: inherit;
    }
  } 
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 24px;
    width: unset;
  }
`;
