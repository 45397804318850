import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Tooltip,
  withStyles,
} from '@material-ui/core';

import themes from 'styles/theme';
import InfoIconWrapper from './style';

/**
 * `InfoToolTip` common component for information tooltip
 * @param {tooltipContent} String
 * @param {onClickEvent} onClick
 * @returns JSX
 */
const InfoToolTip = ({ tooltipContent, onClickEvent }) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  // Closes the tooltip on clicked on outside of the element
  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setTooltipIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const TooltipWrapper = withStyles((theme) => ({
    tooltip: {
      padding: 12,
      backgroundColor: theme.palette.common.white,
      color: themes.fontColor.black,
      boxShadow: themes.boxShadow.whiteWithOpacity,
      fontSize: themes.fontSize.primary,
      width: 200,
      fontWeight: 400,
      borderRadius: 10,
    },
    arrow: {
      color: theme.palette.common.white,
      '&:before': {
        boxShadow: themes.boxShadow.whiteWithOpacity,
      },
    },
  }))(Tooltip);

  return (
    <TooltipWrapper
      arrow
      open={tooltipIsOpen}
      placement='top'
      title={tooltipContent}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickEvent();
        setTooltipIsOpen(!tooltipIsOpen);
      }}
      ref={wrapperRef}
    >
      <InfoIconWrapper fontSize='small' />
    </TooltipWrapper>
  );
};

InfoToolTip.propTypes = {
  tooltipContent: PropTypes.string,
  onClickEvent: PropTypes.func,
};

InfoToolTip.defaultProps = {
  tooltipContent: '',
  onClickEvent: () => { },
};

export default InfoToolTip;
