import React from 'react';
import PropTypes from 'prop-types';

import themes from 'styles/theme';
import BlueStar from 'assets/images/blue_star.svg';
import WhiteStar from 'assets/images/white_star.svg';

import {
  Wrapper,
  Image,
  BackgroundImgWrapper,
  ImageWrapper,
  Rating,
} from './style';

/**
 *
 * @param {number} rating
 * `CustomerRatingCard` displays the customer rating inside a chip
 */

const CustomerRatingChip = ({ rating, backgroundColor, fontColor }) => {
  const getProgress = () => {
    // Converting rating in to percentage
    const percentage = (rating / 5) * 100;
    const width = window.innerWidth > parseInt(themes.breakpoints.tabletLarge, 10) ? 18 : 12;
    const progress = (width / 10) * (percentage / 10);
    const total = `${progress}px`;
    return total;
  };
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <ImageWrapper>
        <BackgroundImgWrapper>
          <Image src={BlueStar} />
        </BackgroundImgWrapper>
        <BackgroundImgWrapper width={getProgress()}>
          <Image src={WhiteStar} />
        </BackgroundImgWrapper>
      </ImageWrapper>
      <Rating fontColor={fontColor}>{rating}</Rating>
    </Wrapper>
  );
};

CustomerRatingChip.propTypes = {
  rating: PropTypes.number,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
};

CustomerRatingChip.defaultProps = {
  rating: 0,
  backgroundColor: themes.backgroundColor.dodgerBlue,
  fontColor: themes.fontColor.white,
};

export default CustomerRatingChip;
