import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import themes from 'styles/theme';
import ReferralCard from 'components/common/ReferralCard/index';
import * as Strings from 'resources/strings';
import * as constants from 'utils/Analytics/flow-constants';
import * as globalConstants from 'utils/global-constants';
import { updateDocumentField } from 'services/firebase-service';
import { clevertapEventTracking } from 'utils/Clevertap/Clevertap';

/**
 * `ReferralLinkCard` used to show the referral link card in the dashboard
 * Returns JSX
 */

const ReferralLinkCard = ({
  referralData,
  currentUser,
  referralLinksData,
}) => {
  const history = useHistory();
  const { initiateTracking } = useAnalytics();

  // 'analyticsEventHandler` analytics event handler for breadcumbs
  const analyticsEventHandler = (eventName, eventLabel) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: constants.navigation,
      eventAction: constants.dashboard,
      eventLabel: `${eventLabel}`,
    });
  };

  // redirect to rewards collected screen
  const goToRewardsCollected = () => {
    analyticsEventHandler(
      constants.referralRewardCollectedAnalytics,
      constants.referralRewardCollectedCard,
    );
    history.push('/rewards-collected');
  };

  // redirect to refer a friend screen
  const redirectToReferAFriendPage = () => {
    // analytics for refer now cta
    clevertapEventTracking(Strings.referralLinkText);
    analyticsEventHandler(
      constants.referNowAnalytics,
      Strings.referralLinkText,
    );
    history.push('/refer-a-friend');
  };

  return (
    <>
      {currentUser?.referral && !currentUser?.referral?.referralRewardSeen && (
        <ReferralCard
          color={themes.backgroundColor.goldenYellow}
          description={referralLinksData?.congratulation?.message ?? ''}
          // redirect to rewards collected screen screen
          onClick={goToRewardsCollected}
          onClose={() => {
            updateDocumentField(globalConstants.mUsers, currentUser.id, {
              referral: { referralRewardSeen: true },
            });
          }}
          showClose
          image={referralLinksData?.congratulation?.icon?.url}
        />
      )}
      {(Object.keys(referralData).length === 0
      || referralData.completed < referralData.total) && (
        <ReferralCard
          color={themes.backgroundColor.titanWhite}
          description={referralLinksData?.invite?.message ?? ''}
          // redirect to refer a friend screen
          onClick={redirectToReferAFriendPage}
          image={referralLinksData?.invite?.icon?.url}
          hasLink
        />
      )}
    </>
  );
};

ReferralLinkCard.propTypes = {
  referralData: PropTypes.shape,
  currentUser: PropTypes.shape,
  referralLinksData: PropTypes.shape,
};

ReferralLinkCard.defaultProps = {
  referralData: {},
  currentUser: {},
  referralLinksData: {},
};

export default ReferralLinkCard;
