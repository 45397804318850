import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isPreLogin ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    flex-direction: column;
  }
`;

export default Wrapper;
