import styled from 'styled-components';

export const CardWrapper = styled.div`
  cursor: pointer;
  margin: 32px 83px 32px 81px;
  display: flex;
  border-radius: 18px;
  position: relative;
  background-color: ${(props) => props.color};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 32px 25px 32px 24px;
  }
`;

export const Description = styled.div`
  font-size: ${(props) => props.theme.fontSize.large};
  line-height: 36px;
  white-space: pre-line;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
  }
`;

export const ImageWrapper = styled.div`
  width: 51px;
  height: 60px;
  position: absolute;
  right: 3%;
  bottom: -12%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 34px;
    height: 40px;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export const IconWrapper = styled.div`
  width: 9px;
  height: 9px;
  cursor: pointer;
  position: relative;
  top: 20px;
  right: 20px;
`;

export const LinkWrapper = styled.p`
  font-size: ${(props) => props.theme.fontSize.large};
  margin-top: 6px;
  color: ${(props) => props.theme.backgroundColor.dodgerBlue};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    margin: 0px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 36px 30px 35px 30px;
  display: block;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 24px 20px 16px 20px;
  }
`;
