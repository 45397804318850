import styled from 'styled-components';

import { Typography } from '@material-ui/core';

const Title = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.primary};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: ${(props) => props.theme.fontColor.white};
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.backgroundColor.dodgerBlue};
  }
`;

export default Title;
