import axios from 'axios';

// Strapi Base URL
const baseURL = process.env.REACT_APP_CMS_URL;
const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJhZHZhLW1pc3Npb25zIn0.Z9Qc9YIFZFDi9rbKNAKOV9PjUw1O0nE1vgkqJVTd2BM',
};

/* Axios Instance */
const axiosInstance = axios.create({
  baseURL,
  headers,
});

/* Request and Response Interceptor */
axiosInstance.interceptors.request.use(
  (request) => request,
  (error) => Promise.reject(error.response.data),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response.data),
);

/* GET, POST, PUT, DELETE Calls */
export const get = (url, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.get(apiURL, config ?? {});
};

export const post = (url, data, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.post(apiURL, data ?? {}, config ?? {});
};

export const put = (url, data, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.put(apiURL, data ?? {}, config ?? {});
};

export const del = (url, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.delete(apiURL, config ?? {});
};
