import React from 'react';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import { DashboardProvider } from 'provider/dashboard-provider';
import ReferralLinkContainer from 'components/containers/ReferralLink';
import { ReferralProvider } from 'provider/referral_provider';

/// [ReferralLink] renders UI for referral link screen
const ReferralLink = () => (
  <BaseLayout hideGutters>
    <DashboardProvider>
      <ReferralProvider>
        <ReferralLinkContainer />
      </ReferralProvider>
    </DashboardProvider>
  </BaseLayout>
);

export default ReferralLink;
