import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import * as Strings from 'resources/strings';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import Button from 'components/common/Button/Button';
import Breadcrumb from 'components/common/BreadCrumb';
import { Title, ButtonWrapper } from 'components/pages/AboutMissions/components/AboutMissionsLanding/style';
import AboutCards from 'components/pages/AboutMissions/components/AboutCards';
import * as constants from 'utils/Analytics/flow-constants';

/**
 * * [AboutMissionsLanding] is the landing page of the about adva missions
 */
const AboutMissionsLanding = ({ aboutMissionsData }) => {
  const history = useHistory();
  const { initiateTracking } = useAnalytics();

  /// `goToDashboard` is used to redirect to the dashboard screen
  const goToDashboard = () => {
    // analytics for go to dashboard cta
    initiateTracking({
      eventName: constants.backToDashboardAnalytics,
      eventCategory: constants.navigation,
      eventAction: constants.aboutAdvaMissions,
      eventLabel: Strings.goToDashboard,
    });
    history.push('/');
  };

  // 'analyticsEventHandler` analytics event handler for breadcumbs
  const analyticsEventHandler = (eventLabel) => {
    initiateTracking({
      eventName: constants.breadCrumbAnalytics,
      eventCategory: constants.navigation,
      eventAction: `${constants.aboutAdvaMissions}_${constants.breadcrumb}`,
      eventLabel: `${eventLabel}`,
    });
  };

  return (
    <div>
      {/* // TODO need to implement analytics event */}
      <Breadcrumb analyticsEvent={analyticsEventHandler} />
      <Title>{Strings.aboutMissionsTitle}</Title>
      <AboutCards
        aboutAdvaMissionsData={aboutMissionsData}
        isPreLogin={false}
      />
      <ButtonWrapper>
        <Button title={Strings.goToDashboard} onClick={() => goToDashboard()} />
      </ButtonWrapper>
    </div>
  );
};

AboutMissionsLanding.propTypes = {
  aboutMissionsData: PropTypes.arrayOf(PropTypes.string),
};

AboutMissionsLanding.defaultProps = {
  aboutMissionsData: [],
};

export default AboutMissionsLanding;
