import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Title = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.secondary};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 24px;
  }
`;

export const TitleWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 0 20px;
  }
`;

export const ViewMissionCardWrapper = styled.div`
  display: inline-block;
  padding: 5px 12px 5px 5px;
  margin-left: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-left: ${(props) => (props.isRequired ? '15px' : '0')};
  }
`;

export const Wrapper = styled.div`
  padding: 0 80px;
  margin-top: 25px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 0;
  }
`;
