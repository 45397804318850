import { getCollectionByOrder, updateDocumentField } from 'services/firebase-service';

// `getUserNotifications` to fetch the user notifications
export const getUserNotifications = (userId) => {
  try {
    const response = getCollectionByOrder(
      'mUsersNotifications',
      userId,
      ['sent', 'notOptedForSms'],
      'desc',
    );
    return response;
  } catch (error) {
    return error;
  }
};

// `updateNotifications` to update the state of the notifications to read
export const updateNotifications = (docId) => {
  try {
    const response = updateDocumentField(
      'mUsersNotifications',
      docId,
      { state: 'read' },
    );
    return response;
  } catch (error) {
    return error;
  }
};

// `updateNotificationsStatus` to update the status of the notifications of the current user
export const updateNotificationsStatus = (userId, status) => {
  try {
    const response = updateDocumentField(
      'mUsers', userId, { disableNotification: status },
    );
    return response;
  } catch (error) {
    return error;
  }
};
