import React from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import ReferralRewardCard from 'components/common/ReferralRewardCard';
import RewardTrophyImage from 'assets/images/reward_trophy_image.svg';
import * as Strings from 'resources/strings';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as constants from 'utils/Analytics/flow-constants';

import { ReferralRewardContainer, GreetingsText, TextWrapper } from './style';

/**
 *
 * @returns renders Referral reward Card UI
 */
const ReferralRewardSection = ({ rewardDetails }) => {
  const history = useHistory();
  const { initiateTracking } = useAnalytics();

  // `goToRewardsCollected redirects to rewards-collected page`
  const goToRewardsCollected = () => {
    // analytics for referral reward card
    initiateTracking({
      eventName: constants.referralRewardCardAnalytics,
      eventCategory: constants.navigation,
      eventAction: constants.missionReward,
      eventLabel: constants.referralrewardCard,
    });
    history.push('/rewards-collected');
  };

  return (
    <ReferralRewardContainer>
      <TextWrapper>
        <GreetingsText>
          <b>{Strings.congratulations}</b>
          &nbsp;
          {Strings.referralCardAvailabilty}
          &nbsp;
          <b>{Strings.rewardsCollectedTitle}</b>
          &nbsp;
          {Strings.sectionInfo}
        </GreetingsText>
      </TextWrapper>
      <ReferralRewardCard
        image={RewardTrophyImage}
        offerTitle={Strings.rewardReferralText}
        offerText={rewardDetails?.primary?.name}
        goToRewardsCollected={goToRewardsCollected}
      />
    </ReferralRewardContainer>
  );
};

ReferralRewardSection.propTypes = {
  rewardDetails: PropTypes.shape({
    primary: PropTypes.shape({
      shortDesc: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
};

ReferralRewardSection.defaultProps = {
  rewardDetails: {
    primary: {
      shortDesc: '',
      image: '',
      name: '',
    },
  },
};

export default ReferralRewardSection;
