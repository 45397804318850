import themes from 'styles/theme';

/// `flow-constants` contains all the metrics constants of the mission
/// that needs to be reused across the app
import CloudIcon from '@material-ui/icons/Cloud';
import NatureIcon from '@material-ui/icons/Nature';
import DeleteIcon from '@material-ui/icons/Delete';
import OpacityIcon from '@material-ui/icons/Opacity';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import StarsIcon from '@material-ui/icons/Stars';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

/// To set the type and image of user stats data of the current user
export const stats = {
  missionsCompleted: {
    icon: <EmojiEventsIcon />,
    type: 'Missions Completed',
    backgroundColor: themes.backgroundColor.blackSqueeze,
    default: true,
  },
  ecoPointsEarned: {
    icon: <StarsIcon />,
    type: 'Ecopoints Earned',
    backgroundColor: themes.backgroundColor.blackSqueeze,
  },
  dollarValueSaved: {
    icon: <AccountBalanceWalletIcon />,
    type: 'Dollar Value Saved',
    backgroundColor: themes.backgroundColor.blackSqueeze,
    default: true,
  },
  carbonSaved: {
    icon: <CloudIcon />,
    type: 'Carbon Saved',
    backgroundColor: themes.backgroundColor.titanWhite,
  },
  waterSaved: {
    icon: <OpacityIcon />,
    type: 'Water Consumed',
    backgroundColor: themes.backgroundColor.titanWhite,
  },
  landSaved: {
    icon: <NatureIcon />,
    type: 'Land Saved',
    backgroundColor: themes.backgroundColor.titanWhite,
  },
  wasteLand: {
    icon: <DeleteIcon />,
    type: 'Waste Reduced',
    backgroundColor: themes.backgroundColor.selago,
  },
  waterPollution: {
    icon: <OpacityIcon />,
    type: 'Water Polluted',
    backgroundColor: themes.backgroundColor.selago,
  },
  plasticConsumption: {
    icon: <NaturePeopleIcon />,
    type: 'Plastic Consumed',
    backgroundColor: themes.backgroundColor.selago,
  },
  default: {
    icon: <NaturePeopleIcon />,
    backgroundColor: themes.backgroundColor.selago,
  },
};

/// [sustainabilityMetrics] To display the unit code of the metrics at required places
export const sustainabilityMetrics = {
  Grams: 'g',
  squareMeters: 'm2',
  KG: 'kg',
  Litres: 'L',
};
