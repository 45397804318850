import styled from 'styled-components';

export const ReferralRewardContainer = styled.div`
  padding: 48px 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 32px 20px;
  }
`;

export const GreetingsText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.robotoRegular};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  font-size: ${(props) => props.theme.fontSize.smMedium};
  line-height: 26px;
  letter-spacing: 0.32px;
  text-align: center;
  margin-bottom: 16px;
  max-width: 373px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
    letter-spacing: 0.25px;
    max-width: 314px;
    margin-bottom: 13px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
