import styled from 'styled-components';

export const Title = styled.div`
  font-family: ${(props) => props.theme.fontFamily.robotoRegular};
  font-size: ${(props) => props.theme.fontSize.secondary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  height: 45px;
  line-height: 22px;
  display: flex;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    height: 35px;
    max-width: 150px;
    line-height: 18px;
  }
`;

export const ImageWrapper = styled.div`
  width: 123px;
  height: 123px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 98px;
    height: 98px;
  }
`;

export const MissionImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

export const Wrapper = styled.div`
  max-width: 325px;
  display: flex;
`;

export const MissionCTA = styled.p`
  font-size: ${(props) => props.theme.fontSize.primary};
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  line-height: 20px;
  cursor: pointer;
`;

export const RewardDesc = styled.p`
  font-size: ${(props) => props.theme.fontSize.description};
  color: ${(props) => props.theme.fontColor.carbonGrey};
  line-height: 20px;
  display: flex;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width:70%;
`;

export const RewardImageWrapper = styled.div`
  width: 18px;
  height: 22px;
  margin-right: 5px;
`;

export const RewardWrapper = styled.div`
  display: flex;
`;

export const MissionDetailsWrapper = styled.div`
  margin-left: 20px;
  padding-top: 5px;
`;
