import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
    padding: 28px 75px 24px 20px;
    position: relative;
    border-radius: 16px;
    background-color: ${(props) => props.theme.backgroundColor.blackSqueeze};
`;

export const ImageWrapper = styled.div`
    position: absolute;
    bottom: -3px;
`;

export const Image = styled.img`
    height: 100%;
    width: 100%;
`;

export const CountWrapper = styled.div`
    position: absolute;
    top: -10px;
    left: 15px;
    border-radius: 30px;
    color: ${(props) => props.theme.fontColor.white};;
    background-color: ${(props) => props.theme.backgroundColor.easternBlue};
    padding: 5px;
`;

export const Description = styled(Typography)`
    font-size: ${(props) => props.theme.fontSize.primary};
`;

export const DashedBorder = styled.div`
    border-left: 2px dashed;
    border-color: ${(props) => props.theme.border.jungleMist};
    height: 30px;
    margin-left: 27px;
    display: ${(props) => (props.isConnector ? 'block' : 'none')};
`;
