import styled from 'styled-components';

export const RewardCardWrapper = styled.div`
display: inline-block ;
overflow: hidden ;
box-shadow: ${(props) => (props.theme.boxShadow.greyShadow)};
margin: 5px;
margin-right: 20px;
border-radius: 10px;
white-space: normal;
`;

export const MissionCardWrapper = styled.div`
  display: inline-block;
`;
