import styled from 'styled-components';

export const MissionCardWrapper = styled.div`
  width:410px;
  display: flex;
  padding: 16px 14px 16px 16px;
  box-shadow: ${(props) => props.theme.boxShadow.greyShadow};
  border-radius: 15px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    border-radius: 11px;
    width: 297px;
  }
  justify-content: space-between;
  cursor: pointer;
`;

export const MissionCardContent = styled.div`
  display: flex;
`;

export const ImageWrapper = styled.div`
  width: 91px;
  height: 101px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 72px;
    height: 81px;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;

export const CardDescWrapper = styled.div`
  padding: 0 12px;
  width: 60%;
`;

export const CategoryTitle = styled.p`
  fontfamily: ${(props) => props.theme.fontFamily.RobotoRegular};
  font-size: ${(props) => props.theme.fontSize.primary};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.fontColor.cloudBlue};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.description};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 18px;
  }
`;

export const CategoryDescription = styled.p`
  font-family: ${(props) => props.theme.fontFamily.RobotoRegular};
  font-size: ${(props) => props.theme.fontSize.secondary};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  line-height: 22px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.fontColor.carbonBlack};
  max-width: 223px;
  box-sizing: border-box;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
    max-width: 182px;
  }
`;

export const OfferDescription = styled.p`
  font-family: ${(props) => props.theme.fontFamily.RobotoRegular};
  font-size: ${(props) => props.theme.fontSize.primary};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.fontColor.carbonGrey};
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.description};
    line-height: 18px;
  }
`;

export const ShareMissionWrapper = styled.div`
  position: relative;
  padding-right: inherit;
  .MuiIconButton-root {
    position: absolute;
    bottom: 0;
    padding: unset !important;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      width: 12px !important;
      height: 13px !important;
    }
  }
  .MuiIconButton-sizeSmall {
    padding: unset !important;
  }
  .MuiIconButton-colorInherit {
    color: ${(props) => props.theme.backgroundColor.dodgerBlue} !important;
  }
`;

export const OfferWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-top: 6px;
  }
  `;

export const OfferImageWrapper = styled.div`
   width: 22px;
   height: 22px;
   margin-right: 5px;
  `;

export const OfferImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  `;
