import React from 'react';

import { DashboardProvider } from 'provider/dashboard-provider';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import MissionBlogsContainer from 'components/containers/MissionBlogs/MissionBlogsContainer';

/**
 * Mission Blogs used to show all the blogs available for missions
 * @returns JSX
 */

function AllMissionBlogs() {
  return (
    <BaseLayout showPoster={false} hideGutters>
      <DashboardProvider>
        <MissionBlogsContainer />
      </DashboardProvider>
    </BaseLayout>
  );
}

export default AllMissionBlogs;
