/// [Helpers] is responsible for all the data and functions
/// that needs to be reused across the dashboard

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import * as Strings from 'resources/strings';
import * as constants from 'utils/Analytics/flow-constants';

const earnedRewards = require('assets/images/reward_icon.svg').default;
const advaMissionsLogo = require('assets/images/adva_logo.png').default;

/// To get the data of eraned rewards and carbon savings data block in dashboard
export const rewardsData = [
  {
    title: Strings.rewardsCollectedTitle,
    desc: Strings.rewardsCollectedDesc,
    path: '/rewards-collected',
    image: earnedRewards,
    imageHeight: '26px',
    imageWidth: '12px',
    eventName: constants.rewardsCollectedAnalytics,
    eventLabel: Strings.rewardsCollectedTitle.toLowerCase(),
  },
  {
    title: Strings.aboutMissionsTitle,
    desc: Strings.aboutMisssionsDesc,
    path: '/about-adva-missions',
    image: advaMissionsLogo,
    imageHeight: '24px',
    imageWidth: '21px',
    eventName: constants.aboutAdvaMissionsAnalytics,
    eventLabel: Strings.aboutMissionsTitle.toLowerCase(),
  },
];

// `eWalletList` Ecopoints List Data
export const eWalletList = {
  credit: {
    title: Strings.credited,
    icon: <ArrowDownwardIcon fontSize='inherit' />,
  },
  pendingDebit: {
    title: Strings.pendingDebit,
    icon: <QueryBuilderIcon fontSize='inherit' />,
  },
  debitted: {
    title: Strings.debited,
    icon: <ArrowUpwardIcon fontSize='inherit' />,
  },
};
