import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import LinkedMissions from 'components/pages/Missions/components/LinkedMissions';
import Loader from 'components/common/Loader/Loader';
import { useMissions } from 'provider/missions-provider';
import { useAllMissions } from 'provider/dashboard-provider';

/**
 * LinkedMissionsContainer is the container for the LinkedMissions component
 */
const LinkedMissionsContainer = (props) => {
  const { missionDataId, startMissions, isReward } = props;
  const {
    linkedMissions, fetchLinkedMissionsData, linkedMissionLoader, isLoading, allMissions,
  } = useMissions();
  const { ongoingMissions, completedMissions, isLoading: missionsDataLoading } = useAllMissions();

  useEffect(() => {
    // get the linked missions only when it is not updated
    // once after fetching all the missions from strapi
    if (linkedMissions.length === 0 && Object.keys(allMissions).length > 0) {
      // `fetchLinkedMissionsData` is used to fetch the linked mission data
      fetchLinkedMissionsData([missionDataId]);
    }
  }, [allMissions && Object.keys(allMissions).length]);

  return linkedMissionLoader || missionsDataLoading ? (<Loader isButtonLoader />) : (
    <>
      {linkedMissions.length > 0 && (
        <LinkedMissions
          linkedMissionArray={linkedMissions}
          ongoingMissions={ongoingMissions}
          completedMissions={completedMissions}
          startMissions={startMissions}
          missionId={missionDataId}
          isReward={isReward}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

LinkedMissionsContainer.propTypes = {
  missionDataId: PropTypes.number,
  startMissions: PropTypes.func,
  isReward: PropTypes.bool,
};

export default LinkedMissionsContainer;
