import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from './styles';

/**
 * InteractiveToolTip that can hold custom components.
 * @returns JSX
 */
const InteractiveToolTip = ({
  children, title, open, disableFocusListener, disableHoverListener,
}) => (
  <Tooltip
    interactive
    arrow
    disableFocusListener={disableFocusListener}
    disableHoverListener={disableHoverListener}
    open={open}
    placement='top-start'
    PopperProps={{
      modifiers: {
        offset: {
          enabled: true,
          offset: '0px, -20px',
        },
      },
    }}
    title={title}
  >
    {children}
  </Tooltip>
);

export default InteractiveToolTip;

InteractiveToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  open: PropTypes.bool,
  disableFocusListener: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
};

InteractiveToolTip.defaultProps = {
  open: false,
  disableFocusListener: true,
  disableHoverListener: true,
};
