import styled from 'styled-components';
import { Typography, Link } from '@material-ui/core';

export const ButtonWrapper = styled.div`
  @media (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    && {
      padding: 5px 16px;
    }
  }
  padding: 5px 36px;
`;

export const MissionsWrapper = styled.div`
  padding: 20px;
`;

export const UploadButton = styled.div`
  color: ${(props) => (!props.disabled
    ? props.theme.fontColor.white
    : props.theme.fontColor.silverChalice)};
  background-color: ${(props) => (!props.disabled
    ? props.theme.backgroundColor.dodgerBlue
    : props.theme.backgroundColor.disabled)} !important;
  text-transform: none;
  border-radius: 8px;
  height: 50px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize.primary};
  cursor: pointer;
`;

export const Message = styled(Typography)`
  && {
    color: ${(props) => (props.status === 'rejected'
    ? props.theme.fontColor.negativeRed
    : props.theme.fontColor.carbonBlack)};
    font-weight: 400;
    font-size: ${(props) => props.theme.fontSize.description};
  }
`;

export const StoreLink = styled(Link)`
  && {
    font-size: ${(props) => props.theme.fontSize.primary};
    color: ${(props) => props.theme.fontColor.dodgerBlue};
    text-decoration: none !important;
    font-weight: 400;
    display:flex;
    justify-content: center;
  }
`;
