import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';

import { useSnackbar } from 'provider/snackbar-provider';
import Spacer from 'components/common/Spacer/Spacer';
import copyText from 'utils/Missions/utils';
import {
  Coupon,
  CouponCode,
  CouponDescription,
  DashedBorder,
  Icon,
  Circle,
} from './style';

function CouponCard({ promoCode }) {
  const { setIsShow, setDisplayText } = useSnackbar();
  const copyCode = () => {
    copyText(promoCode);
    setIsShow(true);
    setDisplayText('Code Copied');
  };
  return (
    <>
      <Coupon>
        <Circle isLeft />
        <CouponDescription>
          Enter code
          <CouponCode>{` '${promoCode}' `}</CouponCode>
          at checkout to redeem the offer
        </CouponDescription>
        <DashedBorder />
        <Icon size='small' aria-label='share' color='inherit' onClick={copyCode}>
          <FileCopyIcon fontSize='small' />
        </Icon>
        <Circle />
      </Coupon>
      <Spacer height='12px' />
    </>
  );
}
export default CouponCard;
CouponCard.propTypes = {
  promoCode: PropTypes.string,
};
