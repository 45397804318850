import React from 'react';

import { useAboutMissions } from 'provider/about-missions-provider';

import * as Strings from 'resources/strings';
import AboutCards from 'components/pages/AboutMissions/components/AboutCards';
import Loader from 'components/common/Loader/Loader';
import { Title, CardWrapper } from './style';

const PreLoginAboutMissions = () => {
  const { aboutMissionsData, aboutMissionsLoader } = useAboutMissions();
  return aboutMissionsLoader ? <Loader /> : (
    <>
      <Title>{Strings.advaMissionsLabel}</Title>
      <CardWrapper>
        <AboutCards isPreLogin aboutAdvaMissionsData={aboutMissionsData} />
      </CardWrapper>
    </>
  );
};

export default PreLoginAboutMissions;
