import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const ImageWrapper = styled.div`
    margin: 74px auto 48px;
    width: 230px;
    height: 175px;
`;

export const ButtonWrapper = styled.div`
    @media (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    && {
        margin: 0 20px;
    }
  }
`;

export const Subtitle = styled(Typography)`
    && {
        margin-bottom: 16px;
        font-size:${(props) => props.theme.fontSize.large};
        line-height: 28px;
        text-align: center;
    }
`;

export const Description = styled(Typography)`
    && {
        margin-bottom: 40px;
        font-size:${(props) => props.theme.fontSize.primary};
        line-height: 21px;
        text-align: center;
    }
`;
