import React from 'react';

import { useReferrals } from 'provider/referral_provider';

import ReferralLinkLanding from 'components/pages/ReferralLink/components/ReferralLinkLanding';
import Loader from 'components/common/Loader/Loader';

/// [ReferralLinkContainer] container for referral link
const ReferralLinkContainer = () => {
  const { referralLinksData, referralLinksLoader, referralData } = useReferrals();
  return referralLinksLoader ? (
    <Loader />
  ) : (
    <ReferralLinkLanding referralInfo={referralLinksData} userReferralDoc={referralData} />
  );
};

export default ReferralLinkContainer;
