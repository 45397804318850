import React from 'react';
import PropTypes from 'prop-types';

import { getAverageReview } from 'utils/Missions/utils';
import { missionCompletedStatus } from 'resources/strings';
import Share from 'components/common/Share';
import themes from 'styles/theme';
import { productPurchase } from 'utils/global-constants';
import CustomerRatingChip from 'components/pages/Missions/components/CustomerRatingChip';
import ProductDetails from 'components/pages/Missions/components/ProductDetails';

import {
  MissionCategory,
  MissionsWrapper,
  MissionTitleWrapper,
  ProductDetailsWrapper,
  MissionDetailsWrapper,
  MissionCompleted,
  Wrapper,
} from './style';

/// `MissionDetails` render UI for mission banner, title, description and healthbenefits
const MissionDetails = ({ missionsData, isMissionComplete }) => (
  <div>
    <MissionsWrapper backgroundurl={missionsData.missionImage?.url ?? ''} />
    <MissionDetailsWrapper>
      <Wrapper>
        {missionsData?.reviews?.length > 0
        && missionsData.activity?.verificationMechanism === productPurchase
        && missionsData.productCheckoutUrl !== null && (
          <CustomerRatingChip
            rating={getAverageReview(missionsData.reviews)}
          />
        )}
        {missionsData?.missionsCategory?.name && <MissionCategory>{missionsData?.missionsCategory?.name ?? ''}</MissionCategory>}
        {isMissionComplete && <MissionCompleted>{missionCompletedStatus}</MissionCompleted>}
      </Wrapper>
      <MissionTitleWrapper>
        {missionsData?.title ?? ''}
        <Share
          color={themes.backgroundColor.dodgerBlue}
          url={missionsData.share.shareLink}
          text={missionsData.share.shareText}
        />
      </MissionTitleWrapper>
      {(missionsData.activity?.verificationMechanism === productPurchase
        && missionsData.productCheckoutUrl != null)
        && (
          <ProductDetailsWrapper>
            <ProductDetails
              boughtInSevenDays={missionsData.boughtInSevenDays}
              productDeliveryDuration={missionsData.productDeliveryDuration}
              productPrice={missionsData.productPrice}
            />
          </ProductDetailsWrapper>
        )}
    </MissionDetailsWrapper>
  </div>
);

MissionDetails.propTypes = {
  missionsData: PropTypes.shape({
    missionImage: PropTypes.shape({
      url: PropTypes.string,
    }),
    missionsCategory: PropTypes.shape({
      name: PropTypes.string,
    }),
    title: PropTypes.string,
    alternateDescription: PropTypes.string,
    content: PropTypes.string,
    healthBenefits: PropTypes.arrayOf(PropTypes.string),
    share: PropTypes.shape({
      shareLink: PropTypes.string,
      shareText: PropTypes.string,
    }),
    reviews: PropTypes.arrayOf(PropTypes.any),
    boughtInSevenDays: PropTypes.number,
    productPrice: PropTypes.number,
    productDeliveryDuration: PropTypes.string,
    productCheckoutUrl: PropTypes.string,
    activity: PropTypes.shape({
      verificationMechanism: PropTypes.string,
    }),
  }),
  isMissionComplete: PropTypes.bool,
};

MissionDetails.defaultProps = {
  missionsData: {
    missionImage: {
      url: '',
    },
    missionsCategory: {
      name: '',
    },
    title: '',
    alternateDescription: '',
    healthBenefits: [],
    share: {
      shareLink: '',
      shareText: '',
    },
    reviews: [],
    boughtInSevenDays: 0,
    productDeliveryDuration: '',
    productPrice: 0,
    activity: {
      verificationMechanism: '',
    },
  },
  isMissionComplete: false,
};

export default MissionDetails;
