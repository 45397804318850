import React, { useEffect, useRef } from 'react';
import { ThemeProvider } from 'styled-components';

import { BrowserRouter as Router } from 'react-router-dom';

import { SnackbarProvider } from 'provider/snackbar-provider';
import { AuthProvider } from './provider/auth-provider';
import { ReferralProvider } from './provider/referral_provider';

import Routes from './navigation/routes';
import themes from './styles/theme';
import GlobalStyle from './styles/global-styles';
import { AnalyticsContainer } from './helpers/Analytics/Analytics';

function App() {
  // useRef to check if the DOM is rendered or not
  const containerRef = useRef();

  // To add the margin-bottom in body if the user is not yet accepted the cookies
  // to see the content behind Accept All Cookies banner
  useEffect(() => {
    // Adding setTimeout for external script to load
    setTimeout(() => {
      const acceptAllCookies = document.getElementById('onetrust-banner-sdk');
      if (containerRef.current != null && acceptAllCookies) {
        const acceptAllCookiesVisibility = window.getComputedStyle(acceptAllCookies).display;
        if (acceptAllCookiesVisibility === 'block') {
          // if there is  Accept All Cookies then add the
          // marginBottom as the Accept All Cookies banner's height
          /* eslint-disable max-len */
          containerRef.current.style.marginBottom = window.getComputedStyle(acceptAllCookies).height;
        }
        // if the user accepted Cookies then add the marginBottom to 0
        document.getElementById('onetrust-accept-btn-handler').addEventListener('click', () => {
          containerRef.current.style.marginBottom = '0';
        });
      }
    }, 600);
  }, []);

  return (
    <div ref={containerRef}>
      <AnalyticsContainer>
        <AuthProvider>
          <ThemeProvider theme={themes}>
            <ReferralProvider>
              <SnackbarProvider>
                <GlobalStyle />
                <Router>
                  <Routes />
                </Router>
              </SnackbarProvider>
            </ReferralProvider>
          </ThemeProvider>
        </AuthProvider>
      </AnalyticsContainer>
    </div>
  );
}

export default App;
