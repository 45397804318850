import styled from 'styled-components';

export const MissionWrapper = styled.div`
  margin: 130px 40px 80px 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 130px 0 54px 52px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 110px 0 54px 0;
  }
`;

export const MissionBlogWrapper = styled.div`
  padding: 0 80px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 0;
  }
`;
