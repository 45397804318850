import styled from 'styled-components';

import { Tabs, Tab } from '@material-ui/core';

export const TabsWrapper = styled(Tabs)`
  && {
    opacity: 1;
    border-bottom: 1px solid ${(props) => props.theme.border.lavenderGrey};
    .MuiTab-textColorInherit.Mui-selected {
      color: ${(props) => props.theme.fontColor.dodgerBlue};
    }
  }
`;

export const TabLabel = styled(Tab)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: ${(props) => props.theme.fontColor.cloudBlue};
    text-transform: capitalize;
    line-height: 24px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fontSize.secondary};
      line-height: 22px;
      min-width: 72px;
    }
  }
`;
