import React from 'react';
import PropTypes from 'prop-types';

import themes from 'styles/theme';
import { CircularLoader, GridWrapper } from './styles';

/**
 * Loader for the app.
 * @returns JSX
 */
const Loader = ({ color, isButtonLoader, size }) => (
  <GridWrapper container justify='center' alignItems='center' isButtonLoader={isButtonLoader}>
    <CircularLoader color={color} size={size} />
  </GridWrapper>
);

Loader.propTypes = {
  color: PropTypes.string,
  isButtonLoader: PropTypes.bool,
  size: PropTypes.number,
};

Loader.defaultProps = {
  color: themes.backgroundColor.dodgerBlue,
  isButtonLoader: false,
  size: 40,
};

export default Loader;
