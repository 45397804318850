import React from 'react';

import CarbonSavings from 'components/pages/CarbonSaving/common';
import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import Title from './style';

/**
 * * [CarbonSavingsLanding] is the landing page of the carbon savings page which holds the
 *  * listed description about the carbon savings and emissions
 * @returns <CarbonSavings>
 */
function CarbonSavingsLanding() {
  const carbonSavings = [
    {
      id: '01',
      description:
        'Carbon emissions are generated by various activities by an individual or an organisation',
    },
    {
      id: '02',
      description:
        'You purchase carbon offsets equivalent to the amount of CO2 you produced',
    },
    {
      id: '03',
      description:
        'Your contribution funds certified projects that conserve and restore forests, generate clean and renewable energy or increase energy efficiency.',
    },
    {
      id: '04',
      description:
        'These projects reduce CO2 and other greenhouse gases by removing existing CO2 from the atmosphere or preventing new emissions',
    },
  ];
  return (
    <BaseLayout>
      <Title>What’s Carbon Savings?</Title>
      {/* // TODO (Manjusha): [carbonSavings] need to be updated
      once the api integration is done */}
      {carbonSavings.map((carbonSavingsData, index) => (
        <CarbonSavings
          key={carbonSavingsData.id}
          data={carbonSavingsData}
          isConnector={carbonSavings.length - 1 !== index}
        />
      ))}
    </BaseLayout>
  );
}

export default CarbonSavingsLanding;
