import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';
import { ArrowForwardOutlined } from '@material-ui/icons';

import { useAuth } from 'provider/auth-provider';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as Strings from 'resources/strings';
import Markdown from 'components/common/Markdown';
import * as constants from 'utils/Analytics/flow-constants';

import {
  CardWrapper,
  Description,
  ImageWrapper,
  Image,
  IconWrapper,
  ContentWrapper,
  LinkWrapper,
} from './style';

/// [ReferralCard] renders the UI for referral card
const ReferralCard = ({
  showClose,
  image,
  onClick,
  color,
  onClose,
  description,
  hasLink,
}) => {
  const [isClose, setIsClose] = useState(true);
  const { currentUser } = useAuth();
  const { initiateTracking } = useAnalytics();

  // `onCloseCard` on closing the congratulations card
  const onCloseCard = (event) => {
    // analytics for close button
    initiateTracking({
      eventName: constants.closeReferralAnalytics,
      eventCategory: constants.visitorAction,
      eventAction: constants.dashboard,
      eventLabel: constants.closeReferralCard,
    });
    onClose(currentUser.id);
    setIsClose(false);
    event.stopPropagation();
  };

  return isClose ? (
    <>
      <CardWrapper onClick={() => onClick()} color={color}>
        <ContentWrapper>
          <Description>
            <Markdown content={description} />
          </Description>
          {hasLink
            && (
              <LinkWrapper>
                {Strings.referralLinkText}
                <ArrowForwardOutlined />
              </LinkWrapper>
            )}
        </ContentWrapper>
        {image
          && (
            <ImageWrapper>
              <Image src={image} alt='referral-image' />
            </ImageWrapper>
          )}
        {showClose && (
          <IconWrapper onClick={(event) => onCloseCard(event)}>
            <CloseIcon fontSize='inherit' />
          </IconWrapper>
        )}
      </CardWrapper>
    </>
  ) : (
    <></>
  );
};

ReferralCard.propTypes = {
  showClose: PropTypes.bool,
  image: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  color: PropTypes.string,
  description: PropTypes.string,
  hasLink: PropTypes.bool,
};

ReferralCard.defaultProps = {
  showClose: false,
  image: '',
  onClose: () => { },
  onClick: () => { },
  color: '',
  description: '',
  hasLink: false,
};

export default ReferralCard;
