import { firebasePost, firebasePatch } from 'services/firebase-api-service';

// `uploadImageFile` uploads an image file and return file's data
export const uploadImageFile = async (imageFile) => {
  try {
    const formData = new FormData();
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('files', imageFile);
    const uploadResponse = await firebasePost('uploadPhotoRouter/upload/photo', formData, header);
    return uploadResponse;
  } catch (error) {
    return error;
  }
};

// `postNewBillUploadData` used to create a new userMission
export const postNewBillUploadData = async (
  userId,
  taskId,
  missionId,
  imageId,
  activityId,
  currentActivityIndex,
  completedCount,
  userPhoneNumber,
) => {
  try {
    const url = `userActivity/create/${userId}`;
    const body = {
      taskId,
      missionId,
      imageId,
      activityId,
      currentActivityIndex,
      completedCount,
      userPhoneNumber,
      source: 'mission_activity',
    };
    const response = await firebasePost(url, body);
    return response;
  } catch (error) {
    return error;
  }
};

// `postReuploadBillData` used to create a new userMission
export const updateReuploadBillData = async (
  userId,
  taskId,
  missionId,
  imageId,
  userActivityId,
  currentActivityIndex,
  completedCount,
  userPhoneNumber,
) => {
  try {
    const url = `userActivity/update/${userId}/${userActivityId}`;
    const body = {
      taskId,
      missionId,
      imageId,
      currentActivityIndex,
      completedCount,
      userPhoneNumber,
      source: 'mission_activity',
    };
    const response = await firebasePatch(url, body);
    return response;
  } catch (error) {
    return error;
  }
};
