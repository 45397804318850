import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  color: ${(props) => props.theme.fontColor.black};
  overflow: hidden;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSize.secondary};
  margin: 25px 0px 0px 80px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-left: 25px;
  }
`;

export const MissionWrapper = styled.div`
  display: inline-block;
  padding: 12px;
  width: 99px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.backgroundColor.blackSqueeze)};
  border-radius: 12px;
  height: 141px;
  margin: ${(props) => (props.isRequired ? '0px 22px 27px 80px' : '0px 22px 27px 0px')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 66px;
    height: 94px;
    margin: ${(props) => (props.isRequired ? '0px 12px 27px 25px' : '0px 12px 27px 0px')};
  }
`;

export const ImageWrapper = styled.div`
  width: 16px;
  height: 16px;
`;

export const Points = styled(Typography)`
  && {
    margin: 32px 0px 4px 0px;
    font-size: ${(props) => props.theme.fontSize.medium};
    line-height: 23px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      margin: 24px 0px 4px 0px;
    }
  }
`;

export const Status = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.secondary};
    line-height: 20px;
    display: flex;
    white-space: pre-line;
    word-break: break-word;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      font-size: ${(props) => props.theme.fontSize.description};
      line-height: 14px;
    }
  }
`;
