import styled from 'styled-components';
import { Link, IconButton } from '@material-ui/core';

export const TitleWrapper = styled.div`
 display: flex;
 align-items: center;
 cursor: pointer;
`;

export const RedirectLink = styled(Link)`
 color: ${(props) => props.theme.fontColor.white} !important;
 text-decoration: none !important;
 display: flex;
`;

export const AppbarIcon = styled(IconButton)`
 color: ${(props) => props.theme.fontColor.white} !important;
 padding: 12px 8px 12px 10px !important;
`;
