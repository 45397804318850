import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 100%;
    margin-left: 0;
    display: block;
  }
`;

export const EarnedRewardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    flex-direction: column;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 21px 26px 19px 24px;
`;

export const HorizontalDivider = styled.div`
  display: ${(props) => (props.index === 0 ? 'none' : 'flex')};
  border-right: 1px solid;
  height: 100px;
  color: ${(props) => props.theme.border.ghost};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 100%;
    border-top: 1px solid;
    border-right: 0;
    display: flex;
    height: 0;
  }
`;

export const Title = styled.div`
  font-weight: ${(props) => props.theme.fontWeight.normal};
  font-size: ${(props) => props.theme.fontSize.large};
  line-height: 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.secondary};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 24px;
  }
`;

export const ImageWrapper = styled.div`
  background: ${(props) => props.theme.backgroundColor.titanWhite};
  border-radius: 12px;
  width: 53px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const Description = styled.div`
  color: ${(props) => props.theme.fontColor.carbonGrey};
  font-size: ${(props) => props.theme.fontSize.secondary};
  line-height: 22px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.description};
    line-height: 18px;
  }
`;
