import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import { useSustainabilityMetrics } from 'provider/sustainability-metrics-provider';
import { useReferrals } from 'provider/referral_provider';
import { useMissions } from 'provider/missions-provider';
import { useAllMissions } from 'provider/dashboard-provider';
import { useAuth } from 'provider/auth-provider';

import Reward from 'components/pages/MissionReward/components/Reward';
import { goToDashboard } from 'resources/strings';
import { mUsers, rewardId } from 'utils/global-constants';
import Loader from 'components/common/Loader/Loader';
import { updateDocumentField } from 'services/firebase-service';

/**
 *
 * Reward which holds all the components of the claim reward screen
 * @param {object} data
 * @returns JSX
 */

function RewardContainer() {
  const {
    fetchMissionsData, missionsData, isLoading: missionsDataLoading,
  } = useMissions();
  const {
    userEarnedRewards,
    isRewardsLoading,
  } = useAllMissions();
  const { referralRewardSeen } = useReferrals();
  const {
    currentUser,
    enteredPath,
    updateMissionRewardStatus,
    fetchUserStats,
    deleteCompletedMissionReward,
  } = useAuth();
  const location = useLocation();
  const { fetchMetrics, isLoading } = useSustainabilityMetrics();
  const params = useParams();
  const history = useHistory();
  const { initiateTracking } = useAnalytics();
  const [metricsData, setMetricsData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState();
  const [completedMissions, setCompletedMissions] = useState();
  const [bonusReward, setBonusReward] = useState({});
  const showProgress = location?.state?.showProgress ?? false;
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const referralRewardId = location?.state?.referralRewardId ?? '';

  useEffect(() => {
    // to update the provider with metrics value
    fetchMetrics();
  }, []);

  // `goToNext` redirects to the next screen
  const goToNext = () => {
    const nextMissionindex = completedMissions?.indexOf(
      completedMissions?.find(
        (mission) => mission.missionId === parseInt(params.missionId, 10),
      ),
    ) + 1;
    if (completedMissions && nextMissionindex !== completedMissions?.length) {
      // update the status of the completed mission refs
      updateMissionRewardStatus(
        currentUser.id,
        { ...completedMissions[nextMissionindex - 1], seen: true },
        completedMissions[nextMissionindex - 1],
      );
      // redirect to the next mission in the stack
      history.push(
        {
          pathname: `/missions/${completedMissions[nextMissionindex].missionId}/mission-reward`,
          search: `?reward-id=${completedMissions[nextMissionindex]?.userRewardIds.missionRewardId}`,
          state: { showProgress: true },
        },
      );
    } else {
      // delete all the completed refs at last redirection to missions
      deleteCompletedMissionReward(currentUser.id);
      history.push(enteredPath);
    }
  };

  useEffect(() => {
    if (!currentUser?.completedMissionsRefs) {
      // fetch the user data
      fetchUserStats();
    }
  }, []);

  useEffect(() => {
    // to fetch the current mission data
    fetchMissionsData(params.missionId);
  }, [params.missionId]);

  // setting the rewardseen to true
  // once the user has seen the reward
  useEffect(() => {
    if (!referralRewardSeen
      || (referralRewardId !== null
        && referralRewardId !== '')) {
      updateDocumentField(mUsers, currentUser.id, {
        referrer: { referralRewardSeen: true },
      });
    }
  }, []);

  useEffect(() => {
    if (location?.state?.showProgress && completedMissions) {
      // set the current index of the completed mission refs
      setCurrentIndex(completedMissions?.indexOf(
        completedMissions?.find(
          (mission) => mission.missionId === parseInt(params.missionId, 10),
        ),
      ));
      // redirect to the next reward for every 5 secs
      setTimeout(() => {
        goToNext();
      }, 5000);
    }
  }, [completedMissions
    && completedMissions?.length > 0]);

  useEffect(() => {
    // sorting the data based on seen true
    const sorted = currentUser?.completedMissionsRefs?.sort((a, b) => {
      const firsChild = a.seen ? 1 : 0;
      const secondChild = b.seen ? 2 : 0;
      return secondChild - firsChild;
    });
    setCompletedMissions(sorted);
  }, [currentUser?.completedMissionsRefs?.length]);

  // trigger analytics event
  const analyticsEventHandler = (
    eventName, eventCategory, eventAction, eventLabel,
  ) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: `${eventCategory}`,
      eventAction: `${eventAction}`,
      eventLabel: `${eventLabel}`,
    });
  };

  const goToDashboardHandler = () => {
    analyticsEventHandler(
      'analytics_back to dashboard',
      'navigation',
      'mission reward',
      `${goToDashboard}`,
    );
    history.push('/');
  };

  useEffect(() => {
    if (missionsData.activity != null) {
      const metricsObj = {};
      // 'metricsData' To get the required metrics data
      missionsData.activity.activityMetrics.forEach((metrics) => {
        metricsObj[metrics.sustainability_metric.metricKey] = metrics.value;
      });
      setMetricsData(metricsObj);
    }
  }, [Object.keys(missionsData).length]);

  useEffect(() => {
    // To get the rewardId from the search parameter
    const id = searchParams.get(rewardId);
    // To get the secondary reward if exists
    setBonusReward(userEarnedRewards[id]?.secondary);
  }, [Object.keys(userEarnedRewards).length]);

  return isLoading || missionsDataLoading || isRewardsLoading ? (
    <Loader />
  ) : (
    <Reward
      showProgress={showProgress}
      completedMissions={completedMissions}
      missionsData={missionsData}
      bonusReward={bonusReward}
      metricsData={metricsData}
      referralRewardSeen={referralRewardSeen}
      userEarnedRewards={userEarnedRewards}
      params={params}
      currentIndex={currentIndex}
      goToNext={goToNext}
      goToDashboardHandler={goToDashboardHandler}
      currentUser={currentUser}
      referralRewardId={referralRewardId}
    />
  );
}

export default RewardContainer;
