import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Title = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 22px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fontSize.secondary};
      line-height: 30px;
    }
  }
`;

export const Link = styled.p`
  font-size: ${(props) => props.theme.fontSize.primary};
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  line-height: 20px;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 25px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  margin: 25px 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 25px 0px;
  }
`;
