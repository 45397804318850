import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  text-align: center;
  margin-right: 25px;
  &{
    :after {
    content: '';
    flex: 1 1;
    border-bottom: 2px solid ${(props) => (props.isCompleted ? props.theme.border.seaGreen : props.theme.border.ghost)};
    min-width: 20px;
    margin: auto;
    position: absolute;
    left: 32px;
    top: 16px;
    border-radius: 2px;
    display: ${(props) => (props.isLast ? 'none' : 'block')};
  }
`;

export const ImageWrapper = styled.div`
  width: 30px;
  height: 30px;
  background-size: cover;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;

`;

export const SlideNumber = styled.p`
  position: absolute;
  font-size: ${(props) => (props.theme.fontSize.primary)};
  font-weight: ${(props) => (props.theme.fontWeight.medium)};
  font-family: ${(props) => (props.theme.fontFamily.robotoRegular)};
  top: 9px;
  left: 11px;
  color: ${(props) => (props.isCurrent ? props.theme.fontColor.dodgerBlue : props.theme.fontColor.grey)};
`;

export const LastMissionSlide = styled.div`
  position: absolute;
  width: 11px;
  height: 16px;
  top: 8px;
  left: 9px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  width: 16px;
  height: 16px;
`;

export const ProgressWrapper = styled.div`
display:flex;
margin-top: 40px;
@media (max-width: ${(props) => (props.theme.breakpoints.mobileLandscape)}){
  justify-content: center;
}
`;
