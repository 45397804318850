import styled from 'styled-components';

export const SpacerWrapper = styled.div`
  height: ${(props) => props.height ?? '0px'};
  width: ${(props) => props.width ?? '0px'};
`;

export const SpacerWidth = styled.div`
  width: ${(props) => props.height};
`;
