import styled from 'styled-components';

import {
  Table,
  TableCell,
  Typography,
  Link,
} from '@material-ui/core';

export const NotificationsNone = styled(Typography)`
  && {
    color: ${(props) => props.theme.fontColor.black};
    font-size: ${(props) => props.theme.fontSize.description};
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
  }
`;

export const NotificationTable = styled(Table)`
  && {
    width: 100%;
    margin: 1%;
    padding: 8px 15px;
    max-height:400px;
    overflow-y: scroll;
    display:block;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const HyperLinkTag = styled(Link)`
  && {
    text-decoration: none !important;
  }
`;

export const NotificationTableCell = styled(TableCell)`
  && {
    width: 71%;
    padding: 3% 1% 3% 1%;
    border-bottom: ${(props) => props.isBorder && 'none'};
  }
`;

export const NotificationText = styled(Typography)`
  && {
    font-weight: ${(props) => props.state && props.theme.fontWeight.semiBold};
    font-size: ${(props) => props.theme.fontSize.description};
  }
`;

export const NotificationDate = styled(Typography)`
  && {
    display: inline-block;
    color: ${(props) => props.theme.fontColor.grey};
    font-size: ${(props) => props.theme.fontSize.description};
  }
`;

export const NotificationStatusWrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.blackSqueeze};
  color: ${(props) => props.theme.fontColor.black};
  display: flex;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
`;
