import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  width: 357px;
  height: 233px;
  padding: 34px 30px 24px;
  border-radius: 12px;
  box-shadow: ${(props) => props.theme.boxShadow.greyShadow};
  margin: ${(props) => (props.isFirstChild ? '4px 8px 4px 4px' : '4px 8px')};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 24px 20px 16px;
    width: 233px;
    height: 151px;
  }
`;

export const InitialWrapper = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 32px;
    height: 32px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UserInitial = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    text-transform: capitalize;
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: ${(props) => props.theme.fontColor.carbonGrey};
    line-height: 22px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      line-height: 30px;
    }
  }
`;

export const UserName = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    text-transform: capitalize;
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 22px;
    margin: auto 8px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fontSize.secondary};
      line-height: 30px;
    }
  }
`;

export const Comments = styled.p`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.fontColor.carbonGrey};
  line-height: 30px;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 25px 0 8px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
    margin: 16px 0 4px;
  }
`;

export const Link = styled.p`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  line-height: 30px;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
  }
`;
