import React from 'react';

import { useAboutMissions } from 'provider/about-missions-provider';

import AboutMissionsLanding from 'components/pages/AboutMissions/components/AboutMissionsLanding';
import Loader from 'components/common/Loader/Loader';

/// [AboutMissionsContainer] contains the about adva missions data
const AboutMissionsContainer = () => {
  const { aboutMissionsData, aboutMissionsLoader } = useAboutMissions();
  return aboutMissionsLoader ? (
    <Loader />
  ) : (
    <AboutMissionsLanding aboutMissionsData={aboutMissionsData} />
  );
};

export default AboutMissionsContainer;
