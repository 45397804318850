import React from 'react';

import PropTypes from 'prop-types';

import * as Strings from 'resources/strings';
import LoginForm from './LoginForm';
import {
  LoginWrapper,
  LoginText,
  CommunityDescription,
  LoginDescription,
} from './style';

/**
 * @param {boolean} showOtp
 * @param {(e)=>void} setOtp
 * @param {object} values
 * @param {(e)=>void} handleInput
 * @param {(e)=>void} submitPhoneNumber
 * @param {(e)=>void} editPhoneNumberHandler
 * @param {(e)=>void} submitOtp
 * @param {(e)=>void} timer
 * @returns Login component which is responsible for creating login form
 */
function Login({
  showOtp, setOtp,
  values, handleInput,
  submitPhoneNumber,
  submitOtp, timer,
  editPhoneNumberHandler,
  clearOtpError,
  otpValue,
  isLoading,
}) {
  return (
    <LoginWrapper>
      <LoginText>Login</LoginText>
      {
        !showOtp ? (
          <CommunityDescription>
            {Strings.welcomeToMissions}
            <LoginDescription>
              {Strings.welcomeDesc}
              <br />
              <br />
              {Strings.featureDesc}
              <br />
              <br />
              {Strings.signupDesc}
            </LoginDescription>
          </CommunityDescription>
        ) : (
          <CommunityDescription>
            {Strings.joinCommunity}
            <LoginDescription>
              {Strings.loginDesc}
            </LoginDescription>
          </CommunityDescription>
        )
      }
      {/* // [LoginForm] component displays the form for Login Page */}
      <LoginForm
        showOtp={showOtp}
        setOtp={setOtp}
        values={values}
        handleInput={handleInput}
        submitPhoneNumber={submitPhoneNumber}
        submitOtp={submitOtp}
        timer={timer}
        editPhoneNumberHandler={editPhoneNumberHandler}
        otpValue={otpValue}
        isLoading={isLoading}
        clearOtpError={clearOtpError}
      />
    </LoginWrapper>
  );
}

Login.propTypes = {
  showOtp: PropTypes.bool,
  setOtp: PropTypes.func,
  values: PropTypes.shape({
    phoneNumber: PropTypes.string,
    username: PropTypes.string,
    phoneNumberError: PropTypes.string,
    isPhoneNumberWrong: PropTypes.bool,
  }),
  handleInput: PropTypes.func,
  submitPhoneNumber: PropTypes.func,
  editPhoneNumberHandler: PropTypes.func,
  submitOtp: PropTypes.func,
  timer: PropTypes.number,
  otpValue: PropTypes.string,
  isLoading: PropTypes.bool,
  clearOtpError: PropTypes.func,
};

Login.defaultProps = {
  showOtp: false,
  setOtp: () => { },
  values: {},
  handleInput: () => { },
  submitPhoneNumber: () => { },
  submitOtp: () => { },
  editPhoneNumberHandler: () => { },
  clearOtpError: () => { },
  otpValue: '',
  isLoading: false,
};

export default Login;
