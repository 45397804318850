import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const SubHeading = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.secondary};
    line-height: 21px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fontSize.primary};
    }
  }
`;

export const ProductPrize = styled.span`
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 21px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.medium};
  }
`;

export const ProductDelivery = styled.span`
  font-size: ${(props) => props.theme.fontSize.secondary};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  line-height: 21px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.primary};
  }
`;

export const RecentPurchase = styled(Typography)`
  color: ${(props) => props.theme.fontColor.carbonGrey};
  font-size: ${(props) => props.theme.fontSize.secondary};
  line-height: 21px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.primary};
  }
`;

export const RecentPurchaseDuration = styled.span`
  color: ${(props) => props.theme.fontColor.carbonGrey};
  font-size: ${(props) => props.theme.fontSize.secondary};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  line-height: 21px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.primary};
  }
`;
