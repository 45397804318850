import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import moment from 'moment';
import { StyledDatePicker, DatePickerProvider } from './style';

/**
 * Common DatePicker Component
 * @param {format}
 * @param {disableToolbar}
 * @param {getSelectedDate}
 * @returns JSX
 */

function DatePicker({
  format,
  disableToolbar,
  getSelectedDate,
}) {
  const [selectedDate, setSelectedDate] = useState(moment(Date()).add(1, 'days').format('MM/DD/yyyy'));
  // user should only select a date which is in future
  const minimumDate = moment(Date()).add(1, 'days').format('MM/DD/yyyy');
  let selected = selectedDate;
  const handleDateChange = (date) => {
    // convert date to milli seconds
    selected = moment(date).unix() * 1000;
    setSelectedDate(selected);
    getSelectedDate(selected);
  };
  return (
    <DatePickerProvider utils={DateFnsUtils}>
      <StyledDatePicker
        InputProps={{
          disableUnderline: true,
        }}
        minDate={minimumDate}
        disableToolbar={disableToolbar}
        format={format}
        margin='normal'
        id='date-picker-dialog'
        label=''
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </DatePickerProvider>
  );
}

export default DatePicker;

DatePicker.propTypes = {
  format: PropTypes.string,
  getSelectedDate: PropTypes.func,
  disableToolbar: PropTypes.bool,
};

DatePicker.defaultProps = {
  format: 'MM/dd/yyyy',
  getSelectedDate: () => { },
  disableToolbar: false,
};
