import PropTypes from 'prop-types';

import IconWrapper from './style';

/**
 * [IconCard] common icon card component
 * @param {icon}
 * @param {color}
 * @returns JSX
 */
const IconCard = ({
  icon,
  color,
  image,
  alt,
  height,
  width,
  wrapperHeight,
  wrapperWidth,
}) => (
  <IconWrapper color={color} wrapperHeight={wrapperHeight} wrapperWidth={wrapperWidth}>
    {image && (
      <img
        src={image}
        alt={alt}
        height={height}
        width={width}
      />
    )}
    {icon}
  </IconWrapper>
);

IconCard.propTypes = {
  icon: PropTypes.element,
  color: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  wrapperHeight: PropTypes.string,
  wrapperWidth: PropTypes.string,
};

IconCard.defaultProps = {
  icon: <></>,
  image: '',
  color: '',
  alt: '',
  height: '19px',
  width: ' 20px',
  wrapperHeight: '58px',
  wrapperWidth: '58px',
};

export default IconCard;
