import React from 'react';
import PropTypes from 'prop-types';

import {
  RewardContainer,
  ImageWrapper,
  Image,
  OfferWrapper,
  OfferTitle,
  OfferText,
} from './style';

/**
 *
 * @param {string} image
 * @param {string} offerTitle
 * @param {string} offerText
 * `ReferralRewardCard` renders the UI for referral-reward card
 */
const ReferralRewardCard = ({
  image,
  offerTitle,
  offerText,
  goToRewardsCollected,
}) => (
  <RewardContainer onClick={goToRewardsCollected}>
    <ImageWrapper>
      <Image src={image} />
    </ImageWrapper>
    <OfferWrapper>
      <OfferTitle>{offerTitle}</OfferTitle>
      <OfferText>{offerText}</OfferText>
    </OfferWrapper>
  </RewardContainer>
);

ReferralRewardCard.propTypes = {
  image: PropTypes.string,
  offerTitle: PropTypes.string,
  offerText: PropTypes.string,
  goToRewardsCollected: PropTypes.func,
};

ReferralRewardCard.defaultProps = {
  image: '',
  offerTitle: '',
  offerText: '',
  goToRewardsCollected: () => {},
};

export default ReferralRewardCard;
