import React from 'react';
import PropTypes from 'prop-types';

import * as Strings from 'resources/strings';

import { InviteMembers, InviteText } from './style';

/// `InviteLink` renders UI for invite link component in refer a friend page
const InviteLink = ({ totalCount, completedCount }) => (
  completedCount === 0 ? (
    <>
      <InviteText>
        {Strings.inviteFriendstext}
        <InviteMembers>
          {totalCount}
          {Strings.friends}
        </InviteMembers>
        {Strings.multipyReward}
      </InviteText>
    </>
  ) : (
    <>
      <InviteText>
        {Strings.inviteText}
        <InviteMembers>
          {completedCount}
          /
          {totalCount}
        </InviteMembers>
        {Strings.friends}
        <br />
        {Strings.inviteFriendstext}
        <InviteMembers>
          {totalCount}
          {Strings.friends}
        </InviteMembers>
        {Strings.multipyReward}
      </InviteText>
    </>
  )
);

InviteLink.propTypes = {
  totalCount: PropTypes.number,
  completedCount: PropTypes.number,
};

InviteLink.defaultProps = {
  totalCount: 0,
  completedCount: 0,
};

export default InviteLink;
