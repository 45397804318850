import styled from 'styled-components';

import { Typography } from '@material-ui/core';

const Title = styled(Typography)`
    && {
        margin: 24px 0px 28px 0px;
        line-height: 24px;
        font-size: ${(props) => props.theme.fontSize.secondary};
        font-weight: 500;
    }
`;

export default Title;
