import styled from 'styled-components';

export const RewardCollectedWrapper = styled.div`
  padding: 12px 20px 12px 19px;
  border-radius: 14px;
  box-shadow: ${(props) => props.theme.boxShadow.greyShadow};
  max-width: 413px;
  background-color: ${(props) => props.theme.backgroundColor.white};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 10px 10px 8px 15px;
    border-radius: 11px;
    max-width: unset;
  }
`;

export const ImageDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 13px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding-bottom: 10px;
  }
`;

export const OfferTitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily.robotoBold};
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 26px;
  color: ${(props) => props.theme.fontColor.carbonBlack};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.secondary};
    line-height: 22px;
    letter-spacing: 0.25px;
  }
`;
export const OfferDescription = styled.p`
  font-family: ${(props) => props.theme.fontFamily.robotoRegular};
  font-size: ${(props) => props.theme.fontSize.secondary};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.fontColor.carbonGrey};
  line-height: 22px;
  letter-spacing: 0.25px;
  max-width: 234px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.description};
    line-height: 18px;
    max-width: 175px;
  }
`;
export const ImageWrapper = styled.div`
  width: 43px;
  height: 48px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 34px;
    height: 38px;
  }
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    border-radius: 4px;
  }
`;
export const CTAContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding-top: 9px;
  }
`;
export const ViewCTA = styled.p`
  font-family: ${(props) => props.theme.fontFamily.robotoRegular};
  font-size: ${(props) => props.theme.fontSize.secondary};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  line-height: 22px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  cursor: default;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
  }
`;
