import React from 'react';
import PropTypes from 'prop-types';

import themes from 'styles/theme';
import high from 'utils/Missions/flow-constants';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import IconCard from 'components/common/IconCard';
import {
  Benefits,
  BenefitsWrapper,
  IconWrapper,
  BenefitsText,
} from './style';

// `MissionHealthBenefits` renders the missions health benefit session
const MissionHealthBenefits = ({ healthBenefits }) => (
  <>
    <BenefitsWrapper>
      {healthBenefits?.length > 0
        && healthBenefits.map((benefit) => (
          <Benefits>
            <IconWrapper>
              <IconCard
                icon={
                  benefit.type === high ? (
                    <ArrowUpward
                      fontSize='inherit'
                      style={{ color: themes.backgroundColor.dodgerBlue }}
                    />
                  ) : (
                    <ArrowDownward
                      fontSize='inherit'
                      style={{ color: themes.backgroundColor.dodgerBlue }}
                    />
                  )
                }
                wrapperHeight='32px'
                wrapperWidth='32px'
                color={themes.backgroundColor.titanWhite}
              />
            </IconWrapper>
            <BenefitsText>{benefit?.name ?? ''}</BenefitsText>
          </Benefits>
        ))}
    </BenefitsWrapper>
  </>
);

export default MissionHealthBenefits;

MissionHealthBenefits.propTypes = {
  healthBenefits: PropTypes.arrayOf(PropTypes.string),
};
MissionHealthBenefits.defaultProps = {
  healthBenefits: [],
};
