import styled from 'styled-components';

const PrimaryButton = styled.button`
    color: ${(props) => (!props.disabled ? props.theme.fontColor.white : props.theme.fontColor.silverChalice)};
    background-color:  ${(props) => props.backgroundColor} !important;
    text-transform: none;
    border-radius: 8px;
    height:50px;
    width: 100%;
    border: none;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.theme.fontSize.primary};
    cursor: ${(props) => (!props.disabled && !props.isLoading ? 'pointer' : 'text')};
`;
export default PrimaryButton;
