import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as Strings from 'resources/strings';
import ButtonLink from 'components/common/ButtonLink/ButtonLink';
import LinkedMissionSeries from './LinkedMissionSeries/index';
import {
  LinkedMissionsWrapper,
  LinkedMissionsTitle,
  LinkedMissionsDescription,
  LinkedMissionSeriesWrapper,
  LinkedMissionsTitleWrapper,
} from './style';

// LinkedMissions component renders linked missions UI
const LinkedMissions = (props) => {
  const {
    showViewAllOption,
    ongoingMissions,
    completedMissions,
    linkedMissionArray,
    startMissions,
    isReward,
    isLoading,
  } = props;
  const [showMore, setShowMore] = useState(false);

  // toggle the show more option
  const showMoreMissions = () => {
    setShowMore(!showMore);
  };

  // get the ongoing linked missions
  const getOngoingLinkedMissions = () => {
    // when show more is true ----> return the entire list
    // else ----> return only first 2 items
    if (showMore) {
      return linkedMissionArray;
    }
    return linkedMissionArray.slice(0, 2);
  };

  return (
    linkedMissionArray.length > 0 && (
      <LinkedMissionsWrapper>
        <LinkedMissionsTitleWrapper>
          <LinkedMissionsTitle>
            {Strings.linkedMissionsTitle}
          </LinkedMissionsTitle>
          {linkedMissionArray.length > 2 && showViewAllOption && (
            <ButtonLink
              title={showMore ? Strings.viewLessLabel : Strings.viewAllLabel}
              onClick={showMoreMissions}
            />
          )}
        </LinkedMissionsTitleWrapper>
        <LinkedMissionsDescription>{Strings.linkedMissionsDesc}</LinkedMissionsDescription>
        {getOngoingLinkedMissions().map((missionData, index) => (
          <LinkedMissionSeriesWrapper>
            {linkedMissionArray.length > 1 && (
              <LinkedMissionsDescription>
                {`${Strings.ongoingSeriesTitle}${index + 1}`}
              </LinkedMissionsDescription>
            )}
            <LinkedMissionSeries
              linkedMissions={missionData}
              ongoingMissions={ongoingMissions}
              completedMissions={completedMissions}
              startMissions={startMissions}
              isReward={isReward}
              isLoading={isLoading}
            />
          </LinkedMissionSeriesWrapper>
        ))}
      </LinkedMissionsWrapper>
    )
  );
};

LinkedMissions.propTypes = {
  showViewAllOption: PropTypes.bool,
  ongoingMissions: PropTypes.instanceOf(Object),
  completedMissions: PropTypes.instanceOf(Object),
  linkedMissionArray: PropTypes.arrayOf(PropTypes.object),
  startMissions: PropTypes.func,
  isReward: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default LinkedMissions;
