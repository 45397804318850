import React from 'react';
import PropTypes from 'prop-types';

import { Dots, Wrapper } from './style';

/**
 *
 * @param {Object} missionsData
 * @param {bool} showGutter
 * @returns CarouselDot indicates the total number of cards in the slider
 */

const CarouselDots = ({ missionsData, showGutter, currentIndex }) => (
  <Wrapper showGutter={showGutter}>
    {Object.keys(missionsData).map((category, index) => (
      <Dots key={category} isVisible={currentIndex === index + 1} />
    ))}
  </Wrapper>
);

export default CarouselDots;

CarouselDots.propTypes = {
  missionsData: PropTypes.shape(PropTypes.any),
  showGutter: PropTypes.bool,
  currentIndex: PropTypes.number,
};

CarouselDots.defaultProps = {
  showGutter: false,
  missionsData: {},
  currentIndex: 1,
};
