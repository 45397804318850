import React from 'react';

import { useAllMissions } from 'provider/dashboard-provider';

import PreLoginDashboardBanner from 'components/pages/PreLoginDashboard/components/PreLoginDashboardBanner';
import FindAllMissionsContainer from 'components/containers/FindAllMissions';
import Footer from 'components/pages/PreLoginDashboard/components/Footer';
import PreLoginAboutMissions from 'components/pages/PreLoginDashboard/components/PreLoginAboutMissions';
import MissionBlogs from 'components/common/MissionBlogs';
import Loader from 'components/common/Loader/Loader';
import { MissionWrapper, MissionBlogWrapper } from './style';

/**
 * * [PreLoginDashboardLanding] is the parent component for all other
 * * components used in the PreLoginDashboard.
 * @returns <>
 *            <PreLoginDashboardBanner />
 *            <FindAllMissionsContainer />
 *            <Footer />
 *          </>
 */
const PreLoginDashboardLanding = () => {
  const {
    isAllBlogsLoading,
    missionBlogs,
  } = useAllMissions();

  return (
    <>
      <PreLoginDashboardBanner />
      <MissionWrapper>
        <FindAllMissionsContainer />
      </MissionWrapper>
      <PreLoginAboutMissions />
      {isAllBlogsLoading ? <Loader /> : (
        missionBlogs?.blogs?.length > 0
        && (
          <MissionBlogWrapper>
            <MissionBlogs missionBlogs={missionBlogs} />
          </MissionBlogWrapper>
        ))}
      <Footer />
    </>
  );
};

export default PreLoginDashboardLanding;
