import React from 'react';
import PropTypes from 'prop-types';

import Spacer from 'components/common/Spacer/Spacer';
import AboutMissionsCard from 'components/pages/AboutMissions/components/AboutMissionsCard';
import Wrapper from './style';

const AboutCards = ({ aboutAdvaMissionsData, isPreLogin }) => (
  <>
    <Wrapper isPreLogin={isPreLogin}>
      {aboutAdvaMissionsData.map((aboutMissionsData, index) => (
        <AboutMissionsCard
          data={aboutMissionsData}
          isConnector={aboutAdvaMissionsData.length - 1 !== index}
          isPreLogin={isPreLogin}
        />
      ))}
    </Wrapper>
    <Spacer height='50px' />
  </>
);

AboutCards.propTypes = {
  aboutAdvaMissionsData: PropTypes.arrayOf(PropTypes.string),
  isPreLogin: PropTypes.bool,
};

AboutCards.defaultProps = {
  aboutAdvaMissionsData: [],
  isPreLogin: false,
};

export default AboutCards;
