import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useAllMissions } from 'provider/dashboard-provider';

import * as constants from 'utils/Analytics/flow-constants';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import ButtonLink from 'components/common/ButtonLink/ButtonLink';
import * as Strings from 'resources/strings';
import Loader from 'components/common/Loader/Loader';
import ViewMissionCard from 'components/pages/Missions/components/ViewMissionCard/index';
import Carousel from 'components/common/Carousel';

import {
  Title,
  Wrapper,
  TitleWrapper,
  ViewMissionCardWrapper,
} from './style';

/**
 * `RecommendedMissions` used to display all the featured missions
 *  available for the user
 * @returns JSX
 */
const RecommendedMissions = () => {
  const missionsCardRef = useRef();
  const history = useHistory();
  const {
    isMissionLoading,
    fetchFeaturedMissions,
    featuredMissions,
    isFeaturedMissionLoading,
  } = useAllMissions();
  const { initiateTracking } = useAnalytics();
  useEffect(() => {
    // to fetch available the featured missions
    fetchFeaturedMissions();
  }, []);

  // `redirectToAllMission` redirect to all missions page
  const redirectToAllMission = () => {
    // analytics for view all
    initiateTracking({
      eventName: constants.recommendedMissionsAnalytics,
      eventCategory: constants.navigation,
      eventAction: `${constants.allCategories}_${constants.recommendedMissions}`,
      eventLabel: Strings.viewAllLabel,
    });
    history.push('/all-categories/all-missions');
  };

  return (isMissionLoading || isFeaturedMissionLoading) ? (
    <Loader />
  ) : (
    <>
      <TitleWrapper>
        <Title>{Strings.missionsYouMightLike}</Title>
        <ButtonLink
          title={Strings.viewAllLabel}
          onClick={() => redirectToAllMission()}
        />
      </TitleWrapper>
      <Wrapper>
        <Carousel missionsData={featuredMissions} showChevron={false}>
          {Object.values(featuredMissions).map((mission, index) => (
            <ViewMissionCardWrapper ref={missionsCardRef} isRequired={index === 0}>
              <ViewMissionCard
                categoryImage={mission.missionImage?.url}
                categoryName={mission?.missionsCategory?.name}
                missionTitle={mission?.title}
                offer={mission?.missionReward?.name}
                altText='mission-image'
                missionId={mission.id}
              />
            </ViewMissionCardWrapper>
          ))}
        </Carousel>
      </Wrapper>
    </>
  );
};

export default RecommendedMissions;
