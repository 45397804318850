import styled from 'styled-components';

import PrimaryButton from 'components/common/Button/style';

export const ReferralTitle = styled.div`
  margin: 23px 0px;
  text-align: center;
  line-height: 28px;
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 16px 24px;
    font-size: ${(props) => props.theme.fontSize.secondary};
    line-height: 24px;
    text-align: left;
  }
`;

export const Wrapper = styled.div`
  margin-top: 38px;
  padding: 23px 24px;
  background-color: ${(props) => props.theme.backgroundColor.titanWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-top: 16px;
    padding: 19px 24px 32px 24px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const ImageWrapper = styled.div`
  width: 207px;
  height: 160px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    width: 163px;
    height: 126px;
  }
`;

export const GetRewardText = styled.div`
  margin-top: 24px;
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  font-size: ${(props) => props.theme.fontSize.smMedium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 22px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-top: 12px;
    font-size: ${(props) => props.theme.fontSize.secondary};
  }
`;

export const Description = styled.div`
  font-size: ${(props) => props.theme.fontSize.smMedium};
  line-height: 22px;
  white-space: pre-line;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
  }
`;

export const CopyLinkText = styled.div`
  margin-top: 53px;
  text-align: center;
  white-space: pre-line;
  font-size: ${(props) => props.theme.fontSize.smMedium};
  line-height: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-top: 32px;
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
  }
`;

export const LinkWrapper = styled.div`
  margin: 4px 80px 4px 46px;
  display: flex;
  padding: 19px 32px;
  border-radius: 12px;
  justify-content: space-between;
  background-color: ${(props) => props.theme.backgroundColor.titanWhite};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 4px 25px 4px 24px;
    border-radius: 10px;
    padding: 16px;
  }
`;

export const LinkText = styled.div`
  font-size: ${(props) => props.theme.fontSize.smMedium};
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  line-height: 21px;
  word-break: break-word;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 17px;
  }
`;

export const ButtonWrapper = styled.div`
  background: ${(props) => props.theme.backgroundColor.white};
  margin: 32px 46px;
  ${PrimaryButton} {
    background-color: ${(props) => props.theme.backgroundColor.white} !important;
    border: 1px solid ${(props) => props.theme.border.dodgerBlue};
    color: ${(props) => props.theme.border.dodgerBlue};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 16px;
  }
`;
