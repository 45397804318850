import styled from 'styled-components';

export const Img = styled.img`
  width: inherit;
  height: inherit;
`;

export const ImageWrapper = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: 0 auto;
  text-align: center;
`;
