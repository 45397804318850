import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useAuth } from 'provider/auth-provider';
import { useSnackbar } from 'provider/snackbar-provider';
import { useTimer } from 'provider/timer-provider';

import { referralLink } from 'utils/global-constants';
import Login from 'components/pages/Login/components/LoginComponent';
import * as Strings from 'resources/strings';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import redirectToMission from 'helpers/RedirectToMissions/flow-helpers';
import * as constants from 'utils/Analytics/flow-constants';
import { clevertapEventTracking, clevertapProfileTracking } from 'utils/Clevertap/Clevertap';
import { sendOTP, verifyPhoneNumber } from 'utils/Clevertap/flow-constants';

/// [LoginContainer] contains all the functions required for the [Login] component
function LoginContainer({ showOtpField }) {
  const history = useHistory();
  const { setIsShow, setDisplayText } = useSnackbar();
  const {
    signInWithPhoneNumberOTP,
    verifyOtp,
    setAuthenticationComplete,
    setAuthLoader,
  } = useAuth();
  // get the referral link from local storage
  const referredLink = localStorage.getItem(referralLink);
  const { timeLeft, startTimer, clearTimer } = useTimer();
  const { initiateTracking } = useAnalytics();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const phoneNumberErrorMessage = 'The entered phone number is invalid. Please enter a valid phone number to continue.';
  const otpErrorMessage = 'The entered OTP is invalid. Please re-enter the code or request a new one.';
  const reCaptchaErrorMessage = 'The reCAPTCHA has expired.';
  /// [values, setValues] is the user object
  const [values, setValues] = useState({
    phoneNumber: '',
    phoneNumberError: '',
    reCaptchaError: '',
    isPhoneNumberWrong: false,
    otpError: '',
  });

  const clearOtpError = () => {
    setValues({
      ...values,
      otpError: '',
    });
  };

  React.useEffect(() => {
    // if user reloads page on verify otp,
    // user should be taken back to login page to enter the number again
    if (values.phoneNumber === '') {
      history.push('/login');
    }
    // if user reloads login page before the the timer is over
    // the send otp button should be disabled until the time left to request for another otp
    // is more than 120seconds
    if (moment(Date()).unix() - localStorage.getItem('otpSendTime') < 120) {
      startTimer(120, 'otpSendTime');
    }
    // need to clear the timer on unmount
    return () => clearTimer();
  }, []);

  /**
   * * [handleInput] function is used for validating the phone number
   * @param {(event: string)=> void} callback
   */
  const handleInput = (event) => {
    const { name, value } = event.target;
    let errorMessage = '';
    let error = false;
    // TODO : need to discuss on the phone number validation and implement
    if (value.length > 8) {
      errorMessage = phoneNumberErrorMessage;
      error = true;
    } else {
      errorMessage = '';
      error = false;
    }
    setValues({
      ...values,
      [name]: `${Strings.phoneNumberPrefix}${value}`,
      phoneNumberError: errorMessage,
      isPhoneNumberWrong: error,
      otpError: '',
    });
  };

  /**
 * * [loginAnalyticsEvents] verifies the phone number of the user
 * @param eventName : name of the event
 * @param eventCategory : category which the event belongs to
 * @param eventLabel : the data string on the call to action
 */
  const loginAnalyticsEventHandler = (eventName, eventCategory, eventAction, eventLabel) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: `${eventCategory}`,
      eventAction: `${eventAction}`,
      eventLabel: `${eventLabel}`,
    });
  };

  /**
   * * [submitPhoneNumber] verifies the phone number of the user
   * @param {(event)=>void} event
   */
  const submitPhoneNumber = async (event) => {
    event.preventDefault();
    setIsButtonLoading(true);
    loginAnalyticsEventHandler('analytics_send otp', 'navigation', 'login', `${Strings.sendOTP}`);
    // clevertap event for sendOTP
    clevertapEventTracking(sendOTP);
    if (!values.isPhoneNumberWrong) {
      const res = await signInWithPhoneNumberOTP(values.phoneNumber);
      if (
        res.message === 'reCAPTCHA has already been rendered in this element' && !res.verificationId
      ) {
        setValues({
          ...values,
          reCaptchaError: reCaptchaErrorMessage,
        });
      } else if (res.code === 'auth/too-many-requests') {
        setValues({
          ...values,
          isPhoneNumberWrong: true,
          phoneNumberError: res.message,
        });
      } else if (!res.verificationId) {
        setValues({
          ...values,
          isPhoneNumberWrong: true,
          phoneNumberError: phoneNumberErrorMessage,
        });
      } else {
        history.push('/login/verify');
        startTimer(120, 'otpSendTime');
      }
    }
    setIsButtonLoading(false);
  };
  /**
   * * [submitOtp] verifies the OTP generated to the user
   * @param {(event)=>void} event
   */
  const submitOtp = async (event) => {
    event.preventDefault();
    setIsButtonLoading(true);
    // clevertap event for verify phone number
    clevertapEventTracking(verifyPhoneNumber);
    loginAnalyticsEventHandler('analytics_verify phone number', 'navigation', 'login verify', `${Strings.verifyPhoneNumber}`);
    const res = await verifyOtp(otp, referredLink);
    if (!res.user) {
      if (res.code === 'auth/invalid-verification-code') {
        setValues({
          ...values,
          otpError: otpErrorMessage,
        });
      } else {
        setValues({
          ...values,
          otpError: res.message,
        });
      }
    } else if (referredLink) {
      // clevertap profile push event for successful login
      clevertapProfileTracking(res.user.uid, values.phoneNumber);
      // remove the referral link from local storage
      localStorage.removeItem(referralLink);
      // if the user is coming referral link and not by missions
      // To route to referral reward page if referral Data exists
      history.push({
        pathname: '/referral-reward',
        state: { referrerData: res?.referrerData },
      });
    } else if (localStorage.getItem('currentMission') && localStorage.getItem('currentMission') !== '' && !referredLink) {
      // clevertap profile push event for successful login
      clevertapProfileTracking(res.user.uid, values.phoneNumber);
      // if the user is coming from missions screen
      // redirect to mission details page
      redirectToMission(res.user.uid, history, setIsShow, setDisplayText, setAuthLoader);
    } else {
      // clevertap profile push event for successful login
      clevertapProfileTracking(res.user.uid, values.phoneNumber);
      history.push('/');
    }
    setIsButtonLoading(false);
    setAuthenticationComplete(false);
  };

  const editPhoneNumberHandler = async () => {
    // analytics for change phone number cta
    loginAnalyticsEventHandler(
      constants.changePhoneNumberAnalytics,
      constants.navigation,
      constants.loginVerify,
      Strings.changeNumberDesc,
    );
    history.goBack();
  };

  return (
    <Login
      showOtp={showOtpField}
      setOtp={setOtp}
      values={values}
      handleInput={handleInput}
      submitPhoneNumber={submitPhoneNumber}
      submitOtp={submitOtp}
      timer={timeLeft}
      editPhoneNumberHandler={editPhoneNumberHandler}
      otpValue={otp}
      isLoading={isButtonLoading}
      clearOtpError={clearOtpError}
    />
  );
}
LoginContainer.propTypes = {
  showOtpField: PropTypes.bool,
  visitedMission: PropTypes.shape({
    missionId: PropTypes.number,
  }),
};

export default LoginContainer;
