import styled from 'styled-components';
import { BreadCrumbWrapper } from 'components/common/BreadCrumb/style';

export const AllMissionsTitle = styled.p`
  margin-left: 81px;
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-left: 24px;
    font-family: ${(props) => props.theme.fontFamily.RobotoRegular};
    font-size: ${(props) => props.theme.fontSize.secondary};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${(props) => props.theme.fontColor.carbonBlack};
  }
`;

export const AllMissionsTitleContainer = styled.div`
  position: sticky;
  top: 64px;
  padding: 8px 0 16px 0;
  z-index: 1;
  box-shadow: ${(props) => props.theme.boxShadow.blackWithBottomShadow};
  background: ${(props) => props.theme.backgroundColor.white};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    top: 56.5px;
    padding: 8px 0 16px 0;
    z-index: 900;
    box-shadow: ${(props) => (props.showMore && props.missionsData?.splicedMissionsData.length ? props.theme.boxShadow.blackWithBottomShadow : 'unset')};
    ${BreadCrumbWrapper} {
      padding: 8px 0 16px 0;
    }
  }
`;
