import styled from 'styled-components';

export const VerifyMissionWrapper = styled.main`
  padding:32px 24px 0px;
  display:flex;
  flex-direction:column;
  font-family:${(props) => props.theme.fontFamily.Roboto};
`;

export const VerifyText = styled.div`
  margin-bottom: 5px;
  font-weight: 400;
  font-size:${(props) => props.theme.fontSize.large};
`;

export const CodeField = styled.div`
  margin-bottom: 92px;
`;

export const VerifyData = styled.div`
  padding:15px 0;
  width:100%;
  line-height:18px;
  font-weight:400;
  font-size:${(props) => props.theme.fontSize.primary};
`;

export const VerifyButton = styled.div`
    color: ${(props) => (!props.disabled ? props.theme.fontColor.white : props.theme.fontColor.silverChalice)};
    background-color: ${(props) => (!props.disabled ? props.theme.backgroundColor.dodgerBlue : props.theme.backgroundColor.disabled)} !important;
    text-transform: none;
    border-radius: 8px;
    height:50px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.theme.fontSize.primary};
`;
