import firebase from 'config/firebase-service';
import { createAccountWithReferralLink } from 'services/Users/user-service';

let verify;
let recaptcha;
/**
 * * [signInWithPhoneNumber] authenticates the user phone number
 * @param {string} phoneNumber
 */
export const signInWithPhoneNumber = async (phoneNumber) => {
  // need to await for recaptcha verifier
  // because, if there was an existing captcha loaded for a failed login, need to wait until the
  // new capctha object is created; otherwise since there is an existing failed captcha
  // the system will try to sign in using the same without waiting to create a new captcha object
  if (recaptcha) {
    recaptcha.clear();
  }
  recaptcha = await new firebase.auth.RecaptchaVerifier('login-button', {
    size: 'invisible',
  });
  const number = phoneNumber;
  try {
    verify = await firebase.auth().signInWithPhoneNumber(number, recaptcha);
  } catch (error) {
    return error;
  }
  return verify;
};

/**
 * *[signInWithCustomToken] used to sign the user with the custom token
 * @param {String} token
 */
export const signInWithCustomToken = async (token) => {
  try {
    const response = await firebase.auth().signInWithCustomToken(token);
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * * [verifyUserOtp] authenticates the otp submitted by the user and updates the user data
 * @param {number} otp
 */
export const verifyUserOtp = async (otp, referralLink) => {
  try {
    const userData = {};
    let referrerData = {};
    const response = await verify.confirm(otp);
    const userId = response.user.uid;
    const { phoneNumber } = response.user;
    userData.phoneNumber = phoneNumber;
    userData.referrerLink = referralLink;
    // To create a new user doc with referral link
    if (response.additionalUserInfo.isNewUser) {
      referrerData = await createAccountWithReferralLink(userId, userData);
    }
    if (referrerData) {
      // To store the referral data in the response
      response.referrerData = referrerData;
    }
    return response;
  } catch (e) {
    return e;
  }
};
