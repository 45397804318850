import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/Button/Button';
import * as Strings from 'resources/strings';
import { MissionCount, ButtonWrapper } from './style';

/**
 *
 * @param {number} dataLength
 * `MissionsFooter` is common footer component for all categories
 * and all missions pages.
 */
const MissionsFooter = (props) => {
  const { dataLength, countLabel, goToDashboard } = props;

  return (
    <ButtonWrapper dataLength={dataLength > 0}>
      {dataLength > 0 && (
        <MissionCount>
          {Strings.viewMissionsText}
          <b>{` ${dataLength}`}</b>
          {` ${countLabel}`}
        </MissionCount>
      )}
      <Button title={Strings.goToDashboard} onClick={() => goToDashboard()} />
    </ButtonWrapper>
  );
};

MissionsFooter.propTypes = {
  dataLength: PropTypes.number,
  countLabel: PropTypes.string,
  goToDashboard: PropTypes.func,
};

MissionsFooter.defaultProps = {
  dataLength: 0,
  countLabel: Strings.missions.toLowerCase(),
  goToDashboard: null,
};

export default MissionsFooter;
