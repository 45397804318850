import React, {
  useEffect, useState, useRef, createRef,
} from 'react';
import PropTypes from 'prop-types';
import * as Strings from 'resources/strings';

import { Divider } from '@material-ui/core';
import TotalSteps from 'components/pages/Missions/components/Steps';
import Spacer from 'components/common/Spacer/Spacer';
import FileInput from 'components/common/FileInput/FileInput';
import moment from 'moment';
import * as constants from 'utils/Analytics/flow-constants';
import { Title } from 'components/common/Accordion/style';
import {
  Stepper,
  StepperWrapper,
  KnowMoreSection,
  ReuploadText,
  ProgressHeader,
  ProgressWrapper,
} from './style';

function ProgressBar({
  userMissions,
  userActivities,
  repetitionCount,
  reUploadFunc,
  activityId,
  getCompletedCount,
  verificationMechanism,
  reminderDate,
  isMissionLoading,
  activityName,
  analyticsEventHandler,
  isLastActivity,
}) {
  // Fetch activiites from MuserMissions
  const { activities } = userMissions;
  const [activitiesProgress, setActivitiesProgress] = useState({});
  const [missionActivities, setMissionActivities] = useState([]);
  const [rejectedCount, setRejectedCount] = useState(0);
  const refs = useRef([]);
  refs.current = activities.map((_element, index) => refs.current[index] ?? createRef());
  // [updateMissionsData] update Missions data
  const updateMissionsData = () => {
    const userActivitiesProgress = { ...userActivities };
    const userMissionActivities = [...activities];
    let completedCount = 0;
    let rejected = 0;
    let unAvailableDataCount = 0;
    const activityLength = userMissionActivities.length;
    unAvailableDataCount = repetitionCount - activityLength;
    for (let i = 0; i < repetitionCount; i += 1) {
      // Added empty status for activity
      if (i < unAvailableDataCount) {
        userMissionActivities.push((unAvailableDataCount + i).toString());
        userActivitiesProgress[(unAvailableDataCount + i).toString()] = {
          id: (unAvailableDataCount + i).toString(),
          verificationStatus: '',
        };
      }
      // To check the completed count of an activity and assign next status
      if (
        userActivitiesProgress[userMissionActivities[i]] && userActivitiesProgress[userMissionActivities[i]].verificationStatus === 'verified'
      ) {
        completedCount += 1;
        if (
          i + 1 !== repetitionCount
          && userActivitiesProgress[userMissionActivities[i + 1]]
          && userActivitiesProgress[userMissionActivities[i + 1]]
            .verificationStatus === ''
        ) {
          userActivitiesProgress[
            userMissionActivities[i + 1]
          ].verificationStatus = 'next';
        }
      }
      // Assigning initial step with next status
      if (
        userActivitiesProgress[userMissionActivities[i]] && userActivitiesProgress[userMissionActivities[i]].verificationStatus === '' && i === 0
      ) {
        userActivitiesProgress[
          userMissionActivities[i]
        ].verificationStatus = 'next';
      }
      if (
        userActivitiesProgress[userMissionActivities[i]] && (userActivitiesProgress[userMissionActivities[i]].verificationStatus === 'rejected'
          || userActivitiesProgress[userMissionActivities[i]].verificationStatus === 'submitted')
      ) {
        rejected += 1;
      }
      // Condition added for showing tooltip
      userActivitiesProgress[userMissionActivities[i]] = {
        ...userActivitiesProgress[userMissionActivities[i]],
        open: false,
      };
    }
    setActivitiesProgress({ ...userActivitiesProgress });
    setRejectedCount(rejected);
    setMissionActivities([...userMissionActivities]);
    getCompletedCount(completedCount, userActivities[activities[0]], activities.length);
  };

  // [updateTooltip] programatically show/hide the tooltip
  const updateTooltip = (id) => {
    const userActivitiesProgress = { ...activitiesProgress };
    const userMissionActivities = [...missionActivities];
    userMissionActivities.forEach((activity) => {
      userActivitiesProgress[activity] = {
        ...userActivitiesProgress[activity],
        open: userActivitiesProgress[activity].open
          ? false
          : userActivitiesProgress[activity].id === id,
      };
    });
    setActivitiesProgress({ ...userActivitiesProgress });
    setMissionActivities([...userMissionActivities]);
  };

  /// [reUploadBill] Programmatically clicking the hidden input file type
  /// when the user clicks on reUpload text
  const reUploadBill = (currentRef) => {
    analyticsEventHandler(
      constants.redoAnalytics,
      constants.navigation,
      constants.missionProgress,
      `${Strings.redo}_${activityName}`,
    );
    currentRef.current.click();
  };

  useEffect(() => {
    // To update the missions Data
    updateMissionsData();
    // Scroll to progress bar
    window.scrollTo(0, document.body.scrollHeight);
  }, [isMissionLoading]);
  return repetitionCount !== 1 && (
    <ProgressWrapper>
      <StepperWrapper>
        <Divider />
        <Spacer height='6px' />
        <ProgressHeader>
          <Title variant='body1'>My progress</Title>
          <KnowMoreSection>{`${activities.length}/${repetitionCount}`}</KnowMoreSection>
        </ProgressHeader>
        <Stepper>
          {missionActivities.map((activity, index) => (
            <div key={activity} id='stepper'>
              <TotalSteps
                step={activitiesProgress[activity]}
                index={index}
                uploadImageProgress={missionActivities}
                onClick={updateTooltip}
                repetitionCount={repetitionCount}
                rejectedCount={rejectedCount}
              />
              <Spacer height='6px' />
              {activitiesProgress[activity].verificationStatus === 'rejected' ? (
                <FileInput
                  onChangeFunc={
                    (event) => reUploadFunc(
                      'redo',
                      event,
                      activityId,
                      index + 1,
                      activitiesProgress[activity].id,
                    )
                  }
                  refs={refs.current[index]}
                >
                  {verificationMechanism === 'photo' && <ReuploadText onClick={() => reUploadBill(refs.current[index])}>{Strings.redo}</ReuploadText>}
                </FileInput>
              )
                : (
                  <Spacer />
                )}
            </div>
          ))}
        </Stepper>
        <KnowMoreSection>
          Click on the progress bar to know more
        </KnowMoreSection>
        {reminderDate?.seconds && !isLastActivity
          && (
            <KnowMoreSection>
              SMS reminder set for
              {' '}
              {moment(reminderDate.seconds * 1000).format('ddd, MMM DD, YYYY')}
            </KnowMoreSection>
          )}
      </StepperWrapper>
    </ProgressWrapper>
  );
}
export default ProgressBar;

ProgressBar.propTypes = {
  userMissions: PropTypes.objectOf(PropTypes.any),
  userActivities: PropTypes.objectOf(PropTypes.any),
  repetitionCount: PropTypes.number,
  reUploadFunc: PropTypes.func,
  activityId: PropTypes.string,
  getCompletedCount: PropTypes.func,
  analyticsEventHandler: PropTypes.func,
  verificationMechanism: PropTypes.string,
  reminderDate: PropTypes.string,
  isMissionLoading: PropTypes.bool,
  activityName: PropTypes.string,
  isLastActivity: PropTypes.bool,
};
