import React, {
  createContext, useContext, useEffect, useState,
} from 'react';

import PropTypes from 'prop-types';

import {
  fetchUserData,
  updateCompletedMissionStatus,
  deleteUserCompletedMissionReward,
} from 'services/Users/user-service';
import { authentication } from '../config/firebase-service';
import {
  signInWithPhoneNumber,
  verifyUserOtp,
} from '../services/Login/login-service';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

/**
 * AuthProvider to wrap the App Component.
 * @param {*} children
 * @returns AuthProvider
 */
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [authLoader, setAuthLoader] = useState(false);
  const [enteredPath, setEnteredPath] = useState('/');
  /**
   * * [signInWithPhoneNumberOTP] verifies the phone number of the current user
   * @param {string} phoneNumber
   * @param {(event)=>void} setShowOtp
   */
  const signInWithPhoneNumberOTP = async (phoneNumber) => {
    const res = await signInWithPhoneNumber(phoneNumber);
    return res;
    // [response] will contain the user instance
    // its already getting updated in auth-provider, so we might not require this data over here
  };

  /**
   * * [verifyOtp] verifies the otp given by user and routes to dashboard accordingly
   * @param {string} phoneNumber
   * @param {string} userName
   * @param {number} otp
   */
  const verifyOtp = async (otp, referralLinkUrl) => {
    const res = await verifyUserOtp(otp, referralLinkUrl);
    setAuthLoader(true);
    return res;
  };

  const logout = async () => {
    await authentication.signOut();
  };

  /// [fetchUserStats] to fetch the userStats data of the current user
  const fetchUserStats = async () => {
    if (currentUser) {
      const userData = await fetchUserData(currentUser.id);
      if (userData) {
        setCurrentUser(userData);
      }
      setIsLoading(false);
    }
  };

  // `updateMissionRewardStatus` update user completed mission refs status
  const updateMissionRewardStatus = async (userId, completedTaskRefs, removeMissionData) => {
    await updateCompletedMissionStatus(userId, completedTaskRefs, removeMissionData);
  };

  // `deleteCompletedMissionReward` delete user completed mission refs
  const deleteCompletedMissionReward = async (userId) => {
    await deleteUserCompletedMissionReward(userId);
  };

  const setAuthenticationComplete = () => {
    setAuthLoader(false);
  };
  const value = {
    currentUser,
    enteredPath,
    isLoading,
    authLoader,
    setAuthLoader,
    signInWithPhoneNumberOTP,
    verifyOtp,
    setEnteredPath,
    logout,
    fetchUserStats,
    setAuthenticationComplete,
    updateMissionRewardStatus,
    deleteCompletedMissionReward,
  };

  useEffect(() => {
    const unsubscribe = authentication.onAuthStateChanged(async (user) => {
      const userData = user && user.uid && { id: user.uid, phoneNumber: user.phoneNumber };
      setCurrentUser(userData);
      setIsLoading(false);
    });
    return unsubscribe;
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
