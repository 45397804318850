import React from 'react';
import PropTypes from 'prop-types';

import CircleBlue from 'assets/images/circle_blue.png';
import CircleGrey from 'assets/images/circle_grey.png';
import TrophyIcon from 'assets/images/trophy_icon.png';
import TrophyActiveIcon from 'assets/images/trophy-active-icon.svg';
import CompletedIcon from 'assets/images/completed-mission-icon.svg';
import CircleGreen from 'assets/images/circle_green.svg';

import {
  Container,
  ImageWrapper,
  SlideNumber,
  LastMissionSlide,
  Image,
  IconWrapper,
  ProgressWrapper,
} from './style';

// LinkedMissionProgress component renders progress of the linked mission card
const LinkedMissionProgress = (props) => {
  const {
    completedIndex, ongoingIndex, linkedMissions,
  } = props;

  // `getStatusIcon` to get the status icon based on the status of the mission
  const getStatusIcon = (slideIndex) => {
    let statusImg = CircleGrey;
    if (completedIndex.includes(slideIndex)) {
      statusImg = CircleGreen;
    } else if (ongoingIndex.includes(slideIndex)
      || slideIndex === 0 || completedIndex.includes(slideIndex - 1)) {
      statusImg = CircleBlue;
    }
    return statusImg;
  };

  return (
    <ProgressWrapper>
      {linkedMissions.map((mission, slideIndex) => (
        <Container
          isLast={linkedMissions.length - 1 === slideIndex}
          isCompleted={completedIndex.includes(slideIndex)}
          key={mission}
        >
          {slideIndex < linkedMissions.length - 1 ? (
            <>
              <ImageWrapper>
                <Image
                  src={getStatusIcon(slideIndex)}
                />
              </ImageWrapper>
              {completedIndex.includes(slideIndex) ? (
                <IconWrapper>
                  <Image
                    src={CompletedIcon}
                  />
                </IconWrapper>
              ) : (
                <SlideNumber isCurrent={ongoingIndex.includes(slideIndex)
                  || slideIndex === 0 || completedIndex.includes(slideIndex - 1)}
                >
                  {slideIndex + 1}
                </SlideNumber>
              )}
            </>
          ) : (
            <>
              <ImageWrapper>
                <Image
                  src={
                    completedIndex.includes(slideIndex - 1)
                      ? CircleBlue
                      : CircleGrey
                  }
                />
              </ImageWrapper>
              <LastMissionSlide>
                <Image
                  src={
                    completedIndex.includes(slideIndex - 1)
                      ? TrophyActiveIcon
                      : TrophyIcon
                  }
                />
              </LastMissionSlide>
            </>
          )}
        </Container>
      ))}
    </ProgressWrapper>
  );
};

LinkedMissionProgress.propTypes = {
  completedIndex: PropTypes.arrayOf(PropTypes.any),
  ongoingIndex: PropTypes.arrayOf(PropTypes.any),
  linkedMissions: PropTypes.arrayOf(PropTypes.any),
};

export default LinkedMissionProgress;
