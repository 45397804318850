import styled, { keyframes } from 'styled-components';

export const LoaderAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 84px);
`;

export const CircularLoader = styled.div`
  && {
    background: ${(props) => props.theme.backgroundColor.gradientDodgerBlue};
    border-radius: 50%;
    -webkit-mask: radial-gradient(
      farthest-side,
      ${(props) => props.theme.border.black} calc(100% - 40px),
      ${(props) => props.theme.backgroundColor.white} 0
    );
    width: 250px;
    height: 250px;
    -webkit-animation: spin 2s linear infinite;
    animation-name: ${LoaderAnimation};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 180px;
    height: 180px;
    -webkit-mask: radial-gradient(
      farthest-side,
      ${(props) => props.theme.border.black} calc(100% - 25px),
      ${(props) => props.theme.backgroundColor.white} 0
    );
  }
`;

export const ProcessingText = styled.div`
  font-size: ${(props) => props.theme.fontSize.smMedium};
  line-height: 25px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
  }
`;

export const ProcessingTextWrapper = styled.div`
  margin: 70px 48px;
  text-align: center;
  max-width: 270px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 64px 24px;
    max-width: 237px;
  }
`;
