import styled from 'styled-components';

export const BreadCrumbWrapper = styled.ul`
    padding: 5px 0;
    margin: 8px 0px 8px 26px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    font-size: ${(props) => props.theme.fontSize.primary};
    list-style-type: none;
    && :first-child{
        div {
            padding-left : 0px;
        }
    }
    margin: 8px 0px 8px 80px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      margin: 8px 0px 8px 26px;
    }
`;

export const BreadCrumb = styled.li`
    color: ${(props) => props.theme.fontColor.carbonGrey};
    text-transform: none;
    border: none;
    font-weight: ${(props) => props.theme.fontWeight.medium};
    font-size: ${(props) => props.theme.fontSize.primary};
    &
    {
    :after{
    content: '>';
    display: ${(props) => (props.isLast ? 'none' : 'inline')};
    padding: 0 5px;
    }
  }
`;

export const Wrapper = styled.div`
  display: inline;
`;
