import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useParams,
  Route,
  useLocation,
} from 'react-router-dom';

import { useAuth } from 'provider/auth-provider';
import { useMissions } from 'provider/missions-provider';
import { useSnackbar } from 'provider/snackbar-provider';
import MissionsLandingCard from 'components/pages/Missions/components/MissionsLanding';
import MissionProgressLanding from 'components/pages/Missions/components/MissionProgressLanding';
import LinkedMissionsContainer from 'components/containers/Missions/LinkedMissionsContainer';
import Loader from 'components/common/Loader/Loader';
import { useAllMissions } from 'provider/dashboard-provider';
import { checkPath } from 'utils/Missions/utils';
import { productMission, productPurchase } from 'utils/global-constants';

import {
  createUserMission,
  checkUserMission,
  addUserReward,
  getPromoCode,
  scheduleMissionReminder,
} from 'services/Missions/missions-service';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import checkLinkedMission from 'helpers/LinkedMission/flow-helpers';
import * as Strings from 'resources/strings';
import * as constants from 'utils/Analytics/flow-constants';
import Button from 'components/common/Button/Button';
import { SecondaryButtonWrapper } from 'components/pages/Missions/components/MissionsLanding/style';
import FindAllMissionsContainer from 'components/containers/FindAllMissions';
import { clevertapEventTracking } from 'utils/Clevertap/Clevertap';
import { startMission, buyNow } from 'utils/Clevertap/flow-constants';

/**
 * Missions container
 * @returns JSX
 */

function MissionsContainer() {
  const location = useLocation();
  const [isMissionCompleted] = useState(location.state?.isMissionCompleted || false);
  const history = useHistory();
  // to get the Missionid from the url
  const params = useParams();
  const { ongoingMissions } = useAllMissions();
  const { currentUser } = useAuth();
  const { setIsShow, setDisplayText } = useSnackbar();
  const {
    fetchMissionsData, missionsData, isLoading, setIsLoading,
    fetchLinkedMissionsData,
    linkedMissionIds,
    linkedMissionLoader,
    allMissions,
  } = useMissions();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isButtonDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState();
  const { initiateTracking } = useAnalytics();

  // `verifyUserMission` is used to check whether the user is already started a mission
  // if the user is already started then redirect to Mission progress page
  // if the user completed the mission then redirect to next available related mission
  const verifyUserMission = async () => {
    const existingUserMissionId = await checkUserMission(currentUser.id, params.missionId);
    // if the mission is in current status then redirecting to that mission progress page
    if (existingUserMissionId.data.status === 'current') {
      setIsLoading(false);
      history.replace({
        pathname: `/missions/${params.missionId}/progress`,
        search: `?instance-id=${existingUserMissionId.data.userMissionId}`,
      });
    } else if (existingUserMissionId.data.status === 'completed') {
      // If the mission is completed and local state exists show
      // the missions page in completed state
      if (isMissionCompleted) {
        setIsLoading(false);
      } else {
        // if the mission is in completed status then check for next available related mission
        checkLinkedMission({
          relatedMissions: linkedMissionIds.filter(
            (e) => e !== parseInt(params.missionId, 10),
          ),
          userId: currentUser.id,
          useHistory: history,
          setIsShowSnackbar: setIsShow,
          setSnackbarText: setDisplayText,
          setPageLoading: setIsLoading,
        });
      }
    } else if (existingUserMissionId.data.status === 'abandoned' || existingUserMissionId.data.status === 'newMission') {
      // if the user is trying to access any linked mission then
      // check for the sequence of the linked mission
      // and redirect to the respective mission
      if (linkedMissionIds.length > 0 && linkedMissionIds[0] !== parseInt(params.missionId, 10)) {
        checkLinkedMission({
          relatedMissions: linkedMissionIds,
          userId: currentUser.id,
          useHistory: history,
          setIsShowSnackbar: setIsShow,
          setSnackbarText: setDisplayText,
          setPageLoading: setIsLoading,
        });
      } else {
        // if the mission is in abandoned status then redirect the user to start mission again
        setIsLoading(false);
        history.replace({
          pathname: `/missions/${params.missionId}`,
        });
      }
    } else {
      setIsLoading(false);
    }
  };

  /**
* * [loginAnalyticsEvents] verifies the phone number of the user
* @param eventName : name of the event
* @param eventCategory : category which the event belongs to
* @param eventAction : current flow which the event belongs to
* @param eventLabel : the data string on the call to action
*/
  const missionsAnalyticsEventHandler = (eventName, eventCategory, eventAction, eventLabel) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: `${eventCategory}`,
      eventAction: `${eventAction}`,
      eventLabel: `${eventLabel}`,
    });
  };

  // `startMissions` function that createUserMission when the user start the mission
  const startMissions = async (missionData, getButtonText = Strings.startMission) => {
    // if user already has 5 ongoing missions
    // then we should not allow him to start a new one
    if (currentUser && Object.keys(ongoingMissions).length > 4) {
      setIsShow(true);
      setDisplayText('You have reached maximum number of ongoing missions. Complete them to start a new one');
    } else {
      let promoCode;
      // fetches the promo code API only if the type of the reward is promoCode
      if (missionData.missionReward.type === 'promoCode' && currentUser) {
        setIsButtonLoading(true);
        promoCode = await getPromoCode(missionData.missionReward.id);
      }
      // clevertap event for start mission
      clevertapEventTracking(getButtonText === Strings.startMission ? startMission : buyNow, {
        'mission-name': missionData.title,
        'mission-category': missionData.missionsCategory?.name,
        'mission-reward': missionData.missionReward?.name,
      });
      missionsAnalyticsEventHandler('analytics_start mission', 'navigation', 'missions', `${getButtonText}_${missionData.title}`);
      const currentMission = {
        missionId: missionsData.id,
        verification: missionsData.activity.verificationMechanism,
        repetitions: missionsData.repetitionCount,
        relatedMissions: linkedMissionIds,
        missionRewardType: missionsData.missionReward.type,
        missionRewardId: missionsData.missionReward.id,
        promocode: promoCode ? promoCode.data.code : null,
        affiliateLink: missionData.affiliate?.affiliateLink ?? '',
        productCheckoutUrl: missionsData.productCheckoutUrl,
      };
      if (currentUser) {
        const existingUserMissionId = await checkUserMission(currentUser.id, missionData.id);
        // if the status of the mission is newMission or abandoned
        // then create user mission else redirect to the progress page
        if (existingUserMissionId.data.status === 'newMission' || existingUserMissionId.data.status === 'abandoned') {
          // if the missions id having productCheckoutUrl then redirect to that url
          if (missionsData.activity.verificationMechanism === productPurchase
            && missionsData.productCheckoutUrl != null) {
            setIsDisabled(true);
            setButtonText(Strings.redirectingCTALabel);
            // store the product mission ids in the session storage
            sessionStorage.setItem(productMission,
              JSON.stringify({
                productMissionId: missionsData.id,
                productLinkedMissionIds: linkedMissionIds,
              }));
            window.open(missionsData.productCheckoutUrl, '_self');
          } else {
            setIsButtonLoading(true);
            const response = await createUserMission(currentUser.id, currentMission);
            if (response.status === 200) {
              // if the verification Mechanism is affiliateLink then redirect to the affiliateLink
              if (missionData.activity.verificationMechanism === 'affiliateLink' && missionData.affiliate?.affiliateLink && missionData.affiliate?.affiliateLink !== '') {
                window.open(missionData.affiliate?.affiliateLink);
              }
              history.replace({
                pathname: `/missions/${missionData.id}/progress`,
                search: `?instance-id=${response.data}`,
              });
            } else {
              // if api fails redirect to mission Landing
              history.push(`/missions/${missionData.id}`);
              setIsShow(true);
              setDisplayText(Strings.errorMessage);
            }
          }
        } else {
          history.replace({
            pathname: `/missions/${missionData.id}/progress`,
            search: `?instance-id=${existingUserMissionId.data.userMissionId}`,
          });
        }
        setIsButtonLoading(false);
      } else {
        localStorage.setItem('currentMission', JSON.stringify(currentMission));
        history.push('/login');
      }
    }
  };

  // `claimReward` function to add userReward in collection when user completed the mission
  const claimReward = async (mUserMissionId) => {
    missionsAnalyticsEventHandler(constants.viewRewardAnalytics, constants.navigation, constants.missionProgress, `${Strings.viewReward}_${missionsData.title}`);
    setIsButtonLoading(true);
    const response = await addUserReward(currentUser.id, mUserMissionId);
    if (response != null) {
      history.push({
        pathname: `/missions/${params.missionId}/mission-reward`,
        search: `?reward-id=${response.data.missionRewardId}`,
        state: { missionData: missionsData, referralRewardId: response.data.referralRewardId },
      });
    }
    setIsButtonLoading(false);
  };

  // 'routeToDashboard ` navigates to dashboard
  const routeToDashboard = () => {
    // analytics events for route to dashboard
    missionsAnalyticsEventHandler(
      constants.backToDashboardAnalytics,
      constants.navigation,
      checkPath('/progress') ? constants.missionProgress : constants.missions,
      Strings.goToDashboard.toLowerCase(),
    );
    history.push('/');
  };

  const scheduleMissionReminderHandler = async (userMissionId, scheduledDate) => {
    const res = await scheduleMissionReminder(
      currentUser.id,
      currentUser.phoneNumber,
      userMissionId,
      scheduledDate,
      params.missionId,
    );
    if (res.status === 200) {
      setIsShow(true);
      setDisplayText('Reminder saved');
    }
    fetchMissionsData(params.missionId);
  };

  useEffect(() => {
    // fetch mission data and linked mission once after fetching all the missions from strapi
    if (Object.keys(allMissions).length > 0) {
      // here we are getting the mission id from the path
      // updating the provider with the particular mission data
      fetchMissionsData(params.missionId);
      // To fetch the linked mission based on mission id
      fetchLinkedMissionsData([params.missionId]);
    }
  }, [Object.keys(allMissions).length && params.missionId]);

  useEffect(() => {
    // To check if the mission is already done -> Take to next mission
    if (currentUser && Object.keys(missionsData).length) {
      setIsLoading(true);
      verifyUserMission();
    }
  }, [linkedMissionIds && missionsData && Object.keys(missionsData).length]);
  return isLoading || linkedMissionLoader ? (
    <Loader />
  ) : (
    <>
      <MissionsLandingCard
        isMissionComplete={isMissionCompleted}
        missionsData={missionsData}
        startMissions={startMissions}
        isButtonLoading={isButtonLoading}
        analyticsEventHandler={missionsAnalyticsEventHandler}
        isDisabled={isButtonDisabled}
        buttonText={buttonText}
      />
      {!isMissionCompleted && (
        <>
          <Route path='/missions/:missionId/progress' exact>
            <MissionProgressLanding
              missionsData={missionsData}
              claimReward={claimReward}
              isButtonLoading={isButtonLoading}
              scheduleSMSReminder={scheduleMissionReminderHandler}
              analyticsEventHandler={missionsAnalyticsEventHandler}
              startMissions={startMissions}
            />
          </Route>
          <LinkedMissionsContainer
            missionDataId={missionsData?.id}
            startMissions={startMissions}
          />
          <FindAllMissionsContainer />
        </>
      )}
      {currentUser && (
        <SecondaryButtonWrapper>
          <Button
            title={Strings.goToDashboard}
            onClick={() => routeToDashboard()}
          />
        </SecondaryButtonWrapper>
      )}
    </>
  );
}

export default MissionsContainer;
