import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useAllMissions } from 'provider/dashboard-provider';
import Loader from 'components/common/Loader/Loader';
import ViewMissions from 'components/common/AllMissionsLayout/ViewMissions';
import * as constants from 'utils/Analytics/flow-constants';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as Strings from 'resources/strings';
import { getCardsLength } from 'utils/Missions/utils';
import themes from 'styles/theme';
import ViewMissionCard from 'components/pages/Missions/components/ViewMissionCard/index';
import getBlogPostShareLink from 'utils/BlogPosts/utils';
import { blogEmptyStateText } from 'resources/strings';
import SadSmiley from 'assets/images/sad_smiley.svg';

/// `MissionBlogsContainer` is the container for displaying all mission blogs cards
const MissionBlogsContainer = () => {
  // missionData contains all the data mission blogs
  // splicedMissionData contains data before slicing mission blogs data when page loads
  // showMore controls hide and show for `Show More` CTA
  const history = useHistory();
  const { initiateTracking } = useAnalytics();
  const {
    missionBlogs,
    isAllBlogsLoading,
  } = useAllMissions();

  // cardsCount stores the number of cards to be displayed
  // on page load.
  const cardsCount = getCardsLength(window.innerHeight);
  const [missionsData, setMissionsData] = useState({
    missionData: [],
    splicedMissionData: [],
    showMore: false,
  });

  useEffect(() => {
    setMissionsData({
      missionData: missionBlogs.blogs,
      splicedMissionData:
        missionBlogs.blogs?.length > cardsCount
          && window.innerWidth <= parseInt(themes.breakpoints.mobileLandscape, 10)
          ? missionBlogs.blogs?.slice(0, cardsCount)
          : missionBlogs.blogs,
      showMore: false,
    });
  }, [missionBlogs.blogs && missionBlogs.blogs.length]);

  // 'analyticsEventHandler` analytics event handler for show more
  const analyticsEventHandler = ({ eventName, eventCategory, eventLabel }) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: `${eventCategory}`,
      eventAction: constants.allBlogsEvent,
      eventLabel: `${eventLabel}`,
    });
  };

  /// `showMoreHandler` is use to control 'Show More' CTA
  const showMoreHandler = () => {
    analyticsEventHandler({
      eventName: constants.showMoreAnalytics,
      eventCategory: constants.visitorAction,
      eventLabel: Strings.showMore,
    });
    setMissionsData({
      missionData: [...missionBlogs?.blogs],
      splicedMissionData: missionBlogs?.blogs,
      showMore: true,
    });
  };

  /// `shareBlogs` share the blog post
  const shareBlogsEvent = (blogName) => {
    analyticsEventHandler({
      eventName: `${constants.analytics}_${constants.share}`,
      eventCategory: constants.visitorAction,
      eventLabel: `${constants.share}_${blogName.substring(1).replaceAll('-', ' ')}`,
    });
  };

  /// `redirectToBlogsLanding` used to redirect to particular blog
  const redirectToBlogsLanding = (blogId) => {
    analyticsEventHandler({
      eventName: `${constants.analytics}_${constants.blogCard}`,
      eventCategory: constants.navigation,
      eventLabel: `${constants.blogCard}_${blogId.substring(1)}`,
    });
    history.push(`/blogs${blogId}`);
  };

  return isAllBlogsLoading ? (
    <Loader />
  ) : (
    <ViewMissions
      missionsData={missionsData}
      history={history}
      showMoreHandler={() => showMoreHandler()}
      cardsCount={cardsCount}
      showMore={missionsData?.showMore}
      analyticsEventHandler={analyticsEventHandler}
      missionTitle={Strings.missionBlogsTitle}
      countLabel={Strings.blogPostCountLabel}
      emptyStateImage={SadSmiley}
      emptyStateText={blogEmptyStateText}
      analyticsEventActionName={constants.allBlogsEvent}
      children={missionsData?.splicedMissionData?.map((blog) => (
        <div key={blog?.id}>
          <ViewMissionCard
            categoryImage={blog.imageUrl}
            categoryName={moment(blog?.publishedAt).format('MMM DD yyy')}
            missionTitle={blog?.title}
            offer={blog?.authorName}
            altText='blog-image'
            missionId={blog.id}
            offerImage={blog.authorImageUrl}
            share={getBlogPostShareLink(blog.id)}
            isDangerouslyHTML
            redirectToBlogsLanding={() => redirectToBlogsLanding(blog.id, blog?.title)}
            shareAnalyticsEvent={() => shareBlogsEvent(blog?.id)}
          />
        </div>
      ))}
    />
  );
};

export default MissionBlogsContainer;
