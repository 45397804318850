import React from 'react';

import { DashboardProvider } from 'provider/dashboard-provider';
import { AboutMissionsProvider } from 'provider/about-missions-provider';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import PreLoginDashboardLanding from './components/PreLoginDashboardLanding';

/**
 * * [PreLoginDashboard] consists of all missions data
 * @returns <PreLoginDashboardComponent>
 */
const PreLoginDashboard = () => (
  <BaseLayout showPoster={false} hideGutters footer>
    <DashboardProvider>
      <AboutMissionsProvider>
        <PreLoginDashboardLanding />
      </AboutMissionsProvider>
    </DashboardProvider>
  </BaseLayout>
);

export default PreLoginDashboard;
