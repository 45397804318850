import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import * as Strings from 'resources/strings';
import LineSeparator from 'components/common/LineSeparator';
import * as analyticsConstants from 'utils/Analytics/flow-constants';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as referralConstants from 'utils/Referrals/flow_constants';
import * as constants from 'utils/global-constants';
import themes from 'styles/theme';

import {
  RewardCollectedWrapper,
  ImageDescriptionWrapper,
  OfferTitle,
  OfferDescription,
  ImageWrapper,
  Image,
  CTAContainer,
  ViewCTA,
} from './style';

/**
 *
 * @param {string} offerTitle
 * @param {string} offerDescription
 * @param {string} image
 * @param {string} altText
 * @param {boolean} bonusReward
 * RewardCollectedCard will return the reward collected card UI
 */
const RewardCollectedCard = (props) => {
  const history = useHistory();
  const { initiateTracking } = useAnalytics();
  const {
    offerTitle,
    offerDescription,
    image,
    altText,
    rewardType,
    openDialog,
    setRewardsData,
    toShowDialog,
    bigReward,
    missionId,
    userRewardId,
    linkedMissionData,
    missionName,
  } = props;

  // `openDialogHandler` is triggered when we click on view all rewards CTA
  const openDialogHandler = () => {
    initiateTracking({
      eventName: analyticsConstants.viewAllRewardsAnalytics,
      eventCategory: analyticsConstants.visitorAction,
      eventAction: analyticsConstants.rewardsCollected,
      eventLabel: `${missionName}_${analyticsConstants.viewAllRewards}`,
    });
    setRewardsData(linkedMissionData);
    openDialog();
  };

  // modifying CTA based on viewport width and bigReward
  // status in mobile and desktop
  const viewCTAHandler = (isBigReward, missionRewardType) => {
    if (
      (!isBigReward
      || window.innerWidth > parseInt(themes.breakpoints.mobileLandscape, 10))
      && missionRewardType !== referralConstants.referralReward
    ) {
      return true;
    }
    return false;
  };

  // `analyticseventHandler` function to handle analytic events
  const analyticsEventHandler = (eventName, eventLabel) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: analyticsConstants.navigation,
      eventAction: analyticsConstants.rewardsCollected,
      eventLabel: `${eventLabel}_${missionName}`,
    });
  };

  // `routeToMissions` routes to the particular mission
  const routeToMissions = () => {
    // analytics for view mission cta
    analyticsEventHandler(analyticsConstants.viewMissionAnalytics, Strings.viewMissionlabel);
    history.push({
      pathname: `/missions/${missionId}`,
      state: { isMissionCompleted: true },
    });
  };

  // `routeToClaimRewardPage` routes to the claim reward page
  const routeToClaimRewardPage = () => {
    // analytics for view reward cta
    analyticsEventHandler(analyticsConstants.viewRewardAnalytics, Strings.viewReward);
    history.push({
      pathname: `/missions/${missionId}/mission-reward`,
      search: `?reward-id=${userRewardId}`,
    });
  };

  return (
    <RewardCollectedWrapper>
      <ImageDescriptionWrapper>
        <div>
          <OfferTitle>{offerTitle}</OfferTitle>
          <OfferDescription>{offerDescription}</OfferDescription>
        </div>
        <ImageWrapper>
          <Image src={image} altText={altText} />
        </ImageWrapper>
      </ImageDescriptionWrapper>
      {viewCTAHandler(bigReward, rewardType)
      && (
        <div>
          <LineSeparator />
          {(rewardType === constants.linkedMissionsReward
          && window.innerWidth
          > parseInt(themes.breakpoints.mobileLandscape, 10)) ? (
            <CTAContainer>
              {(!toShowDialog && bigReward) ? (
                <ViewCTA onClick={() => openDialogHandler()}>
                  {Strings.viewAllRewards}
                </ViewCTA>
              ) : (
                <>
                  <ViewCTA onClick={() => routeToClaimRewardPage()}>{Strings.viewReward}</ViewCTA>
                  <ViewCTA onClick={() => routeToMissions()}>{Strings.viewMissionlabel}</ViewCTA>
                </>
              )}
            </CTAContainer>
            ) : (
              <CTAContainer>
                <ViewCTA onClick={() => routeToClaimRewardPage()}>{Strings.viewReward}</ViewCTA>
                <ViewCTA onClick={() => routeToMissions()}>{Strings.viewMissionlabel}</ViewCTA>
              </CTAContainer>
            )}
        </div>
      )}
    </RewardCollectedWrapper>
  );
};

RewardCollectedCard.propTypes = {
  offerTitle: PropTypes.string,
  offerDescription: PropTypes.string,
  image: PropTypes.string,
  altText: PropTypes.string,
  rewardType: PropTypes.string,
  openDialog: PropTypes.func,
  setRewardsData: PropTypes.func,
  toShowDialog: PropTypes.bool,
  bigReward: PropTypes.bool,
  missionId: PropTypes.number,
  userRewardId: PropTypes.string,
  linkedMissionData: PropTypes.shape({
    missionName: PropTypes.string,
    earnedDate: PropTypes.shape(),
    id: PropTypes.string,
    missionId: PropTypes.string,
    primary: PropTypes.shape(),
    userRewardId: PropTypes.string,
  }),
  missionName: PropTypes.string,
};

RewardCollectedCard.defaultProps = {
  offerTitle: '',
  offerDescription: '',
  image: '',
  altText: '',
  rewardType: '',
  openDialog: () => { },
  setRewardsData: () => { },
  toShowDialog: true,
  bigReward: true,
  missionId: 0,
  userRewardId: '',
  linkedMissionData: {},
  missionName: '',
};

export default RewardCollectedCard;
