import styled from 'styled-components';

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSize.large};
  line-height: 24px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  margin: 32px 0 16px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fontSize.secondary};
  }
`;

export const YoutubeLinkWrapper = styled.div`
  iframe {
    height: 345px;
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    iframe {
      height: 180px;
    }
  }
`;
