import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { useAuth } from 'provider/auth-provider';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as constants from 'utils/Analytics/flow-constants';
import Breadcrumb from 'components/common/BreadCrumb';
import * as Strings from 'resources/strings';
import Markdown from 'components/common/Markdown';
import Button from 'components/common/Button/Button';
import ReferralSteps from 'components/pages/ReferralLink/components/ReferralStepsToComplete';
import InviteLink from 'components/pages/ReferralLink/components/InviteLink';
import Share from 'components/common/Share';
import themes from 'styles/theme';
import { clevertapEventTracking } from 'utils/Clevertap/Clevertap';
import { shareReferralLink } from 'utils/Clevertap/flow-constants';

import {
  ReferralTitle,
  Wrapper,
  ImageWrapper,
  Image,
  GetRewardText,
  Description,
  CopyLinkText,
  LinkWrapper,
  LinkText,
  ButtonWrapper,
} from './style';

/// [ReferralLinkLanding] landing page for referral link
const ReferralLinkLanding = ({ referralInfo, userReferralDoc }) => {
  const history = useHistory();
  const { currentUser } = useAuth();
  const { initiateTracking } = useAnalytics();

  // 'analyticsEventHandler` analytics event handler for breadcumbs
  const analyticsEventHandler = (eventLabel) => {
    initiateTracking({
      eventName: constants.breadCrumbAnalytics,
      eventCategory: constants.navigation,
      eventAction: `${constants.referAFriend}_${constants.breadcrumb}`,
      eventLabel: `${eventLabel}`,
    });
  };

  /// `goToDashboard` is used to redirect to the dashboard screen
  const goToDashboard = () => {
    // analytics for go to dashboard cta
    initiateTracking({
      eventName: constants.backToDashboardAnalytics,
      eventCategory: constants.navigation,
      eventAction: constants.referAFriend,
      eventLabel: Strings.goToDashboard,
    });
    history.push('/');
  };
  return (
    <>
      <Breadcrumb analyticsEvent={analyticsEventHandler} />
      <ReferralTitle>{referralInfo?.name}</ReferralTitle>
      <Wrapper>
        <ImageWrapper>
          <Image src={referralInfo.image?.url} />
        </ImageWrapper>
        <GetRewardText>{referralInfo?.shortDescription}</GetRewardText>
        <Description>
          <Markdown content={referralInfo?.longDescription} />
        </Description>
      </Wrapper>
      <ReferralSteps
        title={referralInfo?.stepsTitle}
        stepsToAchieve={referralInfo?.steps}
      />
      <CopyLinkText>
        <Markdown content={referralInfo?.linkDescription} />
      </CopyLinkText>
      <LinkWrapper>
        <LinkText>{currentUser?.referral?.link}</LinkText>
        <Share
          color={themes.backgroundColor.dodgerBlue}
          url={currentUser?.referral?.link}
          title={Strings.shareReferralTitle}
          text={Strings.shareReferralText}
          analyticsEvent={() => {
            clevertapEventTracking(shareReferralLink);
            initiateTracking({
              eventName: constants.copyReferralCodeAnalytics,
              eventCategory: constants.visitorAction,
              eventAction: constants.referAFriend,
              eventLabel: constants.copyReferralCode,
            });
          }}
        />
      </LinkWrapper>
      <InviteLink
        totalCount={referralInfo?.maxUser}
        completedCount={userReferralDoc?.completed}
      />
      <ButtonWrapper>
        <Button title={Strings.goToDashboard} onClick={() => goToDashboard()} />
      </ButtonWrapper>
    </>
  );
};

ReferralLinkLanding.propTypes = {
  referralInfo: PropTypes.shape({
    name: '',
    image: PropTypes.shape({
      url: '',
    }),
    shortDescription: PropTypes.string,
    longDescription: PropTypes.string,
    stepsTitle: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.string),
    linkDescription: PropTypes.string,
    maxUser: PropTypes.number,
  }),
  userReferralDoc: PropTypes.shape({
    total: PropTypes.number,
    completed: PropTypes.number,
  }),
};

ReferralLinkLanding.defaultProps = {
  referralInfo: {
    name: '',
    image: {
      url: '',
    },
    shortDescription: '',
    longDescription: '',
    stepsTitle: '',
    steps: [],
    maxUser: 0,
  },
  userReferralDoc: {
    total: 0,
    completed: 0,
  },
};

export default ReferralLinkLanding;
