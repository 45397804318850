import React from 'react';
import PropTypes from 'prop-types';

import { getDate } from 'utils/Missions/utils';
import RewardCollectedCard from 'components/common/RewardCollectedCard';
import * as constants from 'utils/Referrals/flow_constants';
import * as Strings from 'resources/strings';

import {
  RewardCardWrapper,
  RewardHeadingWrapper,
  MissionTitle,
  EarnedDate,
} from './style';

/**
 *
 * @param {string} missionName
 * @param {object} earnedDate
 * @param {string} rewardName
 * @param {string} rewardImage
 * @param {boolean} isBigReward
 * @param {string} missionId
 * @param {string} rewardId
 * @param {string} shortDesc
 * MissionRewardSection returns mission reward section with reward card and mission details
 */

const MissionRewardSection = ({
  missionName,
  earnedDate,
  rewardName,
  rewardImage,
  shortDesc,
  isBigReward,
  missionId,
  rewardId,
  rewardType,
  toShowDialog,
  openDialog,
  linkedMissionData,
  setRewardsData,
}) => (
  <RewardCardWrapper>
    <RewardHeadingWrapper>
      <MissionTitle isBigReward={isBigReward}>
        {rewardType === constants.referralReward
          ? Strings.rewardReferralText
          : missionName}
      </MissionTitle>
      <EarnedDate>{getDate(earnedDate)}</EarnedDate>
    </RewardHeadingWrapper>
    <RewardCollectedCard
      offerTitle={rewardName}
      offerDescription={shortDesc}
      image={rewardImage}
      altText='reward_logo'
      bigReward={isBigReward}
      missionId={missionId}
      userRewardId={rewardId}
      rewardType={rewardType}
      toShowDialog={toShowDialog}
      openDialog={openDialog}
      linkedMissionData={linkedMissionData}
      setRewardsData={setRewardsData}
      missionName={missionName}
    />
  </RewardCardWrapper>
);

MissionRewardSection.propTypes = {
  missionName: PropTypes.string,
  earnedDate: PropTypes.shape(),
  rewardName: PropTypes.string,
  rewardImage: PropTypes.string,
  isBigReward: PropTypes.bool,
  missionId: PropTypes.number,
  rewardId: PropTypes.string,
  shortDesc: PropTypes.string,
  rewardType: PropTypes.string,
  toShowDialog: PropTypes.bool,
  openDialog: PropTypes.func,
  linkedMissionData: PropTypes.arrayOf(PropTypes.any),
  setRewardsData: PropTypes.func,
};

MissionRewardSection.defaultProps = {
  missionName: '',
  earnedDate: {},
  rewardName: '',
  rewardImage: '',
  isBigReward: false,
  missionId: '',
  rewardId: '',
  shortDesc: '',
  rewardType: '',
  toShowDialog: false,
  openDialog: () => {},
  linkedMissionData: [],
  setRewardsData: () => {},
};

export default MissionRewardSection;
