import { withStyles } from '@material-ui/core/styles';

import MaterialTooltip from '@material-ui/core/Tooltip';

const InteractiveTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    padding: 0,
    marginTop: '20px',
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(MaterialTooltip);

export default InteractiveTooltip;
