import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  padding: 48px;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.backgroundColor.selago};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 32px;
  }
`;

export const CardWrapper = styled.div`
  max-width: 397px;
  border-radius: 15px;
  padding: 20px;
  background-color: ${(props) => props.theme.backgroundColor.white};
  box-shadow: ${(props) => props.theme.boxShadow.greyShadow};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    max-width: 287px;
  }
`;

export const CouponInfo = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.smMedium};
    line-height: 22px;
    text-align: justify;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      font-size: ${(props) => props.theme.fontSize.primary};
      line-height: 20px;
    }
  }
`;

export const Link = styled.div`
  font-size: ${(props) => props.theme.fontSize.smMedium};
  line-height: 25px;
  text-align: center;
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 20px;
  }
`;
