import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { getCategoryName, getEventAction } from 'utils/Missions/utils';
import * as Strings from 'resources/strings';
import MissionCategory from 'components/common/MissionCategory';
import Carousel from 'components/common/Carousel';

import * as constants from 'utils/Analytics/flow-constants';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import { clevertapEventTracking } from 'utils/Clevertap/Clevertap';
import { viewAllMissions } from 'utils/Clevertap/flow-constants';
import {
  Title,
  Link,
  TitleWrapper,
  Wrapper,
} from './style';

/// [MissionsCategorySection] shows the missions based on the category
const MissionsCategorySection = ({ allMissionsCategories, missionCategories }) => {
  const categoryCardRef = useRef();
  const history = useHistory();
  const { initiateTracking } = useAnalytics();
  const [cardWidth, setCardWidth] = useState(0);

  useEffect(() => {
    // dynamic width of the category card
    const maxCardWidth = categoryCardRef?.current?.clientWidth;
    setCardWidth(maxCardWidth);
  }, [categoryCardRef?.current?.clientWidth]);

  // `routeToAllCategories` routes to all category page
  const routeToAllCategories = () => {
    // clevertap event for view all
    clevertapEventTracking(viewAllMissions);
    // analytics for view all
    initiateTracking({
      eventName: constants.categoryViewAllAnalytics,
      eventCategory: constants.navigation,
      eventAction: getEventAction(),
      eventLabel: Strings.viewAllLabel,
    });
    history.push('/all-categories');
  };
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{Strings.findMissionsTitle}</Title>
        <Link onClick={() => routeToAllCategories()}>
          {Strings.viewAllLabel}
        </Link>
      </TitleWrapper>
      <Carousel
        missionsData={allMissionsCategories}
        cardWidth={cardWidth}
        showChevron
      >
        {Object.keys(allMissionsCategories).map((category, index) => (
          <div ref={categoryCardRef}>
            <MissionCategory
              key={category}
              index={index}
              categoryName={getCategoryName(missionCategories, category)}
              missions={allMissionsCategories[category]}
            />
          </div>
        ))}
      </Carousel>
    </Wrapper>
  );
};
export default MissionsCategorySection;

MissionsCategorySection.propTypes = {
  allMissionsCategories: PropTypes.shape(PropTypes.any),
  missionCategories: PropTypes.arrayOf(PropTypes.any),
};

MissionsCategorySection.defaultProps = {
  allMissionsCategories: {},
  missionCategories: [],
};
