import React from 'react';

import NotificationsProvider from 'provider/notifications-provider';

import Header from './Header';

/// [AppBar] responsible for displaying the header
function AppBar() {
  return (
    <NotificationsProvider>
      <Header />
    </NotificationsProvider>
  );
}

export default AppBar;
