import clevertap from 'clevertap-web-sdk';

/// `clevertapEventTracking` event tracks for actions performed by user
export const clevertapEventTracking = (eventName, eventProperties = {}) => {
  clevertap.event.push(eventName, eventProperties);
};

/// `clevertapProfileTracking` clevertap profile push for users
export const clevertapProfileTracking = (userId, phoneNumber) => {
  clevertap.onUserLogin.push({
    Site: {
      Identity: userId, // String or number
      Phone: phoneNumber, // Phone (with the country code)
    },
  });
};
