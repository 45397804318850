import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  margin: 25px 0;
`;

export const Title = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 22px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fontSize.secondary};
      line-height: 30px;
    }
  }
`;

export const BlogCardWrapper = styled.div`
  display: inline-block;
  padding: 5px 12px 5px 5px;
  margin-left: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-left: ${(props) => (props.isRequired ? '15px' : '0')};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 0 20px;
  }
`;
