import React from 'react';

import { MissionsProvider } from 'provider/missions-provider';
import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import MissionsContainer from 'components/containers/Missions/MissionsContainer';
import { SustainableProvider } from 'provider/sustainability-metrics-provider';
import { DashboardProvider } from 'provider/dashboard-provider';

function Missions() {
  return (
    <BaseLayout hideGutters>
      <DashboardProvider>
        <SustainableProvider>
          <MissionsProvider>
            <MissionsContainer />
          </MissionsProvider>
        </SustainableProvider>
      </DashboardProvider>
    </BaseLayout>
  );
}

export default Missions;
