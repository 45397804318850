import React from 'react';
import PropTypes from 'prop-types';

import MissionRewardSection from 'components/pages/EarnedRewards/components/MissionRewardSection';
import * as referralConstants from 'utils/Referrals/flow_constants';
import * as constants from 'utils/global-constants';
import * as Strings from 'resources/strings';

import LinkedSeriesWrapper from './style';

/**
 *
 * @param {object} rewardsListData
 * `RewardsList` renders the list of colleted rewards
 */

const RewardsList = ({ rewardsListData, openDialog, setRewardsData }) => (
  <>
    {Object.values(rewardsListData).map((rewardData) => (
      <div key={rewardData?.id}>
        {(rewardData?.type === constants.missionReward
         || rewardData?.type === referralConstants.referralReward) && (
         <MissionRewardSection
           missionName={rewardData?.missionName}
           earnedDate={rewardData?.earnedDate}
           rewardName={rewardData?.primary?.name}
           rewardImage={rewardData?.primary?.image}
           shortDesc={rewardData?.primary?.shortDesc}
           isBigReward={false}
           missionId={rewardData?.missionId}
           rewardId={rewardData?.id}
           toShowDialog={false}
           rewardType={rewardData.type}
         />
        )}
        {rewardData?.type === constants.linkedMissionsReward && (
          <LinkedSeriesWrapper>
            <MissionRewardSection
              missionName={Strings.missionSeriesTitle}
              earnedDate={rewardData?.earnedDate}
              rewardName={rewardData?.primary?.name}
              rewardImage={rewardData?.primary?.image}
              shortDesc={rewardData?.primary?.shortDesc}
              isBigReward
              rewardType={rewardData.type}
              toShowDialog={false}
              openDialog={openDialog}
              linkedMissionData={rewardData}
              setRewardsData={setRewardsData}
            />
            {rewardData?.linkedMissions?.map((reward) => (
              <MissionRewardSection
                missionName={reward?.missionName}
                earnedDate={reward?.earnedDate}
                rewardName={reward?.primary?.name}
                rewardImage={reward?.primary?.image}
                shortDesc={reward?.primary?.shortDesc}
                isBigReward={false}
                missionId={reward?.missionId}
                rewardId={reward?.id}
                rewardType={rewardData.type}
                toShowDialog={false}
              />
            ))}
          </LinkedSeriesWrapper>
        )}
      </div>
    ))}
  </>
);

RewardsList.propTypes = {
  rewardsListData: PropTypes.shape({
    missionName: PropTypes.string,
    earnedDate: PropTypes.shape(),
    id: PropTypes.string,
    missionId: PropTypes.number,
    primary: PropTypes.shape(),
    userRewardId: PropTypes.string,
  }),
  openDialog: PropTypes.func,
  setRewardsData: PropTypes.func,
};

RewardsList.defaultProps = {
  rewardsListData: {
    missionName: '',
    earnedDate: {},
    id: '',
    missionId: 0,
    primary: {},
    userRewardId: '',
  },
  openDialog: () => {},
  setRewardsData: () => {},
};

export default RewardsList;
