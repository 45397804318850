import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Title = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.secondary};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 24px;
    margin: 18px 0px 17px 80px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      margin-left: 25px;
    }
  }
`;
export const Wrapper = styled.div`
  padding: 0 80px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 0 20px;
  }
`;
export const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 5px;
  padding: 16px 0;
  margin: 0 20px;
  text-align: center;
`;
export const CategoryImageCard = styled.div`
  width: 295px;
  height: 265px;
  border-radius: 25px;
  margin-bottom: 20px;
  margin-right: 20px;
  box-shadow: ${(props) => props.theme.boxShadow.greyShadow};
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 260px;
  }
`;
export const Category = styled.div`
  padding: 30px 18px 0 20px;
`;
export const CategoryName = styled(Typography)`
 && {
    font-size: ${(props) => props.theme.fontSize.medium};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => props.theme.fontColor.carbonBlack};
    line-height: 26px;
 }
`;
export const MissionCount = styled(CategoryName)`
&& {
 font-size: ${(props) => props.theme.fontSize.primary};
 line-height: 20px;
}
`;
export const Image = styled.img`
  width: inherit;
  height: 58%;
  border-radius: 25px 25px 0 0;
  object-fit: cover;
`;
export const CategoryWrapper = styled.div`
  height: 370px;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
`;
