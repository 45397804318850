import React from 'react';

import { SustainableProvider } from 'provider/sustainability-metrics-provider';
import { MissionsProvider } from 'provider/missions-provider';
import { DashboardProvider } from 'provider/dashboard-provider';
import { ReferralProvider } from 'provider/referral_provider';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import RewardContainer from 'components/containers/RewardContainer';
/**
 * MissionReward is the screen where the user can claim his/her achieved rewards
 * @returns JSX
 */

function MissionReward() {
  return (
    <BaseLayout hideGutters>
      <DashboardProvider>
        <ReferralProvider>
          <MissionsProvider>
            <SustainableProvider>
              <RewardContainer />
            </SustainableProvider>
          </MissionsProvider>
        </ReferralProvider>
      </DashboardProvider>
    </BaseLayout>
  );
}

export default MissionReward;
