import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { customerTestimonialsTitle } from 'resources/strings';
import CustomerRatingCard from 'components/pages/Missions/components/CustomerRatingCard';
import Spacer from 'components/common/Spacer/Spacer';
import Carousel from 'components/common/Carousel';

import { Title, Wrapper, CarouselWrapper } from './style';

// `CustomerTestimonials` is a slider with customer reviews
const CustomerTestimonials = ({ customerReviews }) => {
  const customerRatingCardRef = useRef();
  const [cardWidth, setCardWidth] = useState(0);

  useEffect(() => {
    // width of the customer rating card
    const maxCardWidth = customerRatingCardRef?.current?.clientWidth;
    setCardWidth(maxCardWidth);
  }, [customerRatingCardRef?.current?.clientWidth]);
  return (
    <Wrapper>
      <Title>{customerTestimonialsTitle}</Title>
      <Spacer height='20px' />
      <Carousel showChevron cardWidth={cardWidth}>
        {customerReviews.map((review, index) => (
          <CarouselWrapper ref={customerRatingCardRef}>
            <CustomerRatingCard
              key={review.id}
              name={review.author}
              comment={review.comment}
              rating={review.rating}
              isFirstChild={index === 0}
            />
          </CarouselWrapper>
        ))}
      </Carousel>
    </Wrapper>
  );
};

export default CustomerTestimonials;

CustomerTestimonials.propTypes = {
  customerReviews: PropTypes.arrayOf(PropTypes.any),
};
