import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 8px 4px;
    display: flex;
    justify-content: center;
`;

export const StepperWrapper = styled.div`
  height: 7px;
  width:100%;
  background-color: ${(props) => props.theme.backgroundColor.silver};
  margin-right:4px;
`;

export const Step = styled.div`
   height: 7px;
   width: ${(props) => props.width}%;
   background-color: ${(props) => ((props.missionIndex <= props.currentIndex) ? props.theme.backgroundColor.dodgerBlue : props.theme.backgroundColor.silver)};
   transition:  ${(props) => (!props.seen && props.currentMission ? 'width 5s' : 'none')};
`;
