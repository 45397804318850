import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'provider/auth-provider';
import { getUserReferral, getReferralInfo } from 'services/Referral/referral_service';

const ReferralContext = createContext();

export const useReferrals = () => useContext(ReferralContext);

/// `ReferralProvider` contains all the content which are related to referral links feature
export const ReferralProvider = ({ children }) => {
  const [referralLoader, setReferralLoader] = useState(true);
  const [referralData, setReferralData] = useState({});
  const [referralLinksLoader, setReferralLinksLoader] = useState(true);
  const [referralLinksData, setReferralLinksData] = useState([]);

  const { currentUser } = useAuth();
  const [referralRewardSeen] = useState(currentUser?.referrer?.referralRewardSeen ?? true);

  /// `fetchUserReferral` fetch user referral document
  const fetchUserReferral = async (userId) => {
    // sdk call to fetch the user referral document
    const response = await getUserReferral(userId);
    setReferralData(response);
    setReferralLoader(false);
  };

  // `fetchReferralLinksData` service call to fetch referral links data
  const fetchReferralInfo = async () => {
    getReferralInfo().then((data) => {
      setReferralLinksData(data);
      setReferralLinksLoader(false);
    });
  };

  useEffect(() => {
    if (currentUser) {
      // fetch user referral doc
      fetchUserReferral(currentUser.id);
    }
    fetchReferralInfo();
  }, []);

  const value = {
    referralLoader,
    referralData,
    referralLinksLoader,
    referralLinksData,
    referralRewardSeen,
    fetchReferralInfo,
  };
  return (
    <ReferralContext.Provider value={value}>
      {children}
    </ReferralContext.Provider>
  );
};

ReferralProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
