import styled from 'styled-components';

export const ScrollWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
  scroll-behavior: smooth;
  display: -webkit-box;
`;

export const Wrapper = styled.div`
  margin: 25px 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 25px 0px;
  }
`;

export const LeftArrow = styled.div`
  background-color: ${(props) => (props.isDisabled ? props.theme.backgroundColor.cloudBlue : props.theme.backgroundColor.dodgerBlue)};
  border-radius: 100px;
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  top: 50%;
  left: -3%;
`;

export const RightArrow = styled.div`
  background-color: ${(props) => (props.isDisabled ? props.theme.backgroundColor.cloudBlue : props.theme.backgroundColor.dodgerBlue)};
  border-radius: 100px;
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  top: 50%;
  right: -3.5%;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export const ImageWrapper = styled.div`
 height: 32px;
 width: 32px;
`;

export const CarouselWrapper = styled.div`
  position: relative;
`;
