import React from 'react';

import { MissionsProvider } from 'provider/missions-provider';
import { DashboardProvider } from 'provider/dashboard-provider';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import AllCategoryCards from 'components/pages/AllCategories/components/AllCategories';

/**
 * `AllCategories` used to display all the categories available for missions
 * @returns JSX
 */
const AllCategories = () => (
  <BaseLayout showPoster={false} hideGutters>
    <DashboardProvider>
      <MissionsProvider>
        <AllCategoryCards />
      </MissionsProvider>
    </DashboardProvider>
  </BaseLayout>
);

export default AllCategories;
