import React, { useState, useRef } from 'react';

import {
  useLocation,
  useHistory,
} from 'react-router-dom';

import { postNewBillUploadData, uploadImageFile, updateReuploadBillData } from 'services/UploadBill/upload-bill-service';

import { useAuth } from 'provider/auth-provider';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import { useSnackbar } from 'provider/snackbar-provider';

import * as Strings from 'resources/strings';
import { clevertapEventTracking } from 'utils/Clevertap/Clevertap';
import { submitBill } from 'utils/Clevertap/flow-constants';
import * as constants from 'utils/Analytics/flow-constants';
import Button from 'components/common/Button/Button';
import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import FileInput from '../../common/FileInput/FileInput';
import {
  BillImage,
  ConfirmBill,
  CheckBillText,
  ReUploadButton,
  Wrapper,
} from './styles';

/// [UploadBill] is the preview page for the bill uploaded by the user
function UploadBill() {
  const location = useLocation();
  const history = useHistory();
  const { currentUser } = useAuth();
  const { setIsShow, setDisplayText } = useSnackbar();
  const refs = useRef(null);

  const [uploadProgress, setUploadProgress] = useState(false);
  const [imageFile, setImageFile] = useState(location.state && location.state.image);
  const missionId = location.state && location.state.missionId;
  const activityId = location.state && location.state.activityId;
  const userActivityId = location.state && location.state.userActivityId;
  const userMissionId = location.state && location.state.userMissionId;
  const showReminderPopup = location.state && location.state.showReminderPopup;
  const currentActivityIndex = location.state && location.state.currentActivity;
  const completedCount = location.state && location.state.completed;
  const title = location.state && location.state.title;

  // screen dynamic text coming from strapi backend
  const screenTitle = location.state && location.state?.screenTitle;
  const screenDescription = location.state && location.state?.screenDescription;
  const buttonText = location.state && location.state?.buttonText;
  /// [selectImage] Returns the image selected by the user
  const selectImage = (event) => {
    if (event.target.files.length) {
      // Function used for displaying image on screen
      const image = event.target.files[0];
      if (image) {
        setImageFile(image);
      }
    }
  };
  const { initiateTracking } = useAnalytics();

  const submitBillAnalyticEventHandler = (eventName, eventCategory, eventAction, eventLabel) => {
    clevertapEventTracking(submitBill);
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: `${eventCategory}`,
      eventAction: `${eventAction}`,
      eventLabel: `${eventLabel}`,
    });
  };
  // `uploadFile` to submit the file to be uploaded
  const uploadFile = async () => {
    submitBillAnalyticEventHandler(
      constants.submitBillAnalytics,
      constants.navigation,
      constants.previewBill,
      `${Strings.submitBill}_${title}`.toLowerCase(),
    );
    setUploadProgress(true);
    // upload the file
    const uploadResponse = await uploadImageFile(imageFile);
    if (uploadResponse.data && uploadResponse.data.success) {
      // create a document regarding the uploaded file
      // for the new user activity
      const imageId = uploadResponse.data[0].id;
      if (userActivityId) {
        await updateReuploadBillData(
          currentUser.id,
          userMissionId,
          missionId,
          imageId,
          userActivityId,
          currentActivityIndex,
          completedCount,
          currentUser.phoneNumber,
        );
      } else {
        await postNewBillUploadData(
          currentUser.id,
          userMissionId,
          missionId,
          imageId,
          activityId,
          currentActivityIndex,
          completedCount,
          currentUser.phoneNumber,
        );
      }

      // go to progress screen
      if (missionId && userMissionId) {
        setUploadProgress(false);
        setIsShow(true);
        setDisplayText(Strings.imageUploaded);
        if (showReminderPopup) {
          localStorage.setItem('showSmsReminderPopUp', true);
        }
        history.replace({
          pathname: `/missions/${missionId}/progress`,
          search: `?instance-id=${userMissionId}`,
        });
      }
    } else {
      // go to progress screen
      setUploadProgress(false);
      setDisplayText(Strings.unsupportedFileType);
      setIsShow(true);
      history.replace({
        pathname: `/missions/${missionId}/progress`,
        search: `?instance-id=${userMissionId}`,
      });
    }
  };

  /// [uploadBill] Programmatically clicking the hidden input file type
  /// when the user clicks on upload button
  const uploadNewBill = () => {
    submitBillAnalyticEventHandler('analytics_reupload', 'navigation', 'mission progress_preview bill', `${Strings.reupload}_${title}`);
    refs.current.click();
  };

  return (
    <BaseLayout>
      <Wrapper>
        <ConfirmBill>{screenTitle ?? Strings.confirmBillDesc}</ConfirmBill>
        <CheckBillText>{screenDescription ?? Strings.previewBillDesc}</CheckBillText>
        {imageFile && <BillImage src={URL.createObjectURL(imageFile)} alt='your_bill' />}
        <Button
          isLoading={uploadProgress}
          onClick={uploadFile}
          title={buttonText ?? Strings.submitBill}
          disabled={uploadProgress}
        />
        <FileInput onChangeFunc={selectImage} refs={refs}>
          <ReUploadButton color='primary' onClick={uploadNewBill}>{Strings.reupload}</ReUploadButton>
        </FileInput>
      </Wrapper>
    </BaseLayout>
  );
}

export default UploadBill;
