import React from 'react';

import Breadcrumb from 'components/common/BreadCrumb';
import {
  EarnedRewardsTitleContainer,
  RewardsTitle,
} from 'components/pages/EarnedRewards/styles';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as constants from 'utils/Analytics/flow-constants';
import * as Strings from 'resources/strings';

/**
 *
 * `EarnedRewardsTitle` renders the title container
 */
const EarnedRewardsTitle = () => {
  const { initiateTracking } = useAnalytics();

  // 'analyticsEventHandler` analytics event handler for breadcrumbs
  const analyticsEventHandler = (eventLabel) => {
    initiateTracking({
      eventName: constants.breadCrumbAnalytics,
      eventCategory: constants.navigation,
      eventAction: `${constants.rewardsCollected}_${constants.breadcrumb}`,
      eventLabel: `${eventLabel}`,
    });
  };
  return (
    <EarnedRewardsTitleContainer>
      <Breadcrumb analyticsEvent={analyticsEventHandler} />
      <RewardsTitle>{Strings.rewardsCollectedTitle}</RewardsTitle>
    </EarnedRewardsTitleContainer>
  );
};

export default EarnedRewardsTitle;
