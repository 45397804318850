import React, { useState, useRef, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useAuth } from 'provider/auth-provider';
import { useMissions } from 'provider/missions-provider';
import * as Strings from 'resources/strings';

import Button from 'components/common/Button/Button';
import ProgressBar from 'components/pages/Missions/components/ProgressBar';
import FileInput from 'components/common/FileInput/FileInput';
import Loader from 'components/common/Loader/Loader';
import Spacer from 'components/common/Spacer/Spacer';
import * as constants from 'utils/Analytics/flow-constants';
import CouponCard from 'components/pages/Missions/components/CouponCard';
import { clevertapEventTracking } from 'utils/Clevertap/Clevertap';
import { uploadPhoto } from 'utils/Clevertap/flow-constants';
import {
  ButtonWrapper, UploadButton, Message, StoreLink,
} from './style';
import Reminder from '../Reminder/Reminder';

function MissionProgressLanding({
  missionsData,
  claimReward,
  isButtonLoading,
  scheduleSMSReminder,
  analyticsEventHandler,
}) {
  const history = useHistory();
  // `useQuery` used to get the value of query parameter strings from URL
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { currentUser } = useAuth();
  const {
    fetchUserMissions, isMissionLoading, userMissions, userActivities,
  } = useMissions();
  const [userMissionId] = useState(query.get('instance-id'));
  const [showClaimReward, setShowClaimReward] = useState(false);
  const [showReuploadBill, setShowReuploadBill] = useState(false);
  const [completedCount, setCompletedCount] = useState(0);
  const [showSmsReminderPopUp, setShowSmsReminderPopup] = useState(
    localStorage.getItem('showSmsReminderPopUp'),
  );
  // Need to set reference to the hidden input file element
  const refs = useRef(null);
  /// [selectImage] Returns the image selected by the user
  const selectImage = (
    type,
    event,
    activityId,
    currentActivityIndex,
    userActivityId = '',
  ) => {
    if (event.target.files.length) {
      // Function used for displaying image on screen
      const image = event.target.files[0];
      let setReminderPopup = false;
      const reminderAlreadySet = !!userMissions.reminderDate;
      // show sms reminder pop up after submitting bill
      // only if its not the last activity of the mission & an existing reminder is already there
      if (
        type === 'redo'
        && (userMissions.activities.length === missionsData.repetitionCount
          || reminderAlreadySet
          || currentUser.disableNotification)
      ) {
        setReminderPopup = false;
      } else if (
        type === 'upload'
        && (currentActivityIndex === missionsData.repetitionCount
          || reminderAlreadySet
          || currentUser.disableNotification)
      ) {
        setReminderPopup = false;
      } else {
        setReminderPopup = true;
      }
      if (image) {
        const dynamicTextForUpload = missionsData.activity?.dynamicTextForUpload;
        const uploadScreenTitle = dynamicTextForUpload?.uploadScreenTitle;
        const uploadScreenDescription = dynamicTextForUpload?.uploadScreenDescription;
        const uploadScreenButtonText = dynamicTextForUpload?.uploadScreenButtonText;
        history.push({
          pathname: `/missions/${missionsData.id}/progress/preview-bill`,
          state: {
            image,
            userMissionId,
            missionId: missionsData.id,
            activityId,
            userActivityId,
            completed: completedCount,
            currentActivity: currentActivityIndex,
            showReminderPopup: setReminderPopup,
            screenTitle: uploadScreenTitle,
            screenDescription: uploadScreenDescription,
            buttonText: uploadScreenButtonText,
            title: missionsData.title,
          },
        });
      }
    }
  };

  /// [uploadBill] Programmatically clicking the hidden input file type
  /// when the user clicks on upload button
  const uploadBill = (reupload) => {
    // clevertap event for upload photo
    clevertapEventTracking(uploadPhoto, {
      'mission-name': missionsData.title,
      'mission-category': missionsData.missionsCategory?.name,
      'mission-reward': missionsData.missionReward?.name,
    });
    analyticsEventHandler(
      reupload ? constants.reuploadBillAnalytics : constants.uploadAnalytics,
      constants.navigation,
      constants.missionProgress,
      `${reupload ? Strings.reUploadBill : Strings.upload}_${missionsData.title}`,
    );
    refs.current.click();
  };

  // `getCompletedStatus` to check whether the mission activities are completed
  // to show claim Reward button
  const getCompletedStatus = (
    completed,
    userActivityInfo,
    userActivityLength,
  ) => {
    setCompletedCount(completed);
    if (missionsData.activity.verificationMechanism === 'staffPin') {
      setShowClaimReward(completed === missionsData.repetitionCount);
    } else {
      setShowClaimReward(userActivityLength === missionsData.repetitionCount);
    }
    if (userActivityInfo) {
      setShowReuploadBill(
        userActivityInfo.verificationStatus === 'rejected'
          && missionsData.repetitionCount === 1
          && missionsData.activity.verificationMechanism === 'photo',
      );
    }
  };

  useEffect(() => {
    fetchUserMissions(currentUser.id, userMissionId);
  }, [userMissionId]);

  /// [redirectToVerifyProgress] takes the user to verify progress page to enter staffpin
  /// to complete the activity
  const redirectToVerifyProgress = () => {
    analyticsEventHandler(
      'analytics_verify mission progress',
      'navigation',
      'mission progress',
      `${Strings.verifyMissionProgress}_${missionsData.title}`,
    );
    history.push({
      pathname: `/missions/${missionsData.id}/progress/verify-staffpin`,
      state: {
        missionId: missionsData.id,
        currentUserMissionId: userMissionId,
        missionTitle: missionsData.title,
        currentMissionsData: missionsData,
        goToClaimReward: completedCount === missionsData.repetitionCount - 1,
      },
    });
  };

  const renderVerificationMechanismButton = (currentmissionsData) => {
    switch (currentmissionsData.activity.verificationMechanism) {
      case 'staffPin':
        return (
          <Button
            onClick={() => redirectToVerifyProgress()}
            title={Strings.verifyMissionProgress}
            variant='contained'
            name='verifyOTPButton'
            id='verify-otp-button'
          />
        );
      case 'photo':
        return (
          <FileInput
            onChangeFunc={(event) => selectImage(
              'upload',
              event,
              missionsData.activity.id,
              Object.keys(userActivities).length + 1,
            )}
            refs={refs}
          >
            <UploadButton onClick={() => uploadBill(false)}>{Strings.upload}</UploadButton>
          </FileInput>
        );
      case 'affiliateLink':
        return (
          <Button
            title={
              currentmissionsData.affiliate?.affiliateLinkText
              ?? Strings.goToPartnerPage
            }
            onClick={() => {
              if (
                currentmissionsData.affiliate?.affiliateLink
                && currentmissionsData.affiliate?.affiliateLink !== ''
              ) window.open(currentmissionsData.affiliate?.affiliateLink);
            }}
          />
        );
      default:
        return <></>;
    }
  };
  const onStoreLinkClickEventHandler = () => {
    analyticsEventHandler(
      'analytics_go to store',
      'navigation',
      'mission progress',
      `${missionsData.missionReward.rewardAddress.PartnerLinkText}`,
    );
  };
  return isMissionLoading ? (
    <Loader isButtonLoader />
  ) : (
    <>
      {missionsData.missionReward.type === 'promoCode'
        && userMissions.promocode
        && userMissions.promocode !== 'DEFAULT' && (
          <CouponCard promoCode={userMissions.promocode} />
      )}
      {showSmsReminderPopUp === 'true'
        && !(missionsData.repetitionCount === 1) && (
          <Reminder
            scheduleSMSReminder={scheduleSMSReminder}
            userMissionId={userMissionId}
            analyticsEventHandler={analyticsEventHandler}
            setShowSmsReminderPopup={setShowSmsReminderPopup}
            title={missionsData.title}
          />
      )}
      {missionsData.missionReward.rewardAddress
        && missionsData.missionReward.rewardAddress.PartnerLink && (
          <>
            <StoreLink
              href={missionsData.missionReward.rewardAddress.PartnerLink}
              target='_blank'
              rel='noopener noreferrer'
              onClick={onStoreLinkClickEventHandler}
            >
              {' '}
              {missionsData.missionReward.rewardAddress.PartnerLinkText}
            </StoreLink>
            <Spacer height='20px' />
          </>
      )}
      <ProgressBar
        activityId={missionsData.activity.id}
        activityName={missionsData.title}
        missionId={userMissionId}
        userActivities={userActivities}
        userMissions={userMissions}
        repetitionCount={missionsData.repetitionCount}
        verificationMechanism={missionsData.activity.verificationMechanism}
        reUploadFunc={selectImage}
        getCompletedCount={getCompletedStatus}
        reminderDate={userMissions.reminderDate}
        analyticsEventHandler={analyticsEventHandler}
        isMissionLoading={isMissionLoading}
      />
      <ButtonWrapper>
        {!showClaimReward ? (
          renderVerificationMechanismButton(missionsData)
        ) : (
          <>
            {!showReuploadBill ? (
              <Button
                title={Strings.viewReward}
                onClick={() => claimReward(userMissionId)}
                isLoading={isButtonLoading}
                disabled={completedCount !== missionsData.repetitionCount}
              />
            ) : (
              <FileInput
                onChangeFunc={(event) => selectImage(
                  'upload',
                  event,
                  missionsData.activity.id,
                  Object.keys(userActivities).length,
                  userActivities[userMissions.activities[0]].id,
                )}
                refs={refs}
              >
                <UploadButton onClick={() => uploadBill(true)}>Reupload Bill</UploadButton>
              </FileInput>
            )}
            <Spacer height='6px' />
            {missionsData.repetitionCount === 1
              && missionsData.activity.verificationMechanism === 'photo' && (
                <Message
                  status={
                    userActivities[userMissions.activities[0]]
                      .verificationStatus
                  }
                >
                  {userActivities[userMissions.activities[0]].comments}
                </Message>
            )}
          </>
        )}
      </ButtonWrapper>
    </>
  );
}

export default MissionProgressLanding;

MissionProgressLanding.propTypes = {
  missionsData: PropTypes.objectOf(PropTypes.any),
  claimReward: PropTypes.func,
  isButtonLoading: PropTypes.bool,
  scheduleSMSReminder: PropTypes.func,
  analyticsEventHandler: PropTypes.func,
};
