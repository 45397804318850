import React from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { useAuth } from 'provider/auth-provider';

import { useAllMissions } from 'provider/dashboard-provider';
import { checkUserMission } from 'services/Missions/missions-service';

import * as Strings from 'resources/strings';
import * as constants from 'utils/Analytics/flow-constants';

import {
  MissionType,
  CurrentCompleted,
  Image,
} from 'components/pages/Dashboard/style';
import {
  Banner,
  BannerWrapper,
  ImageWrapper,
  CompletedWrapper,
  ContinueMission,
  CompletePreviousMission,
} from 'components/pages/Dashboard/components/Missions/style';
import { checkPath } from 'utils/Missions/utils';

import
{
  RewardDesc,
  RewardImageWrapper,
  RewardWrapper,
} from 'components/common/AllMissionsCard/style';

/// [MissionCard] holds the missions data to be diplayed in the missions banner in dashboard
function MissionCard({
  index,
  missionId,
  image,
  title,
  activityLength,
  repetitionCount,
  isContinue,
  analyticsEventHandler,
  linkedMissionflag,
  status,
  isCompleted,
  showBorder,
  startMissions,
  isDisabled,
  rewardImage,
  rewardName,
  showReward,
  showGutter,
}) {
  const history = useHistory();
  const { currentUser } = useAuth();
  const { allMissions } = useAllMissions();

  // `continueMissionHandler` function that takes to the corresponding userMission
  const continueMissionHandler = async () => {
    analyticsEventHandler(
      isContinue ? constants.continueMissionAnalytics : constants.viewMissionAnalytics,
      constants.navigation,
      checkPath('/home') ? constants.preLoginDashboard : constants.dashboard,
      isContinue
        ? `${Strings.continueDesc}_${title}`
        : `${Strings.viewMissionlabel}_${constants.featuredMissions}_${title}`.toLowerCase(),
    );
    if (isContinue) {
      const existingUserMissionId = await checkUserMission(
        currentUser.id,
        missionId,
      );
      if (existingUserMissionId.data.userMissionId != null) {
        history.push({
          pathname: `/missions/${missionId}/progress`,
          search: `?instance-id=${existingUserMissionId.data.userMissionId}`,
        });
      }
    } else {
      history.push(`/missions/${missionId}`);
    }
  };

  /* `checkCurrentPageHandler` is used to call alternate functions above
  depending upon current page url  */
  const checkCurrentPageHandler = (id) => {
    if (status !== Strings.completed) {
      analyticsEventHandler(
        `${constants.linkedMissions}_${status}_${title}`.toLowerCase(),
        status,
      );
    }
    if (status === Strings.startMission) {
      startMissions(allMissions[id].mission);
    } else if (status !== Strings.completed) {
      history.push(`/missions/${missionId}`);
    }
  };

  return (
    <>
      <Banner key={missionId} isRequired={index === 0} addMargin={showGutter}>
        <BannerWrapper
          showBorder={showBorder && !(isDisabled || isCompleted)}
          onClick={() => {
            if (linkedMissionflag) {
              continueMissionHandler();
            }
          }}
        >
          {image && (
            <ImageWrapper>
              <Image src={image} alt={title} />
            </ImageWrapper>
          )}
          <CompletedWrapper>
            <MissionType disabled={isDisabled || isCompleted}>{title}</MissionType>
            {isContinue && linkedMissionflag && (
              <CurrentCompleted>
                {activityLength}
                /
                {repetitionCount}
                {' '}
                {Strings.timesCompletedDesc}
              </CurrentCompleted>
            )}
            { showReward && rewardImage && (
            <RewardWrapper>
              <RewardImageWrapper>
                <Image src={rewardImage} />
              </RewardImageWrapper>
              <RewardDesc>{rewardName}</RewardDesc>
            </RewardWrapper>
            )}
            {!showReward && !isContinue && linkedMissionflag && (
              <CurrentCompleted>
                {repetitionCount}
                {' '}
                {Strings.timesDesc}
              </CurrentCompleted>
            )}
            {isDisabled && (
              <CompletePreviousMission>
                {Strings.completePrevMission}
              </CompletePreviousMission>
            )}
            {linkedMissionflag && (
              <ContinueMission>
                {isContinue ? Strings.continueDesc : Strings.view}
                {' '}
                {Strings.missionDesc}
              </ContinueMission>
            )}
            {!isDisabled && (
              <ContinueMission
                onClick={() => checkCurrentPageHandler(missionId)}
                completed={isCompleted}
              >
                {status}
              </ContinueMission>
            )}
          </CompletedWrapper>
        </BannerWrapper>
      </Banner>
    </>
  );
}

MissionCard.propTypes = {
  index: PropTypes.number,
  missionId: PropTypes.number,
  image: PropTypes.string,
  showBorder: PropTypes.bool,
  title: PropTypes.string,
  activityLength: PropTypes.number,
  repetitionCount: PropTypes.number,
  isContinue: PropTypes.bool,
  analyticsEventHandler: PropTypes.func,
  linkedMissionflag: PropTypes.bool,
  status: PropTypes.string,
  isCompleted: PropTypes.bool,
  startMissions: PropTypes.func,
  isDisabled: PropTypes.bool,
  rewardName: PropTypes.string,
  rewardImage: PropTypes.string,
  showReward: PropTypes.bool,
  showGutter: PropTypes.bool,
};

MissionCard.defaultProps = {
  index: 1,
  missionId: 0,
  image: '',
  showBorder: true,
  title: '',
  activityLength: 0,
  repetitionCount: 0,
  isContinue: false,
  analyticsEventHandler: () => { },
  linkedMissionflag: false,
  status: '',
  isCompleted: false,
  startMissions: () => { },
  isDisabled: false,
  rewardName: '',
  rewardImage: '',
  showReward: false,
  showGutter: true,
};

export default MissionCard;
