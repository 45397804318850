import React from 'react';
import PropTypes from 'prop-types';

import * as Strings from 'resources/strings';
import Spacer from 'components/common/Spacer/Spacer';

import {
  CashBackText,
  Cash,
  Image,
  RewardDescription,
} from './style';

/**
 *
 * RewardImage is the component which contains the Reward Image with its description
 * @param {String} Image
 * @param {String} points
 * @param {number} daysLeftForExpire
 * @returns JSX
 */
function RewardImage({
  image, rewardName, rewardDescription,
}) {
  return (
    <>
      <CashBackText>{Strings.earnedRewardDesc}</CashBackText>
      <RewardDescription>{rewardDescription}</RewardDescription>
      <Spacer height='14px' />
      <center>
        <Image src={image} alt='reward' />
      </center>
      <Spacer height='14px' />
      <Cash>{rewardName}</Cash>
    </>
  );
}

RewardImage.propTypes = {
  image: PropTypes.string,
  rewardName: PropTypes.string,
  rewardDescription: PropTypes.string,
};

RewardImage.defaultProps = {
  image: 'starbuck-reward.png',
  rewardName: 'S$2 Cashback',
  rewardDescription: '',
};
export default RewardImage;
