import moment from 'moment';

import * as clipboard from 'clipboard-polyfill';
import * as constants from 'utils/Analytics/flow-constants';

/// `copyText` copies the text to the clipboard
const copyText = async (text) => {
  const data = [new clipboard.ClipboardItem({ 'text/plain': new Blob([text], { type: 'text/plain' }) })];
  await clipboard.write(data).then(() => {
  }, () => {
    console.error('copy failure');
  });
};

// `getCategoryName` to get the category name based on the key
export const getCategoryName = (missionCategories, category) => {
  const index = missionCategories?.findIndex((item) => item.key === category);
  return missionCategories[index]?.name;
};

//  `checkPath` is used to check the path of the current window/page
export const checkPath = (path) => window.location.href.includes(path);

// `getCardsLength` returns the number of cards to be displayed
// on the `all-missions` page
export const getCardsLength = (innerHeight) => {
  if (innerHeight < 670) {
    return 2;
  }
  if (innerHeight >= 670 && innerHeight < 780) {
    return 3;
  }
  return 4;
};

// `getEventAction` to get the event action
export const getEventAction = () => {
  let eventAction = constants.dashboardCategorySection;
  if (checkPath('/home')) {
    eventAction = constants.preLoginDashboardCategory;
  } else if (checkPath('/progress')) {
    eventAction = constants.missionProgressSection;
  } else if (checkPath('/mission-reward')) {
    eventAction = constants.missionRewardSection;
  } else if (checkPath('/missions') && !checkPath('/progress') && !checkPath('/mission-reward')) {
    eventAction = constants.missionsCategorySection;
  }
  return eventAction;
};

// `getDate` returns seconds in to the required date format
export const getDate = (date) => {
  const momentData = moment(date.seconds * 1000).format('ddd DD MMM, YY');
  return momentData;
};

// `getAverageReview` to get average review of the product
export const getAverageReview = (reviews) => {
  const total = reviews.reduce((accum, item) => accum + item.rating, 0);
  const result = total / reviews.length;
  return Number.isInteger(result) ? result : result.toFixed(1);
};

export default copyText;
