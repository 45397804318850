import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.dodgerBlue};
  color: ${(props) => props.theme.fontColor.white};
  border-radius: 0px 0px 20px 20px;
  padding-top: 24px;
  overflow: hidden;
  position: relative;
`;

export const Greetings = styled(Typography)`
  && {
    margin-bottom: 10px;
    margin-left: 80px;
    font-size: ${(props) => props.theme.fontSize.medium};
    line-height: 28px;
    margin-right: 25px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      margin-left: 25px;
  }
  }
`;

export const Description = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 22px;
    margin-left: 80px;
    margin-right: 25px;
    margin-bottom: ${(props) => !props.spacing && '16px'};
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      margin-left: 25px;
  }
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  bottom: -2px;
  left: 0px;
  opacity: 0.1;
  width: 100%;
`;

export const Banner = styled.div`
  margin-bottom: ${(props) => (props.addMargin ? '50px' : 0)};
  overflow: hidden;
  display: inline-block;
  margin-left: ${(props) => (props.isRequired ? '80px' : '0px')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin-left: ${(props) => (props.isRequired ? '25px' : '0px')};
  }
`;

export const BannerWrapper = styled.div`
  border: 1px solid ${(props) => (props.showBorder ? props.theme.border.dodgerBlue : props.theme.border.transparent)};
  box-shadow: ${(props) => (props.theme.boxShadow.greyShadow)};
  margin: 5px;
  background-color: ${(props) => props.theme.backgroundColor.white};
  display: flex;
  margin-right: 12px;
  border-radius: 10px;
  width: 286px;
  height: 110px;
  padding: 16px;
  color: ${(props) => props.theme.fontColor.black};
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  width: 90px;
  height: 110px;
  border-radius: 10px;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export const CompletedWrapper = styled.div`
  margin-left: 20px;
`;

export const MissionType = styled.div`
  margin-bottom: 8px;
  font-size: ${(props) => props.theme.fontSize.primary};
  line-height: 16px;
`;

export const CurrentCompleted = styled(Typography)`
  font-weight: 300;
  font-size: ${(props) => props.theme.fontSize.description};
  line-height: 14px;
`;

export const ContinueMission = styled.div`
  font-family: ${(props) => (props.theme.fontFamily.robotoRegular)};
  font-size: ${(props) => props.theme.fontSize.description};
  line-height: 14px;
  margin: 17px 10px 0px 0px;
  color: ${(props) => props.theme.fontColor.dodgerBlue};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => (props.completed ? props.theme.fontColor.seaGreen : props.theme.fontColor.dodgerBlue)};
`;

export const ArrowImageWrapper = styled.div`
  height: 5px;
  width: 5px;
`;

export const CompletePreviousMission = styled(MissionType)`
font-size: ${(props) => (props.theme.fontSize.description)};
color: ${(props) => (props.theme.fontColor.carbonGrey)};
white-space:normal;
`;

export const MissionCardWrapper = styled.div`
  margin-right:80px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
  margin-right:0px;
`;
