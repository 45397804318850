import React, { useState } from 'react';

import { useAllMissions } from 'provider/dashboard-provider';
import { useAuth } from 'provider/auth-provider';

import { redeemEcoPoints } from 'services/RedeemEcopoints/redeem-ecopoints-service';

import * as Strings from 'resources/strings';
import InfoToolTip from 'components/common/InfoToolTip';
import ButtonLink from 'components/common/ButtonLink/ButtonLink';
import ResponsiveDialog from 'components/common/Dialog/Dialog';
import RedeemList from './components/RedeemList';
import {
  Title,
  WalletTitle,
  RedeemCard,
  ButtonWrapper,
  Wrapper,
} from './style';

/**
 * `RedeemEcopoints` component that shows users Ecopoints
 * @returns JSX
 */
const RedeemEcopoints = () => {
  const { ecoPointsToolTipContent } = useAllMissions();
  const { currentUser, fetchUserStats } = useAuth();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // `updateEcoPoints` used to update the credited ecopoints of the user
  // when user confirms to redeem ecopoints
  const updateEcoPoints = async () => {
    setIsLoading(true);
    await redeemEcoPoints(currentUser.id);
    fetchUserStats();
    setIsLoading(false);
    setShowConfirmationPopup(false);
  };

  return (
    <Wrapper>
      {showConfirmationPopup && (
        <ResponsiveDialog
          title={Strings.redeemEcoPoints}
          description={Strings.areYouSure}
          handleAgree={updateEcoPoints}
          handleDismiss={() => setShowConfirmationPopup(false)}
          openDialog={showConfirmationPopup}
          agreeText={Strings.yes}
          dismissText={Strings.no}
          isLoading={isLoading}
        />
      )}
      <WalletTitle>
        <Title>{Strings.yourWallet}</Title>
        <InfoToolTip tooltipContent={ecoPointsToolTipContent} />
      </WalletTitle>
      <RedeemCard>
        <RedeemList userEcoPoints={currentUser.ecoPoints} />
        <ButtonWrapper>
          <ButtonLink
            title={Strings.redeemEcoPoints}
            onClick={() => setShowConfirmationPopup(true)}
            disabled={currentUser.ecoPoints.credit === 0}
          />
        </ButtonWrapper>
      </RedeemCard>
    </Wrapper>
  );
};

export default RedeemEcopoints;
