import { makeStyles } from '@material-ui/core/styles';
import themes from 'styles/theme';

// styles for changing color of the text field
const TextFieldStyles = makeStyles(() => (
  {
    errorMessage: {
      '& .Mui-error ': {
        color: themes.fontColor.negativeRed,
      },
    },
  }
));

export default TextFieldStyles;
