import React from 'react';
import { Divider } from '@material-ui/core';
import PropTypes from 'prop-types';

import * as Strings from 'resources/strings';
import Spacer from 'components/common/Spacer/Spacer';
import Markdown from 'components/common/Markdown';
import { useSnackbar } from 'provider/snackbar-provider';
import copyText from 'utils/Missions/utils';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as constants from 'utils/Analytics/flow-constants';

import {
  Wrapper,
  CardWrapper,
  CouponInfo,
  Link,
} from './style';

/**
 *
 * @param {String} code
 * @param {String} description
 * @returns CouponCard disables the secondary reward whenever a mission is completed
 */

const CouponCard = ({ code, description, missionName }) => {
  const { setIsShow, setDisplayText } = useSnackbar();
  const { initiateTracking } = useAnalytics();

  const analyticsEventHandler = () => {
    // analytics for copy coupon code cta
    let isCopied = false;
    // condition to trigget the event only on first copy click
    if (!isCopied) {
      initiateTracking({
        eventName: constants.copyCodeAnalytics,
        eventCategory: constants.visitorAction,
        eventAction: constants.missionReward,
        eventLabel: `${Strings.copyCodeLabel}_${missionName}`,
      });
      isCopied = true;
    }
  };

  // `copyCode` copies the coupon code
  const copyCode = () => {
    analyticsEventHandler();
    copyText(code);
    setIsShow(true);
    setDisplayText(Strings.copyCodeSuccessMessage);
  };
  return (
    <Wrapper>
      <CardWrapper>
        <CouponInfo>
          <Markdown
            content={description}
          />
        </CouponInfo>
        <Spacer height='16px' />
        <Divider />
        <Spacer height='8px' />
        <Link onClick={copyCode}>{Strings.copyCodeLabel}</Link>
      </CardWrapper>
    </Wrapper>
  );
};

export default CouponCard;

CouponCard.propTypes = {
  code: PropTypes.string,
  description: PropTypes.string,
  missionName: PropTypes.string,
};

CouponCard.defaultProps = {
  code: '',
  description: '',
  missionName: '',
};
