import { useEffect } from 'react';

import { useAllMissions } from 'provider/dashboard-provider';

import Loader from 'components/common/Loader/Loader';
import LinkedMissions from 'components/pages/Missions/components/LinkedMissions';

/**
 * [OngoingLinkedMissions] to display all the ongoing linked missions
 * @returns JSX
 *  <OngoingLinkedMissions />
 */
function OngoingLinkedMissions() {
  const {
    fetchOngoingLinkedMissions,
    isOngoingLinkedMissionLoading,
    ongoingLinkedMissions,
    completedMissions,
    ongoingMissions,
  } = useAllMissions();

  useEffect(() => {
    fetchOngoingLinkedMissions();
  }, [Object.keys(ongoingMissions).length > 0 || Object.keys(completedMissions).length > 0]);

  return isOngoingLinkedMissionLoading ? <Loader />
    : (
      <LinkedMissions
        showViewAllOption
        linkedMissionArray={ongoingLinkedMissions}
        ongoingMissions={ongoingMissions}
        completedMissions={completedMissions}
      />
    );
}

export default OngoingLinkedMissions;
