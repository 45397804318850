import styled from 'styled-components';

const SnackBarText = styled.span`
  font-weight: 500;
  font-size:${(props) => props.theme.fontSize.primary};
  padding: 0 15px;
  cursor: pointer;
`;

export default SnackBarText;
