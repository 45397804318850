// the page route and its corresponding breadCrumb name is stored in this object
// the key represents one particular path of window url say i.e "abc/path-one/def",
// and this is linked with its corresponding breadcrumbname and entire preceeding path untill

import * as Strings from 'resources/strings';

// this path i.e "abc/path-one", upon routing of which we end up in this path page
const breadCrumbLinks = {
  '': {
    pagePath: '/',
    breadCrumbName: Strings.dashboard,
  },
  'all-categories': {
    pagePath: '/all-categories',
    breadCrumbName: Strings.allCategoryLabel,
  },
  'all-missions': {
    pagePath: '/all-categories/all-missions',
    breadCrumbName: Strings.allMissions,
  },
  'about-adva-missions': {
    pagePath: '/about-adva-missions',
    breadCrumbName: Strings.aboutMissionsTitle,
  },
  'rewards-collected': {
    pagePath: '/rewards-collected',
    breadCrumbName: Strings.rewardsCollectedTitle,
  },
  'refer-a-friend': {
    pagePath: '/refer-a-friend',
    breadCrumbName: Strings.referFriendLabel,
  },
  'learn-more-about-sustainability': {
    pagePath: '/learn-more-about-sustainability',
    breadCrumbName: Strings.missionBlogsTitle,
  },
};

export default breadCrumbLinks;
