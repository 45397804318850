import styled from 'styled-components';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';

export const AccordionWrapper = styled(Accordion)`
  && {
    & {
      box-shadow: none;
      padding: 0;
    }
    &:before {
      height: 0;
    }
  }
`;

export const AccordionDetailsWrapper = styled(AccordionDetails)`
  && {
    display: inline;
    padding: 0;
    background-color: transparent;
  }
`;

export const AccordionSummaryWrapper = styled(AccordionSummary)`
  && {
    padding: 0;
    margin-bottom: 16px;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin: 0px;
    }
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
`;

export const Title = styled(Typography)`
  && {
    font-weight: 500;
    line-height: 30px;
    font-size: ${(props) => props.theme.fontSize.large};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fontSize.secondary};
      line-height: 24px;
    }
  }
`;
