import styled from 'styled-components';

import { Typography, IconButton, Card } from '@material-ui/core';

export const Coupon = styled(Card)`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.backgroundColor.easternBlue} !important;
  margin: ${(props) => (props.hideGutters ? '0' : '0 36px')};
  @media (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    && {
      margin: ${(props) => (props.hideGutters ? '0' : '0 24px')};
    }
  }
  box-shadow:none;
`;

export const MissionsWrapper = styled.div`
  padding: 20px;
`;

export const CouponCode = styled.span`
  font-size: ${(props) => props.theme.fontSize.secondary};
  font-weight: 700;
  color: ${(props) => props.theme.fontColor.white};
`;

export const CouponDescription = styled(Typography)`
  && {
    font-weight: 400;
    font-size: ${(props) => props.theme.fontSize.primary};
    color: ${(props) => props.theme.fontColor.white};
    padding: 11px 0;
    text-align: center;
  }
`;

export const DashedBorder = styled.div`
  border-left: 2px dashed;
  border-color: ${(props) => props.theme.border.white};
  height: inherit;
`;

export const Icon = styled(IconButton)`
  && {
    color: ${(props) => props.theme.fontColor.white};
    padding: 0 12px;
  }
`;

export const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: ${(props) => props.theme.backgroundColor.white};;
  margin: ${(props) => (props.isLeft ? '15px 0 0 -7px' : '15px -7px 0 0')};
`;
