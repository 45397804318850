import React from 'react';
import PropTypes from 'prop-types';

import { customerTestimonialsTitle, closeLabel } from 'resources/strings';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CustomerRatingCard from 'components/pages/Missions/components/CustomerRatingCard';
import Spacer from 'components/common/Spacer/Spacer';

import {
  Wrapper,
  Title,
  CustomerRatingCardWrapper,
  Link,
} from './style';

/**
 *
 * @param {boolean} isDialogOpen
 * @param {function} handleClose
 * @param {string} name
 * @param {string} comment
 * @param {number} rating
 * `CustomerRatingPopup` renders the popup for customer review section which shows
 *  the full review of the product from the user
 */

const CustomerRatingPopup = ({
  isDialogOpen,
  handleClose,
  name,
  comment,
  rating,
}) => {
  const useStyles = makeStyles({
    rootStyle: {
      zIndex: '1 !important',
      top: '90px !important',
    },
  });
  const classes = useStyles();

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: '8px',
        },
      }}
      classes={{
        root: classes.rootStyle,
      }}
    >
      <Wrapper>
        <Title>{customerTestimonialsTitle}</Title>
        <Spacer height='16px' />
        <CustomerRatingCardWrapper>
          <CustomerRatingCard
            name={name}
            comment={comment}
            rating={rating}
            hideShowMore={isDialogOpen}
          />
        </CustomerRatingCardWrapper>
        <Link onClick={handleClose}>{closeLabel}</Link>
      </Wrapper>
    </Dialog>
  );
};

export default CustomerRatingPopup;

CustomerRatingPopup.propTypes = {
  isDialogOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  name: PropTypes.string,
  comment: PropTypes.string,
  rating: PropTypes.number,
};

CustomerRatingPopup.defaultProps = {
  isDialogOpen: false,
  handleClose: () => {},
  name: '',
  comment: '',
  rating: 0,
};
