import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';

const InfoIconWrapper = styled(InfoIcon)`
 margin-top: 2px;
 margin-left: 10px;
 color: ${(props) => props.theme.fontColor.dodgerBlue};
 cursor: pointer;
`;

export default InfoIconWrapper;
