import React from 'react';
import PropTypes from 'prop-types';
import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import LoginContainer from '../../containers/Login/LoginContainer';
import { TimerProvider } from '../../../provider/timer-provider';
import LegalNotice from './components/LegalNotice';
import ManageCookies from './components/ManageCookies';

function LoginPage({ showOTP }) {
  return (
    <BaseLayout>
      <TimerProvider>
        <LoginContainer showOtpField={showOTP} />
        <LegalNotice />
        {process.env.NODE_ENV === 'production' && <ManageCookies />}
      </TimerProvider>
    </BaseLayout>
  );
}

LoginPage.propTypes = {
  showOTP: PropTypes.bool,
};

export default LoginPage;
