import PropTypes from 'prop-types';

import { writtenByLabel } from 'resources/strings';

import {
  AuthorImage,
  AuthorLabel,
  AuthorName,
  AuthorContent,
  AuthorImageWrapper,
  AuthorNameWrapper,
  AuthorBioWrapper,
} from './style';

/**
 * BlogAuthor used to display the author of te blog
 * @param authorBio
 * @param authorName
 * @param authorImage
 * @returns JSX
 */

const BlogAuthor = ({ authorBio, authorName, authorImage }) => (
  <AuthorBioWrapper>
    <AuthorImageWrapper>
      <AuthorImage src={authorImage} alt='author-img' />
      <AuthorNameWrapper>
        <AuthorLabel>{writtenByLabel}</AuthorLabel>
        <AuthorName>{authorName}</AuthorName>
      </AuthorNameWrapper>
    </AuthorImageWrapper>
    <AuthorContent dangerouslySetInnerHTML={{ __html: authorBio }} />
  </AuthorBioWrapper>
);

BlogAuthor.propTypes = {
  authorBio: PropTypes.string,
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
};
BlogAuthor.defaultProps = {
  authorBio: '',
  authorName: '',
  authorImage: '',
};

export default BlogAuthor;
