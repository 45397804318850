import React from 'react';

import BaseLayout from 'components/common/BaseLayout/BaseLayout';
import { DashboardProvider } from 'provider/dashboard-provider';
import MissionBlogLanding from './components/MissionBlogsLanding/index';

/**
 * MissionBlog used to display the particular blog landing page
 * @returns JSX
 */
const MissionBlog = () => (
  <BaseLayout hideGutters>
    <DashboardProvider>
      <MissionBlogLanding />
    </DashboardProvider>
  </BaseLayout>
);

export default MissionBlog;
