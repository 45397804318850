import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const TimerContext = createContext();

export function useTimer() {
  return useContext(TimerContext);
}

/**
 * TimerProvider to wrap the Component where we need to run a timer.
 * @param {*} children
 * @returns TimerProvider
 */
export function TimerProvider({ children }) {
  const [timeLeft, setTimeLeft] = useState();
  const id = React.useRef(null);

  /**
 * clearTimer is to clear the timer
 */
  const clearTimer = () => {
    window.clearInterval(id.current);
  };
  useEffect(() => {
    if (timeLeft === 0) {
      clearTimer();
    }
  }, [timeLeft]);

  /**
 * tickTimer starts the timer and update timeLeft
 */
  const tickTimer = () => {
    id.current = window.setInterval(() => {
      setTimeLeft((time) => time - 1);
    }, 1000);
  };
  /**
 * startTimer to start the timer based on the limit provided
 * and store the timer start value in local storage with name provided as 'localStorageVariable'
 * @param {*} children
 * @returns TimerProvider
 */
  const startTimer = (limit, localStorageVariable) => {
    // if difference between current time and time in local storage is less than limit
    // that means the timer is still needs to ticked until 0
    // and time left will be limit - difference between current time and local storage time
    if (moment(Date()).unix() - localStorage.getItem(localStorageVariable) < limit) {
      setTimeLeft(limit - (moment(Date()).unix() - localStorage.getItem(localStorageVariable)));
      tickTimer();
    } else {
      localStorage.setItem(localStorageVariable, moment(Date()).unix());
      setTimeLeft(limit);
      tickTimer();
    }
  };

  const value = { timeLeft, startTimer, clearTimer };

  return <TimerContext.Provider value={value}>{children}</TimerContext.Provider>;
}

TimerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
