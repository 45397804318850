import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const LinkedMissionsWrapper = styled.div`
  padding: 48px 44px 0 44px;
  overflow-x: clip;
  @media (max-width: ${(props) => (props.theme.breakpoints.mobileLandscape)}) {
    padding: 27px 0 0 22px;
  }
  @media (min-width: ${(props) => (props.theme.breakpoints
    .mobileLandscape)}) and (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    padding: 27px 24px 0 24px;
  }
`;

export const LinkedMissionsTitle = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium} !important;
    line-height: 30px;
    margin-bottom: 8px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      font-size: ${(props) => props.theme.fontSize.secondary};
      line-height: 22px;
      letter-spacing: 0.25px;
    }
  }
`;

export const LinkedMissionsDescription = styled.p`
  font-family: ${(props) => (props.theme.fontFamily.robotoRegular)};
  font-size: ${(props) => (props.theme.fontSize.secondary)};
  line-height: 22px;
  letter-spacing: 0.25px;
  color: ${(props) => (props.theme.fontColor.carbonBlack)};
  margin-bottom: 21px;
  @media (max-width: ${(props) => (props.theme.breakpoints.mobileLandscape)}) {
    font-size: ${(props) => (props.theme.fontSize.primary)};
    line-height: 20px;
    margin-bottom: 16px;
    max-width: 330px;
  }
`;

export const LinkedMissionSeriesWrapper = styled.div`
  margin-bottom: 40px;
`;

export const LinkedMissionSeriesTitle = styled(LinkedMissionsDescription)`
  font-size: ${(props) => (props.theme.fontSize.primary)};
`;

export const LinkedMissionsTitleWrapper = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: baseline;
 margin-right: 16px;
`;
