import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Rating = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.smMedium};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: ${(props) => props.fontColor ?? props.theme.fontColor.white};
    margin-left: 20px;
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fontSize.description};
      margin-left: 16px;
    }
  }
`;

export const Wrapper = styled.div`
  width: 58px;
  height: 23px;
  background-color: ${(props) => props.backgroundColor ?? props.theme.backgroundColor.dodgerBlue};
  border-radius: 4px;
  padding: 4px 6px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2px 4px;
    width: 38px;
    height: 16px;
  }
`;

export const Image = styled.img`
  width: 18px;
  height: 18px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 12px;
    height: 12px;
  }
`;

export const BackgroundImgWrapper = styled.div`
  width: ${(props) => props.width ?? '18px'};
  height: 18px;
  overflow: hidden;
  position: absolute;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: ${(props) => props.width ?? '12px'};
    height: 12px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  left: 4px;
  top: 3px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    left: 2px;
    top: 1px;
  }
`;
