import React, { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';

import getSustainabilityMetrics from 'services/sustainability-metrics/sustainability-metrics';

const SustainabilityMetricsContext = createContext();

export const useSustainabilityMetrics = () => useContext(SustainabilityMetricsContext);

export const SustainableProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sustainableMetrics, setSustainableMetrics] = useState({});

  /// [fetchMetrics] fetches the sustainability metrics data
  const fetchMetrics = async () => {
    const response = await getSustainabilityMetrics();
    const metricsData = {};
    response.data.forEach((unitsData) => {
      metricsData[unitsData.metricKey] = unitsData.unitType;
    });
    setSustainableMetrics(metricsData);
    setIsLoading(false);
  };

  const value = {
    isLoading,
    sustainableMetrics,
    fetchMetrics,
  };

  return (
    <SustainabilityMetricsContext.Provider value={value}>
      {children}
    </SustainabilityMetricsContext.Provider>
  );
};

SustainableProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
