import { checkUserMission, getUserMissions } from 'services/Missions/missions-service';
import { completedRelatedMissionText, errorMessage } from 'resources/strings';
import { productPurchase, productMission } from 'utils/global-constants';

/**
 * `checkRelatedMission` is a common helper function
 *  used to check if the user completed the current mission and
 *  whether the user has any next mission in his stack
 *  and redirects to the corresponding mission respectively...
 * @param {*} relatedMissions
 * @param {*} userId
 * @param {*} useHistory
 * @param {*} setPageLoading
 */

const checkLinkedMission = async ({
  relatedMissions,
  userId,
  useHistory,
  setIsShowSnackbar,
  setSnackbarText,
  setPageLoading,
  currentUserMission,
  createMission,
  getMissionPromoCode,
  setAuthLoading,
}) => {
  if (relatedMissions && relatedMissions.length) {
    const userMission = {};
    let completedCount = 0;
    // to get all the userMission where the missionId is equal to relatedMissions
    const getUserMission = await getUserMissions(
      'mUsers',
      userId,
      'mUserMissions',
      relatedMissions,
      'missionId',
    );
    getUserMission.docs.forEach((data) => {
      const mUserMission = data.data();
      // if there are any related missions which are 'abandoned' and if the same mission
      // is started or completed by the user again then consider the mission with status
      // 'current' or 'completed' and replace the 'abondoned' mission
      if (!(mUserMission.missionId in userMission)) {
        userMission[mUserMission.missionId] = mUserMission;
      } else if (mUserMission.status === 'current' || mUserMission.status === 'completed') {
        userMission[mUserMission.missionId] = mUserMission;
      }
    });
    // to check the status of the related userMissions
    const task = {};
    for (let i = 0; i < relatedMissions.length; i += 1) {
      if ((!(relatedMissions[i] in userMission) || userMission[relatedMissions[i]].status === 'abandoned')) {
        // if any of the relatedMissionId is not in related userMissions
        // or if the status of the mission is 'abandoned'
        // then redirecting to that related mission
        task.progress = false;
        task.missionId = relatedMissions[i];
        break;
      } else if (userMission[relatedMissions[i]].status === 'current') {
        // if any of the related userMission is in current status
        // then redirecting to that mission progress page
        task.missionId = relatedMissions[i];
        task.progress = true;
        break;
      } else if (userMission[relatedMissions[i]].status === 'completed') {
        completedCount += 1;
      }
    }
    if (completedCount === relatedMissions.length) {
      // if all the relatedMissions are completed by the user
      // then redirecting to dashboard directly and
      // showing notification that user has completed all their related mission
      setIsShowSnackbar(true);
      setSnackbarText(completedRelatedMissionText);
      useHistory.replace('/');
    } else if (task.progress) {
      const existingUserMission = await checkUserMission(
        userId,
        task.missionId,
      );
      if (setPageLoading) {
        setPageLoading(false);
      }
      useHistory.replace({
        pathname: `/missions/${task.missionId}/progress`,
        search: `?instance-id=${existingUserMission.data.userMissionId}`,
      });
    } else if (currentUserMission && currentUserMission.missionId === task.missionId) {
      // When the user is coming from login then
      // if the user not started the mission then create new mission
      // and redirecting to mission progress page
      // to get the promocode of the mission if the mission type is promocode
      const missionData = currentUserMission;
      if (currentUserMission.missionRewardType === 'promoCode') {
        const promoCode = await getMissionPromoCode(currentUserMission.missionRewardId);
        missionData.promocode = promoCode?.data?.code;
      }
      // if the missions id having productCheckoutUrl then redirect to that url
      if (missionData.verification === productPurchase
        || missionData.productCheckoutUrl != null) {
        setAuthLoading(true);
        // store the product mission ids in the session storage
        sessionStorage.setItem(productMission,
          JSON.stringify({
            productMissionId: missionData.missionId,
            productLinkedMissionIds: missionData.relatedMissions,
          }));
        window.open(missionData.productCheckoutUrl, '_self');
      } else {
        const response = await createMission(userId, missionData);
        if (response.status === 200) {
          if (currentUserMission.verification === 'affiliateLink' && currentUserMission.affiliateLink && currentUserMission.affiliateLink !== '') {
            window.open(currentUserMission.affiliateLink);
          }
          if (setPageLoading) {
            setPageLoading(false);
          }
          useHistory.replace({
            pathname: `/missions/${currentUserMission.missionId}/progress`,
            search: `?instance-id=${response.data}`,
          });
        } else {
          // if api fails redirect to mission Landing
          useHistory.replace(`/missions/${currentUserMission.missionId}`);
          setIsShowSnackbar(true);
          setSnackbarText(errorMessage);
        }
      }
    } else {
      if (setPageLoading) {
        setPageLoading(false);
      }
      useHistory.replace(`/missions/${task.missionId}`);
    }
  } else {
    // if the mission does not have any relatedMissions
    // then redirecting to dashboard
    useHistory.replace('/');
  }
};

export default checkLinkedMission;
