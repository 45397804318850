import React from 'react';

import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import { rewardsData } from 'utils/Dashboard/flow-constants';

import RightArrow from 'assets/images/arrow_right_blue.svg';

import IconCard from 'components/common/IconCard';

import * as constants from 'utils/Analytics/flow-constants';
import themes from 'styles/theme';
import { clevertapEventTracking } from 'utils/Clevertap/Clevertap';
import { viewRewardsCollected } from 'utils/Clevertap/flow-constants';

import {
  Container,
  Wrapper,
  LeftWrapper,
  EarnedRewardsWrapper,
  Title,
  TextWrapper,
  Description,
  HorizontalDivider,
} from './style';

/// [EarnedRewards] component displays the data of total number of the rewards
/// earned by the user
function EarnedRewards() {
  const history = useHistory();
  const { initiateTracking } = useAnalytics();

  /// [navigateUserTo] routes the user to the selected page
  const navigateUserTo = (pathName, eventName, eventLabel) => {
    clevertapEventTracking(viewRewardsCollected);
    history.push(pathName);
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: constants.navigation,
      eventAction: constants.dashboard,
      eventLabel: `${eventLabel}`,
    });
  };
  return (
    <Container>
      {rewardsData.map((data, index) => (
        <>
          <HorizontalDivider index={index} />
          <EarnedRewardsWrapper
            key={data.title}
            onClick={() => {
              navigateUserTo(data.path, data.eventName, data.eventLabel);
            }}
          >
            <Wrapper>
              <LeftWrapper>
                <IconCard
                  color={themes.backgroundColor.dodgerBlue}
                  icon={data.icon}
                  image={data.image}
                  height={data.imageHeight}
                  width={data.imageWidth}
                  alt={data.title}
                />
                <TextWrapper>
                  <Title>{data.title}</Title>
                  <Description>{data.desc}</Description>
                </TextWrapper>
              </LeftWrapper>
              {window.innerWidth <= parseInt(themes.breakpoints.mobileLandscape, 10) && (
                <img src={RightArrow} alt='show-more' />
              )}
            </Wrapper>
          </EarnedRewardsWrapper>
        </>
      ))}
    </Container>
  );
}

export default EarnedRewards;
