import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useAuth } from 'provider/auth-provider';
import { useNotifications } from 'provider/notifications-provider';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Language } from '@material-ui/icons';
import { ClickAwayListener } from '@material-ui/core';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as Strings from 'resources/strings';
import themes from 'styles/theme';
import Image from 'components/common/Image/Image';
import * as constants from 'utils/Analytics/flow-constants';
import InteractiveToolTip from '../ToolTip/InteractiveToolTip';
import Logout from './components/Logout/Logout';
import Notifications from './components/Notifications/Notifications';
import NotificationsBadge from './components/Notifications/NotificationBadge';
import { TitleWrapper, RedirectLink, AppbarIcon } from './style';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBarSticky: {
    position: 'sticky',
    top: 0,
    zIndex: 999,
  },
  appBar: {
    backgroundColor: themes.backgroundColor.dodgerBlue,
    boxShadow: 'none',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (min-width: 480px)': {
      padding: '0 80px',
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
    fontWeight: 300,
  },
}));
export default function Header() {
  const classes = useStyles();
  // use setAuth with auth provider to show/hide profile icon
  const { currentUser, logout } = useAuth();
  const { openNotifications, setOpenNotifications } = useNotifications();
  const history = useHistory();
  const { initiateTracking } = useAnalytics();
  const [openProfile, setOpenProfile] = useState(false);

  const headerAnalyticEvents = (eventName, eventLabel) => {
    initiateTracking({
      eventName: `${eventName}`,
      eventCategory: 'global header navigation',
      eventAction: 'header navigation',
      eventLabel: `${eventLabel}`,
    });
  };

  const openLogout = () => {
    headerAnalyticEvents('analytics_profile icon', 'my profile');
    setOpenProfile(!openProfile);
    setOpenNotifications(false);
  };
  const closeTooltips = () => {
    setOpenProfile(false);
    setOpenNotifications(false);
  };
  // TODO: Move this to Menu
  const onLogout = async () => {
    headerAnalyticEvents('analytics_logout', 'logout');
    await logout();
    history.push('/home');
  };

  const appLogoClickEventHandler = () => {
    // disable the logo click event when there
    // are any completed mission to show
    if (currentUser
      && (!currentUser?.completedMissionsRefs
        || currentUser?.completedMissionsRefs?.length === 0)) {
      history.push('/');
      headerAnalyticEvents('analytics_logo', 'adva logo');
    } else {
      history.push('/home');
    }
  };

  const notificationIconClickHandler = () => {
    headerAnalyticEvents('analytics_notifications', 'notifications icon');
    setOpenNotifications(!openNotifications);
    setOpenProfile(false);
  };

  // 'analyticsEventHandler` analytics for website redirection
  const analyticsEventHandler = () => {
    initiateTracking({
      eventName: constants.advaWebsiteAnalytics,
      eventCategory: constants.globalHeaderNavigation,
      eventAction: constants.headerNavigation,
      eventLabel: constants.advaIoWebsite,
    });
  };

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.appBarSticky}>
      <div className={classes.root}>
        <AppBar position='static' className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <TitleWrapper onClick={appLogoClickEventHandler}>
              <AppbarIcon edge='start' color='inherit' aria-label='menu'>
                <Image image='adva_logo.png' width='22px' height='24px' />
              </AppbarIcon>
              <Typography variant='h6' className={classes.title}>
                {Strings.advaMissions}
              </Typography>
            </TitleWrapper>
            <div className={classes.iconWrapper}>
              <RedirectLink target='_blank' rel='noreferrer' href='https://www.adva.io/' onClick={analyticsEventHandler}>
                <AppbarIcon
                  aria-label='about-adva'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  color='inherit'
                >
                  <Language />
                </AppbarIcon>
              </RedirectLink>
              {currentUser && (
                <ClickAwayListener onClickAway={closeTooltips}>
                  <div className={classes.iconWrapper}>
                    <InteractiveToolTip
                      open={openProfile}
                      onClose={closeTooltips}
                      disableFocusListener='true'
                      disableHoverListener='true'
                      title={<Logout phoneNumber={currentUser.phoneNumber} onClick={onLogout} />}
                    >
                      <AppbarIcon
                        aria-label='account of current user'
                        aria-controls='menu-appbar'
                        aria-haspopup='true'
                        onClick={openLogout}
                        color='inherit'
                      >
                        <AccountCircle />
                      </AppbarIcon>
                    </InteractiveToolTip>
                    <InteractiveToolTip
                      open={openNotifications}
                      disableFocusListener='true'
                      disableHoverListener='true'
                      title={
                        <Notifications />
                      }
                    >
                      <AppbarIcon
                        aria-label='notifications of current user'
                        aria-controls='menu-appbar'
                        aria-haspopup='true'
                        color='inherit'
                        onClick={notificationIconClickHandler}
                      >
                        <NotificationsBadge />
                      </AppbarIcon>
                    </InteractiveToolTip>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
}
