import React from 'react';
import PropTypes from 'prop-types';

import * as Strings from 'resources/strings';
import Button from 'components/common/Button/Button';
import MissionsTitle from 'components/common/AllMissionsLayout/MissionsTitle';
import HappySmiley from 'assets/images/happy_smiley.svg';
import * as constants from 'utils/Analytics/flow-constants';
import MissionsFooter from 'components/common/MissionsFooter';
import ResponseState from 'components/common/ResponseState';

import themes from 'styles/theme';
import {
  AllMissionsContainer,
  AllMissionsCardContainer,
  CTAContainer,
  ShowMore,
  ButtonWrapper,
} from './style';

/**
 *
 * @param {Object} missionsData
 * @param {Object} history
 * @param {Object} params
 * @param {function} showMoreHandler
 * @param {Array} missionCategories
 * @param {Number} cardsCount
 * @param {bool} showMore
 * `ViewMissions` component is used to render all the missions.
 */
const ViewMissions = (props) => {
  const {
    children,
    missionsData,
    history,
    showMoreHandler,
    cardsCount,
    showMore,
    analyticsEventHandler,
    missionTitle,
    countLabel,
    emptyStateImage,
    emptyStateText,
    analyticsEventActionName,
  } = props;

  /// `goToDashboard` is used to redirect to the dashboard screen
  const goToDashboard = () => {
    analyticsEventHandler({
      eventName: constants.backToDashboardAnalytics,
      eventCategory: constants.navigation,
      eventLabel: Strings.goToDashboard,
    });
    history.push('/');
  };

  return (
    <>
      <MissionsTitle
        showMore={missionsData?.showMore}
        missionTitle={missionTitle}
        analyticsEventActionName={analyticsEventActionName}
      />
      <AllMissionsContainer>
        {missionsData?.splicedMissionData?.length > 0 ? (
          <div>
            <AllMissionsCardContainer showMore={showMore}>
              {children}
            </AllMissionsCardContainer>
            {!missionsData?.showMore
              && missionsData?.missionData?.length > cardsCount && (
                <CTAContainer>
                  <ShowMore onClick={showMoreHandler}>
                    {Strings.showMore}
                  </ShowMore>
                </CTAContainer>
            )}
            {window.innerWidth <= parseInt(themes.breakpoints.mobileLandscape, 10) ? (
              <ButtonWrapper showMore={missionsData?.showMore}>
                <Button
                  title={Strings.goToDashboard}
                  onClick={() => goToDashboard()}
                />
              </ButtonWrapper>
            ) : (
              <MissionsFooter
                dataLength={missionsData?.missionData?.length}
                countLabel={countLabel}
                goToDashboard={goToDashboard}
              />
            )}
          </div>
        ) : (
          <ResponseState
            image={emptyStateImage}
            description={emptyStateText}
            buttonText={Strings.goToDashboard}
            onClick={() => goToDashboard()}
            isEmptyState
          />
        )}
      </AllMissionsContainer>
    </>
  );
};

ViewMissions.propTypes = {
  children: PropTypes.node.isRequired,
  missionsData: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  showMoreHandler: PropTypes.func,
  cardsCount: PropTypes.number,
  showMore: PropTypes.bool,
  analyticsEventHandler: () => { },
  missionTitle: PropTypes.string,
  countLabel: PropTypes.string,
  emptyStateText: PropTypes.string,
  emptyStateImage: PropTypes.string,
  analyticsEventActionName: PropTypes.string,
};

ViewMissions.defaultProps = {
  missionsData: {
    missionData: [],
    splicedMissionData: [],
    showMore: false,
  },
  history: {},
  showMoreHandler: () => { },
  cardsCount: 0,
  showMore: false,
  missionTitle: '',
  countLabel: Strings.missions.toLowerCase(),
  emptyStateText: Strings.missionsEmptyText,
  emptyStateImage: HappySmiley,
  analyticsEventActionName: '',
};

export default ViewMissions;
