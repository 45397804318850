import styled from 'styled-components';

export const WalletTitle = styled.div`
font-weight: 400;
font-size: ${(props) => props.theme.fontSize.description};
line-height: 15px;
`;

export const Points = styled.div`
font-weight: 400;
font-size: ${(props) => props.theme.fontSize.medium};
margin: 5px 0 10px 0;
`;

export const EcopointsList = styled.div`
   text-align: center;
   display: flex;
   justify-content: space-between;
`;

export const IconStatus = styled.div`
  font-size: 15px;
  position: absolute;
  display: inline-block;
`;
