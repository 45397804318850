import React from 'react';

import ReferralRewardContainer from 'components/containers/ReferralReward';
import BaseLayout from 'components/common/BaseLayout/BaseLayout';

/// [ReferralReward] to show the referral reward earned by the user
const ReferralReward = () => (
  <BaseLayout showPoster>
    <ReferralRewardContainer />
  </BaseLayout>
);

export default ReferralReward;
