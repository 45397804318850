import React from 'react';

import { processingText, refreshText } from 'resources/strings';
import {
  Wrapper,
  CircularLoader,
  ProcessingText,
  ProcessingTextWrapper,
} from './style';

/// `PaymentProcessingLanding` renders UI for payment processing screen
const PaymentProcessingLanding = () => (
  <Wrapper>
    <CircularLoader />
    <ProcessingTextWrapper>
      <ProcessingText>{processingText}</ProcessingText>
      <ProcessingText>{refreshText}</ProcessingText>
    </ProcessingTextWrapper>
  </Wrapper>
);

export default PaymentProcessingLanding;
