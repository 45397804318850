import React, { useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import MissionBlogs from 'components/common/MissionBlogs';
import { useAllMissions } from 'provider/dashboard-provider';
import Loader from 'components/common/Loader/Loader';
import Share from 'components/common/Share';
import themes from 'styles/theme';
import Button from 'components/common/Button/Button';
import { goToDashboard, blogShareText, blogShareTitle } from 'resources/strings';
import { useAnalytics } from 'helpers/Analytics/Analytics';
import * as constants from 'utils/Analytics/flow-constants';
import BlogAuthor from '../BlogAuthor';
import {
  BlogWrapper,
  MissionBlogWrapper,
  MissionTitleWrapper,
  SecondaryButtonWrapper,
  MissionTitle,
  BlogContent,
  BlogSectionWrapper,
} from './style';

/**
 * `MissionBlogLanding` used to display the mission blog details
 * @returns JSX
 */
const MissionBlogLanding = () => {
  const {
    isBlogsLoading,
    selectedBlog,
    getMissionBlogById,
    isAllBlogsLoading,
    missionBlogs,
  } = useAllMissions();
  const { initiateTracking } = useAnalytics();
  const history = useHistory();
  const params = useParams();
  useEffect(() => {
    getMissionBlogById(`/${params.blogId}`);
  }, [params.blogId]);

  /// `shareBlogs` share the blog post
  const shareBlogsEvent = (blogName) => {
    initiateTracking({
      eventName: `${constants.analytics}_${constants.share}`,
      eventCategory: constants.visitorAction,
      eventAction: constants.blogs,
      eventLabel: `${constants.share}_${blogName.replaceAll('-', ' ')}`,
    });
  };

  /// `goToDashboard` is used to redirect to the dashboard screen
  const redirectToDashboard = () => {
    initiateTracking({
      eventName: constants.backToDashboardAnalytics,
      eventCategory: constants.navigation,
      eventAction: constants.blogs,
      eventLabel: goToDashboard,
    });
    history.push('/');
  };

  return isBlogsLoading || isAllBlogsLoading ? <Loader /> : (
    <div>
      <BlogWrapper src={selectedBlog.imageUrl ?? ''} alt='blog-poster' />
      <MissionBlogWrapper>
        <MissionTitleWrapper>
          {/* eslint-disable-next-line */}
          <MissionTitle dangerouslySetInnerHTML={{ __html: selectedBlog?.title ?? '' }} />
          <Share
            color={themes.backgroundColor.dodgerBlue}
            title={blogShareTitle}
            text={blogShareText}
            url={`${process.env.REACT_APP_WEBSITE_URL}blogs/${params.blogId}`}
            analyticsEvent={() => shareBlogsEvent(params.blogId)}
          />
        </MissionTitleWrapper>
        {/* eslint-disable-next-line */}
        <BlogContent dangerouslySetInnerHTML={{ __html: selectedBlog?.content ?? '' }} />
        <BlogAuthor
          authorBio={selectedBlog.authorBio}
          authorImage={selectedBlog.authorImageUrl}
          authorName={selectedBlog.authorName}
        />
      </MissionBlogWrapper>
      {missionBlogs?.blogs?.length > 0
        && (
          <BlogSectionWrapper>
            <MissionBlogs missionBlogs={missionBlogs} />
          </BlogSectionWrapper>
        )}
      <SecondaryButtonWrapper>
        <Button
          title={goToDashboard}
          onClick={() => redirectToDashboard()}
        />
      </SecondaryButtonWrapper>
    </div>
  );
};

export default MissionBlogLanding;
