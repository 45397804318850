import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import ArrowRight from 'assets/images/right-chevron-white.svg';
import ArrowLeft from 'assets/images/left-chevron-white.svg';

import themes from 'styles/theme';
import CarouselDots from 'components/common/CarouselDots';

import {
  ScrollWrapper,
  LeftArrow,
  RightArrow,
  Image,
  ImageWrapper,
  CarouselWrapper,
} from './style';

/**
 *
 * @param {Node} children
 * @param {Object} missionsData
 * @param {Number} cardWidth
 * @param {bool} showChevron
 * `Carousel` common carousel for Dot mode slider and Show / hide arrows.
 */

const Carousel = ({
  children,
  missionsData,
  cardWidth,
  showChevron,
}) => {
  const scrollWrapperRef = useRef();
  const [sliderWidth, setSliderWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [disableLeftArrow, setDisableLeftArrow] = useState(true);
  const [disableRightArrow, setDisableRightArrow] = useState(false);

  useEffect(() => {
    // To get the available scroll width
    const scrollWidth = scrollWrapperRef?.current?.scrollWidth
    - scrollWrapperRef?.current?.offsetWidth;
    setSliderWidth(scrollWidth);

    // To disable the chevron arrows if the section is not scrollable
    // For Example: if there is only one card in the section then the
    // section will not scrollable
    if (scrollWidth === 0) {
      setDisableLeftArrow(true);
      setDisableRightArrow(true);
    }
  }, [scrollWrapperRef?.current?.offsetWidth]);

  // `onHorizontalscroll` to enable/disable the chevron arrows
  // and to highlight the dots
  const onHorizontalscroll = () => {
    // To get the average width
    const minWidth = sliderWidth / Object.keys(missionsData).length;
    // To get the current index of the card based on the width covered
    const index = Math.round(scrollWrapperRef.current.scrollLeft / minWidth);
    // Inorder to hightlight the first card if its less than 0
    if (index <= 0) {
      setCurrentIndex(1);
    } else {
      setCurrentIndex(index);
    }

    // to check if there is available space to scroll on left
    // inorder to scroll left
    if (scrollWrapperRef.current.scrollLeft > 0) {
      setDisableLeftArrow(false);
    } else {
      setDisableLeftArrow(true);
    }

    // to check if there is available space to scroll on right
    // by comparing the slider width
    if (Math.round(scrollWrapperRef.current.scrollLeft) < sliderWidth) {
      setDisableRightArrow(false);
    } else {
      setDisableRightArrow(true);
    }
  };

  // `scroll` to scroll the slider to the left/right
  const scroll = (scrollOffset) => {
    // Add/Subract the scrollLeft inorder to scroll left/right
    scrollWrapperRef.current.scrollLeft += scrollOffset;
  };

  return (
    <CarouselWrapper>
      <ScrollWrapper
        ref={scrollWrapperRef}
        onScroll={() => onHorizontalscroll()}
      >
        {children}
      </ScrollWrapper>
      {window.innerWidth < parseInt(themes.breakpoints.tablet, 10) && (
        <CarouselDots missionsData={missionsData} currentIndex={currentIndex} />
      )}
      {window.innerWidth >= parseInt(themes.breakpoints.tablet, 10) && showChevron && (
        <>
          <LeftArrow isDisabled={disableLeftArrow} onClick={() => scroll(-cardWidth)}>
            <ImageWrapper>
              <Image src={ArrowLeft} />
            </ImageWrapper>
          </LeftArrow>
          <RightArrow isDisabled={disableRightArrow} onClick={() => scroll(cardWidth)}>
            <ImageWrapper>
              <Image src={ArrowRight} />
            </ImageWrapper>
          </RightArrow>
        </>
      )}
    </CarouselWrapper>
  );
};
export default Carousel;

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  missionsData: PropTypes.shape(PropTypes.any),
  cardWidth: PropTypes.number,
  showChevron: PropTypes.bool,
};

Carousel.defaultProps = {
  missionsData: {},
  cardWidth: 0,
  showChevron: false,
};
