import styled from 'styled-components';

import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.dodgerBlue};
  color: ${(props) => props.theme.fontColor.white};
  border-radius: 0px 0px 20px 20px;
  padding-top: 24px;
  height: 142px;
  position: relative;
`;

export const Title = styled(Typography)`
  && {
    margin: 0 25px 24px 80px;
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: 28px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      font-size: ${(props) => props.theme.fontSize.smMedium};
      text-align: center;
      margin: 0 37px;
    }
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  bottom: -2px;
  left: 0px;
  opacity: 0.1;
  width: 100%;
`;

export const FeaturedMissionsWrapper = styled.div`
  margin-top: 12px;
`;
