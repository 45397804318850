import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import { showMore } from 'resources/strings';
import {
  showMoreTestimonialsAnalytics,
  visitorAction,
  customerTestimonials,
  closePopup,
  closeTestimonialsAnalytics,
} from 'utils/Analytics/flow-constants';
import { initialBackgroundColors } from 'utils/Missions/flow-constants';
import CustomerRatingChip from 'components/pages/Missions/components/CustomerRatingChip';
import CustomerRatingPopup from 'components/pages/Missions/components/CustomerRatingPopup';

import {
  Wrapper,
  InitialWrapper,
  UserName,
  Comments,
  UserInitial,
  Link,
  FlexWrapper,
} from './style';

/**
 *
 * @param {string} name
 * @param {string} comment
 * @param {number} rating
 * @param {boolean} isFirstChild
 * `CustomerRatingCard` renders the UI for custom review card
 */

const CustomerRatingCard = ({
  name,
  comment,
  rating,
  isFirstChild,
  hideShowMore,
}) => {
  const { initiateTracking } = useAnalytics();
  const [isDialogOpen, setDialogOpen] = useState(false);

  // `getBackgroundColor` to get the random background color
  // inorder to show random background colors for initials for user
  const getBackgroundColor = () => {
    const index = Math.floor(Math.random() * initialBackgroundColors.length);
    return initialBackgroundColors[index];
  };

  // `showPopup` To show popup
  const showPopup = () => {
    setDialogOpen(true);
    // analytics for show more
    initiateTracking({
      eventName: showMoreTestimonialsAnalytics,
      eventCategory: visitorAction,
      eventAction: customerTestimonials,
      eventLabel: showMore,
    });
  };

  const hidePopup = () => {
    setDialogOpen(false);
    // analytics for hiding the popup
    initiateTracking({
      eventName: closeTestimonialsAnalytics,
      eventCategory: visitorAction,
      eventAction: customerTestimonials,
      eventLabel: closePopup,
    });
  };

  return (
    <Wrapper isFirstChild={isFirstChild}>
      <FlexWrapper>
        <FlexWrapper>
          <InitialWrapper backgroundColor={getBackgroundColor()}>
            <UserInitial>{name[0]}</UserInitial>
          </InitialWrapper>
          <UserName>{name}</UserName>
        </FlexWrapper>
        <CustomerRatingChip rating={rating} />
      </FlexWrapper>
      <Comments>{comment}</Comments>
      {comment.length > 140 && !hideShowMore && (
        <Link onClick={() => showPopup()}>{showMore}</Link>
      )}
      <CustomerRatingPopup
        isDialogOpen={isDialogOpen}
        handleClose={() => hidePopup()}
        name={name}
        comment={comment}
        rating={rating}
      />
    </Wrapper>
  );
};

CustomerRatingCard.propTypes = {
  name: PropTypes.string,
  comment: PropTypes.string,
  rating: PropTypes.number,
  isFirstChild: PropTypes.bool,
  hideShowMore: PropTypes.bool,
};

CustomerRatingCard.defaultProps = {
  name: '',
  comment: '',
  rating: 0,
  isFirstChild: false,
  hideShowMore: false,
};
export default CustomerRatingCard;
