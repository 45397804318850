import React from 'react';
import PropTypes from 'prop-types';

import * as Strings from 'resources/strings';
import HappySmiley from 'assets/images/happy_smiley.svg';
import Button from 'components/common/Button/Button';
import Spacer from 'components/common/Spacer/Spacer';
import Markdown from 'components/common/Markdown';

import {
  Wrapper,
  GreetingsMessage,
  Description,
  ImageWrapper,
  Image,
  ButtonWrapper,
  EmailLink,
} from './style';

/// [ResponseState] to show reward/error-states
const ResponseState = ({
  greetingsMessage,
  subtitle,
  description,
  image,
  buttonText,
  onClick,
  isButtonLoading,
  isEmptyState,
  emailLink,
}) => (
  <Wrapper>
    <GreetingsMessage>{greetingsMessage}</GreetingsMessage>
    <Spacer height='8px' />
    <Description>{subtitle}</Description>
    <ImageWrapper>
      <Image src={image} />
    </ImageWrapper>
    <Description isEmptyState={isEmptyState}>
      <Markdown content={description} />
      {emailLink && <EmailLink href={`mailto:${emailLink}`}>{emailLink}</EmailLink>}
    </Description>
    <ButtonWrapper isEmptyState={isEmptyState}>
      <Button
        title={buttonText}
        onClick={onClick}
        isLoading={isButtonLoading}
      />
    </ButtonWrapper>
  </Wrapper>
);

export default ResponseState;

ResponseState.propTypes = {
  greetingsMessage: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  isButtonLoading: PropTypes.bool,
  isEmptyState: PropTypes.bool,
  emailLink: PropTypes.string,
};

ResponseState.defaultProps = {
  greetingsMessage: '',
  subtitle: '',
  emailLink: '',
  description: Strings.referralErrorText,
  image: HappySmiley,
  buttonText: Strings.getStartedLabel,
  onClick: () => { },
  isButtonLoading: false,
  isEmptyState: false,
};
