import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useAnalytics } from 'helpers/Analytics/Analytics';
import {
  missionTabsAnalytics,
  visitorAction,
  missionTabs,
} from 'utils/Analytics/flow-constants';
import Markdown from 'components/common/Markdown';
import Spacer from 'components/common/Spacer/Spacer';
import Tabs from 'components/common/Tabs';
import MissionHealthBenefits from 'components/pages/Missions/components/MissionHealthBenefits';
import UserStatus from 'components/pages/Dashboard/components/UserStatus';
import { missionTabsTitle } from 'utils/Missions/flow-constants';
import { ActivityInfoDesc, UserStatusWrapper, Wrapper } from './style';

// `MissionTabsPanel` renders the mission details in tabs
const MissionTabsPanel = ({ missionsData, activityMetrics }) => {
  const [metricsData, setMetricsData] = useState([]);
  const { initiateTracking } = useAnalytics();

  useEffect(() => {
    if (activityMetrics != null) {
      const metricsObj = {};
      // 'metricsData' To get the required metrics data
      activityMetrics.forEach((metrics) => {
        metricsObj[metrics.sustainability_metric.metricKey] = metrics.value;
      });
      setMetricsData(metricsObj);
    }
  }, [Object.keys(activityMetrics).length]);

  // Analytics for tabs
  const analyticsEventHandler = (tabName) => {
    initiateTracking({
      eventName: missionTabsAnalytics,
      eventCategory: visitorAction,
      eventAction: missionTabs,
      eventLabel: tabName,
    });
  };
  return (
    <Wrapper>
      <Tabs
        tabs={missionTabsTitle}
        analyticsEventHandler={analyticsEventHandler}
      >
        <div>
          <ActivityInfoDesc>
            <Markdown content={missionsData?.content ?? ''} />
          </ActivityInfoDesc>
          <Spacer height='16px' />
          <MissionHealthBenefits
            healthBenefits={missionsData?.healthBenefits}
          />
        </div>
        <div>
          <ActivityInfoDesc>
            <Markdown content={missionsData?.impactOfMission} />
          </ActivityInfoDesc>
          <UserStatusWrapper>
            <UserStatus userStats={metricsData} />
          </UserStatusWrapper>
        </div>
        <ActivityInfoDesc>
          <Markdown content={missionsData?.stepsToComplete} />
        </ActivityInfoDesc>
      </Tabs>
    </Wrapper>
  );
};

export default MissionTabsPanel;

MissionTabsPanel.propTypes = {
  missionsData: PropTypes.shape({
    content: PropTypes.string,
    impactOfMission: PropTypes.string,
    stepsToComplete: PropTypes.string,
    healthBenefits: PropTypes.arrayOf(PropTypes.string),
    alternateDescription: PropTypes.string,
  }),
  activityMetrics: PropTypes.objectOf(PropTypes.any),
};

MissionTabsPanel.defaultProps = {
  missionsData: {
    content: '',
    impactOfMission: '',
    stepsToComplete: '',
    healthBenefits: [],
    alternateDescription: '',
  },
  activityMetrics: {},
};
