import Dashboard from 'components/pages/Dashboard';
import MissionReward from 'components/pages/MissionReward';
import Missions from 'components/pages/Missions/Missions';
import EarnedReward from 'components/pages/EarnedRewards';
import FileUpload from 'components/pages/FileUploader/FileUploader';
import CarbonSavingLanding from 'components/pages/CarbonSaving';
import PageNotFound from 'components/pages/PageNotFound';
import VerifyMissionProgress from 'components/pages/VerifyMissionProgress';
import UploadBill from 'components/pages/UploadBill';
import SplashScreen from 'components/pages/SplashScreen';
import AllCategories from 'components/pages/AllCategories';
import AllMissions from 'components/pages/AllMissions';
import PreLoginDashboard from 'components/pages/PreLoginDashboard';
import AboutMissions from 'components/pages/AboutMissions';
import ReferralReward from 'components/pages/ReferralReward';
import ReferralLink from 'components/pages/ReferralLink';
import PaymentProcessing from 'components/pages/PaymentProcessing';
import PaymentFailed from 'components/pages/PaymentFailed';
import AllMissionBlogs from 'components/pages/AllMissionBlogs';
import MissionBlog from 'components/pages/MissionBlog';

const routeConfigs = [
  {
    key: 'DASHBOARD',
    path: '/',
    exact: true,
    auth: true,
    component: Dashboard,
  },
  {
    key: 'PRE-LOGIN-DASHBOARD',
    path: '/home',
    exact: true,
    auth: false,
    component: PreLoginDashboard,
  },
  {
    key: 'FILE-UPLOAD',
    path: '/fileupload',
    exact: true,
    auth: true,
    component: FileUpload,
  },
  {
    key: 'MISSIONS-LANDING',
    path: '/missions/:missionId',
    exact: true,
    auth: false,
    component: Missions,
  },
  {
    key: 'MISSIONS-PROGRESS_LANDING',
    path: '/missions/:missionId/progress',
    exact: true,
    auth: true,
    component: Missions,
  },
  {
    key: 'CLAIM-REWARD',
    path: '/missions/:missionId/mission-reward',
    exact: true,
    auth: true,
    component: MissionReward,
  },
  {
    key: 'CARBON-SAVINGS',
    path: '/carbon-savings',
    exact: true,
    auth: true,
    component: CarbonSavingLanding,
  },
  {
    key: 'ABOUT-ADVA-MISSIONS ',
    path: '/about-adva-missions',
    exact: true,
    auth: true,
    component: AboutMissions,
  },
  {
    key: 'PAYMENT-PROCESSING',
    path: '/payment-processing',
    exact: true,
    auth: true,
    component: PaymentProcessing,
  },
  {
    key: 'PAYMENT-FAILED',
    path: '/payment-failed',
    exact: true,
    auth: true,
    component: PaymentFailed,
  },
  {
    key: 'EARNED-REWARD',
    path: '/rewards-collected',
    exact: true,
    auth: true,
    component: EarnedReward,
  },
  {
    key: 'Verify-Mission-Progress',
    path: '/missions/:missionid/progress/verify-staffpin',
    exact: true,
    auth: true,
    component: VerifyMissionProgress,
  },
  {
    key: 'Preview-Bill',
    path: '/missions/:missionid/progress/preview-bill',
    exact: true,
    auth: true,
    component: UploadBill,
  },
  {
    key: 'REFER-A-FRIEND',
    path: '/refer-a-friend',
    exact: true,
    auth: true,
    component: ReferralLink,
  },
  {
    key: 'Splash-Screen',
    path: '/splash-screen',
    exact: true,
    auth: true,
    component: SplashScreen,
  },
  {
    key: 'All-Categories',
    path: '/all-categories',
    exact: true,
    auth: false,
    component: AllCategories,
  },
  {
    key: 'All-Missions',
    path: '/all-categories/:categoryname',
    exact: true,
    auth: false,
    component: AllMissions,
  },
  {
    key: 'Referral Reward',
    path: '/referral-reward',
    exact: true,
    auth: true,
    component: ReferralReward,
  },
  {
    key: 'ALL-MISSION-BLOGS',
    path: '/learn-more-about-sustainability',
    exact: true,
    auth: false,
    component: AllMissionBlogs,
  },
  {
    key: 'MISSION-BLOGS-POST',
    path: '/blogs/:blogId',
    exact: true,
    auth: false,
    component: MissionBlog,
  },
  {
    key: 'Page-Not-Found',
    path: '*',
    exact: true,
    auth: true,
    component: PageNotFound,
  },
];
export default routeConfigs;
