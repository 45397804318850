import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as constants from 'utils/global-constants';
import AllMissionsCard from 'components/common/AllMissionsCard';

import {
  CategoryTitle,
  MissionCount,
  Wrapper,
  ListDivider,
  TitleWrapper,
} from './style';

/// [MissionCategory] shows the particular mission category with two missions card
const MissionCategory = ({ index, categoryName, missions }) => {
  const [missionsData, setMissionsData] = useState([]);

  useEffect(() => {
    let missionsObj = [...missions];
    // To show only two missions in the mission category card
    if (missionsObj.length > 2) {
      missionsObj = [...missionsObj.splice(0, 2)];
    }
    setMissionsData(missionsObj);
  }, []);

  return (
    <Wrapper isfirstChild={index === 0}>
      <TitleWrapper>
        <CategoryTitle>{categoryName}</CategoryTitle>
        <MissionCount>
          {missions.length}
          {' '}
          {constants.missions}
        </MissionCount>
      </TitleWrapper>
      {missionsData?.map((data, key) => (
        <>
          <AllMissionsCard
            missionId={data.mission?.id ?? ''}
            title={data.mission?.title ?? ''}
            missionImage={data.mission?.missionImage?.url}
            rewardImage={data.mission?.missionReward?.rewardImage?.url ?? ''}
            rewardName={data.mission?.missionReward?.name ?? ''}
            missionCategory={data.mission?.missionsCategory?.name}
          />
          {missionsData.length !== key + 1 && <ListDivider />}
        </>
      ))}
    </Wrapper>
  );
};
export default MissionCategory;

MissionCategory.propTypes = {
  index: PropTypes.number,
  categoryName: PropTypes.string,
  missions: PropTypes.arrayOf(PropTypes.any),
};

MissionCategory.defaultProps = {
  index: 0,
  categoryName: 'Sustainble Fashion',
  missions: [],
};

MissionCategory.defaultProps = {
  index: 0,
};
