import React from 'react';
import PropTypes from 'prop-types';

import Trophy from 'assets/images/trophy-icon.svg';

import Spacer from 'components/common/Spacer/Spacer';

import {
  RewardDetailsCard,
  Image,
  CompleteMissionDiv,
  TrophyIcon,
  MissionDetails,
  RewardDesc,
  ImageWrapper,
  Offer,
  RewardImageWrapper,
} from './style';

const RewardCard = ({
  isMissionComplete,
  missionReward,
  verificationMechanism,
  height,
}) => (
  <RewardDetailsCard height={height} isMissionComplete={isMissionComplete}>
    {missionReward.rewardImage?.url && (
      <RewardImageWrapper>
        <Image
          src={missionReward.rewardImage?.url}
          title='mission-image'
          component='img'
        />
      </RewardImageWrapper>
    )}
    <ImageWrapper>
      <TrophyIcon src={Trophy} />
    </ImageWrapper>
    <MissionDetails>
      <CompleteMissionDiv>
        <RewardDesc variant='body2'>
          {verificationMechanism === 'promoCode'
            ? 'Use the coupon below to enjoy an exclusive discount'
            : 'Complete this mission to unlock'}
        </RewardDesc>
      </CompleteMissionDiv>
      <Offer variant='h6'>{missionReward.name}</Offer>
      {verificationMechanism === 'promoCode' ? (
        <RewardDesc>on select categories</RewardDesc>
      ) : (
        <Spacer height='12px' />
      )}
    </MissionDetails>
  </RewardDetailsCard>
);

export default RewardCard;

RewardCard.propTypes = {
  isMissionComplete: PropTypes.bool,
  missionReward: PropTypes.objectOf(PropTypes.any),
  verificationMechanism: PropTypes.string,
  height: PropTypes.string,
};
