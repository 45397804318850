import styled from 'styled-components';

import { Typography } from '@material-ui/core';

import PrimaryButton from 'components/common/Button/style';

export const Wrapper = styled.div`
  width: 100%;
  height: 128px;
  bottom: 0;
  left: 0;
  background-color: ${(props) => (props.theme.backgroundColor.selago)}
`;

export const Title = styled(Typography)`
  && {
    padding-top: 20px;
    font-size: ${(props) => props.theme.fontSize.primary};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    line-height: 20px;
    text-align: center;
    margin: 0 25px 11px 25px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${PrimaryButton} {
    max-width: 327px;
    background-color: ${(props) => (props.theme.backgroundColor.white)} !important;
    border: 1px solid ${(props) => (props.theme.border.dodgerBlue)};
    color: ${(props) => (props.theme.border.dodgerBlue)};
    font-weight: ${(props) => (props.theme.fontWeight.medium)};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 11px 24px 27px 24px;
  }
  @media (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    margin: 0 22px;
  }
`;
