import styled from 'styled-components';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

export const StyledDatePicker = styled(KeyboardDatePicker)`
  && {
    border-radius: 8px;
    padding: 8px;
    max-width: 100%;
    margin: 0 20px;
    border: 1px solid ${(props) => props.theme.border.blackwithOpacity};
    font-size: ${(props) => props.theme.fontSize.primary};
   }
`;
export const DatePickerProvider = styled(MuiPickersUtilsProvider)`
  && {
    padding: 18px;
   }
`;

export default StyledDatePicker;
