import { get } from 'services/api-service';
import { firebaseGet, firebasePost } from 'services/firebase-api-service';
import { post } from 'services/adva-server-service';
import { getCollection, getSubCollectionWithFilters } from 'services/firebase-service';

import { db } from 'config/firebase-service';

// `getMissionDetails` which returns the particular mission data from strapi
export const getMissionDetails = async (missionId) => {
  try {
    const response = await get(`missions/${missionId}`, {});
    return response;
  } catch (error) {
    return error;
  }
};

// `checkUserMission` used to check whether the user already started the mission
export const checkUserMission = async (userId, missionId) => {
  try {
    const existingUserMissionId = await firebaseGet({ url: `checkOngoingUserMission/${userId}/verify-mission/${missionId}` });
    return existingUserMissionId;
  } catch (error) {
    return error;
  }
};

// `createUserMission` used to create a new userMission
export const createUserMission = async (userId, currentMission) => {
  try {
    const response = await firebasePost(
      `createUserMission/${userId}`,
      currentMission,
    );
    return response;
  } catch (error) {
    return error;
  }
};

// `getUserMission` used to fetch current user mission acitivity
export const getUserMission = async (userId, userMissionId) => {
  try {
    const response = await getCollection(
      'mUsers',
      userId,
      'mUserMissions',
      userMissionId,
    );
    return response;
  } catch (error) {
    return error;
  }
};
// `claimReward` used to add userRewards if the user completes the mission successfully
export const addUserReward = async (userId, missionId) => {
  try {
    const response = await firebaseGet({ url: `claimReward/${userId}?userMissionId=${missionId}` });
    return response.data;
  } catch (error) {
    return error;
  }
};

// `getUserActivities` used to fetch MUSer Activities
export const getUserActivities = (userId, activities) => {
  try {
    return getSubCollectionWithFilters(
      'mUsers',
      userId,
      'mUserActivities',
      activities,
      'id',
    );
  } catch (error) {
    return error;
  }
};

// `getUserMissions` To fetch the user missions data of the current user
export const getUserMissions = async (
  collection,
  userId,
  subCollection,
  whereFieldIn,
  whereField,
) => {
  const response = await db
    .collection(collection)
    .doc(userId)
    .collection(subCollection)
    .where(whereField, 'in', whereFieldIn)
    .get();
  return response;
};

// `getAllMissions` To fetch all the missions data from strapi
export const getAllMissions = async () => {
  try {
    const response = await get('missions', {});
    return response;
  } catch (error) {
    return error;
  }
};

// `verifyStaffPin` posts the otp entered by staff to verify mission progress for the user
export const verifyStaffPin = async (otp, userId, userMissionId) => {
  try {
    const response = await firebasePost(
      `verifyPin/${userId}/${userMissionId}`,
      { verificationCode: otp },
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const cancelUserMission = async (userId, userMissionId) => {
  try {
    const response = await firebaseGet({ url: `cancelMission/${userId}?missionId=${userMissionId}` });
    return response;
  } catch (error) {
    return error;
  }
};

// `getPromoCode` to fetch the promo code
export const getPromoCode = async (rewardId) => {
  try {
    const response = await get(`promo-codes/reward/${rewardId}`, {});
    return response;
  } catch (error) {
    return error;
  }
};

// `scheduleMissionReminder` posts the date which user selected to schedule a sms reminder
export const scheduleMissionReminder = async (
  userId,
  phoneNumber,
  selectedUserMissionId,
  scheduledDate,
  mission,
) => {
  try {
    const response = await firebasePost(
      `notifications/createScheduleReminder/${userId}`,
      {
        userMissionId: selectedUserMissionId,
        date: scheduledDate,
        userPhoneNumber: phoneNumber,
        missionId: mission,
      },
    );
    return response;
  } catch (error) {
    return error;
  }
};

// 'getLinkedMission' to get linked missions based on the mission id
export const getLinkedMission = async (missionId) => {
  try {
    // concatenate the array of mission ids
    const missionIds = missionId.map((id) => `missions.id=${id}`).join('&');
    const response = await get(`linked-missions?${missionIds}`, {});
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (error) {
    return error;
  }
};

// `getFeaturedMissions` to get all the featured missions
export const getFeaturedMissions = async () => {
  try {
    const response = await get('/missions?featured=yes', {});
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (error) {
    // if it has error pass empty array
    console.error(error);
    return [];
  }
};

// `getMissionCategories` to get the missions categories in ascending order
export const getMissionCategories = async () => {
  try {
    const response = await get('/missions-categories?_sort=order:ASC', {});
    return response.data;
  } catch (error) {
    // if it has error return empty array
    console.error(error);
    return [];
  }
};

// `getAboutMissions` to get the about missions data
export const getAboutMissions = async () => {
  try {
    const response = await get('/about-adva-missions?_sort=id:ASC', {});
    return response.data;
  } catch (error) {
    // if it has error return empty array
    console.error(error);
    return [];
  }
};

/// `productPaymentVerification` API to check the product payment status
export const productPaymentVerification = async (userId, currentMission) => {
  try {
    const response = await post(`/user-missions/${userId}/complete-mission`, currentMission);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// `getAllMissionBlogs` used to get all the blog post
export const getAllMissionBlogs = async () => {
  try {
    const blogs = await firebaseGet({ base: process.env.REACT_APP_OLAM_SERVER_URL, url: 'getBlogData' });
    return blogs;
  } catch (error) {
    console.error(error);
    return {};
  }
};

// `getBlogData` used to get particular blog post
export const getParticularBlogData = async (id) => {
  try {
    const blogData = await firebaseGet({ base: process.env.REACT_APP_OLAM_SERVER_URL, url: `getBlogData?id=${id}` });
    return blogData;
  } catch (error) {
    console.error(error);
    return {};
  }
};
