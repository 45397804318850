import { get } from 'services/api-service';
import { firebasePatch } from '../firebase-api-service';

// `getEcoPointsToottipContent` used to get the ecopoints toolTip content from Strapi
export const getEcoPointsTooltipContent = async () => {
  try {
    const response = await get('eco-points-conversion-rate', {});
    return response.data.conversionDescription;
  } catch (error) {
    return error;
  }
};

// `redeemEcoPoints` used to update the ecopoints of the user
export const redeemEcoPoints = async (userId) => {
  try {
    const response = await firebasePatch(`ecoPoints/redeem/${userId}`, {});
    return response;
  } catch (error) {
    return error;
  }
};
