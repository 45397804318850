import { DetailsTitle, ImpactTitle, StepsTitle } from 'resources/strings';
import themes from 'styles/theme';

// This file contains constants for missions

const high = 'high';

export const missionTabsTitle = [DetailsTitle, ImpactTitle, StepsTitle];
export const initialBackgroundColors = [
  themes.backgroundColor.selago,
  themes.backgroundColor.titanWhite,
  themes.backgroundColor.linen,
  themes.backgroundColor.blackSqueeze,
  themes.backgroundColor.fantasy,
  themes.backgroundColor.carbonGrey,
];

export default high;
