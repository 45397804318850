import axios from 'axios';

import { firebaseService } from 'config/firebase-service';

// Adva Server Base Url
const baseURL = process.env.REACT_APP_ADVA_SERVER_URL;
const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
};

/* Axios Instance */
const axiosInstance = axios.create({
  baseURL,
  headers,
});

/* Request and Response Interceptor */
axiosInstance.interceptors.request.use(
  async (request) => {
    const fsService = firebaseService.getService();
    const token = await fsService.getToken();
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => Promise.reject(error.response.data),
);

/* GET, POST, PUT, DELETE Calls */
export const get = (url, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.get(apiURL, config ?? {});
};

export const post = (url, data, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.post(apiURL, data ?? {}, config ?? {});
};

export const put = (url, data, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.put(apiURL, data ?? {}, config ?? {});
};

export const del = (url, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.delete(apiURL, config ?? {});
};
