import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackBarText from './style';

/**
 * Commom SnackBar component
 * @param {String} copySuccessMessage
 * @param {boolean} showMessage
 * @param {function} onHandleClose
 * @returns
 */

const useStyles = makeStyles(() => ({
  closeButton: {
    opacity: '60%',
  },
}));

const SnackBar = ({ copySuccessMessage, handleClose }) => {
  const classes = useStyles();

  return (
    <Snackbar
      open
      autoHideDuration={3000}
      onClose={handleClose}
      message={copySuccessMessage}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      action={(
        <>
          <SnackBarText onClick={handleClose}>OKAY</SnackBarText>
          <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
            <CloseIcon fontSize='small' className={classes.closeButton} />
          </IconButton>
        </>
      )}
    />
  );
};

export default SnackBar;

SnackBar.propTypes = {
  copySuccessMessage: PropTypes.string,
  handleClose: PropTypes.func,
};

SnackBar.defaultProps = {
  copySuccessMessage: 'Link Copied',
  handleClose: () => { },
};
