import styled from 'styled-components';

export const FixedContainer = styled.div`
  padding: ${(props) => (props.hideGutters ? 0 : '0 20px')};
  background-color: ${(props) => props.theme.backgroundColor.white};
  margin-bottom: ${(props) => (props.footer ? 0 : '20px')};
  width: ${(props) => (props.isShowPoster ? '55%' : '100%')};
  margin-left: ${(props) => (props.isShowPoster ? '45%' : '0')};
  @media (max-width: ${(props) => (props.theme.breakpoints.mobileLandscape)}) {
    && {
      width: 100%;
      margin-left: 0;
    }
  }
  @media (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    && {
      padding: 0;
    }
  }
`;

export const Layout = styled.div`
  display: flex;
`;
