import React from 'react';

import { LegalNoticeWrapper } from './style';

export default function LegalNotice() {
  return (
    <>
      <LegalNoticeWrapper variant='body1'>
        Click to
        {' '}
        <a
          className='ot-sdk-show-settings'
          id='ot-sdk-btn'
          rel='noreferrer'
          href='#'
        >
          Manage Cookies
        </a>
      </LegalNoticeWrapper>
    </>
  );
}
