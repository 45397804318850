import React from 'react';
import PropTypes from 'prop-types';

import {
  Border,
  Circle,
  ContentWrapper,
  Description,
  Image,
  ShortDescription,
  Title,
  Wrapper,
} from './style';

/// [ReferralSteps] renders UI for steps to complete referral
const ReferralSteps = ({ title, stepsToAchieve }) => (
  <>
    <Title>{title}</Title>
    {stepsToAchieve?.map((steps, index) => (
      <>
        <Wrapper>
          <>
            {steps?.icon?.url != null ? (
              <Image src={steps?.icon?.url} />
            ) : (
              <Circle>
                <p>{index + 1}</p>
              </Circle>
            )}
          </>
          <ContentWrapper>
            <Description>{steps?.name}</Description>
            <ShortDescription>{steps?.description}</ShortDescription>
          </ContentWrapper>
        </Wrapper>
        <Border isConnector={stepsToAchieve?.length - 1 !== index} />
      </>
    ))}
  </>
);

ReferralSteps.propTypes = {
  title: PropTypes.string,
  stepsToAchieve: PropTypes.arrayOf(PropTypes.string),
};

ReferralSteps.defaultProps = {
  title: '',
  stepsToAchieve: [],
};

export default ReferralSteps;
