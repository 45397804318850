import styled from 'styled-components';

export const Steps = styled.div`
  display: flex;
  align-items: center;
  ${({ status }) => status && `
   margin-top: -3px;
  `}
`;

export const StatusIcon = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  cursor: ${(props) => ((props.status === 'submitted' || props.status === 'rejected') ? 'pointer' : 'text')};
`;

export const Indicator = styled.div`
  background-color: ${(props) => props.indicatorcolor};
  width: calc(${(props) => props.width}/${(props) => (props.totalSteps - 1)});
  height: 4px;
`;
