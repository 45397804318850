import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { getAboutMissions } from 'services/Missions/missions-service';

const AboutMissionsContext = createContext();

export const useAboutMissions = () => useContext(AboutMissionsContext);

export const AboutMissionsProvider = ({ children }) => {
  const [aboutMissionsLoader, setAboutMissionsLoader] = useState(true);

  const [aboutMissionsData, setAboutMissionsData] = useState([]);

  // `fetchAboutMissions` service call to fetch about missions data
  const fetchAboutMissions = async () => {
    getAboutMissions().then((data) => {
      setAboutMissionsData(data);
      setAboutMissionsLoader(false);
    });
  };

  const value = {
    aboutMissionsLoader,
    aboutMissionsData,
    fetchAboutMissions,
  };

  useEffect(() => {
    // fetch about missions data
    fetchAboutMissions();
  }, []);
  return (
    <AboutMissionsContext.Provider value={value}>
      {children}
    </AboutMissionsContext.Provider>
  );
};

AboutMissionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
