import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.titanWhite};
  opacity: 0.75;
  width: 45%;
  height: 100%;
  position: fixed;
  float:left;
  padding: 30px 0;
  @media (max-width: ${(props) => (props.theme.breakpoints.mobileLandscape)}) {
    && {
      display: none;
    }
  }
  @media (max-width: ${(props) => (props.theme.breakpoints.mobileLandscape)}) {
    && {
      display: none;
    }
  }
`;

export const Title = styled(Typography)`
  &&{
    font-weight: 300;
    color:${(props) => props.theme.fontColor.carbonBlack};
    line-height: 35px;
   }
`;

export const TitleWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`;

export const LogoWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  width: 82%;
  margin: 0 auto;
`;
