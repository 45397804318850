import styled from 'styled-components';
import { Container, Typography, Button } from '@material-ui/core';

export const LogoutContainer = styled(Container)`
  && {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const LogoutText = styled(Typography)`
  && {
    font-weight: 400;
    color: ${(props) => props.theme.fontColor.black};
    font-size: ${(props) => props.theme.fontSize.description};
  }
`;

export const LogoutButton = styled(Button)`
  && {
    padding-right: 0;
    text-transform: capitalize;
    font-size: ${(props) => props.theme.fontSize.description};
  }
`;
