import styled from 'styled-components';

import { Typography } from '@material-ui/core';

import PrimaryButton from 'components/common/Button/style';

export const BlogWrapper = styled.img`
  && {
    width: 100%;
    border-bottom-left-radius: 50% 12%;
    border-bottom-right-radius: 50% 12%;
    clip-path: ellipse(100% 100% at top);
    height: 460px;
  }
`;

export const MissionBlogWrapper = styled.div`
  margin: 29px 43px 0px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    margin: 31px 26px 0px;
  }
`;

export const MissionTitleWrapper = styled.div`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

export const MissionTitle = styled(Typography)`
  && {
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 30px;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      font-size: ${(props) => props.theme.fontSize.medium};
      line-height: 24px;
    }
  }
`;

export const SecondaryButtonWrapper = styled.div`
  margin: 0 40px;
  ${PrimaryButton} {
    background-color: ${(props) => (props.theme.backgroundColor.white)} !important;
    border: 1px solid ${(props) => (props.theme.border.dodgerBlue)};
    color: ${(props) => (props.theme.border.dodgerBlue)};
    font-weight: ${(props) => (props.theme.fontWeight.medium)};
  }
  @media (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    margin: 0 22px;
  }
`;

export const BlogSectionWrapper = styled.div`
  padding: 29px 43px 0px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
    padding: 0;
  }
`;

export const BlogContent = styled(Typography)`
  p{
    font-weight: ${(props) => (props.theme.fontWeight.normal)};
    margin-bottom: 18px;
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 21px;
    letter-spacing: -0.48px;
    overflow-wrap: break-word;
  }
  h2{
    font-weight: ${(props) => (props.theme.fontWeight.medium)};
    margin-bottom: 10px;
    font-size: ${(props) => props.theme.fontSize.smMedium};
    line-height: 21px;
    letter-spacing: -0.48px;
    overflow-wrap: break-word;
    @media (max-width: ${(props) => props.theme.breakpoints.mobileLandscape}) {
      font-size: ${(props) => props.theme.fontSize.secondary};
    }
  }
  ul{
    margin-top: 0;
    margin-bottom: 1rem;
    list-style-type: disc;
  }
  li{
    display: list-item;
    font-weight: ${(props) => (props.theme.fontWeight.normal)};
    font-size: ${(props) => props.theme.fontSize.primary};
    line-height: 21px;
    letter-spacing: -0.48px;
    overflow-wrap: break-word;
  }
`;
