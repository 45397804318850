import styled from 'styled-components';
import PrimaryButton from '../../../../common/Button/style';

export const ButtonWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    && {
      padding: 5px 22px;
    }
  }
  padding: 5px 40px;
  text-align: center;
  position: sticky;
  bottom: 5px;
`;

export const MissionsWrapper = styled.div`
  padding: 20px;
`;

export const SecondaryButtonWrapper = styled.div`
  margin: 0 40px;
  ${PrimaryButton} {
    background-color: ${(props) => (props.theme.backgroundColor.white)} !important;
    border: 1px solid ${(props) => (props.theme.border.dodgerBlue)};
    color: ${(props) => (props.theme.border.dodgerBlue)};
    font-weight: ${(props) => (props.theme.fontWeight.medium)};
  }
  @media (max-width: ${(props) => (props.theme.breakpoints.tablet)}) {
    margin: 0 22px;
  }
`;
